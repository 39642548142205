/**
 * default expires
 */
const days = 365
const hour = 24
const ms = 1000
const m = 60
const s = 60
const cookiesExpires = new Date(Number(new Date()) + days * ms * s * m * hour)

/**
 * default options
 */
const secure = process.env.NODE_ENV !== 'development' // secure equal true on production
const options = {
  httpOnly: true,
  secure,
  path: '/',
  expires: cookiesExpires
}

/**
 * popularQuoteCheck options
*/
const Dt = new Date()
const popularCookieHalfDayExpire = new Date(Number(Dt.setHours(Dt.getHours() + 12)))
const popularHalfDayOption = {
  httpOnly: true,
  secure,
  path: '/',
  expires: popularCookieHalfDayExpire
}
const popularLastCountOption = {
  httpOnly: true,
  secure,
  path: '/',
  expires: new Date(Number(Dt.setFullYear(Dt.getFullYear() + 1)))
}

export {
  cookiesExpires,
  options,
  popularCookieHalfDayExpire,
  popularHalfDayOption,
  popularLastCountOption
}
