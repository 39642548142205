// import dayjs from 'dayjs'
import marketReport from '@/store/research/marketreport'

export const modules = {
  marketReport
}

/**
 * State
 */
const state = () => ({
  payload: {
    keyword: null,
    type: null,
    startDate: null,
    endDate: null,
    writer: null
  }
})

/**
 * Mutations
 */
const mutations = {
  setPayload (state, data) {
    state.payload = data
  },
  setSearchResultItem (state, data) {
    state.searchResultItem = data
  }
}
/**
 * Actions
 */
const actions = {
  setPayload ({ commit }, data) {
    commit('setPayload', data)
  }
}
/**
 * Getters
 */
const getters = {
  getPayload: (state) => {
    return state.payload
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules
}
