import cloneDeep from 'lodash/cloneDeep'
import regulatoryStatistic from '@/store/rulebooks/regulatory-statistic'

export const modules = {
  regulatoryStatistic
}

// function filterCate (arr) {
//   return arr.filter(item => !(item.totalItems === 0 && item.totalChilds === 0)).map((item) => {
//     item = Object.assign({}, item)
//     if (item.childs) {
//       item.childs = filterCate(item.childs)
//     }
//     return item
//   })
// }

/**
 * State
 */
const state = () => ({
  category: null,
  regulation: null,
  ruleBookType: 'Regulation', // Regulation, CircularLetter and Form
  itemByHash: null,
  parentLevel: null,
  searchResult: null,
  paramSearch: {},
  rbLoading: false,
  cateLoading: false,
  searchResultLoading: false
})

/**
 * Mutations
 */
const mutations = {
  setCategory (state, data) {
    state.category = data
  },
  setRegulation (state, data) {
    state.regulation = data
  },
  setRuleBookType (state, data) {
    state.ruleBookType = data
  },
  setItemByHash (state, data) {
    state.itemByHash = data
  },
  setParentLevel (state, data) {
    state.parentLevel = data
  },
  setSearchResult (state, data) {
    state.searchResult = data
  },
  setParamSearch (state, data) {
    state.paramSearch = data
  },
  setRbLoading (state, data) {
    state.rbLoading = data
  },
  setCateLoading (state, data) {
    state.cateLoading = data
  },
  setSearchResultLoading (state, data) {
    state.searchResultLoading = data
  }
}
/**
 * Actions
 */
const actions = {
  setRuleBookType ({ commit }, data) {
    commit('setRuleBookType', data)
  },
  setItemByHash ({ commit }, data) {
    commit('setItemByHash', data)
  },
  setParentLevel ({ commit }, data) {
    commit('setParentLevel', data)
  },
  setParamSearch ({ commit }, data) {
    commit('setParamSearch', data)
  },
  async getCategory ({ commit, state }, payload) {
    const apiPath = '/api/cms/v1/rulebooks/category'
    const {
      language,
      cateCode,
      cateGroup,
      ruleBookType,
      maxLv
    } = payload
    const options = {
      params: {
        language,
        cateCode,
        cateGroup,
        ruleBookType,
        maxLv
      }
    }
    try {
      commit('setCateLoading', false)
      commit('setCategory', null)
      await this.$apiCms
        .get(apiPath, options)
        .then((res) => {
          // const temp = []
          // cloneDeep(res.data).forEach((item, index) => {
          //   if (item && item.childs && item.childs.length) {
          //     const resCateFilter = filterCate(item.childs)
          //     temp.push({ ...item, childs: resCateFilter })
          //   }
          // })
          commit('setCategory', cloneDeep(res.data))
          commit('setCateLoading', true)
        })
        .catch(error => error)
    } catch (ex) {
      commit('setCateLoading', true)
    }
  },
  async getSearchResult ({ commit, state }) {
    commit('setSearchResultLoading', true)
    const apiPath = '/api/cms/v1/rulebooks/search'
    const options = {
      params: state.paramSearch
    }
    await this.$apiCms
      .get(apiPath, options)
      .then((res) => {
        commit('setSearchResult', res.data)
      })
      .catch(error => error)
    commit('setSearchResultLoading', false)
  },
  async getRegulation ({ commit, dispatch, state }, payload) {
    let apiPath = '/api/cms/v1/rulebooks/regulation'
    if (state.ruleBookType === 'Regulation') {
      apiPath = '/api/cms/v1/rulebooks/regulation'
    } else if (state.ruleBookType === 'CircularLetter') {
      apiPath = '/api/cms/v1/rulebooks/CircularLetter'
    } else if (state.ruleBookType === 'Form') {
      apiPath = '/api/cms/v1/rulebooks/Form'
    }
    const { language, cateUuid } = payload
    const options = {
      params: {
        language,
        cateUuid
      }
    }
    // dispatch('setActiveCate', cateUuid)
    try {
      commit('setRbLoading', false)
      commit('setRegulation', null)
      await this.$apiCms
        .get(apiPath, options)
        .then((res) => {
          commit('setRegulation', res.data)
          commit('setRbLoading', true)
        })
        .catch(error => error)
    } catch (ex) {
      commit('setRbLoading', true)
    }
  },
  setCollapseCate ({ commit }, id) {
    const listActive = document.querySelectorAll('.rulebooks-category .nav-item .active')
    if (listActive) {
      listActive.forEach((item, index) => {
        item.classList.remove('active')
      })
    }
    setTimeout(() => {
      const { Collapse } = require('bootstrap')
      const element = document.getElementById(`collapse${id}`)
      const instantTmp = Collapse.getInstance(element)
      if (instantTmp) {
        instantTmp.show()
      } else {
        const ele = document.querySelector(`[data-item-id="${id}"]`)
        if (ele) {
          ele.classList.add('active')
        } else {
          // ele.classList.remove('active')
        }
      }
    }, 500)
  },
  setCollapse ({ commit }, id) {
    setTimeout(() => {
      const { Collapse } = require('bootstrap')
      const element = document.getElementById(`collapse-${id}-rules-books-item`)
      const instantTmp = Collapse.getInstance(element)
      if (instantTmp) {
        instantTmp.show()
      }
    }, 500)
  }
}

/**
 * Getters
 */
const getters = {
  getCategoryData: (state) => {
    return state.category || []
  },
  getRegulationData: (state) => {
    return state.regulation || []
  },
  getRuleBookTypeData: (state) => {
    return state.ruleBookType || ''
  },
  getItemByHash: (state) => {
    return state.itemByHash
  },
  getParentLevel: (state) => {
    return state.parentLevel
  },
  getSearchResult: (state) => {
    return state.searchResult
  },
  getParamSearch: (state) => {
    return state.paramSearch
  },
  getRbLoading: (state) => {
    return state.rbLoading
  },
  getCateLoading: (state) => {
    return state.cateLoading
  },
  getSearchResultLoading: (state) => {
    return state.searchResultLoading
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules
}
