import localeTH from 'dayjs/locale/th'
import customdayjs from '@/plugins/dayjs'
// const buddhistEra = require('dayjs/plugin/buddhistEra')
// customdayjs.extend(buddhistEra)

const dayjs = (date) => {
  if (date) {
    const timePattern = /\d{2}:\d{2}(:\d{2})?/
    const hasTime = timePattern.test(date)
    const inputDate = hasTime
      ? new Date(date)
      : new Date(date).toLocaleString('en-EN', {
        dateStyle: 'short',
        timeStyle: undefined
      })
    const mainUtcDate = inputDate.toLocaleString('en-EN', {
      timeZone: 'Asia/Bangkok',
      dateStyle: 'short',
      timeStyle: 'medium'
    })
    return customdayjs(mainUtcDate)
  } else {
    return customdayjs(date).tz()
  }
}
/**
 * GET month of year
 * @param {*} date
 * @param {*} locale
 * @returns string month of year
 */
const monthOfYear = (date, locale = 'th') => {
  if (!date) { return '' }
  customdayjs.locale(locale)
  const dateByDayjs = dayjs(date)
  return dateByDayjs.format('M')
}
/**
 * GET day of week
 * @param {*} date
 * @param {*} locale
 * @returns string day of week
 */
const dayOfMonth = (date, locale = 'th') => {
  if (!date) { return '' }
  customdayjs.locale(locale)
  const dateByDayjs = dayjs(date)
  return dateByDayjs.format('D')
}
/**
 * Date short format
 * @param {String} date // YYYY-MM-DD
 * @param {String} locale // th, en, ...
 * @returns string DD/MM/YYYY
 */
const dateFormat = (date, locale = 'th') => {
  if (!date) { return '' }

  customdayjs.locale(locale)
  const dateByDayjs = dayjs(date)
  const dateFormat = []
  dateFormat.push(dateByDayjs.format('DD'))
  dateFormat.push(dateByDayjs.format('MM'))
  if (locale === 'th') {
    dateFormat.push(parseInt(dateByDayjs.format('YYYY')) + 543)
  } else {
    dateFormat.push(dateByDayjs.format('YYYY'))
  }
  return dateFormat.join('/')
}
/**
 * Date long format
 * @param {String} date // YYYY-MM-DD
 * @param {String} locale // th, en, ...
 * @returns string D MMMM YYYY
 */
const dateLongFormat = (date, locale = 'th') => {
  if (!date) { return '' }

  customdayjs.locale(locale)
  const dateByDayjs = dayjs(date)
  const dateFormat = []
  dateFormat.push(dateByDayjs.format('D'))
  dateFormat.push(dateByDayjs.format('MMMM'))
  if (locale === 'th') {
    dateFormat.push(parseInt(dateByDayjs.format('YYYY')) + 543)
  } else {
    dateFormat.push(dateByDayjs.format('YYYY'))
  }
  return dateFormat.join(' ')
}
/**
 * Date thai format
 * @param {String} date // YYYY-MM-DD
 * @returns string ex 1 ก.8. 2564
 */
const thaiShortFormat = (inputdate) => {
  if (!inputdate) { return '' }
  const dateTime = dayjs(inputdate).$d
  const date = dateTime.toLocaleDateString('th-TH', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  })
  return date
}

// 21 ก.ย. 2564
const formatDateShort = (date, locale = 'th') => {
  if (date && dayjs(date).isValid()) {
    return locale === 'th' ? dayjs(date).locale(locale).format('DD MMM BBBB') : dayjs(date).locale(locale).format('DD MMM YYYY')
  } else if (date === null || date === undefined || date === '') {
    return '-'
  }
  return date
}

// 21 ก.ย. 2564 18:12
const formatDateTimeShort = (date, locale = 'th') => {
  if (date && dayjs(date).isValid()) {
    return locale === 'th'
      ? dayjs(date)
        .locale(locale)
        .format('DD MMM BBBB HH:mm')
      : dayjs(date)
        .locale(locale)
        .format('DD MMM YYYY HH:mm')
  } else if (date === null || date === undefined || date === '') {
    return '-'
  }

  return date
}
// 18:12:56
const formatFullTime = (date, locale = 'th') => {
  if (date && dayjs(date).isValid()) {
    return dayjs(date)
      .locale(locale)
      .format('HH:mm:ss')
  } else if (date === null || date === undefined || date === '') {
    return '-'
  }

  return date
}

// 21 ก.ย. 2564 18:12:56
const formatDateTimeLong = (date, locale = 'th') => {
  if (date && dayjs(date).isValid()) {
    return locale === 'th'
      ? dayjs(date)
        .locale(locale)
        .format('DD MMM BBBB HH:mm:ss')
      : dayjs(date)
        .locale(locale)
        .format('DD MMM YYYY HH:mm:ss')
  } else if (date === null || date === undefined || date === '') {
    return '-'
  }

  return date
}
// 21 ก.ย.
const formatDateMonth = (date, locale = 'th') => {
  // const monthsthmini = ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.']
  if (date && dayjs(date).isValid()) {
    return dayjs(date).locale(locale).format('DD MMM')
  } else if (date === null || date === undefined || date === '') {
    return '-'
  } else if (date && !dayjs(date).isValid()) {
    const year = dayjs().year()
    const dm = date.split('/')
    // const monthIndex = parseInt(dm[1]) - 1
    return dayjs(`${year}/${dm[1]}/${dm[0]}`).locale(locale).format('DD MMM')// `${dm[0]} ${monthsthmini[parseInt(monthIndex)]}`
  }
  return date
}

// 2021
const formatYear = (date, locale = 'th') => {
  if (date && dayjs(date).isValid()) {
    return locale === 'th' ? dayjs(date).locale(locale).format('BBBB') : dayjs(date).locale(locale).format('YYYY')
  } else if (date === null || date === undefined || date === '') {
    return '-'
  }
  return date
}

const yearFormat = (year, locale = 'th') => {
  return locale === 'th' ? parseInt(year) + 543 : year
}

// 2564
const formatYearByLang = (date, locale = 'th') => {
  if (locale === 'th') {
    date += 543
  }
  return date.toString()
}

// ธ.ค. 2564
const formatShortMonthYear = (date, locale = 'th') => {
  if (date && dayjs(date).isValid()) {
    return locale === 'th' ? dayjs(date).locale(locale).format('MMM BBBB') : dayjs(date).locale(locale).format('MMM YYYY')
  } else if (date === null || date === undefined || date === '') {
    return '-'
  }
  return date
}

// ธ.ค. 64
const formatShortMonthShortYear = (date, locale = 'th') => {
  if (date && dayjs(date).isValid()) {
    return locale === 'th' ? dayjs(date).locale(locale).format('MMM BB') : dayjs(date).locale(locale).format('MMM YY')
  } else if (date === null || date === undefined || date === '') {
    return '-'
  }
  return date
}
// 14 ธ.ค. 64
const formatDateShortMonthShortYear = (date, locale = 'th') => {
  if (date && dayjs(date).isValid()) {
    return locale === 'th' ? dayjs(date).locale(locale).format('DD MMM BB') : dayjs(date).locale(locale).format('DD MMM YY')
  } else if (date === null || date === undefined || date === '') {
    return '-'
  }
  return date
}

// ธันวาคม 2564
const formatFullMonthYear = (date, locale = 'th') => {
  if (date && dayjs(date).isValid()) {
    return locale === 'th' ? dayjs(date).locale(locale).format('MMMM BBBB') : dayjs(date).locale(locale).format('MMMM YYYY')
  } else if (date === null || date === undefined || date === '') {
    return '-'
  }
  return date
}

// 18:12
const formatTimeShort = (date, locale = 'th') => {
  if (date && dayjs(date).isValid()) {
    return dayjs(date)
      .locale(locale)
      .format('HH:mm')
  } else if (date === null || date === undefined || date === '') {
    return '-'
  }

  return date
}
// return 2021-31-12
const formatDateDash = (date, locale = 'th') => {
  if (!date) { return '' }

  customdayjs.locale(locale)
  const dateByDayjs = dayjs(date)
  const dateFormat = []
  dateFormat.push(dateByDayjs.format('YYYY'))
  dateFormat.push(dateByDayjs.format('MM'))
  dateFormat.push(dateByDayjs.format('DD'))
  return dateFormat.join('-')
}
const checkDateEventNoZero = (startDate, endDate) => {
  const isSameDate = dayjs(startDate).isSame(dayjs(endDate), 'day')
  return isSameDate
    ? dayjs(startDate).date()
    : dayjs(startDate).date() + '-' + dayjs(endDate).date()
}
const checkMonthYearEvent = (startDate, endDate) => {
  const isSameMonth = dayjs(startDate).isSame(dayjs(endDate), 'month')
  const isSameYear = dayjs(startDate).isSame(dayjs(endDate), 'year')
  if (isSameYear) {
    if (isSameMonth) {
      return localeTH.monthsShort[dayjs(startDate).month()] +
      ' ' +
      dayjs(endDate).add(543, 'year').format('YY')
    } else {
      return localeTH.monthsShort[dayjs(startDate).month()] +
      ' - ' + localeTH.monthsShort[dayjs(endDate).month()] +
      ' ' +
      dayjs(endDate).add(543, 'year').format('YY')
    }
  } else {
    return localeTH.monthsShort[dayjs(startDate).month()] +
      ' ' +
      dayjs(endDate).add(543, 'year').format('YY') +
      ' - ' +
      localeTH.monthsShort[dayjs(endDate).month()] +
      ' ' +
      dayjs(endDate).add(543, 'year').format('YY')
  }
}
const checkOfferingPeriodsIPO = (startDate, endDate, locale = 'th') => {
  const isSameMonth = dayjs(startDate).isSame(dayjs(endDate), 'month')
  const isSameYear = dayjs(startDate).isSame(dayjs(endDate), 'year')
  if (locale === 'th') {
    if (isSameYear) {
      if (isSameMonth) {
        return checkDateEventNoZero(startDate, endDate) + ' ' + localeTH.monthsShort[dayjs(startDate).month()] +
      ' ' +
      dayjs(endDate).add(543, 'year').format('YYYY')
      } else {
        return dayjs(startDate).date() + ' ' + localeTH.monthsShort[dayjs(startDate).month()] +
      (endDate === null ? '' : ' - ' + dayjs(endDate).date() + ' ' + localeTH.monthsShort[dayjs(endDate).month()] + ' ' + dayjs(endDate).add(543, 'year').format('YYYY'))
      }
    } else {
      return dayjs(startDate).date() + ' ' + localeTH.monthsShort[dayjs(startDate).month()] +
      ' ' +
      dayjs(startDate).add(543, 'year').format('YYYY') +
       (endDate === null ? '' : ' - ' + dayjs(endDate).date() + ' ' + localeTH.monthsShort[dayjs(endDate).month()] + dayjs(endDate).add(543, 'year').format('YYYY'))
    }
  } else if (isSameYear) {
    if (isSameMonth) {
      return checkDateEventNoZero(startDate, endDate) + ' ' + dayjs(startDate).locale(locale).format('MMM') +
      ' ' +
      dayjs(endDate).add(0, 'year').format('YYYY')
    } else {
      return dayjs(startDate).date() + ' ' + dayjs(startDate).locale(locale).format('MMM') +
      (endDate === null ? '' : ' - ' + dayjs(endDate).date() + ' ' + dayjs(endDate).locale(locale).format('MMM') + ' ' + dayjs(endDate).add(0, 'year').format('YYYY'))
    }
  } else {
    return dayjs(startDate).date() + ' ' + dayjs(startDate).locale(locale).format('MMM') +
      ' ' +
      dayjs(startDate).add(0, 'year').format('YYYY') +
       (endDate === null ? '' : ' - ' + dayjs(endDate).date() + ' ' + dayjs(endDate).locale(locale).format('MMM') + dayjs(endDate).add(0, 'year').format('YYYY'))
  }
}

const checkDateTimeEvent = (startDate, endDate) => {
  // const localizedFormat = require('dayjs/plugin/localizedFormat')
  // customdayjs.extend(localizedFormat)
  const isSameDate = dayjs(startDate).isSame(dayjs(endDate), 'day')
  const isSameMonth = dayjs(startDate).isSame(dayjs(endDate), 'month')
  const isSameYear = dayjs(startDate).isSame(dayjs(endDate), 'year')

  return isSameMonth && isSameYear && isSameDate
    ? dayjs(startDate).format('DD') +
        ' ' +
        localeTH.monthsShort[dayjs(startDate).month()] +
        ' ' +
        dayjs(startDate).add(543, 'year').format('YY') +
        ' เวลา ' +
        dayjs(startDate).format('H:mm') +
        '-' +
        dayjs(endDate).format('H:mm') +
        ' น.'
    : dayjs(startDate).format('DD') +
        ' ' +
        localeTH.monthsShort[dayjs(startDate).month()] +
        ' ' +
        dayjs(startDate).add(543, 'year').format('YY') +
        ' - ' +
        dayjs(endDate).format('DD') +
        ' ' +
        localeTH.monthsShort[dayjs(endDate).month()] +
        ' ' +
        dayjs(endDate).add(543, 'year').format('YY') +
        ' เวลา ' +
        dayjs(startDate).format('H:mm') +
        '-' +
        dayjs(endDate).format('H:mm') +
        ' น.'
}

// return day name
const dayName = (date, locale = 'th') => {
  if (!date) { return '' }

  customdayjs.locale(locale)
  const dateByDayjs = dayjs(date)
  return dateByDayjs.format('dddd')
}
const setMonthOnly = (monthNumber) => {
  switch (monthNumber) {
    case 1:
      return 'ม.ค.'
    case 2:
      return 'ก.พ.'
    case 3:
      return 'มี.ค.'
    case 4:
      return 'เม.ย.'
    case 5:
      return 'พ.ค.'
    case 6:
      return 'มิ.ย.'
    case 7:
      return 'ก.ค.'
    case 8:
      return 'ส.ค.'
    case 9:
      return 'ก.ย.'
    case 10:
      return 'ต.ค.'
    case 11:
      return 'พ.ย.'
    case 12:
      return 'ธ.ค.'
  }
}
const setMonthLongOnly = (monthNumber, locale = 'th') => {
  if (locale === 'th') {
    switch (monthNumber) {
      case 1:
        return 'มกราคม'
      case 2:
        return 'กุมภาพันธ์'
      case 3:
        return 'มีนาคม'
      case 4:
        return 'เมษายน'
      case 5:
        return 'พฤษภาคม'
      case 6:
        return 'มิถุนายน'
      case 7:
        return 'กรกฎาคม'
      case 8:
        return 'สิงหาคม'
      case 9:
        return 'กันยายน'
      case 10:
        return 'ตุลาคม'
      case 11:
        return 'พฤศจิกายน'
      case 12:
        return 'ธันวาคม'
    }
  } else {
    switch (monthNumber) {
      case 1:
        return 'January'
      case 2:
        return 'February'
      case 3:
        return 'March'
      case 4:
        return 'April'
      case 5:
        return 'May'
      case 6:
        return 'June'
      case 7:
        return 'July'
      case 8:
        return 'August'
      case 9:
        return 'September'
      case 10:
        return 'October'
      case 11:
        return 'November'
      case 12:
        return 'December'
    }
  }
}
const setDayName = (date, locale = 'th') => {
  const daysEN = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const daysTH = ['วันอาทิตย์', 'วันจันทร์', 'วันอังคาร', 'วันพุธ', 'วันพฤหัสบดี', 'วันศุกร์', 'วันเสาร์']
  const d = dayjs(date).$d
  const dayName = locale === 'th' ? daysTH[d.getDay()] : daysEN[d.getDay()]
  return dayName
}
const formatTimeLongWithSpace = (value, locale = 'th') => {
  if (value && dayjs(value).isValid()) {
    return dayjs(value)
      .locale(locale)
      .format('HH : mm : ss')
  } else if (value === null || value === undefined || value === '') {
    return '-'
  }

  return value
}
const convertToThaiDate = (date) => {
  const inputDate = new Date(date)
  const mainUtcDate = inputDate.toLocaleString('en-EN', {
    timeZone: 'Asia/Bangkok',
    dateStyle: 'short'
  })
  return mainUtcDate
}
export {
  dayjs,
  dayName,
  dateFormat,
  dateLongFormat,
  thaiShortFormat,
  formatDateShort,
  formatDateTimeShort,
  formatFullTime,
  formatDateTimeLong,
  formatDateMonth,
  formatYear,
  yearFormat,
  checkDateEventNoZero,
  checkMonthYearEvent,
  checkDateTimeEvent,
  formatShortMonthYear,
  formatFullMonthYear,
  formatTimeShort,
  formatDateDash,
  formatShortMonthShortYear,
  formatYearByLang,
  checkOfferingPeriodsIPO,
  formatDateShortMonthShortYear,
  setMonthOnly,
  setMonthLongOnly,
  setDayName,
  monthOfYear,
  dayOfMonth,
  formatTimeLongWithSpace,
  convertToThaiDate
}
