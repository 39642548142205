//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FooterFactsheet'
}
