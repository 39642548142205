/**
 * Get convertToPriceFormat
 * @param {Int} number
 * @returns string 1344120000.123455 --> 1,344,120,000.12
 */
const convertToPriceFormat = (number) => {
  let result = 0
  if (number) {
    result = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }
  return result
}
/**
 * Get convertToMillionFormat
 * @param {Int} number
 * @returns string 1344120000 --> 1,344.12
 */
// const convertToMillionFormat = (number) => {
//   let result = 0
//   if (number) {
//     if (number.toFixed(0).length > 10) {
//       result = number / 1000000
//     } else if (number.toFixed(0).length >= 7) {
//       result = number / 1000
//     } else {
//       result = number
//     }
//     result = convertToPriceFormat(result)
//   }
//   return result
// }
const convertToMillionFormat = (number, isFormatNumber = true) => {
  if (typeof number === 'number') {
    let result = 0
    result = number / 1000000.00
    result = isFormatNumber ? convertToPriceFormat(result) : result
    return result
  } else if (number === null || number === undefined || number === '') {
    return '-'
  } else {
    return number
  }
}

const millionFormat = (number) => {
  if (typeof number === 'number') {
    let result = 0
    result = number / 1000000
    result = convertToPriceFormat(result)
    return result
  } else if (number === null || number === undefined || number === '') {
    return '-'
  } else {
    return number
  }
}
const millionFormatInt = (number) => {
  if (typeof number === 'number') {
    let result = 0
    result = number / 1000000
    result = formatNumber(result, 0)
    return result
  } else if (number === null || number === undefined || number === '') {
    return '-'
  } else {
    return number
  }
}
const dividerThousand = (number, dividerThousand = 1) => {
  if (typeof number === 'number') {
    let result = 0
    result = number / dividerThousand
    result = convertToPriceFormat(result)
    return result
  } else if (number === null || number === undefined || number === '') {
    return '-'
  } else {
    return number
  }
}

const convertToMillionChangeFormat = (number) => {
  if (typeof number === 'number') {
    let result = 0
    result = number / 1000000
    result = formatNumberInteger(result)
    return result
  } else if (number === null || number === undefined || number === '') {
    return '-'
  } else {
    return number
  }
}

/**
 * formatNumber
 * @param {*} value
 * @param {Number} digits
 * @param {Boolean} showDash
 * @returns
 */
const formatNumber = (value, digits = 2, showDash = true) => {
  if (typeof value === 'number') {
    return value.toLocaleString(undefined, {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits
    })
  } else if (value === null || value === undefined || value === '') {
    return showDash ? '-' : ''
  } else {
    return value
  }
}
const formatVolume = (value) => {
  if (typeof value === 'number') {
    return value.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
  } else if (value === null || value === undefined || value === '') {
    return '-'
  } else {
    return value
  }
}
const formatNumberInteger = (value, digits = 2) => {
  if (typeof value === 'number') {
    return value > 0
      ? '+' +
          value.toLocaleString(undefined, {
            minimumFractionDigits: digits,
            maximumFractionDigits: digits
          })
      : value.toLocaleString(undefined, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits
      })
  } else if (value === null || value === undefined || value === '') {
    return '-'
  } else {
    return value
  }
}

const formatNumberPercent = (value, digits = 2) => {
  if (typeof value === 'number') {
    return value > 0
      ? '+' +
          value.toLocaleString(undefined, {
            minimumFractionDigits: digits,
            maximumFractionDigits: digits
          }) +
          '%'
      : value.toLocaleString(undefined, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits
      }) + '%'
  } else if (value === null || value === undefined || value === '') {
    return '-'
  } else {
    return value
  }
}

const formatNumberPercentWithOutPrefix = (value, digits = 2) => {
  if (typeof value === 'number') {
    return value > 0
      ? value.toLocaleString(undefined, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits
      }) + '%'
      : value.toLocaleString(undefined, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits
      }) + '%'
  } else if (value === null || value === undefined || value === '') {
    return '-'
  } else {
    return value
  }
}
const formatNumberForWarrantStatus = (value, digits = 2) => {
  if (typeof value === 'number') {
    return value > 0
      ? value.toLocaleString(undefined, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits
      }) + '%'
      : value.toLocaleString(undefined, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits
      }) + '%'
  } else if (value === null || value === undefined || value === '') {
    return ''
  } else {
    return value
  }
}

const validateNumber = (evt) => {
  evt = evt || window.event
  const charCode = evt.which ? evt.which : evt.keyCode
  if (
    (charCode > 31 &&
      (charCode < 48 || charCode > 57) &&
      charCode !== 46 &&
      charCode !== 37) ||
    (evt.target.value.includes('.') && evt.key === '.')
  ) {
    evt.preventDefault()
  } else {
    return true
  }
}

const numberDecimal = (value) => {
  if (typeof value === 'number') {
    return value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 4
    })
  } else if (value === null || value === undefined || value === '') {
    return '-'
  } else {
    return value
  }
}
const formatNumberX = (value, digits = 2) => {
  if (typeof value === 'number') {
    return value.toLocaleString(undefined, {
      minimumFractionDigits: digits,
      maximumFractionDigits: 5
    })
  } else if (value === null || value === undefined || value === '') {
    return '-'
  } else {
    return value
  }
}

/**
 * Remove commas
 * @param {Stirng|Number} number
 * @returns number
 */
const removeCommas = (number) => {
  if (typeof number === 'string') {
    return Number(number.replace(/,/g, ''))
  }
  return number
}

/**
 * SignNumberFormat
 * @param {Stirng|Number} number
 * @returns number
 */
const quoteSignNumberFormat = (number) => {
  let num = number
  let resultNumber = null
  if (typeof num === 'string') {
    const stirngPrice = num && num.toString().split('.')[1]
    if (stirngPrice && stirngPrice.length === 3 && stirngPrice.charAt(2) === '0') {
      resultNumber = num + '0'
      return resultNumber
    } else if (num !== '-') {
      num = parseFloat(number)
    }
  }
  if (typeof num === 'number') {
    const digitsPrice = num && num.toString().split('.')[1]
    resultNumber = null
    if (digitsPrice && (digitsPrice.length === 4 || digitsPrice.length === 2 || digitsPrice.length > 4)) {
      const digits = digitsPrice.length
      resultNumber = formatNumber(num, digits)
    } else if (digitsPrice && (digitsPrice.length === 1 || digitsPrice.length === 3)) {
      const digits = digitsPrice.length + 1
      resultNumber = formatNumber(num, digits)
    } else if (digitsPrice && digitsPrice.length === 0) {
      resultNumber = formatNumber(num, 2)
    } else {
      resultNumber = formatNumber(num)
    }
    return resultNumber
  } else if (num === null || num === undefined || num === '') {
    return '-'
  } else {
    return num
  }
}

const quoteParValueFormat = (number) => {
  let num = number
  let resultNumber = null
  if (typeof num === 'string' && num !== '-') {
    num = parseFloat(number)
  }
  if (typeof num === 'number') {
    const digitsPrice = num && num.toString().split('.')[1]
    resultNumber = null
    if (digitsPrice && (digitsPrice.length === 1)) {
      const digits = digitsPrice.length + 1
      resultNumber = formatNumber(num, digits)
    } else if (digitsPrice && (digitsPrice.length >= 2)) {
      const digits = digitsPrice.length
      resultNumber = formatNumber(num, digits)
    } else {
      resultNumber = formatNumber(num, 2)
    }
    return resultNumber
  } else if (num === null || num === undefined || num === '') {
    return '-'
  } else {
    return num
  }
}
export {
  convertToPriceFormat,
  convertToMillionFormat,
  convertToMillionChangeFormat,
  formatNumber,
  formatNumberInteger,
  formatNumberPercent,
  validateNumber,
  millionFormat,
  dividerThousand,
  formatVolume,
  numberDecimal,
  formatNumberX,
  removeCommas,
  millionFormatInt,
  quoteSignNumberFormat,
  quoteParValueFormat,
  formatNumberPercentWithOutPrefix,
  formatNumberForWarrantStatus
}
