//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Menu',
  props: {
    lastUpdate: {
      type: Date,
      default: null
    },
    menuList: {
      type: Array,
      required: true
    }
  },
  mounted () {
  }
}
