/**
 * State
 */
const state = () => ({
  firstday: null,
  upcoming: null,
  recent: null
})

/**
   * Mutations
   */
const mutations = {
  setHomeIpoFirstday (state, data) {
    state.firstday = data
  },
  setHomeIpoUpcoming (state, data) {
    state.upcoming = data
  },
  setHomeIpoRecent (state, data) {
    state.recent = data
  }
}

/**
   * Actions
   */
const actions = {
  async getHomeIPO ({ dispatch }, payload) {
    await Promise.allSettled([
      dispatch('getHomeIpoFirstday'),
      dispatch('getHomeIpoUpcoming'),
      dispatch('getHomeIpoRecent')
    ])
  },
  async getHomeIpoFirstday ({ commit }, payload) {
    try {
      // const response = await this.$axios.$get(`/api/set/ipo/firstday?date=25/12/2020&lang=${this.$i18n.locale}`)
      const response = await this.$axios.$get(`/api/set/ipo/firstday?lang=${this.$i18n.locale}`)
      commit('setHomeIpoFirstday', response)
    } catch (error) {
      commit('setHomeIpoFirstday', null)
    }
  },
  async getHomeIpoUpcoming ({ commit }, payload) {
    try {
      const response = await this.$axios.$get(`/api/set/ipo/upcoming?limit=6&lang=${this.$i18n.locale}`)
      commit('setHomeIpoUpcoming', response.data)
    } catch (error) {
      commit('setHomeIpoUpcoming', null)
    }
  },
  async getHomeIpoRecent ({ commit }, payload) {
    try {
      const response = await this.$axios.$get(`/api/set/ipo/recently?limit=6&lang=${this.$i18n.locale}`)
      commit('setHomeIpoRecent', response)
    } catch (error) {
      commit('setHomeIpoRecent', null)
    }
  }
}

const getters = {
  getHomeIpoFirstday: (state) => {
    return state.firstday || []
  },
  getHomeIpoUpcoming: (state) => {
    return state.upcoming || []
  },
  getHomeIpoRecent: (state) => {
    return state.recent || []
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
