/**
 * State
 */
const state = () => ({
  profile: null,
  hightLight: null,
  underlying: null,
  tradingSummary: null,
  overview: null,
  ETFList: null,
  issuers: null,
  searchList: null,
  underlyingPerformanceChart: null,
  pricePerformance: null,
  navPerformanceChart: null,
  statisticsMonth: null,
  symbolList: null,
  top5: {
    mostActiveValue: null,
    mostActiveVolume: null,
    topGainer: null,
    topLoser: null,
    popular: null
  },
  top5Chart: {
    mostActiveValue: null,
    mostActiveVolume: null,
    topGainer: null,
    topLoser: null,
    popular: null
  },
  search: {
    underlyingClass: null,
    fee: null,
    totalNav: null,
    YTDPercentChange: null,
    pnav: null
  }
})

/**
 * Mutations
 */
const mutations = {
  setProfile (state, data) {
    state.profile = data
  },

  setHightLight (state, data) {
    state.hightLight = data
  },

  setUnderlying (state, data) {
    state.underlying = data
  },

  setTradingSummary (state, data) {
    state.tradingSummary = data
  },

  setOverview (state, data) {
    state.overview = data
  },

  setETFList (state, data) {
    state.ETFList = data
  },

  setIssuers (state, data) {
    state.issuers = data
  },

  setSearchList (state, data) {
    state.searchList = data
  },

  setUnderlyingPerformanceChart (state, data) {
    state.underlyingPerformanceChart = data
  },

  setNavPerformanceChart (state, data) {
    state.navPerformanceChart = data
  },

  setPricePerformance (state, data) {
    state.pricePerformance = data
  },

  setStatisticsMonth (state, data) {
    state.statisticsMonth = data
  },

  setUnderlyingClass (state, data) {
    state.search.underlyingClass = data
  },

  setFee (state, data) {
    state.search.fee = data
  },

  setTotalNav (state, data) {
    state.search.totalNav = data
  },
  setPNAV (state, data) {
    state.search.pnav = data
  },

  setYTDPercentChange (state, data) {
    state.search.YTDPercentChange = data
  },

  setTop5 (state, top5) {
    const [mostActiveValue, mostActiveVolume, topGainer, topLoser, popular] =
      top5
    state.top5.mostActiveValue = mostActiveValue || null
    state.top5.mostActiveVolume = mostActiveVolume || null
    state.top5.topGainer = topGainer || null
    state.top5.topLoser = topLoser || null
    state.top5.popular = popular || null
  },

  setTop5Chart (state, top5Chart) {
    const [
      mostActiveValueChartList,
      mostActiveVolumeChartList,
      topGainerChartList,
      topLoserChartList,
      popularChartList
    ] = top5Chart
    state.top5Chart.mostActiveValue = mostActiveValueChartList || []
    state.top5Chart.mostActiveVolume = mostActiveVolumeChartList || []
    state.top5Chart.topGainer = topGainerChartList || []
    state.top5Chart.topLoser = topLoserChartList || []
    state.top5Chart.popular = popularChartList || []
  },

  setEtfSymbolList (state, data) {
    if (data) {
      state.symbolList = data.securitySymbols.map((item) => {
        const { symbol } = item
        return {
          text: symbol
        }
      })
    }
  }
}

/**
 * Actions
 */
const actions = {
  async getETFOverview ({ dispatch }, payload) {
    await Promise.allSettled([
      dispatch('getTradingSummary'),
      dispatch('getOverview'),
      dispatch('getETFList'),
      dispatch('getIssuers'),
      dispatch('getTop5'),
      dispatch('getStatisticsMonth', { limit: 3 })
    ])
  },

  async getProfile ({ commit }, payload) {
    const { symbol } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/etf/${symbol}/profile?lang=${this.$i18n.locale}`
      )
      commit('setProfile', response)
    } catch (err) {
      commit('setProfile', null)
    }
  },

  async getHightLight ({ commit }, payload) {
    const { symbol } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/etf/${symbol}/highlight-data?lang=${this.$i18n.locale}`
      )
      commit('setHightLight', response)
    } catch (err) {
      commit('setHightLight', null)
    }
  },

  async getUnderlying ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/etf/underlying-class')
      commit('setUnderlying', response)
    } catch (err) {
      commit('setUnderlying', null)
    }
  },

  async getTradingSummary ({ commit }) {
    try {
      const response = await this.$axios.$get(
        '/api/set/etf/trading-stock-summary'
      )
      commit('setTradingSummary', response)
    } catch (err) {
      commit('setTradingSummary', null)
    }
  },

  async getOverview ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/etf/overview')
      commit('setOverview', response)
    } catch (err) {
      commit('setOverview', null)
    }
  },

  async getETFList ({ commit }) {
    try {
      const response = await this.$axios.$get(
        `/api/set/etf/list?lang=${this.$i18n.locale}`
      )
      commit('setETFList', response)
    } catch (err) {
      commit('setETFList', null)
    }
  },

  async getIssuers ({ commit }) {
    try {
      const response = await this.$axios.$get(
        `/api/set/etf/issuers?lang=${this.$i18n.locale}`
      )
      commit('setIssuers', response)
    } catch (err) {
      commit('setIssuers', null)
    }
  },

  async getSearchList ({ commit }, payload) {
    const {
      symbols,
      underlyingClassIds,
      totalNavRanges,
      feeRanges,
      ytdPercentChanges,
      riskLevels,
      dividendPolicy,
      issuerIds,
      pNavs
    } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/etf/search?symbols=${
          (symbols && symbols.toUpperCase()) || ''
        }&underlyingClassIds=${underlyingClassIds || ''}&totalNavRanges=${
          totalNavRanges || ''
        }&feeRanges=${feeRanges || ''}&ytdPercentChanges=${
          ytdPercentChanges || ''
        }&riskLevels=${riskLevels || ''}&dividendPolicy=${
          dividendPolicy || ''
        }&issuerIds=${issuerIds || ''}&pNavs=${pNavs || ''}&lang=${this.$i18n.locale}`
      )
      commit('setSearchList', response)
    } catch (err) {
      commit('setSearchList', null)
    }
  },

  async getUnderlyingPerformanceChart ({ commit }, payload) {
    const { period } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/etf/1div/chart-underlying-performance?period=${period}`
      )
      if (response && response.quotations) {
        commit('setUnderlyingPerformanceChart', response.quotations)
      }
    } catch (err) {
      commit('setUnderlyingPerformanceChart', null)
    }
  },

  async getNavPerformanceChart ({ commit }, payload) {
    const { period } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/etf/1div/chart-nav-performance?period=${period}`
      )
      if (response && response.quotations) {
        commit('setNavPerformanceChart', response.quotations)
      }
    } catch (err) {
      commit('setNavPerformanceChart', null)
    }
  },

  async getPricePerformance ({ commit }) {
    try {
      const response = await this.$axios.$get(
        '/api/set/etf/1div/price-performance'
      )
      commit('setPricePerformance', response)
    } catch (err) {
      commit('setPricePerformance', null)
    }
  },

  async getStatisticsMonth ({ commit }, payload) {
    const { limit } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/report/monthly/product/ETF/list?limit=${limit}`
      )
      commit('setStatisticsMonth', response)
    } catch (err) {
      commit('setStatisticsMonth', null)
    }
  },

  async getUnderlyingClass ({ commit }) {
    try {
      const response = await this.$axios.$get(
        '/api/set/etf/search/condition/underlying-class'
      )
      commit('setUnderlyingClass', response)
    } catch (err) {
      commit('setUnderlyingClass', null)
    }
  },

  async getFee ({ commit }) {
    try {
      const response = await this.$axios.$get(
        '/api/set/etf/search/condition/fee'
      )
      commit('setFee', response)
    } catch (err) {
      commit('setFee', null)
    }
  },

  async getTotalNav ({ commit }) {
    try {
      const response = await this.$axios.$get(
        '/api/set/etf/search/condition/total-nav'
      )
      commit('setTotalNav', response)
    } catch (err) {
      commit('setTotalNav', null)
    }
  },

  async getYTDPercentChange ({ commit }) {
    try {
      const response = await this.$axios.$get(
        '/api/set/etf/search/condition/ytd-percent-change'
      )
      commit('setYTDPercentChange', response)
    } catch (err) {
      commit('setYTDPercentChange', null)
    }
  },
  async getPNAV ({ commit }) {
    try {
      const response = await this.$axios.$get(
        '/api/set/etf/search/condition/pnav'
      )
      commit('setPNAV', response)
    } catch (err) {
      commit('setPNAV', null)
    }
  },

  async getTop5 ({ commit }) {
    try {
      const mostActiveValueService = this.$axios.$get(
        '/api/set/ranking/mostActiveValue/set/L?count=5'
      )
      const mostActiveVolumeService = this.$axios.$get(
        '/api/set/ranking/mostActiveVolume/set/L?count=5'
      )
      const topGainerService = this.$axios.$get(
        '/api/set/ranking/topGainer/set/L?count=5'
      )
      const topLoserService = this.$axios.$get(
        '/api/set/ranking/topLoser/set/L?count=5'
      )
      const popularService = this.$apiCms.$get(
        '/api/cms/v1/popularquote/getpopular?market=SET&securityType=L&limit=5'
      )
      const [
        {
          value: {
            stocks: mostActiveValue,
            marketDateTime: mostActiveValueDateTime
          }
        },
        {
          value: {
            stocks: mostActiveVolume,
            marketDateTime: mostActiveVolumeDateTime
          }
        },
        {
          value: { stocks: topGainer, marketDateTime: topGainerDateTime }
        },
        {
          value: { stocks: topLoser, marketDateTime: topLoserDateTime }
        },
        {
          value: { stocks: popular, marketDateTime: popularDateTime }
        }
      ] = await Promise.allSettled([
        mostActiveValueService,
        mostActiveVolumeService,
        topGainerService,
        topLoserService,
        popularService
      ])

      const top5 = [
        { data: mostActiveValue, datetime: mostActiveValueDateTime },
        { data: mostActiveVolume, datetime: mostActiveVolumeDateTime },
        { data: topGainer, datetime: topGainerDateTime },
        { data: topLoser, datetime: topLoserDateTime },
        { data: popular, datetime: popularDateTime }
      ]
      commit('setTop5', top5)

      let mostActiveValueChartService = []
      let mostActiveValueChartList = []
      let mostActiveVolumeChartService = []
      let mostActiveVolumeChartList = []
      let topGainerChartService = []
      let topGainerChartList = []
      let topLoserChartService = []
      let topLoserChartList = []
      let popularChartService = []
      let popularChartList = []

      for (const { symbol } of mostActiveValue) {
        mostActiveValueChartService = [
          ...mostActiveValueChartService,
          this.$axios.$get(
            `api/set/stock/${symbol}/chart-quotation?period=1D&accumulated=true`
          )
        ]
      }

      await Promise.allSettled([...mostActiveValueChartService]).then((res) => {
        mostActiveValueChartList = [
          ...mostActiveValueChartList,
          ...res.map(item => item.value)
        ]
      })

      for (const { symbol } of mostActiveVolume) {
        mostActiveVolumeChartService = [
          ...mostActiveVolumeChartService,
          this.$axios.$get(
            `api/set/stock/${symbol}/chart-quotation?period=1D&accumulated=true`
          )
        ]
      }

      await Promise.allSettled([...mostActiveVolumeChartService]).then((res) => {
        mostActiveVolumeChartList = [
          ...mostActiveVolumeChartList,
          ...res.map(item => item.value)
        ]
      })

      for (const { symbol } of topGainer) {
        topGainerChartService = [
          ...topGainerChartService,
          this.$axios.$get(
            `api/set/stock/${symbol}/chart-quotation?period=1D&accumulated=true`
          )
        ]
      }

      await Promise.allSettled([...topGainerChartService]).then((res) => {
        topGainerChartList = [
          ...topGainerChartList,
          ...res.map(item => item.value)
        ]
      })

      for (const { symbol } of topLoser) {
        topLoserChartService = [
          ...topLoserChartService,
          this.$axios.$get(
            `api/set/stock/${symbol}/chart-quotation?period=1D&accumulated=true`
          )
        ]
      }

      await Promise.allSettled([...topLoserChartService]).then((res) => {
        topLoserChartList = [
          ...topLoserChartList,
          ...res.map(item => item.value)
        ]
      })

      for (const { symbol } of popular) {
        popularChartService = [
          ...popularChartService,
          this.$axios.$get(
            `api/set/stock/${symbol}/chart-quotation?period=1D&accumulated=true`
          )
        ]
      }

      await Promise.allSettled([...popularChartService]).then((res) => {
        popularChartList = [...popularChartList, ...res.map(item => item.value)]
      })

      const top5Chart = [
        mostActiveValueChartList,
        mostActiveVolumeChartList,
        topGainerChartList,
        topLoserChartList,
        popularChartList
      ]
      commit('setTop5Chart', top5Chart)
    } catch (err) {
      commit('setTop5', null)
      commit('setTop5Chart', null)
    }
  },
  async getEtfSymbolList ({ commit }) {
    try {
      const response = await this.$axios.$get(
        '/api/set/stock/list?securityType=L'
      )
      commit('setEtfSymbolList', response)
    } catch (err) {
      commit('setEtfSymbolList', null)
    }
  }
}

/**
 * Getters
 */
const getters = {
  getProfile (state) {
    return state.profile
  },

  getEtfSymbolList (state) {
    return state.symbolList
  },

  getHightLight (state) {
    return state.hightLight
  },

  getUnderlying (state) {
    return state.underlying || []
  },

  getTradingSummary (state) {
    return state.tradingSummary
  },

  getOverview (state) {
    return state.overview
  },

  getETFList (state) {
    return state.ETFList || []
  },

  getIssuers (state) {
    return state.issuers || []
  },
  getPNAV (state) {
    return state.search.pnav || []
  },
  getSearchList (state) {
    return state.searchList
  },

  getUnderlyingPerformanceChart (state) {
    return state.underlyingPerformanceChart || []
  },

  getNavPerformanceChart (state) {
    return state.navPerformanceChart || []
  },

  getPricePerformance (state) {
    return state.pricePerformance
  },

  getStatisticsMonth (state) {
    return state.statisticsMonth || []
  },

  getTop5 (state) {
    return state.top5
  },

  getTop5Chart (state) {
    return state.top5Chart
  },

  getUnderlyingClass (state) {
    return state.search.underlyingClass || []
  },

  getFee (state) {
    return state.search.fee || []
  },

  getTotalNav (state) {
    return state.search.totalNav || []
  },

  getYTDPercentChange (state) {
    return state.search.YTDPercentChange || []
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
