//
//
//
//
//
//

export default {
  name: 'MyQuoteRecentlyView',
  props: {
    updateRecentlyViewed: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      cookiesName: 'my-quote',
      swiperOptions: {
        slidesPerView: 'auto',
        showPagination: false,
        showNavigation: false,
        watchOverflow: true,
        resizeObserver: true,
        spaceBetween: 16,
        navigation: {
          prevEl: '.my-quote-recently .swiper-btn-prev',
          nextEl: '.my-quote-recently .swiper-btn-next'
        }
      },
      itemsList: []
    }
  },
  watch: {
    updateRecentlyViewed () {
      this.init()
    },
    $route () {
      this.init()
    }
  },
  mounted () {
    const tabName = localStorage.getItem('isOpenMyQuote')
    if (tabName === 'recentlyView') {
      this.init()
    } else if (tabName === 'Close') {
      this.init() // close tab
    }
  },
  methods: {
    async init () {
      const quoteList = this.$cookies.get(this.cookiesName)
      if (quoteList && quoteList.length > 0) {
        const symbols = quoteList.join(',')
        const url = `api/set/stock-info/list-by-symbols?symbols=${symbols}`
        await this.$axios.get(url).then((res) => {
          if (res && res.data) {
            this.itemsList = res.data
          }
        })
      }
    }
  }
}
