import Vue from 'vue'
import { dayjs } from '@/helpers/dateTime'
import { convertToMillionFormat, formatNumberX, millionFormat, millionFormatInt, quoteSignNumberFormat, formatNumberPercentWithOutPrefix, formatNumberForWarrantStatus } from '~/helpers/number'
import { formatDateShortMonthShortYear, formatShortMonthShortYear } from '~/helpers/dateTime'
import { quoteTypeString } from '~/helpers'
import { setQuater } from '~/helpers/utilities'
// require('dayjs/locale/th')
// const buddhistEra = require('dayjs/plugin/buddhistEra')
// dayjs.extend(buddhistEra)

export default (context, inject) => {
  Vue.filter('dashIfEmpty', (value) => {
    if (value === null || value === undefined || value === '') {
      return '-'
    }

    return value
  })
  // 21 ก.ย. 2564
  Vue.filter('formatDateShort', (value) => {
    if (value && dayjs(value).isValid()) {
      return context.i18n.locale === 'th' ? dayjs(value).locale(context.i18n.locale).format('DD MMM BBBB') : dayjs(value).locale(context.i18n.locale).format('DD MMM YYYY')
    } else if (value === null || value === undefined || value === '') {
      return '-'
    }

    return value
  })
  Vue.filter('formatDateShortMonthShortYear', (value) => {
    return formatDateShortMonthShortYear(value || null)
  })
  // 21 ก.ย. 2564 18:12
  Vue.filter('formatDateTimeShort', (value) => {
    if (value && dayjs(value).isValid()) {
      return context.i18n.locale === 'th'
        ? dayjs(value)
          .locale(context.i18n.locale)
          .format('DD MMM BBBB HH:mm')
        : dayjs(value)
          .locale(context.i18n.locale)
          .format('DD MMM YYYY HH:mm')
    } else if (value === null || value === undefined || value === '') {
      return '-'
    }

    return value
  })
  // 21 ก.ย. 2564 18:12:56
  Vue.filter('formatDateTimeLong', (value) => {
    if (value && dayjs(value).isValid()) {
      return context.i18n.locale === 'th'
        ? dayjs(value)
          .locale(context.i18n.locale)
          .format('DD MMM BBBB HH:mm:ss')
        : dayjs(value)
          .locale(context.i18n.locale)
          .format('DD MMM YYYY HH:mm:ss')
    } else if (value === null || value === undefined || value === '') {
      return '-'
    }

    return value
  })
  // 18:12
  Vue.filter('formatTimeShort', (value) => {
    if (value && dayjs(value).isValid()) {
      return dayjs(value)
        .locale(context.i18n.locale)
        .format('HH:mm')
    } else if (value === null || value === undefined || value === '') {
      return '-'
    }

    return value
  })
  // 18:12:00
  Vue.filter('formatTimeLong', (value) => {
    if (value && dayjs(value).isValid()) {
      return dayjs(value)
        .locale(context.i18n.locale)
        .format('HH:mm:ss')
    } else if (value === null || value === undefined || value === '') {
      return '-'
    }

    return value
  })
  // 18 : 12 : 00
  Vue.filter('formatTimeLongWithSpace', (value) => {
    if (value && dayjs(value).isValid()) {
      return dayjs(value)
        .locale(context.i18n.locale)
        .format('HH : mm : ss')
    } else if (value === null || value === undefined || value === '') {
      return '-'
    }

    return value
  })
  Vue.filter('formatDateMonth', (value) => {
    if (value && dayjs(value).isValid()) {
      return dayjs(value)
        .locale(context.i18n.locale)
        .format('DD MMM')
    } else if (value === null || value === undefined || value === '') {
      return '-'
    }
    return value
  })

  Vue.filter('formatNumber', (value) => {
    if (typeof value === 'number') {
      return value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    } else if (value === null || value === undefined || value === '') {
      return '-'
    } else {
      return value
    }
  })
  Vue.filter('formatVolume', (value) => {
    if (typeof value === 'number') {
      return value.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })
    } else if (value === null || value === undefined || value === '') {
      return '-'
    } else {
      return value
    }
  })

  Vue.filter('formatNumberInteger', (value) => {
    if (typeof value === 'number') {
      return value > 0
        ? '+' +
            value.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
        : value.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
    } else if (value === null || value === undefined || value === '') {
      return '-'
    } else {
      return value
    }
  })

  Vue.filter('formatNumberInt', (value) => {
    if (typeof value === 'number') {
      return value > 0
        ? value.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
        : value.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    } else if (value === null || value === undefined || value === '') {
      return '-'
    } else {
      return value
    }
  })

  Vue.filter('formatNumberPercent', (value) => {
    if (typeof value === 'number') {
      return value > 0
        ? '+' +
            value.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }) +
            '%'
        : value.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }) + '%'
    } else if (value === null || value === undefined || value === '') {
      return '-'
    } else {
      return value
    }
  })

  Vue.filter('formatNumberPercentWithOutPrefix', (value) => {
    return formatNumberPercentWithOutPrefix(value)
  })
  Vue.filter('formatNumberForWarrantStatus', (value) => {
    return formatNumberForWarrantStatus(value)
  })
  // 2021 -> 2564
  Vue.filter('yearFormat', (value) => {
    return context.i18n.locale === 'th' ? parseInt(value) + 543 : value
  })
  Vue.filter('formatShortMonthShortYear', (value) => {
    return formatShortMonthShortYear(value || null)
  })
  Vue.filter('convertToMillionFormat', (value) => {
    return convertToMillionFormat(value || null)
  })
  Vue.filter('millionFormat', (value) => {
    return millionFormat(value || null)
  })
  Vue.filter('millionFormatInt', (value) => {
    return millionFormatInt(value || null)
  })
  // S => หุ้น
  Vue.filter('quoteTypeString', (securityType) => {
    return quoteTypeString(securityType)
  })
  Vue.filter('setQuater', (quater) => {
    return setQuater(quater || null)
  })
  Vue.filter('formatNumberX', (value, digits = 2) => formatNumberX(value, digits))
  Vue.filter('quoteSignNumberFormat', value => quoteSignNumberFormat(value))
}
