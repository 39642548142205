//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
// import { openTab } from '~/helpers'
import { setCategoryType } from '@/helpers/utilities'

export default {
  name: 'ButtonQuote',
  props: {
    newTab: {
      type: Boolean,
      default: false
    },
    hasFavorite: {
      type: Boolean,
      default: true
    },
    btnQuoteData: {
      type: Object,
      default: () => ({
        securities: '',
        symbol: '',
        securityType: 'S',
        sign: '',
        tradingSign: ''
      })
    },
    highlight: {
      type: Boolean,
      default: false
    },
    showSymbol: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dwExpire: {
      type: Boolean,
      default: false
    },
    flagStatus: {
      type: String,
      default: 'Expired'
    }
  },

  data () {
    return {
      favoriteData: this.btnQuoteData,
      stockInfo: null,
      securityType: null,
      favorite: false,
      renderStar: true,
      checkStockList: false
    }
  },

  computed: {
    ...mapGetters({
      stockList: 'quote/getSearchStockList',
      // stockFavorite: 'setmember/favorite/getStock',
      // etfFavorite: 'setmember/favorite/getETF',
      // dwFavorite: 'setmember/favorite/getDW',
      // drFavorite: 'setmember/favorite/getDR',
      // drxFavorite: 'setmember/favorite/getDRx',
      favoriteSymbols: 'setmember/favorite/getFavoriteSymbols'
    }),
    // securityType () {
    //   return (this.stockInfo && this.stockInfo.securityType) || ''
    // },
    isMarketIndex () {
      return this.btnQuoteData.isMarketIndex
    },
    isSecurityType_S () {
      return (
        this.securityType === 'S' ||
        this.securityType === 'F' ||
        this.securityType === 'Q' ||
        this.securityType === 'P' ||
        this.securityType === 'W' ||
        this.securityType === 'U'
      )
    },

    isSecurityType_L () {
      return this.securityType === 'L'
    },

    isSecurityType_V () {
      return this.securityType === 'V'
    },

    isSecurityType_X () {
      return this.securityType === 'X'
    },

    isSecurityType_I () {
      return this.securityType === 'I'
    },

    isSecurityType_XF () {
      return this.securityType === 'XF'
    },

    isSecurityType_underlying () {
      return this.securityType === 'underlying'
    },

    disabledBtn () {
      return this.disabled || (!this.securityType && !this.isMarketIndex)
    }
  },

  watch: {
    btnQuoteData: {
      immediate: true,
      handler (btnQuoteData) {
        this.favoriteData = btnQuoteData
        this.setStockInfoData()
        setTimeout(() => {
          this.setFavoriteSymbol()
          if (!process.server) {
            this.initTooltip()
          }
        }, 100)
        // this.chceckFavoriteSymbol()
      }
    },
    favoriteSymbols: {
      // immediate: true,
      deep: true,
      handler (val) {
        // this.chceckFavoriteSymbol()
        setTimeout(() => {
          this.setFavoriteSymbol()
          this.initTooltip()
        }, 100)
      }
    }

  },
  async created () {
    await this.setStockInfoData()
  },
  mounted () {
    this.setFavoriteSymbol()
    this.initTooltip()
    this.$emit('onRenderSymbol', {
      disabled: this.disabledBtn
    })
  },
  destroyed () {
    this.destroyTooltip()
  },
  methods: {
    onClickIconDWexpire (e) {
      e.stopPropagation()
    },
    initTooltip () {
      const { Tooltip } = require('bootstrap')
      const tooltipTriggerList = [].slice.call(
        document.querySelectorAll('btn-quote [data-bs-toggle="tooltip"]')
      )
      tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
      })
    },

    destroyTooltip () {
      const { Tooltip } = require('bootstrap')
      const tooltipTriggerList = [].slice.call(
        document.querySelectorAll('btn-quote [data-bs-toggle="tooltip"]')
      )
      tooltipTriggerList.forEach((tooltipTriggerEl) => {
        const tooltipEle = Tooltip.getOrCreateInstance(tooltipTriggerEl)
        tooltipEle.dispose()
      })
    },
    setCategoryType,
    ...mapActions({
      getStockInfoData: 'quote/getStockInfoData',
      addFavorite: 'setmember/favorite/addFavorite',
      delFavorite: 'setmember/favorite/delFavorite'
    }),
    setStockInfoData () {
      let symbol = this.btnQuoteData.symbol
        ? this.btnQuoteData.symbol.toLowerCase()
        : ''
      if (this.btnQuoteData.symbol && this.btnQuoteData.symbol.includes('-R')) {
        symbol = symbol.replace(/-[r,R]/g, '').toLowerCase()
      }
      const securities = this.btnQuoteData.securities
        ? this.btnQuoteData.securities.toLowerCase()
        : ''

      let stockInfo = null
      if (this.stockList) {
        stockInfo = this.stockList.find(
          item => item.symbol.toLowerCase() === (securities || symbol)
        )
        this.checkStockList = !!((stockInfo && stockInfo.securityType))
      }

      const securityType = (stockInfo && stockInfo.securityType) || ''
      this.securityType = (securityType || this.btnQuoteData.securityType) || ''
      // if (this.btnQuoteData.securityType === 'S') {
      //   this.securityType = securityType
      // } else if (this.btnQuoteData.securityType !== 'I') {
      //   // const payload =
      //   //   {
      //   //     quote: this.btnQuoteData.securities || this.btnQuoteData.symbol
      //   //   }
      //   // const stockInfo = await this.getStockInfoData(payload)
      //   if (stockInfo) {
      //     this.securityType = stockInfo.securityType || ''
      //   } else {
      //     this.securityType = ''
      //   }
      // } else {
      //   this.securityType =
      //     this.btnQuoteData.securityType || this.btnQuoteData.underlyingType
      // }
    },

    symbolPath () {
      // this.setStockInfoData()
      let url = ''
      // console.log('checkStockList', this.checkStockList)
      const symbol = this.btnQuoteData.securities || this.btnQuoteData.symbol
      if (this.isMarketIndex) {
        url = `/${this.$i18n.locale}/market/index/${symbol}`
      } else if (this.isSecurityType_S && this.checkStockList) {
        if (symbol && symbol.includes('-R')) {
          const regSymbol = symbol.replace(/-[r,R]/g, '').toUpperCase()
          // url = `/${this.$i18n.locale}/market/product/stock/quote/${regSymbol}/company-profile/major-shareholders?type=nvdr`
          url = `/${this.$i18n.locale}/market/product/stock/quote/${regSymbol}/major-shareholders?type=nvdr`
        } else {
          url = `/${this.$i18n.locale}/market/product/stock/quote/${symbol}/price`
        }
      } else if (this.isSecurityType_L && this.checkStockList) {
        url = `/${this.$i18n.locale}/market/product/etf/quote/${symbol}/price`
      } else if (this.isSecurityType_V && this.checkStockList) {
        url = `/${this.$i18n.locale}/market/product/dw/quote/${symbol}/price`
      } else if (this.isSecurityType_X && this.checkStockList) {
        url = `/${this.$i18n.locale}/market/product/dr/quote/${symbol}/price`
      } else if (this.isSecurityType_XF && this.checkStockList) {
        url = `/${this.$i18n.locale}/market/product/drx/quote/${symbol}/price`
      }
      return url
    },
    openModalLimitFavorite (category) {
      // this.$refs.modalLimitFavoriteStock.show()
      const { Modal } = require('bootstrap')
      const myModalEl = document.querySelector('#modalLimitFavoriteStock2')
      const modal = Modal.getOrCreateInstance(myModalEl)
      modal.show({ content: category })
    },
    // closeModalLimitFavorite () {
    //   this.$refs.modalLimitFavoriteStock.hide()
    // },
    async onFavorite (event) {
      event.stopPropagation()
      // category => stock | etf | dr | drx | dw | futures | options | fund
      // favorite/stock/PSL
      const data = {
        category: this.setCategoryType(this.securityType),
        symbols: this.btnQuoteData.securities || this.btnQuoteData.symbol

      }
      // this.favorite = !this.favorite
      const isLogin = await this.$authUser.isLogin()
      if (isLogin) {
        if (this.favorite === false) {
          this.addFavorite(data).then((res) => {
            if (res.status === 201) {
              this.setFavoriteSymbol()
              this.handleClickTracking('myQuote7', { symbol: this.btnQuoteData.securities || this.btnQuoteData.symbol })
              this.$toast.success('favorite')
            } else if (res.response.status === 400) {
              // console.log('List full', res.response)
              this.openModalLimitFavorite(data.category)
              // this.$toast.fail(res.response.data.message)
              // this.chceckFavoriteSymbol()
            }
          }).catch(err => err)
        } else if (this.favorite === true) {
        // this.delFavorite(data)
          this.delFavorite(data).then((res) => {
            // console.log('res.status=>', res)
            if (res.status === 202) {
              this.setFavoriteSymbol()
              this.handleClickTracking('myQuote8', { symbol: this.btnQuoteData.securities || this.btnQuoteData.symbol })
              this.$toast.success('delete')
              this.$emit('delFavorite')
            }
          }).catch(err => err)
          // console.log('delFavorite=>>', data, this.favorite)
        }
      } else {
        this.$cookies.set('favorite-quote-remember', data, { path: '/' })
        window.location.href = this.$authUser.login()
      }
    },
    setFavoriteSymbol () {
      const symbol = this.btnQuoteData.symbol || this.btnQuoteData.securities
      const favoriteSymbolsList = Object.values(this.favoriteSymbols).flat()
      this.favorite = favoriteSymbolsList.includes(symbol)
      // console.log('this.favoriteSymbols==>>', favoriteSymbolsList, symbol, this.favorite)
    },
    handleClickTracking (name, optionLang) {
      this.$personalized.clickElement({
        name,
        optionLang
      })
    }
    // chceckFavoriteSymbol () {
    //   const data = {
    //     category: this.setCategoryType(this.securityType),
    //     symbols: this.btnQuoteData.securities || this.btnQuoteData.symbol

    //   }
    //   const stockFavorite = this.checkFavorite(data.category)
    //   // console.log('arrayfavoriteList==>>>>', stockFavorite, data.symbols, data.category)
    //   this.favorite = stockFavorite.includes(data.symbols)
    // },
    // checkFavorite (category) {
    //   let stockFavoriteList = []
    //   if (category === 'stock') {
    //     stockFavoriteList = this.stockFavorite
    //   } else if (category === 'etf') {
    //     stockFavoriteList = this.etfFavorite
    //   } else if (category === 'dw') {
    //     stockFavoriteList = this.dwFavorite
    //   } else if (category === 'dr') {
    //     stockFavoriteList = this.drFavorite
    //   } else if (category === 'drx') {
    //     stockFavoriteList = this.drxFavorite
    //   }
    //   return stockFavoriteList
    // }
  }
}
