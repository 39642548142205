//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      quoteModal: 0
    }
  },
  head () {
    return this.$embedded.embedded.default()
  },
  computed: {
    ...mapGetters({ content: 'contents/getContentData' }),
    showHeader () {
      if (this.content && this.content.settings) {
        return this.content.settings.showHeader
      }
      return true
    },
    showFooter () {
      if (this.content && this.content.settings) {
        return this.content.settings.showFooter
      }
      return true
    }
  },
  watch: {
    '$route.fullPath': {
      immediate: true,
      handler (newPage, oldPage) {
        const { contentDataLayer: { currentPath } } = this.$store.state
        const isPush = process.client && currentPath !== newPage
        if (isPush) {
          this.$personalized.pagePersonalized()
        }
      }
    }
  },
  methods: {
    openModalAddQuote () {
      this.quoteModal += 1
    }
  }
}
