function isFunctionalPage (routeName) {
  const isMatch = routeName?.includes('error-500_') ||
    routeName?.includes('error-503_') ||
    routeName?.includes('error-oldversion_') ||
    routeName?.includes('redirect_') ||
    routeName?.includes('health-check_') ||
    routeName?.includes('event-tracking-report_')
  return isMatch
}

export default isFunctionalPage
