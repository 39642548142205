import { formatNumber } from '@/helpers/number'

const state = () => ({
  indexInfo: null,
  indexList: [],
  indexListDropDown: [],
  indexFTSEList: null,
  indexFTSEAseanList: [],
  indexFTSEStockList: null,
  FTSEAseanHistoricalTrading: [],
  indexChart: [],
  ftseChart: [],
  indexPrior: null,
  ftsePrior: null,
  indexPerformanceList: null,
  indexCompositionInfo: null,
  indexCompositionList: null,
  indexCompositionChart: null,
  industryList: null,
  TRIindices: null,
  NetTRIindices: null,
  industryListTRI: null,
  TRIChart: [],
  TRIPrior: null,
  marketStatus: null,
  relatedProductDW: null,
  relatedProductETF: null,
  relatedProductTFEX: null
})

const mutations = {
  /* Market Overview */

  setIndexInfo (state, data) {
    state.indexInfo = data
  },

  setIndexList (state, data) {
    state.indexList = data
  },

  setIndexListDropDown (state, data) {
    state.indexListDropDown = data
  },

  setIndexChart (state, data) {
    state.indexChart = data || []
  },

  setIndexPrior (state, data) {
    state.indexPrior = data
  },

  setFTSEList (state, data) {
    state.indexFTSEList = data
  },

  setFTSEAseanList (state, data) {
    state.indexFTSEAseanList = data
  },

  setFTSEStockList (state, data) {
    state.indexFTSEStockList = data
  },

  setFTSEAseanHistoricalTrading (state, data) {
    state.FTSEAseanHistoricalTrading = data
  },

  setFTSEChart (state, data) {
    state.ftseChart = data || []
  },

  setFTSEPrior (state, data) {
    state.ftsePrior = data
  },

  setIndexPerformanceList (state, data) {
    state.indexPerformanceList = data || []
  },

  setIndexCompositionInfo (state, data) {
    state.indexCompositionInfo = data
  },

  setIndexCompositionList (state, data) {
    state.indexCompositionList = data
  },

  setIndexCompositionChart (state, data) {
    state.indexCompositionChart = data
  },

  setIndexIndustryList (state, data) {
    state.industryList = data || []
  },

  setTRIindices (state, data) {
    state.TRIindices = data
  },

  setNetTRIindices (state, data) {
    state.NetTRIindices = data
  },

  setIndexIndustryListTRI (state, data) {
    state.industryListTRI = data
  },

  setTRIChart (state, data) {
    state.TRIChart = data || []
  },

  setTRIPrior (state, data) {
    state.TRIPrior = data
  },

  setMarketStatus (state, data) {
    state.marketStatus = data
  },
  setMarketIndexRelatedProduct (
    state,
    [relatedDWData, relatedETFData, relatedTFEXData]
  ) {
    state.relatedProductDW = relatedDWData
    state.relatedProductETF = relatedETFData
    state.relatedProductTFEX = relatedTFEXData
  }
}

const actions = {
  /* Market Overview */

  async getIndexInfo ({ commit }, payload) {
    const { market } = payload
    try {
      const response = await this.$axios.$get(`api/set/index/${market}/info`, {
        params: { lang: this.$i18n.locale }
      })
      commit('setIndexInfo', response)
    } catch (err) {
      commit('setIndexInfo', null)
    }
  },

  async getIndexList ({ commit }, payload) {
    const { index, type, market } = payload
    try {
      const responseParent = this.$axios.$get('api/set/index/list', {
        params: { type: 'index', lang: this.$i18n.locale }
      })
      const response = this.$axios.$get(
        `api/set/index/list?type=${type}&market=${market}&lang=${this.$i18n.locale}`
      )
      const [{ value: marketIndex }, { value: symbolIndex }] =
        await Promise.allSettled([responseParent, response])

      let indexList = []
      if (type === 'industry') {
        indexList = [
          ...symbolIndex,
          ...marketIndex.filter(item => item.symbol.toLowerCase() === market)
        ]
      } else {
        indexList = [
          ...marketIndex.filter(item => item.symbol.toLowerCase() === market),
          ...symbolIndex
        ]

        // let tri = null

        // if (!market) {
        //   tri = marketIndex
        //     .filter(item => item.symbol.toLowerCase() === index.toLowerCase())
        //     .map(item => ({
        //       ...item,
        //       symbol: item.symbol + ' TRI',
        //       querySymbol: item.symbol + ' TRI'
        //     }))
        //   indexList.splice(indexList.length - 1, 0, ...tri)
        // }
      }

      let legendList = []
      if (
        (market === 'set' || market === 'mai') &&
        index &&
        (index !== 'set' || index !== 'mai')
      ) {
        const parent = indexList.find(
          item => item.querySymbol.toLowerCase() === index
        )
        if (parent && parent.level === 'SECTOR') {
          legendList = [
            parent,
            ...indexList.filter(item => item.symbol === parent.parentIndex),
            ...marketIndex.filter(item => item.symbol.toLowerCase() === market)
          ]
        } else {
          legendList = [
            parent,
            ...indexList.filter(item => item.parentIndex === parent.symbol),
            ...marketIndex.filter(item => item.symbol.toLowerCase() === market)
          ]
        }
      } else {
        legendList = indexList
      }
      legendList = legendList.filter(
        (item, index, array) =>
          array
            .map(item => item.symbol.toLowerCase())
            .indexOf(item.symbol.toLowerCase()) === index
      )

      commit('setIndexList', legendList)
      return legendList
    } catch (err) {
      commit('setIndexList', null)
      return []
    }
  },

  async getIndexListDropDown ({ commit }, payload) {
    const { type, market } = payload
    try {
      const response = await this.$axios.$get(
        `api/set/index/list?type=${type}&market=${market}&lang=${this.$i18n.locale}`
      )
      commit('setIndexListDropDown', response)
      return response || []
    } catch (err) {
      commit('setIndexListDropDown', null)
    }
  },

  async getIndexChart ({ commit }, payload) {
    const { index, period } = payload
    try {
      const { prior, quotations } = await this.$axios.$get(
        `api/set/index/${index}/chart-quotation?period=${period}&lang=${this.$i18n.locale}`
      )
      const chartData = [
        ...[
          ...(quotations.map((item, indexItem, array) => {
            return {
              datetime: new Date(item.localDatetime),
              marketTitle: index,
              [`${index.toLowerCase()}`]: item.price,
              [`${index.toLowerCase()}Text`]: item.price
                ? formatNumber(item.price, 2)
                : item.price
            }
          }) || [])
        ]
          .reduce(
            (cur, acc) =>
              cur.set(
                acc.datetime,
                Object.assign(
                  cur.get(acc.datetime) || {
                    datetime: null,
                    marketTitle: null
                  },
                  acc
                )
              ),
            new Map()
          )
          .values()
      ]
      commit('setIndexPrior', prior)
      commit('setIndexChart', chartData)
    } catch (err) {
      commit('setIndexPrior', null)
      commit('setIndexChart', null)
    }
  },

  async getIndexChartCompare ({ commit, state }, payload) {
    const { market, period, indices } = payload
    let mainIndex = null
    if (indices?.length) {
      mainIndex = indices[0]?.querySymbol ?? null
    }
    try {
      let chartCompareList = []
      // const indexList = state.indexList
      const indexList = indices
      if (indexList && indexList.length > 0) {
        let chartCompareListData = []
        for (const { symbol } of indexList) {
          if (!symbol.includes('TRI')) {
            const url = mainIndex && mainIndex !== symbol
              ? `api/set/index/${symbol}/chart-performance?period=${period}&mainComparison=${mainIndex}&lang=${this.$i18n.locale}`
              : `api/set/index/${symbol}/chart-performance?period=${period}&lang=${this.$i18n.locale}`
            chartCompareListData = [
              ...chartCompareListData,
              this.$axios.$get(
                url
              )
            ]
          }
        }

        if (indexList.some(item => item.symbol.includes('TRI'))) {
          let compareTri = []

          const tri = indexList.find(
            item => item.symbol.toLowerCase() === market && market.toLowerCase()
          )?.symbol

          if (tri) {
            compareTri = [
              ...compareTri,
              this.$axios.$get(
                `api/set/tri/${tri}/chart-performance?period=${period}&lang=${this.$i18n.locale}`
              )
            ]
          }

          chartCompareListData.splice(
            indexList.length - 2,
            0,
            ...compareTri
          )
        }

        await Promise.allSettled([...chartCompareListData]).then((res) => {
          for (const [
            indexData,
            {
              value: { quotations: data }
            }
          ] of res.entries()) {
            chartCompareList = [
              ...[
                ...chartCompareList,
                ...[
                  ...(data.map((item, dataIndex, array) => {
                    return {
                      datetime: item.localDatetime,
                      marketTitle: indexList[indexData].querySymbol,
                      [`${indexList[
                        indexData
                      ].querySymbol.toLowerCase()}`]: item.price,
                      [`${indexList[
                        indexData
                      ].querySymbol.toLowerCase()}Text`]: item.price
                        ? formatNumber(item.price, 2)
                        : item.price
                    }
                  }) || [])
                ]
              ]
                .reduce(
                  (cur, acc) =>
                    cur.set(
                      acc.datetime,
                      Object.assign(
                        cur.get(acc.datetime) || {
                          datetime: null,
                          marketTitle: null,
                          market: null
                        },
                        acc
                      )
                    ),
                  new Map()
                )
                .values()
            ]
          }
        })
        // commit('setIndexList', indexList)
        commit('setIndexChart', chartCompareList)
      }
    } catch (err) {
      commit('setIndexChart', null)
    }
  },

  async getIndexPerformanceList ({ commit }, payload) {
    const { ArrayIndex } = payload
    try {
      let indexPerformance = []
      let indexPerformanceService = []
      for (const performance of ArrayIndex) {
        const { symbol } = performance
        indexPerformanceService = [
          ...indexPerformanceService,
          this.$axios.$get(`api/set/index/${symbol}/performance`, {
            params: { lang: this.$i18n.locale }
          })
        ]
      }
      await Promise.allSettled([...indexPerformanceService]).then((res) => {
        for (const { value: data } of res) {
          indexPerformance = [...indexPerformance, data]
        }
      })
      commit('setIndexPerformanceList', indexPerformance)
    } catch (err) {
      commit('setIndexPerformanceList', null)
    }
  },

  async getIndexCompositionList ({ commit, dispatch }, payload) {
    const { index = '' } = payload
    try {
      // const marketComposition = this.$axios.$get(`api/set/index/${market}/info`)
      commit('setIndexCompositionChart', null)
      const symbolComposition = this.$axios.$get(
        `api/set/index/${index}/composition`, {
          params: { lang: this.$i18n.locale }
        }
      )
      // const [
      //   { value: marketInfos },
      //   {
      //     value: { indexInfos, composition }
      //   }
      // ] = await Promise.allSettled([marketComposition, symbolComposition])
      const [
        {
          value: { indexInfos, composition }
        }
      ] = await Promise.allSettled([symbolComposition])
      commit('setIndexCompositionInfo', indexInfos)
      commit('setIndexCompositionList', composition)

      // if (
      //   composition &&
      //   composition.subIndices &&
      //   composition.subIndices.length > 0
      // ) {
      //   let stockListService = []
      //   const stockListServiceList = []

      //   console.log('🚀 ~ stockList1', composition)
      //   for (const [indexIndices, { stockInfos }] of composition.subIndices.entries()) {
      //     for (const { symbol } of stockInfos) {
      //       stockListService = [
      //         ...stockListService,
      //         this.$axios.$get(
      //           `api/set/stock/${symbol}/chart-quotation?period=1D&accumulated=true`
      //         )
      //       ]
      //     }
      //     await Promise.allSettled([...stockListService]).then((res) => {
      //       stockListServiceList[indexIndices] = res.map(item => item.value)
      //     })
      //   }
      //   console.log('🚀 ~ stockList2', stockListServiceList)
      //   commit('setIndexCompositionChart', stockListServiceList)
      // }
      // if (
      //   composition &&
      //   composition.stockInfos &&
      //   composition.stockInfos.length > 0
      // ) {
      //   let stockListService = []
      //   let stockListServiceList = []
      //   for (const { symbol } of composition.stockInfos) {
      //     stockListService = [
      //       ...stockListService,
      //       this.$axios.$get(
      //         `api/set/stock/${symbol}/chart-quotation?period=1D&accumulated=true`
      //       )
      //     ]
      //   }
      //   await Promise.allSettled([...stockListService]).then((res) => {
      //     for (const { value } of res) {
      //       stockListServiceList = [...stockListServiceList, value]
      //     }
      //   })
      //   commit('setIndexCompositionChart', stockListServiceList)
      //   console.log('🚀 ~ stockListServiceList', stockListServiceList)
      // }
    } catch (err) {
      commit('setIndexCompositionList', null)
    }
  },

  async getIndexIndustryList ({ commit }, payload) {
    const { market = '' } = payload
    try {
      const response = await this.$axios.$get(
        `api/set/index/info/list?type=industry&market=${market}&lang=${this.$i18n.locale}`
      )
      if (response && response.indexIndustrySectors.length > 0) {
        let industryList = []
        const indexIndustrySectors = response.indexIndustrySectors
        const INDUSTRY = indexIndustrySectors.filter(
          item => item.level === 'INDUSTRY'
        )
        industryList = INDUSTRY.map(industry => ({
          ...industry,
          sectorList: indexIndustrySectors.filter(
            item =>
              item.industryName === industry.industryName &&
              item.level === 'SECTOR'
          )
        }))
        commit('setIndexIndustryList', industryList)
      }
    } catch (err) {
      commit('setIndexIndustryList', null)
    }
  },

  async getFTSEList ({ commit }, payload) {
    const { type = '' } = payload
    const queryString = type ? `?type=${type}&lang=${this.$i18n.locale}` : `?lang=${this.$i18n.locale}`
    try {
      const response = await this.$axios.$get(
        `api/set/ftse-set/indices${queryString}`
      )
      commit('setFTSEList', response)
    } catch (err) {
      commit('setFTSEList', null)
    }
  },

  async getFTSEListByType ({ commit }, payload) {
    const { type = '' } = payload
    const queryString = type ? `?type=${type}&lang=${this.$i18n.locale}` : `?lang=${this.$i18n.locale}`
    try {
      const response = await this.$axios.$get(
        `api/set/ftse-set/indices${queryString}`
      )
      if (response) {
        return response
      }
    } catch (err) {
      return []
    }
  },

  async getFTSEAseanList ({ commit }, payload) {
    try {
      const response = await this.$axios.$get('api/set/ftse-asean/indices', {
        params: { lang: this.$i18n.locale }
      })
      commit('setFTSEAseanList', response)
      if (response) {
        return response
      }
    } catch (err) {
      commit('setFTSEAseanList', null)
      return []
    }
  },

  async getFTSEStockList ({ commit }) {
    try {
      const response = await this.$axios.$get(
        'api/set/ftse-set/fsthl/stock-list', {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setFTSEStockList', response)
    } catch (err) {
      commit('setFTSEStockList', null)
    }
  },

  async getFTSEAseanHistoricalTrading ({ commit }) {
    try {
      const response = await this.$axios.$get(
        'api/set/ftse-asean/historical-trading', {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setFTSEAseanHistoricalTrading', response)
    } catch (err) {
      commit('setFTSEAseanHistoricalTrading', null)
    }
  },

  async getFTSEChart ({ commit }, payload) {
    const { index, period } = payload
    try {
      const { prior, quotations } = await this.$axios.$get(
        `api/set/ftse/${index}/chart-quotation?period=${period}&lang=${this.$i18n.locale}`
      )
      const chartData = [
        ...[
          ...(quotations.map((item, indexItem, array) => {
            return {
              datetime: new Date(item.localDatetime),
              marketTitle: index,
              [`${index.toLowerCase()}`]: item.price,
              [`${index.toLowerCase()}Text`]: item.price
                ? formatNumber(item.price, 2)
                : item.price
            }
          }) || [])
        ]
          .reduce(
            (cur, acc) =>
              cur.set(
                acc.datetime,
                Object.assign(
                  cur.get(acc.datetime) || {
                    datetime: null,
                    marketTitle: null
                  },
                  acc
                )
              ),
            new Map()
          )
          .values()
      ]
      commit('setFTSEPrior', prior)
      commit('setFTSEChart', chartData)
    } catch (err) {
      commit('setFTSEPrior', null)
      commit('setFTSEChart', null)
    }
  },

  async getFTSEChartCompare ({ commit, state }, payload) {
    const { period, indices } = payload
    try {
      let chartCompareList = []
      if (indices && indices.length > 0) {
        let chartCompareListData = []
        for (const { symbol } of indices) {
          chartCompareListData = [
            ...chartCompareListData,
            this.$axios.$get(
              `api/set/ftse/${symbol}/chart-performance?period=${period}&lang=${this.$i18n.locale}`
            )
          ]
        }
        await Promise.allSettled([...chartCompareListData]).then((res) => {
          for (const [
            indexData,
            {
              value: { quotations: data }
            }
          ] of res.entries()) {
            chartCompareList = [
              ...[
                ...chartCompareList,
                ...[
                  ...(data.map((item, dataIndex, array) => {
                    return {
                      datetime: item.localDatetime,
                      marketTitle: indices[indexData].symbol,
                      [`${indices[indexData].symbol.toLowerCase()}`]: item.price,
                      [`${indices[indexData].symbol.toLowerCase()}Text`]:
                      formatNumber(item.price, 2)
                    }
                  }) || [])
                ]
              ]
                .reduce(
                  (cur, acc) =>
                    cur.set(
                      acc.datetime,
                      Object.assign(
                        cur.get(acc.datetime) || {
                          datetime: null,
                          marketTitle: null,
                          market: null
                        },
                        acc
                      )
                    ),
                  new Map()
                )
                .values()
            ]
          }
        })
        // commit('setIndexList', indexList)
        commit('setFTSEChart', chartCompareList)
      }
    } catch (err) {
      commit('setFTSEChart', null)
    }
  },

  async getTRIindices ({ commit }) {
    try {
      const response = await this.$axios.$get('api/set/tri/indices', {
        params: { lang: this.$i18n.locale }
      })
      commit('setTRIindices', response)
    } catch (err) {
      commit('setTRIindices', null)
    }
  },

  async getNetTRIindices ({ commit }) {
    try {
      const response = await this.$axios.$get('api/set/net-tri/indices', {
        params: { lang: this.$i18n.locale }
      })
      if (response && response.data) {
        commit('setNetTRIindices', response.data)
      }
    } catch (err) {
      commit('setNetTRIindices', null)
      return []
    }
  },

  async getIndexIndustryListTRI ({ commit }, payload) {
    try {
      const industrySETService = this.$axios.$get(
        `api/set/tri/industry-sector?market=SET&lang=${this.$i18n.locale}`
      )
      const industryMAIService = this.$axios.$get(
        `api/set/tri/industry-sector?market=mai&lang=${this.$i18n.locale}`
      )
      const [
        {
          value: { data: industrySET }
        },
        {
          value: { data: industryMAI }
        }
      ] = await Promise.allSettled([industrySETService, industryMAIService])

      if (industrySET.length > 0 && industryMAI.length > 0) {
        let industryListSET = []
        let industrySETGroup = null

        industrySET.forEach((item, index) => {
          if (item.level === 'SECTOR') {
            industrySETGroup.sectorList.push(item)
          } else if (item.level === 'INDUSTRY') {
            industrySETGroup = { ...item, sectorList: [] }
            industryListSET = [...industryListSET, industrySETGroup]
          }
        })

        let industryListMAI = []
        let industryMAIGroup = null

        industryMAI.forEach((item, index) => {
          if (item.level === 'SECTOR') {
            industryMAIGroup.sectorList.push(item)
          } else if (item.level === 'INDUSTRY') {
            industryMAIGroup = { ...item, sectorList: [] }
            industryListMAI = [...industryListMAI, industryMAIGroup]
          }
        })

        commit('setIndexIndustryListTRI', [industryListSET, industryListMAI])
      }
    } catch (err) {
      commit('setIndexIndustryListTRI', null)
    }
  },

  async getTRIChart ({ commit }, payload) {
    const { index, period } = payload
    try {
      const { prior, quotations } = await this.$axios.$get(
        `api/set/tri/${index}/chart-quotation?period=${period}&lang=${this.$i18n.locale}`
      )
      const chartData = [
        ...[
          ...(quotations.map((item, indexItem, array) => {
            return {
              datetime: new Date(item.localDatetime),
              marketTitle: index,
              [`${index.toLowerCase()} tri`]: item.price,
              [`${index.toLowerCase()} triText`]: item.price
                ? formatNumber(item.price, 2)
                : item.price
            }
          }) || [])
        ]
          .reduce(
            (cur, acc) =>
              cur.set(
                acc.datetime,
                Object.assign(
                  cur.get(acc.datetime) || {
                    datetime: null,
                    marketTitle: null
                  },
                  acc
                )
              ),
            new Map()
          )
          .values()
      ]
      commit('setTRIPrior', prior)
      commit('setTRIChart', chartData)
    } catch (err) {
      commit('setTRIPrior', null)
      commit('setTRIChart', null)
    }
  },

  async getTRIChartCompare ({ commit }, payload) {
    const { period, indices } = payload
    try {
      let chartCompareList = []
      if (indices && indices.length > 0) {
        let chartCompareListData = []
        for (const { symbol } of indices) {
          chartCompareListData = [
            ...chartCompareListData,
            this.$axios.$get(
              `api/set/tri/${symbol}/chart-performance?period=${period}&lang=${this.$i18n.locale}`
            )
          ]
        }
        await Promise.allSettled([...chartCompareListData]).then((res) => {
          for (const [
            indexData,
            {
              value: { quotations: data }
            }
          ] of res.entries()) {
            chartCompareList = [
              ...[
                ...chartCompareList,
                ...[
                  ...(data.map((item, dataIndex, array) => {
                    return {
                      datetime: item.localDatetime,
                      marketTitle: indices[indexData].symbol + ' TRI',
                      [`${indices[indexData].symbol.toLowerCase()} tri`]: item.price,
                      [`${indices[indexData].symbol.toLowerCase()} triText`]:
                      formatNumber(item.price, 2)
                    }
                  }) || [])
                ]
              ]
                .reduce(
                  (cur, acc) =>
                    cur.set(
                      acc.datetime,
                      Object.assign(
                        cur.get(acc.datetime) || {
                          datetime: null,
                          marketTitle: null,
                          market: null
                        },
                        acc
                      )
                    ),
                  new Map()
                )
                .values()
            ]
          }
        })
        // commit('setIndexList', indexList)
        commit('setTRIChart', chartCompareList)
      }
    } catch (err) {
      commit('setTRIChart', null)
    }
  },
  async getMarketStatus ({ commit }, payload) {
    try {
      const response = await this.$axios.$get('api/set/market/SET/marketstatus', {
        params: { lang: this.$i18n.locale }
      })
      if (response) {
        commit('setMarketStatus', response)
      }
    } catch (err) {
      commit('setMarketStatus', null)
    }
  },
  async getMarketIndexRelatedProduct ({ commit }, payload) {
    const { index } = payload
    try {
      const relatedProduct = await this.$axios.$get(`/api/set/index/${index}/related-product`)
      const relatedDWData = relatedProduct.filter(v => v.type === 'V')
      const relatedETFData = relatedProduct.filter(v => v.type === 'L')
      const relatedTFEXData = relatedProduct.filter(v => v.type === 'TFEX')
      commit('setMarketIndexRelatedProduct', [
        relatedDWData && relatedDWData[0] ? relatedDWData[0] : relatedDWData,
        relatedETFData && relatedETFData[0] ? relatedETFData[0] : relatedETFData,
        relatedTFEXData && relatedTFEXData[0] ? relatedTFEXData[0] : relatedTFEXData
      ])
    } catch (err) {
      commit('setMarketIndexRelatedProduct', null)
    }
  },
  async getLastMonthlyReport ({ commit }, payload) {
    const { index } = payload
    try {
      let lastMonthLyReport = null
      const monthlyReport = await this.$axios.$get(
        `/api/set/report/monthly/product/${index}`, {
          params: { lang: this.$i18n.locale }
        }
      )
      if (monthlyReport && monthlyReport.reports) {
        const dataLastYear = monthlyReport.reports.filter(item => item.data.some(v => v.url !== '')).reduce((acc, cur) => acc.year > cur.year ? acc : cur)
        const dataLastMonth = dataLastYear.data.filter(item => item.url !== '').reduce((acc, cur) => acc.month > cur.month ? acc : cur)
        lastMonthLyReport = dataLastMonth
      }
      return lastMonthLyReport
    } catch (err) {
      return null
    }
  }
}

const getters = {
  /* Market Overview */

  getIndexInfo: (state) => {
    return state.indexInfo
  },

  getIndexList: (state) => {
    return state.indexList || []
  },

  getIndexListDropDown: (state) => {
    return state.indexListDropDown || []
  },

  getIndexChart: (state) => {
    return state.indexChart
  },

  getIndexPrior: (state) => {
    return state.indexPrior
  },

  getFTSEList: (state) => {
    return state.indexFTSEList || null
  },

  getFTSEAseanList: (state) => {
    return state.indexFTSEAseanList || null
  },

  getFTSEStockList: (state) => {
    return state.indexFTSEStockList || null
  },

  getFTSEAseanHistoricalTrading: (state) => {
    return state.FTSEAseanHistoricalTrading || null
  },

  getFTSEChart: (state) => {
    return state.ftseChart
  },

  getFTSEPrior: (state) => {
    return state.ftsePrior
  },

  getIndexPerformanceList: (state) => {
    return state.indexPerformanceList
  },

  getIndexCompositionInfo: (state) => {
    return state.indexCompositionInfo || []
  },

  getIndexCompositionList: (state) => {
    return state.indexCompositionList
  },

  getIndexCompositionChart: (state) => {
    return state.indexCompositionChart
  },

  getIndexIndustryList: (state) => {
    return state.industryList
  },

  getTRIindices: (state) => {
    return state.TRIindices || null
  },

  getNetTRIindices: (state) => {
    return state.NetTRIindices || []
  },

  getIndexIndustryListTRI: (state) => {
    return state.industryListTRI
  },

  getTRIChart: (state) => {
    return state.TRIChart
  },

  getTRIPrior: (state) => {
    return state.TRIPrior
  },

  getMarketStatus: (state) => {
    return state.marketStatus
  },
  getMarketIndexRelatedProductDW: (state) => {
    return state.relatedProductDW ? state.relatedProductDW.relatedProducts : []
  },

  getMarketIndexRelatedProductETF: (state) => {
    return state.relatedProductETF
      ? state.relatedProductETF.relatedProducts
      : []
  },

  getMarketIndexRelatedProductTFEX: (state) => {
    return state.relatedProductTFEX
      ? state.relatedProductTFEX.relatedProducts
      : []
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
