/**
 * Render chatbot script and css
 */
// const addChatbotScript = () => {
//   const cssSrc = process.env.CHATBOT_CSS
//   const jsSrc = process.env.CHATBOT_JS

//   // Render js
//   const script = document.createElement('script')
//   script.type = 'text/javascript'
//   script.src = jsSrc
//   script.async = true
//   const s = document.getElementsByTagName('script')[0]
//   s.parentNode.insertBefore(script, s)

//   // Render css
//   const linkCSS = document.createElement('link')
//   linkCSS.rel = 'stylesheet'
//   linkCSS.type = 'text/css'
//   linkCSS.href = cssSrc
//   const l = document.getElementsByTagName('link')[0]
//   l.parentNode.insertBefore(linkCSS, l)
// }

/**
 * Add body class
 */
const addBodyClass = () => {
  const body = document.body
  body?.classList.add('window-loaded')
}
export default ({ store }) => {
  const handleWindowLoaded = () => {
    addBodyClass()
    // setTimeout(() => {
    //   addChatbotScript()
    // }, 250)
    store.commit('setWindowLoaded', true)
  }

  const registerEvent = () => {
    window.addEventListener('load', handleWindowLoaded)
  }

  // Register event
  registerEvent()
}
