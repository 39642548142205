const middleware = {}

middleware['authorization'] = require('../middleware/authorization.js')
middleware['authorization'] = middleware['authorization'].default || middleware['authorization']

middleware['cacheControl'] = require('../middleware/cacheControl.js')
middleware['cacheControl'] = middleware['cacheControl'].default || middleware['cacheControl']

middleware['memberEntry'] = require('../middleware/memberEntry.js')
middleware['memberEntry'] = middleware['memberEntry'].default || middleware['memberEntry']

export default middleware
