import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  Menu: () => import('../../components/Menu/Menu.vue' /* webpackChunkName: "components/menu" */).then(c => wrapFunctional(c.default || c)),
  MenuItem: () => import('../../components/Menu/MenuItem.vue' /* webpackChunkName: "components/menu-item" */).then(c => wrapFunctional(c.default || c)),
  MenuRelateLinkItem: () => import('../../components/Menu/MenuRelateLinkItem.vue' /* webpackChunkName: "components/menu-relate-link-item" */).then(c => wrapFunctional(c.default || c)),
  MenuTab: () => import('../../components/Menu/MenuTab.vue' /* webpackChunkName: "components/menu-tab" */).then(c => wrapFunctional(c.default || c)),
  SubMenuCard: () => import('../../components/Menu/SubMenu/Card.vue' /* webpackChunkName: "components/sub-menu-card" */).then(c => wrapFunctional(c.default || c)),
  SubMenuItem: () => import('../../components/Menu/SubMenu/Item.vue' /* webpackChunkName: "components/sub-menu-item" */).then(c => wrapFunctional(c.default || c)),
  SubMenuSpecial: () => import('../../components/Menu/SubMenu/Special.vue' /* webpackChunkName: "components/sub-menu-special" */).then(c => wrapFunctional(c.default || c)),
  SubMenu: () => import('../../components/Menu/SubMenu/SubMenu.vue' /* webpackChunkName: "components/sub-menu" */).then(c => wrapFunctional(c.default || c)),
  CoverTabs: () => import('../../components/NavTabs/CoverTabs.vue' /* webpackChunkName: "components/cover-tabs" */).then(c => wrapFunctional(c.default || c)),
  Tab: () => import('../../components/NavTabs/Tab.vue' /* webpackChunkName: "components/tab" */).then(c => wrapFunctional(c.default || c)),
  TabContent: () => import('../../components/NavTabs/TabContent.vue' /* webpackChunkName: "components/tab-content" */).then(c => wrapFunctional(c.default || c)),
  TabPane: () => import('../../components/NavTabs/TabPane.vue' /* webpackChunkName: "components/tab-pane" */).then(c => wrapFunctional(c.default || c)),
  Tabs: () => import('../../components/NavTabs/Tabs.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c)),
  PageTabs: () => import('../../components/Pages/PageTabs.vue' /* webpackChunkName: "components/page-tabs" */).then(c => wrapFunctional(c.default || c)),
  Search: () => import('../../components/Pages/Search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c)),
  Welcome: () => import('../../components/Pages/Welcome.vue' /* webpackChunkName: "components/welcome" */).then(c => wrapFunctional(c.default || c)),
  AboutHeader: () => import('../../components/Pages/About/Header.vue' /* webpackChunkName: "components/about-header" */).then(c => wrapFunctional(c.default || c)),
  Error404: () => import('../../components/Pages/Error/404.vue' /* webpackChunkName: "components/error404" */).then(c => wrapFunctional(c.default || c)),
  Error500: () => import('../../components/Pages/Error/500.vue' /* webpackChunkName: "components/error500" */).then(c => wrapFunctional(c.default || c)),
  Error503: () => import('../../components/Pages/Error/503.vue' /* webpackChunkName: "components/error503" */).then(c => wrapFunctional(c.default || c)),
  ErrorParallel404: () => import('../../components/Pages/Error/Parallel404.vue' /* webpackChunkName: "components/error-parallel404" */).then(c => wrapFunctional(c.default || c)),
  ErrorOldversion: () => import('../../components/Pages/Error/oldversion.vue' /* webpackChunkName: "components/error-oldversion" */).then(c => wrapFunctional(c.default || c)),
  EducationAdvancedSearch: () => import('../../components/Pages/Education/AdvancedSearch.vue' /* webpackChunkName: "components/education-advanced-search" */).then(c => wrapFunctional(c.default || c)),
  EducationCover: () => import('../../components/Pages/Education/Cover.vue' /* webpackChunkName: "components/education-cover" */).then(c => wrapFunctional(c.default || c)),
  EducationCoverSearchResult: () => import('../../components/Pages/Education/CoverSearchResult.vue' /* webpackChunkName: "components/education-cover-search-result" */).then(c => wrapFunctional(c.default || c)),
  EducationSearch: () => import('../../components/Pages/Education/Search.vue' /* webpackChunkName: "components/education-search" */).then(c => wrapFunctional(c.default || c)),
  EducationTabMenu: () => import('../../components/Pages/Education/TabMenu.vue' /* webpackChunkName: "components/education-tab-menu" */).then(c => wrapFunctional(c.default || c)),
  FilterMain: () => import('../../components/Pages/Filter/Main.vue' /* webpackChunkName: "components/filter-main" */).then(c => wrapFunctional(c.default || c)),
  FilterToggleCollapse: () => import('../../components/Pages/Filter/ToggleCollapse.vue' /* webpackChunkName: "components/filter-toggle-collapse" */).then(c => wrapFunctional(c.default || c)),
  Home: () => import('../../components/Pages/Home/Home.vue' /* webpackChunkName: "components/home" */).then(c => wrapFunctional(c.default || c)),
  HomeIndexSet: () => import('../../components/Pages/Home/IndexSet.vue' /* webpackChunkName: "components/home-index-set" */).then(c => wrapFunctional(c.default || c)),
  MemberCover: () => import('../../components/Pages/Member/Cover.vue' /* webpackChunkName: "components/member-cover" */).then(c => wrapFunctional(c.default || c)),
  MemberTab: () => import('../../components/Pages/Member/Tab.vue' /* webpackChunkName: "components/member-tab" */).then(c => wrapFunctional(c.default || c)),
  Member: () => import('../../components/Pages/Member/index.vue' /* webpackChunkName: "components/member" */).then(c => wrapFunctional(c.default || c)),
  NewsAndAlertCardSearchNews: () => import('../../components/Pages/NewsAndAlert/CardSearchNews.vue' /* webpackChunkName: "components/news-and-alert-card-search-news" */).then(c => wrapFunctional(c.default || c)),
  NewsAndAlertTableSummaryMain: () => import('../../components/Pages/NewsAndAlert/TableSummaryMain.vue' /* webpackChunkName: "components/news-and-alert-table-summary-main" */).then(c => wrapFunctional(c.default || c)),
  NewsAndAlertTab: () => import('../../components/Pages/NewsAndAlert/tab.vue' /* webpackChunkName: "components/news-and-alert-tab" */).then(c => wrapFunctional(c.default || c)),
  RegulatoryStatisticsExchangeEnquiries: () => import('../../components/Pages/RegulatoryStatistics/ExchangeEnquiries.vue' /* webpackChunkName: "components/regulatory-statistics-exchange-enquiries" */).then(c => wrapFunctional(c.default || c)),
  RegulatoryStatisticsHead: () => import('../../components/Pages/RegulatoryStatistics/Head.vue' /* webpackChunkName: "components/regulatory-statistics-head" */).then(c => wrapFunctional(c.default || c)),
  RegulatoryStatisticsTable: () => import('../../components/Pages/RegulatoryStatistics/Table.vue' /* webpackChunkName: "components/regulatory-statistics-table" */).then(c => wrapFunctional(c.default || c)),
  RegulatoryStatistics: () => import('../../components/Pages/RegulatoryStatistics/index.vue' /* webpackChunkName: "components/regulatory-statistics" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksAttachCollapse: () => import('../../components/Pages/RulesBooks/AttachCollapse.vue' /* webpackChunkName: "components/rules-books-attach-collapse" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksCirculars: () => import('../../components/Pages/RulesBooks/Circulars.vue' /* webpackChunkName: "components/rules-books-circulars" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksCopyLink: () => import('../../components/Pages/RulesBooks/CopyLink.vue' /* webpackChunkName: "components/rules-books-copy-link" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksCover: () => import('../../components/Pages/RulesBooks/Cover.vue' /* webpackChunkName: "components/rules-books-cover" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksDatePickerRange: () => import('../../components/Pages/RulesBooks/DatePickerRange.vue' /* webpackChunkName: "components/rules-books-date-picker-range" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksFooter: () => import('../../components/Pages/RulesBooks/Footer.vue' /* webpackChunkName: "components/rules-books-footer" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksForms: () => import('../../components/Pages/RulesBooks/Forms.vue' /* webpackChunkName: "components/rules-books-forms" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksInputSuggest: () => import('../../components/Pages/RulesBooks/InputSuggest.vue' /* webpackChunkName: "components/rules-books-input-suggest" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksRegulations: () => import('../../components/Pages/RulesBooks/Regulations.vue' /* webpackChunkName: "components/rules-books-regulations" */).then(c => wrapFunctional(c.default || c)),
  RulesBooks: () => import('../../components/Pages/RulesBooks/RulesBooks.vue' /* webpackChunkName: "components/rules-books" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksSearch: () => import('../../components/Pages/RulesBooks/Search.vue' /* webpackChunkName: "components/rules-books-search" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksTabMenu: () => import('../../components/Pages/RulesBooks/TabMenu.vue' /* webpackChunkName: "components/rules-books-tab-menu" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksTagType: () => import('../../components/Pages/RulesBooks/TagType.vue' /* webpackChunkName: "components/rules-books-tag-type" */).then(c => wrapFunctional(c.default || c)),
  SearchResult: () => import('../../components/Pages/SearchResult/Index.vue' /* webpackChunkName: "components/search-result" */).then(c => wrapFunctional(c.default || c)),
  SearchResultInputBox: () => import('../../components/Pages/SearchResult/InputBox.vue' /* webpackChunkName: "components/search-result-input-box" */).then(c => wrapFunctional(c.default || c)),
  SearchResultNoData: () => import('../../components/Pages/SearchResult/NoData.vue' /* webpackChunkName: "components/search-result-no-data" */).then(c => wrapFunctional(c.default || c)),
  SearchResultTabContent: () => import('../../components/Pages/SearchResult/TabContent.vue' /* webpackChunkName: "components/search-result-tab-content" */).then(c => wrapFunctional(c.default || c)),
  SearchResultTabNews: () => import('../../components/Pages/SearchResult/TabNews.vue' /* webpackChunkName: "components/search-result-tab-news" */).then(c => wrapFunctional(c.default || c)),
  SearchResultTabOverview: () => import('../../components/Pages/SearchResult/TabOverview.vue' /* webpackChunkName: "components/search-result-tab-overview" */).then(c => wrapFunctional(c.default || c)),
  SearchResultTabStock: () => import('../../components/Pages/SearchResult/TabStock.vue' /* webpackChunkName: "components/search-result-tab-stock" */).then(c => wrapFunctional(c.default || c)),
  StatisticsHeader: () => import('../../components/Pages/Statistics/Header.vue' /* webpackChunkName: "components/statistics-header" */).then(c => wrapFunctional(c.default || c)),
  StatisticsTabs: () => import('../../components/Pages/Statistics/Tabs.vue' /* webpackChunkName: "components/statistics-tabs" */).then(c => wrapFunctional(c.default || c)),
  Statistics: () => import('../../components/Pages/Statistics/index.vue' /* webpackChunkName: "components/statistics" */).then(c => wrapFunctional(c.default || c)),
  TchCover: () => import('../../components/Pages/Tch/Cover.vue' /* webpackChunkName: "components/tch-cover" */).then(c => wrapFunctional(c.default || c)),
  TchRuleCover: () => import('../../components/Pages/Tch/RuleCover.vue' /* webpackChunkName: "components/tch-rule-cover" */).then(c => wrapFunctional(c.default || c)),
  TsdCover: () => import('../../components/Pages/Tsd/Cover.vue' /* webpackChunkName: "components/tsd-cover" */).then(c => wrapFunctional(c.default || c)),
  TsdRuleCover: () => import('../../components/Pages/Tsd/RuleCover.vue' /* webpackChunkName: "components/tsd-rule-cover" */).then(c => wrapFunctional(c.default || c)),
  VideoDetail: () => import('../../components/Pages/Video/Detail.vue' /* webpackChunkName: "components/video-detail" */).then(c => wrapFunctional(c.default || c)),
  VideoPlaylist: () => import('../../components/Pages/Video/Playlist.vue' /* webpackChunkName: "components/video-playlist" */).then(c => wrapFunctional(c.default || c)),
  VideoPlaylistDetail: () => import('../../components/Pages/Video/PlaylistDetail.vue' /* webpackChunkName: "components/video-playlist-detail" */).then(c => wrapFunctional(c.default || c)),
  VideoStatusLive: () => import('../../components/Pages/Video/StatusLive.vue' /* webpackChunkName: "components/video-status-live" */).then(c => wrapFunctional(c.default || c)),
  AboutHolidayList: () => import('../../components/Pages/About/Holiday/List.vue' /* webpackChunkName: "components/about-holiday-list" */).then(c => wrapFunctional(c.default || c)),
  AboutHoliday: () => import('../../components/Pages/About/Holiday/index.vue' /* webpackChunkName: "components/about-holiday" */).then(c => wrapFunctional(c.default || c)),
  AboutJoinUsCover: () => import('../../components/Pages/About/JoinUs/Cover.vue' /* webpackChunkName: "components/about-join-us-cover" */).then(c => wrapFunctional(c.default || c)),
  AboutJoinUsDetail: () => import('../../components/Pages/About/JoinUs/Detail.vue' /* webpackChunkName: "components/about-join-us-detail" */).then(c => wrapFunctional(c.default || c)),
  AboutJoinUsFooter: () => import('../../components/Pages/About/JoinUs/Footer.vue' /* webpackChunkName: "components/about-join-us-footer" */).then(c => wrapFunctional(c.default || c)),
  AboutJoinUsJoblist: () => import('../../components/Pages/About/JoinUs/Joblist.vue' /* webpackChunkName: "components/about-join-us-joblist" */).then(c => wrapFunctional(c.default || c)),
  AboutJoinUsMain: () => import('../../components/Pages/About/JoinUs/Main.vue' /* webpackChunkName: "components/about-join-us-main" */).then(c => wrapFunctional(c.default || c)),
  AboutJoinUsShortcut: () => import('../../components/Pages/About/JoinUs/Shortcut.vue' /* webpackChunkName: "components/about-join-us-shortcut" */).then(c => wrapFunctional(c.default || c)),
  AboutJoinUsVDO: () => import('../../components/Pages/About/JoinUs/VDO.vue' /* webpackChunkName: "components/about-join-us-v-d-o" */).then(c => wrapFunctional(c.default || c)),
  AboutSetSourceCoverInsights: () => import('../../components/Pages/About/SetSource/CoverInsights.vue' /* webpackChunkName: "components/about-set-source-cover-insights" */).then(c => wrapFunctional(c.default || c)),
  AboutSetSourceTab: () => import('../../components/Pages/About/SetSource/Tab.vue' /* webpackChunkName: "components/about-set-source-tab" */).then(c => wrapFunctional(c.default || c)),
  DWOverviewTab: () => import('../../components/Pages/DW/Overview/Tab.vue' /* webpackChunkName: "components/d-w-overview-tab" */).then(c => wrapFunctional(c.default || c)),
  DWQuote: () => import('../../components/Pages/DW/Quote/index.vue' /* webpackChunkName: "components/d-w-quote" */).then(c => wrapFunctional(c.default || c)),
  DRXOverviewTab: () => import('../../components/Pages/DRX/Overview/Tab.vue' /* webpackChunkName: "components/d-r-x-overview-tab" */).then(c => wrapFunctional(c.default || c)),
  DRXQuoteTab: () => import('../../components/Pages/DRX/Quote/Tab.vue' /* webpackChunkName: "components/d-r-x-quote-tab" */).then(c => wrapFunctional(c.default || c)),
  DRXQuote: () => import('../../components/Pages/DRX/Quote/index.vue' /* webpackChunkName: "components/d-r-x-quote" */).then(c => wrapFunctional(c.default || c)),
  ETFOverviewTab: () => import('../../components/Pages/ETF/Overview/Tab.vue' /* webpackChunkName: "components/e-t-f-overview-tab" */).then(c => wrapFunctional(c.default || c)),
  ETFQuoteTab: () => import('../../components/Pages/ETF/Quote/Tab.vue' /* webpackChunkName: "components/e-t-f-quote-tab" */).then(c => wrapFunctional(c.default || c)),
  ETFQuote: () => import('../../components/Pages/ETF/Quote/index.vue' /* webpackChunkName: "components/e-t-f-quote" */).then(c => wrapFunctional(c.default || c)),
  EducationGlossaryCover: () => import('../../components/Pages/Education/Glossary/GlossaryCover.vue' /* webpackChunkName: "components/education-glossary-cover" */).then(c => wrapFunctional(c.default || c)),
  EducationGlossaryDetail: () => import('../../components/Pages/Education/Glossary/GlossaryDetail.vue' /* webpackChunkName: "components/education-glossary-detail" */).then(c => wrapFunctional(c.default || c)),
  EducationGlossaryEmpty: () => import('../../components/Pages/Education/Glossary/GlossaryEmpty.vue' /* webpackChunkName: "components/education-glossary-empty" */).then(c => wrapFunctional(c.default || c)),
  EducationGlossaryItem: () => import('../../components/Pages/Education/Glossary/GlossaryItem.vue' /* webpackChunkName: "components/education-glossary-item" */).then(c => wrapFunctional(c.default || c)),
  EducationGlossaryList: () => import('../../components/Pages/Education/Glossary/GlossaryList.vue' /* webpackChunkName: "components/education-glossary-list" */).then(c => wrapFunctional(c.default || c)),
  EducationGlossaryNavigation: () => import('../../components/Pages/Education/Glossary/GlossaryNavigation.vue' /* webpackChunkName: "components/education-glossary-navigation" */).then(c => wrapFunctional(c.default || c)),
  EducationGlossarySearchResult: () => import('../../components/Pages/Education/Glossary/GlossarySearchResult.vue' /* webpackChunkName: "components/education-glossary-search-result" */).then(c => wrapFunctional(c.default || c)),
  EducationGlossary: () => import('../../components/Pages/Education/Glossary/index.vue' /* webpackChunkName: "components/education-glossary" */).then(c => wrapFunctional(c.default || c)),
  EducationKnowledgeCardItem: () => import('../../components/Pages/Education/Knowledge/CardItem.vue' /* webpackChunkName: "components/education-knowledge-card-item" */).then(c => wrapFunctional(c.default || c)),
  EducationKnowledge: () => import('../../components/Pages/Education/Knowledge/Knowledge.vue' /* webpackChunkName: "components/education-knowledge" */).then(c => wrapFunctional(c.default || c)),
  EducationKnowledgeRelatePodcastPlaylist: () => import('../../components/Pages/Education/Knowledge/RelatePodcastPlaylist.vue' /* webpackChunkName: "components/education-knowledge-relate-podcast-playlist" */).then(c => wrapFunctional(c.default || c)),
  EducationKnowledgeRelateVideoPlaylist: () => import('../../components/Pages/Education/Knowledge/RelateVideoPlaylist.vue' /* webpackChunkName: "components/education-knowledge-relate-video-playlist" */).then(c => wrapFunctional(c.default || c)),
  EducationKnowledgeTab: () => import('../../components/Pages/Education/Knowledge/Tab.vue' /* webpackChunkName: "components/education-knowledge-tab" */).then(c => wrapFunctional(c.default || c)),
  EducationResearchAttachCollapse: () => import('../../components/Pages/Education/Research/AttachCollapse.vue' /* webpackChunkName: "components/education-research-attach-collapse" */).then(c => wrapFunctional(c.default || c)),
  EducationResearchAttachListItemContent: () => import('../../components/Pages/Education/Research/AttachListItemContent.vue' /* webpackChunkName: "components/education-research-attach-list-item-content" */).then(c => wrapFunctional(c.default || c)),
  EducationResearchContentCard: () => import('../../components/Pages/Education/Research/ContentCard.vue' /* webpackChunkName: "components/education-research-content-card" */).then(c => wrapFunctional(c.default || c)),
  EducationResearchCover: () => import('../../components/Pages/Education/Research/Cover.vue' /* webpackChunkName: "components/education-research-cover" */).then(c => wrapFunctional(c.default || c)),
  EducationResearchListItemContent: () => import('../../components/Pages/Education/Research/ListItemContent.vue' /* webpackChunkName: "components/education-research-list-item-content" */).then(c => wrapFunctional(c.default || c)),
  EducationResearchSearch: () => import('../../components/Pages/Education/Research/search.vue' /* webpackChunkName: "components/education-research-search" */).then(c => wrapFunctional(c.default || c)),
  EducationSearchResultItems: () => import('../../components/Pages/Education/SearchResult/Items.vue' /* webpackChunkName: "components/education-search-result-items" */).then(c => wrapFunctional(c.default || c)),
  EducationSearchResult: () => import('../../components/Pages/Education/SearchResult/SearchResult.vue' /* webpackChunkName: "components/education-search-result" */).then(c => wrapFunctional(c.default || c)),
  EducationSearchResultTab: () => import('../../components/Pages/Education/SearchResult/Tab.vue' /* webpackChunkName: "components/education-search-result-tab" */).then(c => wrapFunctional(c.default || c)),
  EducationSetBookCover: () => import('../../components/Pages/Education/SetBook/Cover.vue' /* webpackChunkName: "components/education-set-book-cover" */).then(c => wrapFunctional(c.default || c)),
  EducationSetBookHighligth: () => import('../../components/Pages/Education/SetBook/Highligth.vue' /* webpackChunkName: "components/education-set-book-highligth" */).then(c => wrapFunctional(c.default || c)),
  EducationSetBookItem: () => import('../../components/Pages/Education/SetBook/Item.vue' /* webpackChunkName: "components/education-set-book-item" */).then(c => wrapFunctional(c.default || c)),
  EducationSetBookItemHighlight: () => import('../../components/Pages/Education/SetBook/ItemHighlight.vue' /* webpackChunkName: "components/education-set-book-item-highlight" */).then(c => wrapFunctional(c.default || c)),
  EducationSetBookList: () => import('../../components/Pages/Education/SetBook/List.vue' /* webpackChunkName: "components/education-set-book-list" */).then(c => wrapFunctional(c.default || c)),
  EducationSetBookMain: () => import('../../components/Pages/Education/SetBook/Main.vue' /* webpackChunkName: "components/education-set-book-main" */).then(c => wrapFunctional(c.default || c)),
  EducationSetBookSearch: () => import('../../components/Pages/Education/SetBook/Search.vue' /* webpackChunkName: "components/education-set-book-search" */).then(c => wrapFunctional(c.default || c)),
  EducationSetBookTab: () => import('../../components/Pages/Education/SetBook/Tab.vue' /* webpackChunkName: "components/education-set-book-tab" */).then(c => wrapFunctional(c.default || c)),
  EducationSetBookTabMenu: () => import('../../components/Pages/Education/SetBook/TabMenu.vue' /* webpackChunkName: "components/education-set-book-tab-menu" */).then(c => wrapFunctional(c.default || c)),
  EducationSetBook: () => import('../../components/Pages/Education/SetBook/index.vue' /* webpackChunkName: "components/education-set-book" */).then(c => wrapFunctional(c.default || c)),
  InformationMemberList: () => import('../../components/Pages/Information/MemberList/index.vue' /* webpackChunkName: "components/information-member-list" */).then(c => wrapFunctional(c.default || c)),
  HomeAnnouncementAnnounceList: () => import('../../components/Pages/Home/Announcement/AnnounceList.vue' /* webpackChunkName: "components/home-announcement-announce-list" */).then(c => wrapFunctional(c.default || c)),
  HomeAnnouncement: () => import('../../components/Pages/Home/Announcement/Announcement.vue' /* webpackChunkName: "components/home-announcement" */).then(c => wrapFunctional(c.default || c)),
  HomeAnnouncementCardRelated: () => import('../../components/Pages/Home/Announcement/CardRelated.vue' /* webpackChunkName: "components/home-announcement-card-related" */).then(c => wrapFunctional(c.default || c)),
  HomeAnnouncementProcurement: () => import('../../components/Pages/Home/Announcement/Procurement.vue' /* webpackChunkName: "components/home-announcement-procurement" */).then(c => wrapFunctional(c.default || c)),
  HomeAnnouncementPublicRelations: () => import('../../components/Pages/Home/Announcement/PublicRelations.vue' /* webpackChunkName: "components/home-announcement-public-relations" */).then(c => wrapFunctional(c.default || c)),
  HomeCalendarActivity: () => import('../../components/Pages/Home/Calendar/Activity.vue' /* webpackChunkName: "components/home-calendar-activity" */).then(c => wrapFunctional(c.default || c)),
  HomeCalendar: () => import('../../components/Pages/Home/Calendar/Calendar.vue' /* webpackChunkName: "components/home-calendar" */).then(c => wrapFunctional(c.default || c)),
  HomeCalendarTab: () => import('../../components/Pages/Home/Calendar/CalendarTab.vue' /* webpackChunkName: "components/home-calendar-tab" */).then(c => wrapFunctional(c.default || c)),
  HomeCalendarCollapse: () => import('../../components/Pages/Home/Calendar/Collapse.vue' /* webpackChunkName: "components/home-calendar-collapse" */).then(c => wrapFunctional(c.default || c)),
  HomeCalendarCollapseMeeting: () => import('../../components/Pages/Home/Calendar/CollapseMeeting.vue' /* webpackChunkName: "components/home-calendar-collapse-meeting" */).then(c => wrapFunctional(c.default || c)),
  HomeCalendarEventActivity: () => import('../../components/Pages/Home/Calendar/EventActivity.vue' /* webpackChunkName: "components/home-calendar-event-activity" */).then(c => wrapFunctional(c.default || c)),
  HomeIPOFirstday: () => import('../../components/Pages/Home/IPO/Firstday.vue' /* webpackChunkName: "components/home-i-p-o-firstday" */).then(c => wrapFunctional(c.default || c)),
  HomeIPO: () => import('../../components/Pages/Home/IPO/IPO.vue' /* webpackChunkName: "components/home-i-p-o" */).then(c => wrapFunctional(c.default || c)),
  HomeIPORecent: () => import('../../components/Pages/Home/IPO/Recent.vue' /* webpackChunkName: "components/home-i-p-o-recent" */).then(c => wrapFunctional(c.default || c)),
  HomeIPOUpcoming: () => import('../../components/Pages/Home/IPO/Upcoming.vue' /* webpackChunkName: "components/home-i-p-o-upcoming" */).then(c => wrapFunctional(c.default || c)),
  HomeInFocusHighlight: () => import('../../components/Pages/Home/InFocus/Highlight.vue' /* webpackChunkName: "components/home-in-focus-highlight" */).then(c => wrapFunctional(c.default || c)),
  HomeInFocusMore: () => import('../../components/Pages/Home/InFocus/More.vue' /* webpackChunkName: "components/home-in-focus-more" */).then(c => wrapFunctional(c.default || c)),
  HomeInFocusShortcutPartner: () => import('../../components/Pages/Home/InFocus/ShortcutPartner.vue' /* webpackChunkName: "components/home-in-focus-shortcut-partner" */).then(c => wrapFunctional(c.default || c)),
  HomeInFocus: () => import('../../components/Pages/Home/InFocus/index.vue' /* webpackChunkName: "components/home-in-focus" */).then(c => wrapFunctional(c.default || c)),
  HomeMarketUpdateChartMai: () => import('../../components/Pages/Home/MarketUpdate/ChartMai.vue' /* webpackChunkName: "components/home-market-update-chart-mai" */).then(c => wrapFunctional(c.default || c)),
  HomeMarketUpdateChartSET: () => import('../../components/Pages/Home/MarketUpdate/ChartSET.vue' /* webpackChunkName: "components/home-market-update-chart-s-e-t" */).then(c => wrapFunctional(c.default || c)),
  HomeMarketUpdateDaySummaryTab: () => import('../../components/Pages/Home/MarketUpdate/DaySummaryTab.vue' /* webpackChunkName: "components/home-market-update-day-summary-tab" */).then(c => wrapFunctional(c.default || c)),
  HomeMarketUpdateDaySummaryTabChart: () => import('../../components/Pages/Home/MarketUpdate/DaySummaryTabChart.vue' /* webpackChunkName: "components/home-market-update-day-summary-tab-chart" */).then(c => wrapFunctional(c.default || c)),
  HomeMarketUpdateFTSESET: () => import('../../components/Pages/Home/MarketUpdate/FTSESET.vue' /* webpackChunkName: "components/home-market-update-f-t-s-e-s-e-t" */).then(c => wrapFunctional(c.default || c)),
  HomeMarketUpdateListIndex: () => import('../../components/Pages/Home/MarketUpdate/ListIndex.vue' /* webpackChunkName: "components/home-market-update-list-index" */).then(c => wrapFunctional(c.default || c)),
  HomeMarketUpdate: () => import('../../components/Pages/Home/MarketUpdate/MarketUpdate.vue' /* webpackChunkName: "components/home-market-update" */).then(c => wrapFunctional(c.default || c)),
  HomeMarketUpdateStock: () => import('../../components/Pages/Home/MarketUpdate/MarketUpdateStock.vue' /* webpackChunkName: "components/home-market-update-stock" */).then(c => wrapFunctional(c.default || c)),
  HomeMarketUpdateSetTRI: () => import('../../components/Pages/Home/MarketUpdate/SetTRI.vue' /* webpackChunkName: "components/home-market-update-set-t-r-i" */).then(c => wrapFunctional(c.default || c)),
  HomeMarketUpdateStatus: () => import('../../components/Pages/Home/MarketUpdate/Status.vue' /* webpackChunkName: "components/home-market-update-status" */).then(c => wrapFunctional(c.default || c)),
  HomeMarketUpdateTableDaySummary: () => import('../../components/Pages/Home/MarketUpdate/TableDaySummary.vue' /* webpackChunkName: "components/home-market-update-table-day-summary" */).then(c => wrapFunctional(c.default || c)),
  HomeOppdayArchive: () => import('../../components/Pages/Home/Oppday/Archive.vue' /* webpackChunkName: "components/home-oppday-archive" */).then(c => wrapFunctional(c.default || c)),
  HomeOppday: () => import('../../components/Pages/Home/Oppday/Oppday.vue' /* webpackChunkName: "components/home-oppday" */).then(c => wrapFunctional(c.default || c)),
  HomeOppdayUpcoming: () => import('../../components/Pages/Home/Oppday/Upcoming.vue' /* webpackChunkName: "components/home-oppday-upcoming" */).then(c => wrapFunctional(c.default || c)),
  HomeSetNewsItem: () => import('../../components/Pages/Home/SetNews/Item.vue' /* webpackChunkName: "components/home-set-news-item" */).then(c => wrapFunctional(c.default || c)),
  HomeSetNews: () => import('../../components/Pages/Home/SetNews/SetNews.vue' /* webpackChunkName: "components/home-set-news" */).then(c => wrapFunctional(c.default || c)),
  HomeShortcutStakeholderItem: () => import('../../components/Pages/Home/ShortcutStakeholder/Item.vue' /* webpackChunkName: "components/home-shortcut-stakeholder-item" */).then(c => wrapFunctional(c.default || c)),
  HomeShortcutStakeholderMenu: () => import('../../components/Pages/Home/ShortcutStakeholder/Menu.vue' /* webpackChunkName: "components/home-shortcut-stakeholder-menu" */).then(c => wrapFunctional(c.default || c)),
  HomeShortcutStakeholderMenuItem: () => import('../../components/Pages/Home/ShortcutStakeholder/MenuItem.vue' /* webpackChunkName: "components/home-shortcut-stakeholder-menu-item" */).then(c => wrapFunctional(c.default || c)),
  HomeShortcutStakeholder: () => import('../../components/Pages/Home/ShortcutStakeholder/index.vue' /* webpackChunkName: "components/home-shortcut-stakeholder" */).then(c => wrapFunctional(c.default || c)),
  HomeTFEXMarketOverview: () => import('../../components/Pages/Home/TFEX/MarketOverview.vue' /* webpackChunkName: "components/home-t-f-e-x-market-overview" */).then(c => wrapFunctional(c.default || c)),
  HomeTFEXStatus: () => import('../../components/Pages/Home/TFEX/Status.vue' /* webpackChunkName: "components/home-t-f-e-x-status" */).then(c => wrapFunctional(c.default || c)),
  HomeTFEX: () => import('../../components/Pages/Home/TFEX/TFEX.vue' /* webpackChunkName: "components/home-t-f-e-x" */).then(c => wrapFunctional(c.default || c)),
  HomeTFEXTableTFEX: () => import('../../components/Pages/Home/TFEX/TableTFEX.vue' /* webpackChunkName: "components/home-t-f-e-x-table-t-f-e-x" */).then(c => wrapFunctional(c.default || c)),
  ListingIpoHeader: () => import('../../components/Pages/Listing/Ipo/Header.vue' /* webpackChunkName: "components/listing-ipo-header" */).then(c => wrapFunctional(c.default || c)),
  ListingIpo: () => import('../../components/Pages/Listing/Ipo/index.vue' /* webpackChunkName: "components/listing-ipo" */).then(c => wrapFunctional(c.default || c)),
  ListingOtherInstrumentsTab: () => import('../../components/Pages/Listing/OtherInstruments/Tab.vue' /* webpackChunkName: "components/listing-other-instruments-tab" */).then(c => wrapFunctional(c.default || c)),
  ListingOtherInstruments: () => import('../../components/Pages/Listing/OtherInstruments/index.vue' /* webpackChunkName: "components/listing-other-instruments" */).then(c => wrapFunctional(c.default || c)),
  DROverviewTab: () => import('../../components/Pages/DR/Overview/Tab.vue' /* webpackChunkName: "components/d-r-overview-tab" */).then(c => wrapFunctional(c.default || c)),
  DRQuoteTab: () => import('../../components/Pages/DR/Quote/Tab.vue' /* webpackChunkName: "components/d-r-quote-tab" */).then(c => wrapFunctional(c.default || c)),
  DRQuote: () => import('../../components/Pages/DR/Quote/index.vue' /* webpackChunkName: "components/d-r-quote" */).then(c => wrapFunctional(c.default || c)),
  MarketFactSheet: () => import('../../components/Pages/Market/FactSheet/index.vue' /* webpackChunkName: "components/market-fact-sheet" */).then(c => wrapFunctional(c.default || c)),
  MarketGetQuoteChildContent: () => import('../../components/Pages/Market/GetQuote/ChildContent.vue' /* webpackChunkName: "components/market-get-quote-child-content" */).then(c => wrapFunctional(c.default || c)),
  MarketGetQuoteContent: () => import('../../components/Pages/Market/GetQuote/Content.vue' /* webpackChunkName: "components/market-get-quote-content" */).then(c => wrapFunctional(c.default || c)),
  MarketGetQuoteFooter: () => import('../../components/Pages/Market/GetQuote/Footer.vue' /* webpackChunkName: "components/market-get-quote-footer" */).then(c => wrapFunctional(c.default || c)),
  MarketGetQuoteForeignBtn: () => import('../../components/Pages/Market/GetQuote/ForeignBtn.vue' /* webpackChunkName: "components/market-get-quote-foreign-btn" */).then(c => wrapFunctional(c.default || c)),
  MarketGetQuoteHeader: () => import('../../components/Pages/Market/GetQuote/Header.vue' /* webpackChunkName: "components/market-get-quote-header" */).then(c => wrapFunctional(c.default || c)),
  MarketGetQuoteTable: () => import('../../components/Pages/Market/GetQuote/Table.vue' /* webpackChunkName: "components/market-get-quote-table" */).then(c => wrapFunctional(c.default || c)),
  MarketOverviewContentReadMore: () => import('../../components/Pages/Market/Overview/ContentReadMore.vue' /* webpackChunkName: "components/market-overview-content-read-more" */).then(c => wrapFunctional(c.default || c)),
  MarketOverviewFooter: () => import('../../components/Pages/Market/Overview/Footer.vue' /* webpackChunkName: "components/market-overview-footer" */).then(c => wrapFunctional(c.default || c)),
  MarketOverviewHeader: () => import('../../components/Pages/Market/Overview/Header.vue' /* webpackChunkName: "components/market-overview-header" */).then(c => wrapFunctional(c.default || c)),
  MarketCardHighlight: () => import('../../components/Pages/Market/Overview/MarketCardHighlight.vue' /* webpackChunkName: "components/market-card-highlight" */).then(c => wrapFunctional(c.default || c)),
  MarketIndexOverviewTab: () => import('../../components/Pages/Market/Overview/MarketIndexOverviewTab.vue' /* webpackChunkName: "components/market-index-overview-tab" */).then(c => wrapFunctional(c.default || c)),
  MarketOverviewProductCardOverview: () => import('../../components/Pages/Market/Overview/ProductCardOverview.vue' /* webpackChunkName: "components/market-overview-product-card-overview" */).then(c => wrapFunctional(c.default || c)),
  MarketOverview: () => import('../../components/Pages/Market/Overview/index.vue' /* webpackChunkName: "components/market-overview" */).then(c => wrapFunctional(c.default || c)),
  MarketStockCalendarBenefitsCollapse: () => import('../../components/Pages/Market/StockCalendar/BenefitsCollapse.vue' /* webpackChunkName: "components/market-stock-calendar-benefits-collapse" */).then(c => wrapFunctional(c.default || c)),
  MarketStockCalendarHeader: () => import('../../components/Pages/Market/StockCalendar/Header.vue' /* webpackChunkName: "components/market-stock-calendar-header" */).then(c => wrapFunctional(c.default || c)),
  MarketStockCalendarXBadge: () => import('../../components/Pages/Market/StockCalendar/XBadge.vue' /* webpackChunkName: "components/market-stock-calendar-x-badge" */).then(c => wrapFunctional(c.default || c)),
  MemberFavoriteCalendar: () => import('../../components/Pages/Member/Favorite/Calendar.vue' /* webpackChunkName: "components/member-favorite-calendar" */).then(c => wrapFunctional(c.default || c)),
  MemberFavoriteFinancialStatements: () => import('../../components/Pages/Member/Favorite/FinancialStatements.vue' /* webpackChunkName: "components/member-favorite-financial-statements" */).then(c => wrapFunctional(c.default || c)),
  MemberFavoriteFormLists: () => import('../../components/Pages/Member/Favorite/FormLists.vue' /* webpackChunkName: "components/member-favorite-form-lists" */).then(c => wrapFunctional(c.default || c)),
  MemberFavoriteMessageFollow: () => import('../../components/Pages/Member/Favorite/MessageFollow.vue' /* webpackChunkName: "components/member-favorite-message-follow" */).then(c => wrapFunctional(c.default || c)),
  MemberFavoriteMessageLimitFollow: () => import('../../components/Pages/Member/Favorite/MessageLimitFollow.vue' /* webpackChunkName: "components/member-favorite-message-limit-follow" */).then(c => wrapFunctional(c.default || c)),
  MemberFavoriteNews: () => import('../../components/Pages/Member/Favorite/News.vue' /* webpackChunkName: "components/member-favorite-news" */).then(c => wrapFunctional(c.default || c)),
  MemberFavoriteOpportunity: () => import('../../components/Pages/Member/Favorite/Opportunity.vue' /* webpackChunkName: "components/member-favorite-opportunity" */).then(c => wrapFunctional(c.default || c)),
  MemberFavoriteSearch: () => import('../../components/Pages/Member/Favorite/Search.vue' /* webpackChunkName: "components/member-favorite-search" */).then(c => wrapFunctional(c.default || c)),
  MemberFavoriteTab: () => import('../../components/Pages/Member/Favorite/Tab.vue' /* webpackChunkName: "components/member-favorite-tab" */).then(c => wrapFunctional(c.default || c)),
  MemberFavoriteTable: () => import('../../components/Pages/Member/Favorite/Table.vue' /* webpackChunkName: "components/member-favorite-table" */).then(c => wrapFunctional(c.default || c)),
  MemberFavoriteTableDRx: () => import('../../components/Pages/Member/Favorite/TableDRx.vue' /* webpackChunkName: "components/member-favorite-table-d-rx" */).then(c => wrapFunctional(c.default || c)),
  MemberFavoriteTableDW: () => import('../../components/Pages/Member/Favorite/TableDW.vue' /* webpackChunkName: "components/member-favorite-table-d-w" */).then(c => wrapFunctional(c.default || c)),
  MemberFavoriteTableETF: () => import('../../components/Pages/Member/Favorite/TableETF.vue' /* webpackChunkName: "components/member-favorite-table-e-t-f" */).then(c => wrapFunctional(c.default || c)),
  MemberFavorite: () => import('../../components/Pages/Member/Favorite/index.vue' /* webpackChunkName: "components/member-favorite" */).then(c => wrapFunctional(c.default || c)),
  MemberMyProfileDropdownBox: () => import('../../components/Pages/Member/MyProfile/DropdownBox.vue' /* webpackChunkName: "components/member-my-profile-dropdown-box" */).then(c => wrapFunctional(c.default || c)),
  MemberMyProfileMain: () => import('../../components/Pages/Member/MyProfile/Main.vue' /* webpackChunkName: "components/member-my-profile-main" */).then(c => wrapFunctional(c.default || c)),
  MemberMyProfileMenu: () => import('../../components/Pages/Member/MyProfile/Menu.vue' /* webpackChunkName: "components/member-my-profile-menu" */).then(c => wrapFunctional(c.default || c)),
  MemberMyProfilePrivilegeItem: () => import('../../components/Pages/Member/MyProfile/PrivilegeItem.vue' /* webpackChunkName: "components/member-my-profile-privilege-item" */).then(c => wrapFunctional(c.default || c)),
  MemberMyProfileSetMemberPrivilege: () => import('../../components/Pages/Member/MyProfile/SetMemberPrivilege.vue' /* webpackChunkName: "components/member-my-profile-set-member-privilege" */).then(c => wrapFunctional(c.default || c)),
  MemberMyProfile: () => import('../../components/Pages/Member/MyProfile/index.vue' /* webpackChunkName: "components/member-my-profile" */).then(c => wrapFunctional(c.default || c)),
  NewsAndAlertAuditorsOpinionFilter: () => import('../../components/Pages/NewsAndAlert/AuditorsOpinion/Filter.vue' /* webpackChunkName: "components/news-and-alert-auditors-opinion-filter" */).then(c => wrapFunctional(c.default || c)),
  NewsAndAlertAuditorsOpinion: () => import('../../components/Pages/NewsAndAlert/AuditorsOpinion/index.vue' /* webpackChunkName: "components/news-and-alert-auditors-opinion" */).then(c => wrapFunctional(c.default || c)),
  NewsAndAlertCashBalance: () => import('../../components/Pages/NewsAndAlert/CashBalance/index.vue' /* webpackChunkName: "components/news-and-alert-cash-balance" */).then(c => wrapFunctional(c.default || c)),
  NewsAndAlertMarketAlertItems: () => import('../../components/Pages/NewsAndAlert/MarketAlert/Items.vue' /* webpackChunkName: "components/news-and-alert-market-alert-items" */).then(c => wrapFunctional(c.default || c)),
  NewsAndAlertMarketAlert: () => import('../../components/Pages/NewsAndAlert/MarketAlert/index.vue' /* webpackChunkName: "components/news-and-alert-market-alert" */).then(c => wrapFunctional(c.default || c)),
  NewsAndAlertMarketClarification: () => import('../../components/Pages/NewsAndAlert/MarketClarification/index.vue' /* webpackChunkName: "components/news-and-alert-market-clarification" */).then(c => wrapFunctional(c.default || c)),
  NewsAndAlertNews: () => import('../../components/Pages/NewsAndAlert/News/index.vue' /* webpackChunkName: "components/news-and-alert-news" */).then(c => wrapFunctional(c.default || c)),
  NewsAndAlertNewsDetails: () => import('../../components/Pages/NewsAndAlert/NewsDetails/index.vue' /* webpackChunkName: "components/news-and-alert-news-details" */).then(c => wrapFunctional(c.default || c)),
  NewsAndAlertSignPostingFilter: () => import('../../components/Pages/NewsAndAlert/SignPosting/Filter.vue' /* webpackChunkName: "components/news-and-alert-sign-posting-filter" */).then(c => wrapFunctional(c.default || c)),
  NewsAndAlertSignPosting: () => import('../../components/Pages/NewsAndAlert/SignPosting/index.vue' /* webpackChunkName: "components/news-and-alert-sign-posting" */).then(c => wrapFunctional(c.default || c)),
  NewsAndAlertSurveillanceCSignTemporaryTradingFilter: () => import('../../components/Pages/NewsAndAlert/SurveillanceCSignTemporaryTrading/Filter.vue' /* webpackChunkName: "components/news-and-alert-surveillance-c-sign-temporary-trading-filter" */).then(c => wrapFunctional(c.default || c)),
  NewsAndAlertSurveillanceCSignTemporaryTradingLevel: () => import('../../components/Pages/NewsAndAlert/SurveillanceCSignTemporaryTrading/Level.vue' /* webpackChunkName: "components/news-and-alert-surveillance-c-sign-temporary-trading-level" */).then(c => wrapFunctional(c.default || c)),
  NewsAndAlertSurveillanceCSignTemporaryTradingMarketMeasureList: () => import('../../components/Pages/NewsAndAlert/SurveillanceCSignTemporaryTrading/MarketMeasureList.vue' /* webpackChunkName: "components/news-and-alert-surveillance-c-sign-temporary-trading-market-measure-list" */).then(c => wrapFunctional(c.default || c)),
  NewsAndAlertSurveillanceCSignTemporaryTradingSummary: () => import('../../components/Pages/NewsAndAlert/SurveillanceCSignTemporaryTrading/Summary.vue' /* webpackChunkName: "components/news-and-alert-surveillance-c-sign-temporary-trading-summary" */).then(c => wrapFunctional(c.default || c)),
  NewsAndAlertSurveillanceCSignTemporaryTrading: () => import('../../components/Pages/NewsAndAlert/SurveillanceCSignTemporaryTrading/SurveillanceCSignTemporaryTrading.vue' /* webpackChunkName: "components/news-and-alert-surveillance-c-sign-temporary-trading" */).then(c => wrapFunctional(c.default || c)),
  NewsAndAlertSurveillanceCSignTemporaryTradingCSign: () => import('../../components/Pages/NewsAndAlert/SurveillanceCSignTemporaryTrading/SurveillanceCSignTemporaryTradingCSign.vue' /* webpackChunkName: "components/news-and-alert-surveillance-c-sign-temporary-trading-c-sign" */).then(c => wrapFunctional(c.default || c)),
  NewsAndAlertSurveillanceCSignTemporaryTradingTab: () => import('../../components/Pages/NewsAndAlert/SurveillanceCSignTemporaryTrading/Tab.vue' /* webpackChunkName: "components/news-and-alert-surveillance-c-sign-temporary-trading-tab" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksCanceledContent: () => import('../../components/Pages/RulesBooks/Canceled/Content.vue' /* webpackChunkName: "components/rules-books-canceled-content" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksCanceledCover: () => import('../../components/Pages/RulesBooks/Canceled/Cover.vue' /* webpackChunkName: "components/rules-books-canceled-cover" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksCanceledFilter: () => import('../../components/Pages/RulesBooks/Canceled/Filter.vue' /* webpackChunkName: "components/rules-books-canceled-filter" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksCanceled: () => import('../../components/Pages/RulesBooks/Canceled/index.vue' /* webpackChunkName: "components/rules-books-canceled" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksCategory: () => import('../../components/Pages/RulesBooks/Category/Category.vue' /* webpackChunkName: "components/rules-books-category" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksCategoryCollapse: () => import('../../components/Pages/RulesBooks/Category/Collapse.vue' /* webpackChunkName: "components/rules-books-category-collapse" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksItemCategoryList: () => import('../../components/Pages/RulesBooks/Item/CategoryList.vue' /* webpackChunkName: "components/rules-books-item-category-list" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksItemChild: () => import('../../components/Pages/RulesBooks/Item/Child.vue' /* webpackChunkName: "components/rules-books-item-child" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksItem: () => import('../../components/Pages/RulesBooks/Item/Item.vue' /* webpackChunkName: "components/rules-books-item" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksItemRuleUpdate: () => import('../../components/Pages/RulesBooks/Item/RuleUpdate.vue' /* webpackChunkName: "components/rules-books-item-rule-update" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksRenderRecursiveItem: () => import('../../components/Pages/RulesBooks/RenderRecursive/Item.vue' /* webpackChunkName: "components/rules-books-render-recursive-item" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksRenderRecursive: () => import('../../components/Pages/RulesBooks/RenderRecursive/RenderRecursive.vue' /* webpackChunkName: "components/rules-books-render-recursive" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksSearchResult: () => import('../../components/Pages/RulesBooks/SearchResult/SearchResult.vue' /* webpackChunkName: "components/rules-books-search-result" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksSearchResultList: () => import('../../components/Pages/RulesBooks/SearchResult/SearchResultList.vue' /* webpackChunkName: "components/rules-books-search-result-list" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksSecuritiesSymbolCover: () => import('../../components/Pages/RulesBooks/SecuritiesSymbol/Cover.vue' /* webpackChunkName: "components/rules-books-securities-symbol-cover" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksSecuritiesSymbolTable: () => import('../../components/Pages/RulesBooks/SecuritiesSymbol/Table.vue' /* webpackChunkName: "components/rules-books-securities-symbol-table" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksSecuritiesSymbol: () => import('../../components/Pages/RulesBooks/SecuritiesSymbol/index.vue' /* webpackChunkName: "components/rules-books-securities-symbol" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksUpdateCirculars: () => import('../../components/Pages/RulesBooks/Update/Circulars.vue' /* webpackChunkName: "components/rules-books-update-circulars" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksUpdateForms: () => import('../../components/Pages/RulesBooks/Update/Forms.vue' /* webpackChunkName: "components/rules-books-update-forms" */).then(c => wrapFunctional(c.default || c)),
  RulesBooksUpdateRegulations: () => import('../../components/Pages/RulesBooks/Update/Regulations.vue' /* webpackChunkName: "components/rules-books-update-regulations" */).then(c => wrapFunctional(c.default || c)),
  SearchResultContentAttachItem: () => import('../../components/Pages/SearchResult/Content/AttachItem.vue' /* webpackChunkName: "components/search-result-content-attach-item" */).then(c => wrapFunctional(c.default || c)),
  SearchResultContentItem: () => import('../../components/Pages/SearchResult/Content/ContentItem.vue' /* webpackChunkName: "components/search-result-content-item" */).then(c => wrapFunctional(c.default || c)),
  SearchResultContentEducationItem: () => import('../../components/Pages/SearchResult/Content/EducationItem.vue' /* webpackChunkName: "components/search-result-content-education-item" */).then(c => wrapFunctional(c.default || c)),
  SearchResultContentOverview: () => import('../../components/Pages/SearchResult/Content/Overview.vue' /* webpackChunkName: "components/search-result-content-overview" */).then(c => wrapFunctional(c.default || c)),
  SearchResultContentResearchItem: () => import('../../components/Pages/SearchResult/Content/ResearchItem.vue' /* webpackChunkName: "components/search-result-content-research-item" */).then(c => wrapFunctional(c.default || c)),
  SearchResultContentRulebookItem: () => import('../../components/Pages/SearchResult/Content/RulebookItem.vue' /* webpackChunkName: "components/search-result-content-rulebook-item" */).then(c => wrapFunctional(c.default || c)),
  SearchResultDropdownContent: () => import('../../components/Pages/SearchResult/Dropdown/Content.vue' /* webpackChunkName: "components/search-result-dropdown-content" */).then(c => wrapFunctional(c.default || c)),
  SearchResultDropdownMarket: () => import('../../components/Pages/SearchResult/Dropdown/Market.vue' /* webpackChunkName: "components/search-result-dropdown-market" */).then(c => wrapFunctional(c.default || c)),
  SearchResultDropdownSecurities: () => import('../../components/Pages/SearchResult/Dropdown/Securities.vue' /* webpackChunkName: "components/search-result-dropdown-securities" */).then(c => wrapFunctional(c.default || c)),
  SearchResultDropdownTreadingUp: () => import('../../components/Pages/SearchResult/Dropdown/TreadingUp.vue' /* webpackChunkName: "components/search-result-dropdown-treading-up" */).then(c => wrapFunctional(c.default || c)),
  SearchResultDropdown: () => import('../../components/Pages/SearchResult/Dropdown/index.vue' /* webpackChunkName: "components/search-result-dropdown" */).then(c => wrapFunctional(c.default || c)),
  StatisticsFiveDaysTableLast: () => import('../../components/Pages/Statistics/FiveDays/TableLast.vue' /* webpackChunkName: "components/statistics-five-days-table-last" */).then(c => wrapFunctional(c.default || c)),
  StatisticsFiveDays: () => import('../../components/Pages/Statistics/FiveDays/index.vue' /* webpackChunkName: "components/statistics-five-days" */).then(c => wrapFunctional(c.default || c)),
  StatisticsInvestorType: () => import('../../components/Pages/Statistics/InvestorType/index.vue' /* webpackChunkName: "components/statistics-investor-type" */).then(c => wrapFunctional(c.default || c)),
  StatisticsNvdrFooter: () => import('../../components/Pages/Statistics/Nvdr/Footer.vue' /* webpackChunkName: "components/statistics-nvdr-footer" */).then(c => wrapFunctional(c.default || c)),
  StatisticsNvdrTabs: () => import('../../components/Pages/Statistics/Nvdr/Tabs.vue' /* webpackChunkName: "components/statistics-nvdr-tabs" */).then(c => wrapFunctional(c.default || c)),
  StatisticsNvdr: () => import('../../components/Pages/Statistics/Nvdr/index.vue' /* webpackChunkName: "components/statistics-nvdr" */).then(c => wrapFunctional(c.default || c)),
  StatisticsProgramTradingValueDatePicker: () => import('../../components/Pages/Statistics/ProgramTradingValue/DatePicker.vue' /* webpackChunkName: "components/statistics-program-trading-value-date-picker" */).then(c => wrapFunctional(c.default || c)),
  StatisticsProgramTradingValueTableFilterStockList: () => import('../../components/Pages/Statistics/ProgramTradingValue/TableFilterStockList.vue' /* webpackChunkName: "components/statistics-program-trading-value-table-filter-stock-list" */).then(c => wrapFunctional(c.default || c)),
  StatisticsProgramTradingValueTableSummary: () => import('../../components/Pages/Statistics/ProgramTradingValue/TableSummary.vue' /* webpackChunkName: "components/statistics-program-trading-value-table-summary" */).then(c => wrapFunctional(c.default || c)),
  StatisticsProgramTradingValue: () => import('../../components/Pages/Statistics/ProgramTradingValue/index.vue' /* webpackChunkName: "components/statistics-program-trading-value" */).then(c => wrapFunctional(c.default || c)),
  StatisticsShortsalesFilters: () => import('../../components/Pages/Statistics/Shortsales/Filters.vue' /* webpackChunkName: "components/statistics-shortsales-filters" */).then(c => wrapFunctional(c.default || c)),
  StatisticsShortsalesMain: () => import('../../components/Pages/Statistics/Shortsales/Main.vue' /* webpackChunkName: "components/statistics-shortsales-main" */).then(c => wrapFunctional(c.default || c)),
  StatisticsShortsalesTableOverview: () => import('../../components/Pages/Statistics/Shortsales/TableOverview.vue' /* webpackChunkName: "components/statistics-shortsales-table-overview" */).then(c => wrapFunctional(c.default || c)),
  StatisticsShortsales: () => import('../../components/Pages/Statistics/Shortsales/index.vue' /* webpackChunkName: "components/statistics-shortsales" */).then(c => wrapFunctional(c.default || c)),
  StatisticsShortsalesTable: () => import('../../components/Pages/Statistics/Shortsales/table.vue' /* webpackChunkName: "components/statistics-shortsales-table" */).then(c => wrapFunctional(c.default || c)),
  StockFactSheet: () => import('../../components/Pages/Stock/FactSheet/index.vue' /* webpackChunkName: "components/stock-fact-sheet" */).then(c => wrapFunctional(c.default || c)),
  StockOverviewTab: () => import('../../components/Pages/Stock/Overview/Tab.vue' /* webpackChunkName: "components/stock-overview-tab" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteTab: () => import('../../components/Pages/Stock/Quote/Tab.vue' /* webpackChunkName: "components/stock-quote-tab" */).then(c => wrapFunctional(c.default || c)),
  StockQuote: () => import('../../components/Pages/Stock/Quote/index.vue' /* webpackChunkName: "components/stock-quote" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteF: () => import('../../components/Pages/Stock/QuoteF/index.vue' /* webpackChunkName: "components/stock-quote-f" */).then(c => wrapFunctional(c.default || c)),
  StockQuotePTab: () => import('../../components/Pages/Stock/QuoteP/Tab.vue' /* webpackChunkName: "components/stock-quote-p-tab" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteP: () => import('../../components/Pages/Stock/QuoteP/index.vue' /* webpackChunkName: "components/stock-quote-p" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteUTab: () => import('../../components/Pages/Stock/QuoteU/Tab.vue' /* webpackChunkName: "components/stock-quote-u-tab" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteU: () => import('../../components/Pages/Stock/QuoteU/index.vue' /* webpackChunkName: "components/stock-quote-u" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteWTab: () => import('../../components/Pages/Stock/QuoteW/Tab.vue' /* webpackChunkName: "components/stock-quote-w-tab" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteW: () => import('../../components/Pages/Stock/QuoteW/index.vue' /* webpackChunkName: "components/stock-quote-w" */).then(c => wrapFunctional(c.default || c)),
  TchOverviewBanner: () => import('../../components/Pages/Tch/Overview/Banner.vue' /* webpackChunkName: "components/tch-overview-banner" */).then(c => wrapFunctional(c.default || c)),
  TchOverviewContact: () => import('../../components/Pages/Tch/Overview/Contact.vue' /* webpackChunkName: "components/tch-overview-contact" */).then(c => wrapFunctional(c.default || c)),
  TchOverviewLink: () => import('../../components/Pages/Tch/Overview/Link.vue' /* webpackChunkName: "components/tch-overview-link" */).then(c => wrapFunctional(c.default || c)),
  TchOverviewNews: () => import('../../components/Pages/Tch/Overview/News.vue' /* webpackChunkName: "components/tch-overview-news" */).then(c => wrapFunctional(c.default || c)),
  TchOverview: () => import('../../components/Pages/Tch/Overview/Overview.vue' /* webpackChunkName: "components/tch-overview" */).then(c => wrapFunctional(c.default || c)),
  TchOverviewRelateLink: () => import('../../components/Pages/Tch/Overview/RelateLink.vue' /* webpackChunkName: "components/tch-overview-relate-link" */).then(c => wrapFunctional(c.default || c)),
  TchOverviewStatistics: () => import('../../components/Pages/Tch/Overview/Statistics.vue' /* webpackChunkName: "components/tch-overview-statistics" */).then(c => wrapFunctional(c.default || c)),
  TsdInformationCompanyList: () => import('../../components/Pages/Tsd/Information/CompanyList.vue' /* webpackChunkName: "components/tsd-information-company-list" */).then(c => wrapFunctional(c.default || c)),
  TsdInformationInformCover: () => import('../../components/Pages/Tsd/Information/InformCover.vue' /* webpackChunkName: "components/tsd-information-inform-cover" */).then(c => wrapFunctional(c.default || c)),
  TsdInformationIsinCfiFisn: () => import('../../components/Pages/Tsd/Information/IsinCfiFisn.vue' /* webpackChunkName: "components/tsd-information-isin-cfi-fisn" */).then(c => wrapFunctional(c.default || c)),
  TsdInformationSearch: () => import('../../components/Pages/Tsd/Information/Search.vue' /* webpackChunkName: "components/tsd-information-search" */).then(c => wrapFunctional(c.default || c)),
  TsdInformationSearchResult: () => import('../../components/Pages/Tsd/Information/SearchResult.vue' /* webpackChunkName: "components/tsd-information-search-result" */).then(c => wrapFunctional(c.default || c)),
  TsdOverviewBanner: () => import('../../components/Pages/Tsd/Overview/Banner.vue' /* webpackChunkName: "components/tsd-overview-banner" */).then(c => wrapFunctional(c.default || c)),
  TsdOverviewContact: () => import('../../components/Pages/Tsd/Overview/Contact.vue' /* webpackChunkName: "components/tsd-overview-contact" */).then(c => wrapFunctional(c.default || c)),
  TsdOverviewLink: () => import('../../components/Pages/Tsd/Overview/Link.vue' /* webpackChunkName: "components/tsd-overview-link" */).then(c => wrapFunctional(c.default || c)),
  TsdOverviewNews: () => import('../../components/Pages/Tsd/Overview/News.vue' /* webpackChunkName: "components/tsd-overview-news" */).then(c => wrapFunctional(c.default || c)),
  TsdOverview: () => import('../../components/Pages/Tsd/Overview/Overview.vue' /* webpackChunkName: "components/tsd-overview" */).then(c => wrapFunctional(c.default || c)),
  TsdOverviewRelateLink: () => import('../../components/Pages/Tsd/Overview/RelateLink.vue' /* webpackChunkName: "components/tsd-overview-relate-link" */).then(c => wrapFunctional(c.default || c)),
  TsdOverviewService: () => import('../../components/Pages/Tsd/Overview/Service.vue' /* webpackChunkName: "components/tsd-overview-service" */).then(c => wrapFunctional(c.default || c)),
  AboutEventDetails: () => import('../../components/Pages/About/Event/Details/index.vue' /* webpackChunkName: "components/about-event-details" */).then(c => wrapFunctional(c.default || c)),
  AboutEventDetailsPlaceholder: () => import('../../components/Pages/About/Event/Details/placeholder.vue' /* webpackChunkName: "components/about-event-details-placeholder" */).then(c => wrapFunctional(c.default || c)),
  AboutEventOverviewContentCard: () => import('../../components/Pages/About/Event/Overview/ContentCard.vue' /* webpackChunkName: "components/about-event-overview-content-card" */).then(c => wrapFunctional(c.default || c)),
  AboutEventOverviewTabContent: () => import('../../components/Pages/About/Event/Overview/TabContent.vue' /* webpackChunkName: "components/about-event-overview-tab-content" */).then(c => wrapFunctional(c.default || c)),
  AboutEventOverview: () => import('../../components/Pages/About/Event/Overview/index.vue' /* webpackChunkName: "components/about-event-overview" */).then(c => wrapFunctional(c.default || c)),
  AboutSetSourceCategoriesItems: () => import('../../components/Pages/About/SetSource/Categories/Items.vue' /* webpackChunkName: "components/about-set-source-categories-items" */).then(c => wrapFunctional(c.default || c)),
  AboutSetSourceCategoriesTab: () => import('../../components/Pages/About/SetSource/Categories/Tab.vue' /* webpackChunkName: "components/about-set-source-categories-tab" */).then(c => wrapFunctional(c.default || c)),
  AboutSetSourceCategories: () => import('../../components/Pages/About/SetSource/Categories/index.vue' /* webpackChunkName: "components/about-set-source-categories" */).then(c => wrapFunctional(c.default || c)),
  AboutSetSourceSearchResultContent: () => import('../../components/Pages/About/SetSource/SearchResult/Content.vue' /* webpackChunkName: "components/about-set-source-search-result-content" */).then(c => wrapFunctional(c.default || c)),
  AboutSetSourceSearchResultCover: () => import('../../components/Pages/About/SetSource/SearchResult/Cover.vue' /* webpackChunkName: "components/about-set-source-search-result-cover" */).then(c => wrapFunctional(c.default || c)),
  AboutSetSourceSearchResult: () => import('../../components/Pages/About/SetSource/SearchResult/index.vue' /* webpackChunkName: "components/about-set-source-search-result" */).then(c => wrapFunctional(c.default || c)),
  AboutSetSourceVideoBanner: () => import('../../components/Pages/About/SetSource/Video/Banner.vue' /* webpackChunkName: "components/about-set-source-video-banner" */).then(c => wrapFunctional(c.default || c)),
  AboutSetSourceVideoListByCategory: () => import('../../components/Pages/About/SetSource/Video/ListByCategory.vue' /* webpackChunkName: "components/about-set-source-video-list-by-category" */).then(c => wrapFunctional(c.default || c)),
  AboutSetSourceVideoRecommend: () => import('../../components/Pages/About/SetSource/Video/Recommend.vue' /* webpackChunkName: "components/about-set-source-video-recommend" */).then(c => wrapFunctional(c.default || c)),
  AboutSetSourceVideo: () => import('../../components/Pages/About/SetSource/Video/index.vue' /* webpackChunkName: "components/about-set-source-video" */).then(c => wrapFunctional(c.default || c)),
  AboutSetSourceYaekYaeBanner: () => import('../../components/Pages/About/SetSource/YaekYae/Banner.vue' /* webpackChunkName: "components/about-set-source-yaek-yae-banner" */).then(c => wrapFunctional(c.default || c)),
  AboutSetSourceYaekYaeCover: () => import('../../components/Pages/About/SetSource/YaekYae/Cover.vue' /* webpackChunkName: "components/about-set-source-yaek-yae-cover" */).then(c => wrapFunctional(c.default || c)),
  AboutSetSourceYaekYae: () => import('../../components/Pages/About/SetSource/YaekYae/index.vue' /* webpackChunkName: "components/about-set-source-yaek-yae" */).then(c => wrapFunctional(c.default || c)),
  DWOverviewIndex: () => import('../../components/Pages/DW/Overview/Index/index.vue' /* webpackChunkName: "components/d-w-overview-index" */).then(c => wrapFunctional(c.default || c)),
  DWOverviewSearch: () => import('../../components/Pages/DW/Overview/Search/index.vue' /* webpackChunkName: "components/d-w-overview-search" */).then(c => wrapFunctional(c.default || c)),
  DWQuoteHistorical: () => import('../../components/Pages/DW/Quote/Historical/index.vue' /* webpackChunkName: "components/d-w-quote-historical" */).then(c => wrapFunctional(c.default || c)),
  DWQuoteImportant: () => import('../../components/Pages/DW/Quote/Important/index.vue' /* webpackChunkName: "components/d-w-quote-important" */).then(c => wrapFunctional(c.default || c)),
  DWQuoteInfo: () => import('../../components/Pages/DW/Quote/Info/index.vue' /* webpackChunkName: "components/d-w-quote-info" */).then(c => wrapFunctional(c.default || c)),
  DWQuoteNews: () => import('../../components/Pages/DW/Quote/News/index.vue' /* webpackChunkName: "components/d-w-quote-news" */).then(c => wrapFunctional(c.default || c)),
  DWQuoteProduct: () => import('../../components/Pages/DW/Quote/Product/index.vue' /* webpackChunkName: "components/d-w-quote-product" */).then(c => wrapFunctional(c.default || c)),
  DWQuoteRelateDocument: () => import('../../components/Pages/DW/Quote/RelateDocument/index.vue' /* webpackChunkName: "components/d-w-quote-relate-document" */).then(c => wrapFunctional(c.default || c)),
  DRXOverviewIndex: () => import('../../components/Pages/DRX/Overview/Index/index.vue' /* webpackChunkName: "components/d-r-x-overview-index" */).then(c => wrapFunctional(c.default || c)),
  DRXOverviewNews: () => import('../../components/Pages/DRX/Overview/News/index.vue' /* webpackChunkName: "components/d-r-x-overview-news" */).then(c => wrapFunctional(c.default || c)),
  DRXOverviewSearch: () => import('../../components/Pages/DRX/Overview/Search/index.vue' /* webpackChunkName: "components/d-r-x-overview-search" */).then(c => wrapFunctional(c.default || c)),
  ETFOverviewIndex: () => import('../../components/Pages/ETF/Overview/Index/index.vue' /* webpackChunkName: "components/e-t-f-overview-index" */).then(c => wrapFunctional(c.default || c)),
  ETFOverviewSearch: () => import('../../components/Pages/ETF/Overview/Search/index.vue' /* webpackChunkName: "components/e-t-f-overview-search" */).then(c => wrapFunctional(c.default || c)),
  ETFQuoteFactsheet: () => import('../../components/Pages/ETF/Quote/factsheet/index.vue' /* webpackChunkName: "components/e-t-f-quote-factsheet" */).then(c => wrapFunctional(c.default || c)),
  ETFQuoteFactsheetTable: () => import('../../components/Pages/ETF/Quote/factsheet/table.vue' /* webpackChunkName: "components/e-t-f-quote-factsheet-table" */).then(c => wrapFunctional(c.default || c)),
  EducationResearchArticlePaper: () => import('../../components/Pages/Education/Research/ArticlePaper/ArticlePaper.vue' /* webpackChunkName: "components/education-research-article-paper" */).then(c => wrapFunctional(c.default || c)),
  EducationResearchArticlePaperPage: () => import('../../components/Pages/Education/Research/ArticlePaper/Page.vue' /* webpackChunkName: "components/education-research-article-paper-page" */).then(c => wrapFunctional(c.default || c)),
  EducationResearchArticlePaperMain: () => import('../../components/Pages/Education/Research/ArticlePaper/main.vue' /* webpackChunkName: "components/education-research-article-paper-main" */).then(c => wrapFunctional(c.default || c)),
  EducationResearchDatabase: () => import('../../components/Pages/Education/Research/Database/Database.vue' /* webpackChunkName: "components/education-research-database" */).then(c => wrapFunctional(c.default || c)),
  EducationResearchDatabaseMain: () => import('../../components/Pages/Education/Research/Database/main.vue' /* webpackChunkName: "components/education-research-database-main" */).then(c => wrapFunctional(c.default || c)),
  EducationResearchMarketReport: () => import('../../components/Pages/Education/Research/MarketReport/MarketReport.vue' /* webpackChunkName: "components/education-research-market-report" */).then(c => wrapFunctional(c.default || c)),
  EducationResearchMarketReportPage: () => import('../../components/Pages/Education/Research/MarketReport/Page.vue' /* webpackChunkName: "components/education-research-market-report-page" */).then(c => wrapFunctional(c.default || c)),
  EducationResearchMarketReportMain: () => import('../../components/Pages/Education/Research/MarketReport/main.vue' /* webpackChunkName: "components/education-research-market-report-main" */).then(c => wrapFunctional(c.default || c)),
  EducationResearchOverviewCapitalMarketDB: () => import('../../components/Pages/Education/Research/Overview/CapitalMarketDB.vue' /* webpackChunkName: "components/education-research-overview-capital-market-d-b" */).then(c => wrapFunctional(c.default || c)),
  EducationResearchOverviewFAQ: () => import('../../components/Pages/Education/Research/Overview/FAQ.vue' /* webpackChunkName: "components/education-research-overview-f-a-q" */).then(c => wrapFunctional(c.default || c)),
  EducationResearchOverview: () => import('../../components/Pages/Education/Research/Overview/index.vue' /* webpackChunkName: "components/education-research-overview" */).then(c => wrapFunctional(c.default || c)),
  EducationResearchGrant: () => import('../../components/Pages/Education/Research/ResearchGrant/ResearchGrant.vue' /* webpackChunkName: "components/education-research-grant" */).then(c => wrapFunctional(c.default || c)),
  EducationResearchSearchResultCover: () => import('../../components/Pages/Education/Research/SearchResult/Cover.vue' /* webpackChunkName: "components/education-research-search-result-cover" */).then(c => wrapFunctional(c.default || c)),
  EducationResearchSearchResultInputTags: () => import('../../components/Pages/Education/Research/SearchResult/InputTags.vue' /* webpackChunkName: "components/education-research-search-result-input-tags" */).then(c => wrapFunctional(c.default || c)),
  EducationResearchSearchResultRecommended: () => import('../../components/Pages/Education/Research/SearchResult/Recommended.vue' /* webpackChunkName: "components/education-research-search-result-recommended" */).then(c => wrapFunctional(c.default || c)),
  EducationResearchSearchResult: () => import('../../components/Pages/Education/Research/SearchResult/SearchResult.vue' /* webpackChunkName: "components/education-research-search-result" */).then(c => wrapFunctional(c.default || c)),
  EducationResearchSearchResultContent: () => import('../../components/Pages/Education/Research/SearchResult/SearchResultContent.vue' /* webpackChunkName: "components/education-research-search-result-content" */).then(c => wrapFunctional(c.default || c)),
  EducationResearchSearchResultItems: () => import('../../components/Pages/Education/Research/SearchResult/items.vue' /* webpackChunkName: "components/education-research-search-result-items" */).then(c => wrapFunctional(c.default || c)),
  EducationSetBookDetailItem: () => import('../../components/Pages/Education/SetBook/Detail/Item.vue' /* webpackChunkName: "components/education-set-book-detail-item" */).then(c => wrapFunctional(c.default || c)),
  EducationSetBookDetailRelated: () => import('../../components/Pages/Education/SetBook/Detail/Related.vue' /* webpackChunkName: "components/education-set-book-detail-related" */).then(c => wrapFunctional(c.default || c)),
  EducationSetBookDetail: () => import('../../components/Pages/Education/SetBook/Detail/index.vue' /* webpackChunkName: "components/education-set-book-detail" */).then(c => wrapFunctional(c.default || c)),
  EducationSetBookPocketBookDetail: () => import('../../components/Pages/Education/SetBook/PocketBook/Detail.vue' /* webpackChunkName: "components/education-set-book-pocket-book-detail" */).then(c => wrapFunctional(c.default || c)),
  EducationSetBookPocketBookMain: () => import('../../components/Pages/Education/SetBook/PocketBook/Main.vue' /* webpackChunkName: "components/education-set-book-pocket-book-main" */).then(c => wrapFunctional(c.default || c)),
  EducationSetBookTextBookDetail: () => import('../../components/Pages/Education/SetBook/TextBook/Detail.vue' /* webpackChunkName: "components/education-set-book-text-book-detail" */).then(c => wrapFunctional(c.default || c)),
  EducationSetBookTextBookMain: () => import('../../components/Pages/Education/SetBook/TextBook/Main.vue' /* webpackChunkName: "components/education-set-book-text-book-main" */).then(c => wrapFunctional(c.default || c)),
  InformationMemberListMain: () => import('../../components/Pages/Information/MemberList/Main/index.vue' /* webpackChunkName: "components/information-member-list-main" */).then(c => wrapFunctional(c.default || c)),
  HomeShortcutStakeholderEnglishDesktop: () => import('../../components/Pages/Home/ShortcutStakeholder/English/Desktop.vue' /* webpackChunkName: "components/home-shortcut-stakeholder-english-desktop" */).then(c => wrapFunctional(c.default || c)),
  HomeShortcutStakeholderEnglishItem: () => import('../../components/Pages/Home/ShortcutStakeholder/English/Item.vue' /* webpackChunkName: "components/home-shortcut-stakeholder-english-item" */).then(c => wrapFunctional(c.default || c)),
  HomeShortcutStakeholderEnglishMobile: () => import('../../components/Pages/Home/ShortcutStakeholder/English/Mobile.vue' /* webpackChunkName: "components/home-shortcut-stakeholder-english-mobile" */).then(c => wrapFunctional(c.default || c)),
  HomeShortcutStakeholderEnglishSubMenu: () => import('../../components/Pages/Home/ShortcutStakeholder/English/SubMenu.vue' /* webpackChunkName: "components/home-shortcut-stakeholder-english-sub-menu" */).then(c => wrapFunctional(c.default || c)),
  HomeShortcutStakeholderEnglish: () => import('../../components/Pages/Home/ShortcutStakeholder/English/index.vue' /* webpackChunkName: "components/home-shortcut-stakeholder-english" */).then(c => wrapFunctional(c.default || c)),
  ListingIpoFirstTradingDayCard: () => import('../../components/Pages/Listing/Ipo/First-trading-day/card.vue' /* webpackChunkName: "components/listing-ipo-first-trading-day-card" */).then(c => wrapFunctional(c.default || c)),
  ListingIpoFirstTradingDay: () => import('../../components/Pages/Listing/Ipo/First-trading-day/index.vue' /* webpackChunkName: "components/listing-ipo-first-trading-day" */).then(c => wrapFunctional(c.default || c)),
  ListingIpoIpoStatistics: () => import('../../components/Pages/Listing/Ipo/Ipo-statistics/index.vue' /* webpackChunkName: "components/listing-ipo-ipo-statistics" */).then(c => wrapFunctional(c.default || c)),
  ListingIpoUpcomingIpoModal: () => import('../../components/Pages/Listing/Ipo/Upcoming-Ipo/Modal.vue' /* webpackChunkName: "components/listing-ipo-upcoming-ipo-modal" */).then(c => wrapFunctional(c.default || c)),
  ListingIpoUpcomingIpo: () => import('../../components/Pages/Listing/Ipo/Upcoming-Ipo/index.vue' /* webpackChunkName: "components/listing-ipo-upcoming-ipo" */).then(c => wrapFunctional(c.default || c)),
  ListingIpoUpcomingIpoTab: () => import('../../components/Pages/Listing/Ipo/Upcoming-Ipo/tab.vue' /* webpackChunkName: "components/listing-ipo-upcoming-ipo-tab" */).then(c => wrapFunctional(c.default || c)),
  ListingOtherInstrumentsEligibleUnderlyingFilter: () => import('../../components/Pages/Listing/OtherInstruments/EligibleUnderlying/Filter.vue' /* webpackChunkName: "components/listing-other-instruments-eligible-underlying-filter" */).then(c => wrapFunctional(c.default || c)),
  ListingOtherInstrumentsEligibleUnderlyingTable: () => import('../../components/Pages/Listing/OtherInstruments/EligibleUnderlying/Table.vue' /* webpackChunkName: "components/listing-other-instruments-eligible-underlying-table" */).then(c => wrapFunctional(c.default || c)),
  ListingOtherInstrumentsEligibleUnderlying: () => import('../../components/Pages/Listing/OtherInstruments/EligibleUnderlying/index.vue' /* webpackChunkName: "components/listing-other-instruments-eligible-underlying" */).then(c => wrapFunctional(c.default || c)),
  ListingOtherInstrumentsIssuers: () => import('../../components/Pages/Listing/OtherInstruments/Issuers/index.vue' /* webpackChunkName: "components/listing-other-instruments-issuers" */).then(c => wrapFunctional(c.default || c)),
  ListingOtherInstrumentsOutstandingReportFilter: () => import('../../components/Pages/Listing/OtherInstruments/OutstandingReport/Filter.vue' /* webpackChunkName: "components/listing-other-instruments-outstanding-report-filter" */).then(c => wrapFunctional(c.default || c)),
  ListingOtherInstrumentsOutstandingReportTable: () => import('../../components/Pages/Listing/OtherInstruments/OutstandingReport/Table.vue' /* webpackChunkName: "components/listing-other-instruments-outstanding-report-table" */).then(c => wrapFunctional(c.default || c)),
  ListingOtherInstrumentsOutstandingReport: () => import('../../components/Pages/Listing/OtherInstruments/OutstandingReport/index.vue' /* webpackChunkName: "components/listing-other-instruments-outstanding-report" */).then(c => wrapFunctional(c.default || c)),
  DROverviewIndex: () => import('../../components/Pages/DR/Overview/Index/index.vue' /* webpackChunkName: "components/d-r-overview-index" */).then(c => wrapFunctional(c.default || c)),
  DROverviewInformation: () => import('../../components/Pages/DR/Overview/Information/index.vue' /* webpackChunkName: "components/d-r-overview-information" */).then(c => wrapFunctional(c.default || c)),
  DROverviewSearch: () => import('../../components/Pages/DR/Overview/Search/index.vue' /* webpackChunkName: "components/d-r-overview-search" */).then(c => wrapFunctional(c.default || c)),
  MarketIndexGmsExchangesCover: () => import('../../components/Pages/Market/Index/GmsExchanges/Cover.vue' /* webpackChunkName: "components/market-index-gms-exchanges-cover" */).then(c => wrapFunctional(c.default || c)),
  MarketIndexGmsExchangesMenuTab: () => import('../../components/Pages/Market/Index/GmsExchanges/MenuTab.vue' /* webpackChunkName: "components/market-index-gms-exchanges-menu-tab" */).then(c => wrapFunctional(c.default || c)),
  MarketIndexGmsExchangesRelateLink: () => import('../../components/Pages/Market/Index/GmsExchanges/RelateLink.vue' /* webpackChunkName: "components/market-index-gms-exchanges-relate-link" */).then(c => wrapFunctional(c.default || c)),
  MarketIndexGmsExchangesStockListTable: () => import('../../components/Pages/Market/Index/GmsExchanges/StockListTable.vue' /* webpackChunkName: "components/market-index-gms-exchanges-stock-list-table" */).then(c => wrapFunctional(c.default || c)),
  MarketIndexGmsExchangesTrading: () => import('../../components/Pages/Market/Index/GmsExchanges/Trading.vue' /* webpackChunkName: "components/market-index-gms-exchanges-trading" */).then(c => wrapFunctional(c.default || c)),
  MarketIndexMarketTab: () => import('../../components/Pages/Market/Index/Market/Tab.vue' /* webpackChunkName: "components/market-index-market-tab" */).then(c => wrapFunctional(c.default || c)),
  MarketIndexMarket: () => import('../../components/Pages/Market/Index/Market/index.vue' /* webpackChunkName: "components/market-index-market" */).then(c => wrapFunctional(c.default || c)),
  MarketIndexSymbolTab: () => import('../../components/Pages/Market/Index/Symbol/Tab.vue' /* webpackChunkName: "components/market-index-symbol-tab" */).then(c => wrapFunctional(c.default || c)),
  MarketIndexSymbol: () => import('../../components/Pages/Market/Index/Symbol/index.vue' /* webpackChunkName: "components/market-index-symbol" */).then(c => wrapFunctional(c.default || c)),
  MarketInformationSecuritiesListConcessions: () => import('../../components/Pages/Market/Information/SecuritiesList/Concessions.vue' /* webpackChunkName: "components/market-information-securities-list-concessions" */).then(c => wrapFunctional(c.default || c)),
  MarketInformationSecuritiesListConcessionsList: () => import('../../components/Pages/Market/Information/SecuritiesList/ConcessionsList.vue' /* webpackChunkName: "components/market-information-securities-list-concessions-list" */).then(c => wrapFunctional(c.default || c)),
  MarketInformationSecuritiesListCover: () => import('../../components/Pages/Market/Information/SecuritiesList/Cover.vue' /* webpackChunkName: "components/market-information-securities-list-cover" */).then(c => wrapFunctional(c.default || c)),
  MarketOverviewElementComponentsHighlightCardContent: () => import('../../components/Pages/Market/Overview/ElementComponents/HighlightCardContent.vue' /* webpackChunkName: "components/market-overview-element-components-highlight-card-content" */).then(c => wrapFunctional(c.default || c)),
  MarketOverviewElementComponentsIndexCardOverview: () => import('../../components/Pages/Market/Overview/ElementComponents/IndexCardOverview.vue' /* webpackChunkName: "components/market-overview-element-components-index-card-overview" */).then(c => wrapFunctional(c.default || c)),
  MarketOverviewElementComponentsProductCardContent: () => import('../../components/Pages/Market/Overview/ElementComponents/ProductCardContent.vue' /* webpackChunkName: "components/market-overview-element-components-product-card-content" */).then(c => wrapFunctional(c.default || c)),
  MarketOverviewElementComponentsProductOverviewCard: () => import('../../components/Pages/Market/Overview/ElementComponents/ProductOverviewCard.vue' /* webpackChunkName: "components/market-overview-element-components-product-overview-card" */).then(c => wrapFunctional(c.default || c)),
  MemberMyProfileRegistrationCheckRegistrationStatus: () => import('../../components/Pages/Member/MyProfile/Registration/CheckRegistrationStatus.vue' /* webpackChunkName: "components/member-my-profile-registration-check-registration-status" */).then(c => wrapFunctional(c.default || c)),
  MemberMyProfileRegistrationEvents: () => import('../../components/Pages/Member/MyProfile/Registration/Events.vue' /* webpackChunkName: "components/member-my-profile-registration-events" */).then(c => wrapFunctional(c.default || c)),
  MemberMyProfileRegistrationExamination: () => import('../../components/Pages/Member/MyProfile/Registration/Examination.vue' /* webpackChunkName: "components/member-my-profile-registration-examination" */).then(c => wrapFunctional(c.default || c)),
  MemberMyProfileRegistrationMessageCancelRegister: () => import('../../components/Pages/Member/MyProfile/Registration/MessageCancelRegister.vue' /* webpackChunkName: "components/member-my-profile-registration-message-cancel-register" */).then(c => wrapFunctional(c.default || c)),
  MemberMyProfileRegistrationPastTrainingExamination: () => import('../../components/Pages/Member/MyProfile/Registration/PastTrainingExamination.vue' /* webpackChunkName: "components/member-my-profile-registration-past-training-examination" */).then(c => wrapFunctional(c.default || c)),
  MemberMyProfileRegistrationTable: () => import('../../components/Pages/Member/MyProfile/Registration/Table.vue' /* webpackChunkName: "components/member-my-profile-registration-table" */).then(c => wrapFunctional(c.default || c)),
  MemberMyProfileRegistrationTableSection: () => import('../../components/Pages/Member/MyProfile/Registration/TableSection.vue' /* webpackChunkName: "components/member-my-profile-registration-table-section" */).then(c => wrapFunctional(c.default || c)),
  MemberMyProfileRegistrationTraining: () => import('../../components/Pages/Member/MyProfile/Registration/Training.vue' /* webpackChunkName: "components/member-my-profile-registration-training" */).then(c => wrapFunctional(c.default || c)),
  NewsAndAlertAuditorsOpinionDataTableResult: () => import('../../components/Pages/NewsAndAlert/AuditorsOpinion/DataTable/Result.vue' /* webpackChunkName: "components/news-and-alert-auditors-opinion-data-table-result" */).then(c => wrapFunctional(c.default || c)),
  NewsAndAlertAuditorsOpinionDataTableSummary: () => import('../../components/Pages/NewsAndAlert/AuditorsOpinion/DataTable/Summary.vue' /* webpackChunkName: "components/news-and-alert-auditors-opinion-data-table-summary" */).then(c => wrapFunctional(c.default || c)),
  NewsAndAlertSignPostingDataTableResult: () => import('../../components/Pages/NewsAndAlert/SignPosting/DataTable/Result.vue' /* webpackChunkName: "components/news-and-alert-sign-posting-data-table-result" */).then(c => wrapFunctional(c.default || c)),
  NewsAndAlertSignPostingDataTableSummary: () => import('../../components/Pages/NewsAndAlert/SignPosting/DataTable/Summary.vue' /* webpackChunkName: "components/news-and-alert-sign-posting-data-table-summary" */).then(c => wrapFunctional(c.default || c)),
  NewsAndAlertSurveillanceCSignTemporaryTradingDataTableMeasureList: () => import('../../components/Pages/NewsAndAlert/SurveillanceCSignTemporaryTrading/DataTable/MeasureList.vue' /* webpackChunkName: "components/news-and-alert-surveillance-c-sign-temporary-trading-data-table-measure-list" */).then(c => wrapFunctional(c.default || c)),
  NewsAndAlertSurveillanceCSignTemporaryTradingDataTableResult: () => import('../../components/Pages/NewsAndAlert/SurveillanceCSignTemporaryTrading/DataTable/Result.vue' /* webpackChunkName: "components/news-and-alert-surveillance-c-sign-temporary-trading-data-table-result" */).then(c => wrapFunctional(c.default || c)),
  NewsAndAlertSurveillanceCSignTemporaryTradingDataTableStatus: () => import('../../components/Pages/NewsAndAlert/SurveillanceCSignTemporaryTrading/DataTable/Status.vue' /* webpackChunkName: "components/news-and-alert-surveillance-c-sign-temporary-trading-data-table-status" */).then(c => wrapFunctional(c.default || c)),
  NewsAndAlertSurveillanceCSignTemporaryTradingDataTableSummary: () => import('../../components/Pages/NewsAndAlert/SurveillanceCSignTemporaryTrading/DataTable/Summary.vue' /* webpackChunkName: "components/news-and-alert-surveillance-c-sign-temporary-trading-data-table-summary" */).then(c => wrapFunctional(c.default || c)),
  NewsAndAlertSurveillanceCSignTemporaryTradingDataTableTmpTrading: () => import('../../components/Pages/NewsAndAlert/SurveillanceCSignTemporaryTrading/DataTable/TmpTrading.vue' /* webpackChunkName: "components/news-and-alert-surveillance-c-sign-temporary-trading-data-table-tmp-trading" */).then(c => wrapFunctional(c.default || c)),
  StatisticsInvestorTypeGroupTable: () => import('../../components/Pages/Statistics/InvestorType/Group/Table.vue' /* webpackChunkName: "components/statistics-investor-type-group-table" */).then(c => wrapFunctional(c.default || c)),
  StatisticsInvestorTypeGroup: () => import('../../components/Pages/Statistics/InvestorType/Group/index.vue' /* webpackChunkName: "components/statistics-investor-type-group" */).then(c => wrapFunctional(c.default || c)),
  StatisticsInvestorTypeLastYearChart: () => import('../../components/Pages/Statistics/InvestorType/LastYear/Chart.vue' /* webpackChunkName: "components/statistics-investor-type-last-year-chart" */).then(c => wrapFunctional(c.default || c)),
  StatisticsInvestorTypeLastYear: () => import('../../components/Pages/Statistics/InvestorType/LastYear/index.vue' /* webpackChunkName: "components/statistics-investor-type-last-year" */).then(c => wrapFunctional(c.default || c)),
  StatisticsMarketStatisticsCapitalRaise: () => import('../../components/Pages/Statistics/MarketStatistics/CapitalRaise/index.vue' /* webpackChunkName: "components/statistics-market-statistics-capital-raise" */).then(c => wrapFunctional(c.default || c)),
  StatisticsMarketStatisticsIPO: () => import('../../components/Pages/Statistics/MarketStatistics/IPO/index.vue' /* webpackChunkName: "components/statistics-market-statistics-i-p-o" */).then(c => wrapFunctional(c.default || c)),
  StatisticsMarketStatisticsMonthlyReportCard: () => import('../../components/Pages/Statistics/MarketStatistics/MonthlyReport/Card.vue' /* webpackChunkName: "components/statistics-market-statistics-monthly-report-card" */).then(c => wrapFunctional(c.default || c)),
  StatisticsMarketStatisticsMonthlyReportTab: () => import('../../components/Pages/Statistics/MarketStatistics/MonthlyReport/Tab.vue' /* webpackChunkName: "components/statistics-market-statistics-monthly-report-tab" */).then(c => wrapFunctional(c.default || c)),
  StatisticsMarketStatisticsMonthlyReport: () => import('../../components/Pages/Statistics/MarketStatistics/MonthlyReport/index.vue' /* webpackChunkName: "components/statistics-market-statistics-monthly-report" */).then(c => wrapFunctional(c.default || c)),
  StatisticsNvdrMethodTable: () => import('../../components/Pages/Statistics/Nvdr/Method/Table.vue' /* webpackChunkName: "components/statistics-nvdr-method-table" */).then(c => wrapFunctional(c.default || c)),
  StatisticsNvdrMethod: () => import('../../components/Pages/Statistics/Nvdr/Method/index.vue' /* webpackChunkName: "components/statistics-nvdr-method" */).then(c => wrapFunctional(c.default || c)),
  StatisticsNvdrOverview: () => import('../../components/Pages/Statistics/Nvdr/Overview/index.vue' /* webpackChunkName: "components/statistics-nvdr-overview" */).then(c => wrapFunctional(c.default || c)),
  StatisticsNvdrSectorTable: () => import('../../components/Pages/Statistics/Nvdr/Sector/Table.vue' /* webpackChunkName: "components/statistics-nvdr-sector-table" */).then(c => wrapFunctional(c.default || c)),
  StatisticsNvdrSector: () => import('../../components/Pages/Statistics/Nvdr/Sector/index.vue' /* webpackChunkName: "components/statistics-nvdr-sector" */).then(c => wrapFunctional(c.default || c)),
  StatisticsNvdrShareFilters: () => import('../../components/Pages/Statistics/Nvdr/Share/Filters.vue' /* webpackChunkName: "components/statistics-nvdr-share-filters" */).then(c => wrapFunctional(c.default || c)),
  StatisticsNvdrShareTable: () => import('../../components/Pages/Statistics/Nvdr/Share/Table.vue' /* webpackChunkName: "components/statistics-nvdr-share-table" */).then(c => wrapFunctional(c.default || c)),
  StatisticsNvdrShare: () => import('../../components/Pages/Statistics/Nvdr/Share/index.vue' /* webpackChunkName: "components/statistics-nvdr-share" */).then(c => wrapFunctional(c.default || c)),
  StatisticsNvdrStockFilters: () => import('../../components/Pages/Statistics/Nvdr/Stock/Filters.vue' /* webpackChunkName: "components/statistics-nvdr-stock-filters" */).then(c => wrapFunctional(c.default || c)),
  StatisticsNvdrStockTable: () => import('../../components/Pages/Statistics/Nvdr/Stock/Table.vue' /* webpackChunkName: "components/statistics-nvdr-stock-table" */).then(c => wrapFunctional(c.default || c)),
  StatisticsNvdrStock: () => import('../../components/Pages/Statistics/Nvdr/Stock/index.vue' /* webpackChunkName: "components/statistics-nvdr-stock" */).then(c => wrapFunctional(c.default || c)),
  StatisticsShortsalesOutstandingShortPositionsFilter: () => import('../../components/Pages/Statistics/Shortsales/OutstandingShortPositions/Filter.vue' /* webpackChunkName: "components/statistics-shortsales-outstanding-short-positions-filter" */).then(c => wrapFunctional(c.default || c)),
  StatisticsShortsalesOutstandingShortPositions: () => import('../../components/Pages/Statistics/Shortsales/OutstandingShortPositions/index.vue' /* webpackChunkName: "components/statistics-shortsales-outstanding-short-positions" */).then(c => wrapFunctional(c.default || c)),
  StockFactSheetFinancial: () => import('../../components/Pages/Stock/FactSheet/Financial/index.vue' /* webpackChunkName: "components/stock-fact-sheet-financial" */).then(c => wrapFunctional(c.default || c)),
  StockOverviewIndexTable: () => import('../../components/Pages/Stock/Overview/Index/Table.vue' /* webpackChunkName: "components/stock-overview-index-table" */).then(c => wrapFunctional(c.default || c)),
  StockOverviewIndex: () => import('../../components/Pages/Stock/Overview/Index/index.vue' /* webpackChunkName: "components/stock-overview-index" */).then(c => wrapFunctional(c.default || c)),
  StockOverviewIndex2: () => import('../../components/Pages/Stock/Overview/Index/index2.vue' /* webpackChunkName: "components/stock-overview-index2" */).then(c => wrapFunctional(c.default || c)),
  StockOverviewSearch: () => import('../../components/Pages/Stock/Overview/Search/index.vue' /* webpackChunkName: "components/stock-overview-search" */).then(c => wrapFunctional(c.default || c)),
  StockOverviewTopRanking: () => import('../../components/Pages/Stock/Overview/TopRanking/index.vue' /* webpackChunkName: "components/stock-overview-top-ranking" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteFHistorical: () => import('../../components/Pages/Stock/QuoteF/Historical/index.vue' /* webpackChunkName: "components/stock-quote-f-historical" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteFInfo: () => import('../../components/Pages/Stock/QuoteF/Info/index.vue' /* webpackChunkName: "components/stock-quote-f-info" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteFProduct: () => import('../../components/Pages/Stock/QuoteF/Product/index.vue' /* webpackChunkName: "components/stock-quote-f-product" */).then(c => wrapFunctional(c.default || c)),
  TsdInformationDabentureDebentureCover: () => import('../../components/Pages/Tsd/Information/Dabenture/DebentureCover.vue' /* webpackChunkName: "components/tsd-information-dabenture-debenture-cover" */).then(c => wrapFunctional(c.default || c)),
  TsdInformationDabentureDebentureTable: () => import('../../components/Pages/Tsd/Information/Dabenture/DebentureTable.vue' /* webpackChunkName: "components/tsd-information-dabenture-debenture-table" */).then(c => wrapFunctional(c.default || c)),
  TsdInformationDabenture: () => import('../../components/Pages/Tsd/Information/Dabenture/Index.vue' /* webpackChunkName: "components/tsd-information-dabenture" */).then(c => wrapFunctional(c.default || c)),
  TsdInformationEProxyVotingFilter: () => import('../../components/Pages/Tsd/Information/EProxyVoting/Filter.vue' /* webpackChunkName: "components/tsd-information-e-proxy-voting-filter" */).then(c => wrapFunctional(c.default || c)),
  TsdInformationEProxyVotingInput: () => import('../../components/Pages/Tsd/Information/EProxyVoting/Input.vue' /* webpackChunkName: "components/tsd-information-e-proxy-voting-input" */).then(c => wrapFunctional(c.default || c)),
  TsdInformationEProxyVotingSearchResult: () => import('../../components/Pages/Tsd/Information/EProxyVoting/SearchResult.vue' /* webpackChunkName: "components/tsd-information-e-proxy-voting-search-result" */).then(c => wrapFunctional(c.default || c)),
  TsdInformationEProxyVoting: () => import('../../components/Pages/Tsd/Information/EProxyVoting/index.vue' /* webpackChunkName: "components/tsd-information-e-proxy-voting" */).then(c => wrapFunctional(c.default || c)),
  AboutEventDetailsAgenda: () => import('../../components/Pages/About/Event/Details/Agenda/index.vue' /* webpackChunkName: "components/about-event-details-agenda" */).then(c => wrapFunctional(c.default || c)),
  AboutEventDetailsContentCardDeduction: () => import('../../components/Pages/About/Event/Details/ContentCard/deduction.vue' /* webpackChunkName: "components/about-event-details-content-card-deduction" */).then(c => wrapFunctional(c.default || c)),
  AboutEventDetailsContentCardDescription: () => import('../../components/Pages/About/Event/Details/ContentCard/description.vue' /* webpackChunkName: "components/about-event-details-content-card-description" */).then(c => wrapFunctional(c.default || c)),
  AboutEventDetailsContentCardFee: () => import('../../components/Pages/About/Event/Details/ContentCard/fee.vue' /* webpackChunkName: "components/about-event-details-content-card-fee" */).then(c => wrapFunctional(c.default || c)),
  AboutEventDetailsContentCardReceipt: () => import('../../components/Pages/About/Event/Details/ContentCard/receipt.vue' /* webpackChunkName: "components/about-event-details-content-card-receipt" */).then(c => wrapFunctional(c.default || c)),
  AboutEventDetailsModalAlreadyRegister: () => import('../../components/Pages/About/Event/Details/modal/alreadyRegister.vue' /* webpackChunkName: "components/about-event-details-modal-already-register" */).then(c => wrapFunctional(c.default || c)),
  AboutEventDetailsModalRegister: () => import('../../components/Pages/About/Event/Details/modal/register.vue' /* webpackChunkName: "components/about-event-details-modal-register" */).then(c => wrapFunctional(c.default || c)),
  AboutEventDetailsModalSearch: () => import('../../components/Pages/About/Event/Details/modal/search.vue' /* webpackChunkName: "components/about-event-details-modal-search" */).then(c => wrapFunctional(c.default || c)),
  DWOverviewIndexFilterDW: () => import('../../components/Pages/DW/Overview/Index/FilterDW/index.vue' /* webpackChunkName: "components/d-w-overview-index-filter-d-w" */).then(c => wrapFunctional(c.default || c)),
  DWOverviewIndexFooter: () => import('../../components/Pages/DW/Overview/Index/Footer/index.vue' /* webpackChunkName: "components/d-w-overview-index-footer" */).then(c => wrapFunctional(c.default || c)),
  DWOverviewIndexKnowledgeDW: () => import('../../components/Pages/DW/Overview/Index/KnowledgeDW/index.vue' /* webpackChunkName: "components/d-w-overview-index-knowledge-d-w" */).then(c => wrapFunctional(c.default || c)),
  DWOverviewIndexLiquidityMonthlyStatistics: () => import('../../components/Pages/DW/Overview/Index/Liquidity/MonthlyStatistics.vue' /* webpackChunkName: "components/d-w-overview-index-liquidity-monthly-statistics" */).then(c => wrapFunctional(c.default || c)),
  DWOverviewIndexLiquiditySearch: () => import('../../components/Pages/DW/Overview/Index/Liquidity/Search.vue' /* webpackChunkName: "components/d-w-overview-index-liquidity-search" */).then(c => wrapFunctional(c.default || c)),
  DWOverviewIndexLiquidity: () => import('../../components/Pages/DW/Overview/Index/Liquidity/index.vue' /* webpackChunkName: "components/d-w-overview-index-liquidity" */).then(c => wrapFunctional(c.default || c)),
  DWOverviewIndexMaxVolume: () => import('../../components/Pages/DW/Overview/Index/MaxVolume/index.vue' /* webpackChunkName: "components/d-w-overview-index-max-volume" */).then(c => wrapFunctional(c.default || c)),
  DWOverviewIndexTop10Table: () => import('../../components/Pages/DW/Overview/Index/Top10/Top10Table.vue' /* webpackChunkName: "components/d-w-overview-index-top10-table" */).then(c => wrapFunctional(c.default || c)),
  DWOverviewIndexTop10: () => import('../../components/Pages/DW/Overview/Index/Top10/index.vue' /* webpackChunkName: "components/d-w-overview-index-top10" */).then(c => wrapFunctional(c.default || c)),
  DWOverviewSearchFilterOtherTab: () => import('../../components/Pages/DW/Overview/Search/Filter/OtherTab.vue' /* webpackChunkName: "components/d-w-overview-search-filter-other-tab" */).then(c => wrapFunctional(c.default || c)),
  DWOverviewSearchFilter: () => import('../../components/Pages/DW/Overview/Search/Filter/index.vue' /* webpackChunkName: "components/d-w-overview-search-filter" */).then(c => wrapFunctional(c.default || c)),
  DWOverviewSearchNotationSecurities: () => import('../../components/Pages/DW/Overview/Search/Notation/NotationSecurities.vue' /* webpackChunkName: "components/d-w-overview-search-notation-securities" */).then(c => wrapFunctional(c.default || c)),
  DWOverviewSearchNotation: () => import('../../components/Pages/DW/Overview/Search/Notation/index.vue' /* webpackChunkName: "components/d-w-overview-search-notation" */).then(c => wrapFunctional(c.default || c)),
  DWOverviewSearchTablePlaceholders: () => import('../../components/Pages/DW/Overview/Search/Table/Placeholders.vue' /* webpackChunkName: "components/d-w-overview-search-table-placeholders" */).then(c => wrapFunctional(c.default || c)),
  DWOverviewSearchTableShowQuoteBySelect: () => import('../../components/Pages/DW/Overview/Search/Table/ShowQuoteBySelect.vue' /* webpackChunkName: "components/d-w-overview-search-table-show-quote-by-select" */).then(c => wrapFunctional(c.default || c)),
  DWOverviewSearchTableSecurities: () => import('../../components/Pages/DW/Overview/Search/Table/TableSecurities.vue' /* webpackChunkName: "components/d-w-overview-search-table-securities" */).then(c => wrapFunctional(c.default || c)),
  DWOverviewSearchTable: () => import('../../components/Pages/DW/Overview/Search/Table/index.vue' /* webpackChunkName: "components/d-w-overview-search-table" */).then(c => wrapFunctional(c.default || c)),
  DRXOverviewIndexFooter: () => import('../../components/Pages/DRX/Overview/Index/Footer/index.vue' /* webpackChunkName: "components/d-r-x-overview-index-footer" */).then(c => wrapFunctional(c.default || c)),
  DRXOverviewIndexIssuer: () => import('../../components/Pages/DRX/Overview/Index/Issuer/index.vue' /* webpackChunkName: "components/d-r-x-overview-index-issuer" */).then(c => wrapFunctional(c.default || c)),
  DRXOverviewIndexKnowledge: () => import('../../components/Pages/DRX/Overview/Index/Knowledge/index.vue' /* webpackChunkName: "components/d-r-x-overview-index-knowledge" */).then(c => wrapFunctional(c.default || c)),
  DRXOverviewIndexStatistic: () => import('../../components/Pages/DRX/Overview/Index/Statistic/index.vue' /* webpackChunkName: "components/d-r-x-overview-index-statistic" */).then(c => wrapFunctional(c.default || c)),
  DRXOverviewIndexTopRanking: () => import('../../components/Pages/DRX/Overview/Index/TopRanking/index.vue' /* webpackChunkName: "components/d-r-x-overview-index-top-ranking" */).then(c => wrapFunctional(c.default || c)),
  DRXOverviewIndexTopRankingTable: () => import('../../components/Pages/DRX/Overview/Index/TopRanking/table.vue' /* webpackChunkName: "components/d-r-x-overview-index-top-ranking-table" */).then(c => wrapFunctional(c.default || c)),
  DRXOverviewIndexTradingInformation: () => import('../../components/Pages/DRX/Overview/Index/TradingInformation/index.vue' /* webpackChunkName: "components/d-r-x-overview-index-trading-information" */).then(c => wrapFunctional(c.default || c)),
  DRXOverviewSearchFilter: () => import('../../components/Pages/DRX/Overview/Search/Filter/index.vue' /* webpackChunkName: "components/d-r-x-overview-search-filter" */).then(c => wrapFunctional(c.default || c)),
  DRXOverviewSearchTradingInformation: () => import('../../components/Pages/DRX/Overview/Search/TradingInformation/index.vue' /* webpackChunkName: "components/d-r-x-overview-search-trading-information" */).then(c => wrapFunctional(c.default || c)),
  DRXQuoteTabsBenefits: () => import('../../components/Pages/DRX/Quote/Tabs/Benefits/index.vue' /* webpackChunkName: "components/d-r-x-quote-tabs-benefits" */).then(c => wrapFunctional(c.default || c)),
  DRXQuoteTabsPrice: () => import('../../components/Pages/DRX/Quote/Tabs/Price/index.vue' /* webpackChunkName: "components/d-r-x-quote-tabs-price" */).then(c => wrapFunctional(c.default || c)),
  DRXQuoteTabsRealty: () => import('../../components/Pages/DRX/Quote/Tabs/Realty/index.vue' /* webpackChunkName: "components/d-r-x-quote-tabs-realty" */).then(c => wrapFunctional(c.default || c)),
  ETFOverviewIndexFooter: () => import('../../components/Pages/ETF/Overview/Index/Footer/index.vue' /* webpackChunkName: "components/e-t-f-overview-index-footer" */).then(c => wrapFunctional(c.default || c)),
  ETFOverviewIndexInformationModalMarketMaker: () => import('../../components/Pages/ETF/Overview/Index/Information/ModalMarketMaker.vue' /* webpackChunkName: "components/e-t-f-overview-index-information-modal-market-maker" */).then(c => wrapFunctional(c.default || c)),
  ETFOverviewIndexInformation: () => import('../../components/Pages/ETF/Overview/Index/Information/index.vue' /* webpackChunkName: "components/e-t-f-overview-index-information" */).then(c => wrapFunctional(c.default || c)),
  ETFOverviewIndexKnowledge: () => import('../../components/Pages/ETF/Overview/Index/Knowledge/index.vue' /* webpackChunkName: "components/e-t-f-overview-index-knowledge" */).then(c => wrapFunctional(c.default || c)),
  ETFOverviewIndexStatistics: () => import('../../components/Pages/ETF/Overview/Index/Statistics/index.vue' /* webpackChunkName: "components/e-t-f-overview-index-statistics" */).then(c => wrapFunctional(c.default || c)),
  ETFOverviewIndexTop5: () => import('../../components/Pages/ETF/Overview/Index/Top5/index.vue' /* webpackChunkName: "components/e-t-f-overview-index-top5" */).then(c => wrapFunctional(c.default || c)),
  ETFOverviewSearchFilter: () => import('../../components/Pages/ETF/Overview/Search/Filter/index.vue' /* webpackChunkName: "components/e-t-f-overview-search-filter" */).then(c => wrapFunctional(c.default || c)),
  ETFOverviewSearchNotationSecurities: () => import('../../components/Pages/ETF/Overview/Search/Notation/NotationSecurities.vue' /* webpackChunkName: "components/e-t-f-overview-search-notation-securities" */).then(c => wrapFunctional(c.default || c)),
  ETFOverviewSearchNotation: () => import('../../components/Pages/ETF/Overview/Search/Notation/index.vue' /* webpackChunkName: "components/e-t-f-overview-search-notation" */).then(c => wrapFunctional(c.default || c)),
  ETFOverviewSearchTable: () => import('../../components/Pages/ETF/Overview/Search/Table/index.vue' /* webpackChunkName: "components/e-t-f-overview-search-table" */).then(c => wrapFunctional(c.default || c)),
  ETFQuoteTabsBenefits: () => import('../../components/Pages/ETF/Quote/Tabs/Benefits/index.vue' /* webpackChunkName: "components/e-t-f-quote-tabs-benefits" */).then(c => wrapFunctional(c.default || c)),
  ETFQuoteTabsPrice: () => import('../../components/Pages/ETF/Quote/Tabs/Price/index.vue' /* webpackChunkName: "components/e-t-f-quote-tabs-price" */).then(c => wrapFunctional(c.default || c)),
  ETFQuoteTabsRealty: () => import('../../components/Pages/ETF/Quote/Tabs/Realty/index.vue' /* webpackChunkName: "components/e-t-f-quote-tabs-realty" */).then(c => wrapFunctional(c.default || c)),
  ETFQuoteFactsheetFinancial: () => import('../../components/Pages/ETF/Quote/factsheet/Financial/index.vue' /* webpackChunkName: "components/e-t-f-quote-factsheet-financial" */).then(c => wrapFunctional(c.default || c)),
  ETFQuoteFactsheetFinancialTableTradingStats: () => import('../../components/Pages/ETF/Quote/factsheet/Financial/tableTradingStats.vue' /* webpackChunkName: "components/e-t-f-quote-factsheet-financial-table-trading-stats" */).then(c => wrapFunctional(c.default || c)),
  ETFQuoteFactsheetInformationPolicy: () => import('../../components/Pages/ETF/Quote/factsheet/Information/Policy.vue' /* webpackChunkName: "components/e-t-f-quote-factsheet-information-policy" */).then(c => wrapFunctional(c.default || c)),
  ETFQuoteFactsheetInformationBasic: () => import('../../components/Pages/ETF/Quote/factsheet/Information/basic.vue' /* webpackChunkName: "components/e-t-f-quote-factsheet-information-basic" */).then(c => wrapFunctional(c.default || c)),
  ETFQuoteFactsheetInformationTopten: () => import('../../components/Pages/ETF/Quote/factsheet/Information/topten.vue' /* webpackChunkName: "components/e-t-f-quote-factsheet-information-topten" */).then(c => wrapFunctional(c.default || c)),
  ETFQuoteFactsheetOverall: () => import('../../components/Pages/ETF/Quote/factsheet/Overall/index.vue' /* webpackChunkName: "components/e-t-f-quote-factsheet-overall" */).then(c => wrapFunctional(c.default || c)),
  EducationResearchOverviewArticlesAndResearchItem: () => import('../../components/Pages/Education/Research/Overview/ArticlesAndResearch/Item.vue' /* webpackChunkName: "components/education-research-overview-articles-and-research-item" */).then(c => wrapFunctional(c.default || c)),
  EducationResearchOverviewArticlesAndResearch: () => import('../../components/Pages/Education/Research/Overview/ArticlesAndResearch/index.vue' /* webpackChunkName: "components/education-research-overview-articles-and-research" */).then(c => wrapFunctional(c.default || c)),
  EducationResearchOverviewSummaryItem: () => import('../../components/Pages/Education/Research/Overview/Summary/SummaryItem.vue' /* webpackChunkName: "components/education-research-overview-summary-item" */).then(c => wrapFunctional(c.default || c)),
  EducationResearchOverviewSummaryItems: () => import('../../components/Pages/Education/Research/Overview/Summary/SummaryItems.vue' /* webpackChunkName: "components/education-research-overview-summary-items" */).then(c => wrapFunctional(c.default || c)),
  ListingIpoIpoStatisticsTableStock: () => import('../../components/Pages/Listing/Ipo/Ipo-statistics/Table/Stock.vue' /* webpackChunkName: "components/listing-ipo-ipo-statistics-table-stock" */).then(c => wrapFunctional(c.default || c)),
  ListingIpoIpoStatisticsTableSummary: () => import('../../components/Pages/Listing/Ipo/Ipo-statistics/Table/Summary.vue' /* webpackChunkName: "components/listing-ipo-ipo-statistics-table-summary" */).then(c => wrapFunctional(c.default || c)),
  ListingIpoUpcomingIpoCardIff: () => import('../../components/Pages/Listing/Ipo/Upcoming-Ipo/Card/iff.vue' /* webpackChunkName: "components/listing-ipo-upcoming-ipo-card-iff" */).then(c => wrapFunctional(c.default || c)),
  ListingIpoUpcomingIpoCardReit: () => import('../../components/Pages/Listing/Ipo/Upcoming-Ipo/Card/reit.vue' /* webpackChunkName: "components/listing-ipo-upcoming-ipo-card-reit" */).then(c => wrapFunctional(c.default || c)),
  ListingIpoUpcomingIpoCardSet: () => import('../../components/Pages/Listing/Ipo/Upcoming-Ipo/Card/set.vue' /* webpackChunkName: "components/listing-ipo-upcoming-ipo-card-set" */).then(c => wrapFunctional(c.default || c)),
  ListingIpoUpcomingIpoTableSummary: () => import('../../components/Pages/Listing/Ipo/Upcoming-Ipo/Table/Summary.vue' /* webpackChunkName: "components/listing-ipo-upcoming-ipo-table-summary" */).then(c => wrapFunctional(c.default || c)),
  DROverviewIndexFooter: () => import('../../components/Pages/DR/Overview/Index/Footer/index.vue' /* webpackChunkName: "components/d-r-overview-index-footer" */).then(c => wrapFunctional(c.default || c)),
  DROverviewIndexIssuer: () => import('../../components/Pages/DR/Overview/Index/Issuer/index.vue' /* webpackChunkName: "components/d-r-overview-index-issuer" */).then(c => wrapFunctional(c.default || c)),
  DROverviewIndexKnowledge: () => import('../../components/Pages/DR/Overview/Index/Knowledge/index.vue' /* webpackChunkName: "components/d-r-overview-index-knowledge" */).then(c => wrapFunctional(c.default || c)),
  DROverviewIndexTradingInformation: () => import('../../components/Pages/DR/Overview/Index/TradingInformation/index.vue' /* webpackChunkName: "components/d-r-overview-index-trading-information" */).then(c => wrapFunctional(c.default || c)),
  DRQuoteTabsBenefits: () => import('../../components/Pages/DR/Quote/Tabs/Benefits/index.vue' /* webpackChunkName: "components/d-r-quote-tabs-benefits" */).then(c => wrapFunctional(c.default || c)),
  DRQuoteTabsPrice: () => import('../../components/Pages/DR/Quote/Tabs/Price/index.vue' /* webpackChunkName: "components/d-r-quote-tabs-price" */).then(c => wrapFunctional(c.default || c)),
  DRQuoteTabsRealty: () => import('../../components/Pages/DR/Quote/Tabs/Realty/index.vue' /* webpackChunkName: "components/d-r-quote-tabs-realty" */).then(c => wrapFunctional(c.default || c)),
  MarketInformationSecuritiesListPolicyText: () => import('../../components/Pages/Market/Information/SecuritiesList/Policy/Text.vue' /* webpackChunkName: "components/market-information-securities-list-policy-text" */).then(c => wrapFunctional(c.default || c)),
  MarketInformationSecuritiesListPolicyTextEN: () => import('../../components/Pages/Market/Information/SecuritiesList/Policy/TextEN.vue' /* webpackChunkName: "components/market-information-securities-list-policy-text-e-n" */).then(c => wrapFunctional(c.default || c)),
  MarketInformationSecuritiesListPolicyTextTH: () => import('../../components/Pages/Market/Information/SecuritiesList/Policy/TextTH.vue' /* webpackChunkName: "components/market-information-securities-list-policy-text-t-h" */).then(c => wrapFunctional(c.default || c)),
  MarketInformationSecuritiesListPolicy: () => import('../../components/Pages/Market/Information/SecuritiesList/Policy/index.vue' /* webpackChunkName: "components/market-information-securities-list-policy" */).then(c => wrapFunctional(c.default || c)),
  StatisticsMarketStatisticsIPOTableStock: () => import('../../components/Pages/Statistics/MarketStatistics/IPO/Table/Stock.vue' /* webpackChunkName: "components/statistics-market-statistics-i-p-o-table-stock" */).then(c => wrapFunctional(c.default || c)),
  StatisticsMarketStatisticsIPOTableSummary: () => import('../../components/Pages/Statistics/MarketStatistics/IPO/Table/Summary.vue' /* webpackChunkName: "components/statistics-market-statistics-i-p-o-table-summary" */).then(c => wrapFunctional(c.default || c)),
  StatisticsNvdrOverviewComparativeTable: () => import('../../components/Pages/Statistics/Nvdr/Overview/Comparative/table.vue' /* webpackChunkName: "components/statistics-nvdr-overview-comparative-table" */).then(c => wrapFunctional(c.default || c)),
  StatisticsNvdrOverviewMarketTable: () => import('../../components/Pages/Statistics/Nvdr/Overview/Market/table.vue' /* webpackChunkName: "components/statistics-nvdr-overview-market-table" */).then(c => wrapFunctional(c.default || c)),
  StatisticsNvdrOverviewNvdrTable: () => import('../../components/Pages/Statistics/Nvdr/Overview/Nvdr/table.vue' /* webpackChunkName: "components/statistics-nvdr-overview-nvdr-table" */).then(c => wrapFunctional(c.default || c)),
  StatisticsShortsalesOutstandingShortPositionsDataTableResult: () => import('../../components/Pages/Statistics/Shortsales/OutstandingShortPositions/DataTable/Result.vue' /* webpackChunkName: "components/statistics-shortsales-outstanding-short-positions-data-table-result" */).then(c => wrapFunctional(c.default || c)),
  StockOverviewIndexCalendarActivity: () => import('../../components/Pages/Stock/Overview/Index/Calendar/Activity.vue' /* webpackChunkName: "components/stock-overview-index-calendar-activity" */).then(c => wrapFunctional(c.default || c)),
  StockOverviewIndexCalendarContent: () => import('../../components/Pages/Stock/Overview/Index/Calendar/Content.vue' /* webpackChunkName: "components/stock-overview-index-calendar-content" */).then(c => wrapFunctional(c.default || c)),
  StockOverviewIndexCalendar: () => import('../../components/Pages/Stock/Overview/Index/Calendar/index.vue' /* webpackChunkName: "components/stock-overview-index-calendar" */).then(c => wrapFunctional(c.default || c)),
  StockOverviewIndexChart: () => import('../../components/Pages/Stock/Overview/Index/Chart/Chart.vue' /* webpackChunkName: "components/stock-overview-index-chart" */).then(c => wrapFunctional(c.default || c)),
  StockOverviewIndexChart2: () => import('../../components/Pages/Stock/Overview/Index/Chart/Chart2.vue' /* webpackChunkName: "components/stock-overview-index-chart2" */).then(c => wrapFunctional(c.default || c)),
  StockOverviewIndexEquity: () => import('../../components/Pages/Stock/Overview/Index/Equity/index.vue' /* webpackChunkName: "components/stock-overview-index-equity" */).then(c => wrapFunctional(c.default || c)),
  StockOverviewIndexInsight: () => import('../../components/Pages/Stock/Overview/Index/Insight/index.vue' /* webpackChunkName: "components/stock-overview-index-insight" */).then(c => wrapFunctional(c.default || c)),
  StockOverviewIndexNews: () => import('../../components/Pages/Stock/Overview/Index/News/index.vue' /* webpackChunkName: "components/stock-overview-index-news" */).then(c => wrapFunctional(c.default || c)),
  StockOverviewIndexTopten: () => import('../../components/Pages/Stock/Overview/Index/Topten/index.vue' /* webpackChunkName: "components/stock-overview-index-topten" */).then(c => wrapFunctional(c.default || c)),
  StockOverviewSearchIndustry: () => import('../../components/Pages/Stock/Overview/Search/Industry/index.vue' /* webpackChunkName: "components/stock-overview-search-industry" */).then(c => wrapFunctional(c.default || c)),
  StockOverviewSearchSETMAI: () => import('../../components/Pages/Stock/Overview/Search/SETMAI/index.vue' /* webpackChunkName: "components/stock-overview-search-s-e-t-m-a-i" */).then(c => wrapFunctional(c.default || c)),
  StockOverviewSearchSETOther: () => import('../../components/Pages/Stock/Overview/Search/SETOther/index.vue' /* webpackChunkName: "components/stock-overview-search-s-e-t-other" */).then(c => wrapFunctional(c.default || c)),
  StockOverviewSearchSecurities: () => import('../../components/Pages/Stock/Overview/Search/Securities/index.vue' /* webpackChunkName: "components/stock-overview-search-securities" */).then(c => wrapFunctional(c.default || c)),
  StockOverviewSearchTradingMethodAutoMatching: () => import('../../components/Pages/Stock/Overview/Search/TradingMethod/AutoMatching.vue' /* webpackChunkName: "components/stock-overview-search-trading-method-auto-matching" */).then(c => wrapFunctional(c.default || c)),
  StockOverviewSearchTradingMethod: () => import('../../components/Pages/Stock/Overview/Search/TradingMethod/index.vue' /* webpackChunkName: "components/stock-overview-search-trading-method" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteTabsBenefits: () => import('../../components/Pages/Stock/Quote/Tabs/Benefits/index.vue' /* webpackChunkName: "components/stock-quote-tabs-benefits" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteTabsFinancial5Years: () => import('../../components/Pages/Stock/Quote/Tabs/Financial/Financial5Years.vue' /* webpackChunkName: "components/stock-quote-tabs-financial5-years" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteTabsFinancialLastest: () => import('../../components/Pages/Stock/Quote/Tabs/Financial/FinancialLastest.vue' /* webpackChunkName: "components/stock-quote-tabs-financial-lastest" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteTabsFinancialTab: () => import('../../components/Pages/Stock/Quote/Tabs/Financial/FinancialTab.vue' /* webpackChunkName: "components/stock-quote-tabs-financial-tab" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteTabsFinancialLastestTab: () => import('../../components/Pages/Stock/Quote/Tabs/Financial/LastestTab.vue' /* webpackChunkName: "components/stock-quote-tabs-financial-lastest-tab" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteTabsFinancialTabLastest: () => import('../../components/Pages/Stock/Quote/Tabs/Financial/TabLastest.vue' /* webpackChunkName: "components/stock-quote-tabs-financial-tab-lastest" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteTabsFinancialTableLastest: () => import('../../components/Pages/Stock/Quote/Tabs/Financial/TableLastest.vue' /* webpackChunkName: "components/stock-quote-tabs-financial-table-lastest" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteTabsFinancialTableTradingstat: () => import('../../components/Pages/Stock/Quote/Tabs/Financial/TableTradingstat.vue' /* webpackChunkName: "components/stock-quote-tabs-financial-table-tradingstat" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteTabsFinancialTableTurnover: () => import('../../components/Pages/Stock/Quote/Tabs/Financial/TableTurnover.vue' /* webpackChunkName: "components/stock-quote-tabs-financial-table-turnover" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteTabsFinancial: () => import('../../components/Pages/Stock/Quote/Tabs/Financial/index.vue' /* webpackChunkName: "components/stock-quote-tabs-financial" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteTabsHistorical: () => import('../../components/Pages/Stock/Quote/Tabs/Historical/index.vue' /* webpackChunkName: "components/stock-quote-tabs-historical" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteTabsNews: () => import('../../components/Pages/Stock/Quote/Tabs/News/index.vue' /* webpackChunkName: "components/stock-quote-tabs-news" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteTabsPrice: () => import('../../components/Pages/Stock/Quote/Tabs/Price/index.vue' /* webpackChunkName: "components/stock-quote-tabs-price" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteTabsRealty: () => import('../../components/Pages/Stock/Quote/Tabs/Realty/index.vue' /* webpackChunkName: "components/stock-quote-tabs-realty" */).then(c => wrapFunctional(c.default || c)),
  StockQuotePTabsBenefits: () => import('../../components/Pages/Stock/QuoteP/Tabs/Benefits/index.vue' /* webpackChunkName: "components/stock-quote-p-tabs-benefits" */).then(c => wrapFunctional(c.default || c)),
  StockQuotePTabsHistoricalTrading: () => import('../../components/Pages/Stock/QuoteP/Tabs/HistoricalTrading/index.vue' /* webpackChunkName: "components/stock-quote-p-tabs-historical-trading" */).then(c => wrapFunctional(c.default || c)),
  StockQuotePTabsPrice: () => import('../../components/Pages/Stock/QuoteP/Tabs/Price/index.vue' /* webpackChunkName: "components/stock-quote-p-tabs-price" */).then(c => wrapFunctional(c.default || c)),
  StockQuotePTabsRealty: () => import('../../components/Pages/Stock/QuoteP/Tabs/Realty/index.vue' /* webpackChunkName: "components/stock-quote-p-tabs-realty" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteUTabsHistorical: () => import('../../components/Pages/Stock/QuoteU/Tabs/Historical/index.vue' /* webpackChunkName: "components/stock-quote-u-tabs-historical" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteUTabsNews: () => import('../../components/Pages/Stock/QuoteU/Tabs/News/index.vue' /* webpackChunkName: "components/stock-quote-u-tabs-news" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteUTabsPrice: () => import('../../components/Pages/Stock/QuoteU/Tabs/Price/index.vue' /* webpackChunkName: "components/stock-quote-u-tabs-price" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteUTabsRealty: () => import('../../components/Pages/Stock/QuoteU/Tabs/Realty/index.vue' /* webpackChunkName: "components/stock-quote-u-tabs-realty" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteWTabsBenefits: () => import('../../components/Pages/Stock/QuoteW/Tabs/Benefits/index.vue' /* webpackChunkName: "components/stock-quote-w-tabs-benefits" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteWTabsHistorical: () => import('../../components/Pages/Stock/QuoteW/Tabs/Historical/index.vue' /* webpackChunkName: "components/stock-quote-w-tabs-historical" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteWTabsNews: () => import('../../components/Pages/Stock/QuoteW/Tabs/News/index.vue' /* webpackChunkName: "components/stock-quote-w-tabs-news" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteWTabsPrice: () => import('../../components/Pages/Stock/QuoteW/Tabs/Price/index.vue' /* webpackChunkName: "components/stock-quote-w-tabs-price" */).then(c => wrapFunctional(c.default || c)),
  TsdServicesInvestorsTransactionMenuTab: () => import('../../components/Pages/Tsd/Services/Investors/Transaction/MenuTab.vue' /* webpackChunkName: "components/tsd-services-investors-transaction-menu-tab" */).then(c => wrapFunctional(c.default || c)),
  DRXOverviewIndexTradingInformationTableModal: () => import('../../components/Pages/DRX/Overview/Index/TradingInformation/Table/Modal.vue' /* webpackChunkName: "components/d-r-x-overview-index-trading-information-table-modal" */).then(c => wrapFunctional(c.default || c)),
  DRXOverviewIndexTradingInformationTable: () => import('../../components/Pages/DRX/Overview/Index/TradingInformation/Table/index.vue' /* webpackChunkName: "components/d-r-x-overview-index-trading-information-table" */).then(c => wrapFunctional(c.default || c)),
  DRXQuoteTabsPriceHistorical: () => import('../../components/Pages/DRX/Quote/Tabs/Price/Historical/index.vue' /* webpackChunkName: "components/d-r-x-quote-tabs-price-historical" */).then(c => wrapFunctional(c.default || c)),
  DRXQuoteTabsPriceImportant: () => import('../../components/Pages/DRX/Quote/Tabs/Price/Important/index.vue' /* webpackChunkName: "components/d-r-x-quote-tabs-price-important" */).then(c => wrapFunctional(c.default || c)),
  DRXQuoteTabsPriceInfo: () => import('../../components/Pages/DRX/Quote/Tabs/Price/Info/index.vue' /* webpackChunkName: "components/d-r-x-quote-tabs-price-info" */).then(c => wrapFunctional(c.default || c)),
  DRXQuoteTabsPriceNews: () => import('../../components/Pages/DRX/Quote/Tabs/Price/News/index.vue' /* webpackChunkName: "components/d-r-x-quote-tabs-price-news" */).then(c => wrapFunctional(c.default || c)),
  ETFQuoteTabsPriceHistorical: () => import('../../components/Pages/ETF/Quote/Tabs/Price/Historical/index.vue' /* webpackChunkName: "components/e-t-f-quote-tabs-price-historical" */).then(c => wrapFunctional(c.default || c)),
  ETFQuoteTabsPriceImportant: () => import('../../components/Pages/ETF/Quote/Tabs/Price/Important/index.vue' /* webpackChunkName: "components/e-t-f-quote-tabs-price-important" */).then(c => wrapFunctional(c.default || c)),
  ETFQuoteTabsPriceInfo: () => import('../../components/Pages/ETF/Quote/Tabs/Price/Info/index.vue' /* webpackChunkName: "components/e-t-f-quote-tabs-price-info" */).then(c => wrapFunctional(c.default || c)),
  ETFQuoteTabsPriceNews: () => import('../../components/Pages/ETF/Quote/Tabs/Price/News/index.vue' /* webpackChunkName: "components/e-t-f-quote-tabs-price-news" */).then(c => wrapFunctional(c.default || c)),
  ETFQuoteTabsPriceRelatedProduct: () => import('../../components/Pages/ETF/Quote/Tabs/Price/RelatedProduct/index.vue' /* webpackChunkName: "components/e-t-f-quote-tabs-price-related-product" */).then(c => wrapFunctional(c.default || c)),
  DROverviewIndexTradingInformationTable: () => import('../../components/Pages/DR/Overview/Index/TradingInformation/Table/index.vue' /* webpackChunkName: "components/d-r-overview-index-trading-information-table" */).then(c => wrapFunctional(c.default || c)),
  DRQuoteTabsPriceHistorical: () => import('../../components/Pages/DR/Quote/Tabs/Price/Historical/index.vue' /* webpackChunkName: "components/d-r-quote-tabs-price-historical" */).then(c => wrapFunctional(c.default || c)),
  DRQuoteTabsPriceImportant: () => import('../../components/Pages/DR/Quote/Tabs/Price/Important/index.vue' /* webpackChunkName: "components/d-r-quote-tabs-price-important" */).then(c => wrapFunctional(c.default || c)),
  DRQuoteTabsPriceInfo: () => import('../../components/Pages/DR/Quote/Tabs/Price/Info/index.vue' /* webpackChunkName: "components/d-r-quote-tabs-price-info" */).then(c => wrapFunctional(c.default || c)),
  DRQuoteTabsPriceNews: () => import('../../components/Pages/DR/Quote/Tabs/Price/News/index.vue' /* webpackChunkName: "components/d-r-quote-tabs-price-news" */).then(c => wrapFunctional(c.default || c)),
  MarketIndexMarketTabOverview: () => import('../../components/Pages/Market/Index/Market/Tab/Overview/index.vue' /* webpackChunkName: "components/market-index-market-tab-overview" */).then(c => wrapFunctional(c.default || c)),
  MarketIndexSymbolTabOverview: () => import('../../components/Pages/Market/Index/Symbol/Tab/Overview/index.vue' /* webpackChunkName: "components/market-index-symbol-tab-overview" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteTabsPriceHistorical: () => import('../../components/Pages/Stock/Quote/Tabs/Price/Historical/index.vue' /* webpackChunkName: "components/stock-quote-tabs-price-historical" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteTabsPriceImportant: () => import('../../components/Pages/Stock/Quote/Tabs/Price/Important/index.vue' /* webpackChunkName: "components/stock-quote-tabs-price-important" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteTabsPriceInfo: () => import('../../components/Pages/Stock/Quote/Tabs/Price/Info/index.vue' /* webpackChunkName: "components/stock-quote-tabs-price-info" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteTabsPriceNews: () => import('../../components/Pages/Stock/Quote/Tabs/Price/News/index.vue' /* webpackChunkName: "components/stock-quote-tabs-price-news" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteTabsPriceProduct: () => import('../../components/Pages/Stock/Quote/Tabs/Price/Product/index.vue' /* webpackChunkName: "components/stock-quote-tabs-price-product" */).then(c => wrapFunctional(c.default || c)),
  StockQuotePTabsPriceHistorical: () => import('../../components/Pages/Stock/QuoteP/Tabs/Price/Historical/index.vue' /* webpackChunkName: "components/stock-quote-p-tabs-price-historical" */).then(c => wrapFunctional(c.default || c)),
  StockQuotePTabsPriceInfo: () => import('../../components/Pages/Stock/QuoteP/Tabs/Price/Info/index.vue' /* webpackChunkName: "components/stock-quote-p-tabs-price-info" */).then(c => wrapFunctional(c.default || c)),
  StockQuotePTabsPriceProduct: () => import('../../components/Pages/Stock/QuoteP/Tabs/Price/Product/index.vue' /* webpackChunkName: "components/stock-quote-p-tabs-price-product" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteUTabsPriceHistorical: () => import('../../components/Pages/Stock/QuoteU/Tabs/Price/Historical/index.vue' /* webpackChunkName: "components/stock-quote-u-tabs-price-historical" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteUTabsPriceInfo: () => import('../../components/Pages/Stock/QuoteU/Tabs/Price/Info/index.vue' /* webpackChunkName: "components/stock-quote-u-tabs-price-info" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteUTabsPriceNews: () => import('../../components/Pages/Stock/QuoteU/Tabs/Price/News/index.vue' /* webpackChunkName: "components/stock-quote-u-tabs-price-news" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteWTabsPriceHistorical: () => import('../../components/Pages/Stock/QuoteW/Tabs/Price/Historical/index.vue' /* webpackChunkName: "components/stock-quote-w-tabs-price-historical" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteWTabsPriceImportant: () => import('../../components/Pages/Stock/QuoteW/Tabs/Price/Important/index.vue' /* webpackChunkName: "components/stock-quote-w-tabs-price-important" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteWTabsPriceInfo: () => import('../../components/Pages/Stock/QuoteW/Tabs/Price/Info/index.vue' /* webpackChunkName: "components/stock-quote-w-tabs-price-info" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteWTabsPriceNews: () => import('../../components/Pages/Stock/QuoteW/Tabs/Price/News/index.vue' /* webpackChunkName: "components/stock-quote-w-tabs-price-news" */).then(c => wrapFunctional(c.default || c)),
  TsdServicesInvestorsCalculatorsRightOfferingCard: () => import('../../components/Pages/Tsd/Services/Investors/Calculators/RightOffering/Card.vue' /* webpackChunkName: "components/tsd-services-investors-calculators-right-offering-card" */).then(c => wrapFunctional(c.default || c)),
  TsdServicesInvestorsCalculatorsRightOfferingCover: () => import('../../components/Pages/Tsd/Services/Investors/Calculators/RightOffering/Cover.vue' /* webpackChunkName: "components/tsd-services-investors-calculators-right-offering-cover" */).then(c => wrapFunctional(c.default || c)),
  TsdServicesInvestorsCalculatorsRightOffering: () => import('../../components/Pages/Tsd/Services/Investors/Calculators/RightOffering/Index.vue' /* webpackChunkName: "components/tsd-services-investors-calculators-right-offering" */).then(c => wrapFunctional(c.default || c)),
  TsdServicesInvestorsCalculatorsRightOfferingSearchBox: () => import('../../components/Pages/Tsd/Services/Investors/Calculators/RightOffering/SearchBox.vue' /* webpackChunkName: "components/tsd-services-investors-calculators-right-offering-search-box" */).then(c => wrapFunctional(c.default || c)),
  TsdServicesInvestorsCalculatorsStockDividendCard: () => import('../../components/Pages/Tsd/Services/Investors/Calculators/StockDividend/Card.vue' /* webpackChunkName: "components/tsd-services-investors-calculators-stock-dividend-card" */).then(c => wrapFunctional(c.default || c)),
  TsdServicesInvestorsCalculatorsStockDividendCover: () => import('../../components/Pages/Tsd/Services/Investors/Calculators/StockDividend/Cover.vue' /* webpackChunkName: "components/tsd-services-investors-calculators-stock-dividend-cover" */).then(c => wrapFunctional(c.default || c)),
  TsdServicesInvestorsCalculatorsStockDividend: () => import('../../components/Pages/Tsd/Services/Investors/Calculators/StockDividend/Index.vue' /* webpackChunkName: "components/tsd-services-investors-calculators-stock-dividend" */).then(c => wrapFunctional(c.default || c)),
  TsdServicesInvestorsCalculatorsStockDividendSearchBox: () => import('../../components/Pages/Tsd/Services/Investors/Calculators/StockDividend/SearchBox.vue' /* webpackChunkName: "components/tsd-services-investors-calculators-stock-dividend-search-box" */).then(c => wrapFunctional(c.default || c)),
  TsdServicesInvestorsTransactionCertificateLossListCover: () => import('../../components/Pages/Tsd/Services/Investors/Transaction/CertificateLossList/Cover.vue' /* webpackChunkName: "components/tsd-services-investors-transaction-certificate-loss-list-cover" */).then(c => wrapFunctional(c.default || c)),
  TsdServicesInvestorsTransactionCertificateLossList: () => import('../../components/Pages/Tsd/Services/Investors/Transaction/CertificateLossList/index.vue' /* webpackChunkName: "components/tsd-services-investors-transaction-certificate-loss-list" */).then(c => wrapFunctional(c.default || c)),
  TsdServicesInvestorsTransactionChangesNameCover: () => import('../../components/Pages/Tsd/Services/Investors/Transaction/ChangesName/Cover.vue' /* webpackChunkName: "components/tsd-services-investors-transaction-changes-name-cover" */).then(c => wrapFunctional(c.default || c)),
  TsdServicesInvestorsTransactionChangesNameFilter: () => import('../../components/Pages/Tsd/Services/Investors/Transaction/ChangesName/Filter.vue' /* webpackChunkName: "components/tsd-services-investors-transaction-changes-name-filter" */).then(c => wrapFunctional(c.default || c)),
  TsdServicesInvestorsTransactionChangesName: () => import('../../components/Pages/Tsd/Services/Investors/Transaction/ChangesName/Index.vue' /* webpackChunkName: "components/tsd-services-investors-transaction-changes-name" */).then(c => wrapFunctional(c.default || c)),
  TsdServicesInvestorsTransactionChangesNameModal: () => import('../../components/Pages/Tsd/Services/Investors/Transaction/ChangesName/Modal.vue' /* webpackChunkName: "components/tsd-services-investors-transaction-changes-name-modal" */).then(c => wrapFunctional(c.default || c)),
  TsdServicesInvestorsTransactionChangesNameTable: () => import('../../components/Pages/Tsd/Services/Investors/Transaction/ChangesName/Table.vue' /* webpackChunkName: "components/tsd-services-investors-transaction-changes-name-table" */).then(c => wrapFunctional(c.default || c)),
  ETFQuoteTabsRealtyTabsCompanyInfo: () => import('../../components/Pages/ETF/Quote/Tabs/Realty/Tabs/CompanyInfo/index.vue' /* webpackChunkName: "components/e-t-f-quote-tabs-realty-tabs-company-info" */).then(c => wrapFunctional(c.default || c)),
  ETFQuoteTabsRealtyTabsMajorShareholder: () => import('../../components/Pages/ETF/Quote/Tabs/Realty/Tabs/MajorShareholder/index.vue' /* webpackChunkName: "components/e-t-f-quote-tabs-realty-tabs-major-shareholder" */).then(c => wrapFunctional(c.default || c)),
  MarketIndexMarketTabOverviewFTSEASEAN: () => import('../../components/Pages/Market/Index/Market/Tab/Overview/FTSEASEAN/index.vue' /* webpackChunkName: "components/market-index-market-tab-overview-f-t-s-e-a-s-e-a-n" */).then(c => wrapFunctional(c.default || c)),
  MarketIndexMarketTabOverviewFTSESET: () => import('../../components/Pages/Market/Index/Market/Tab/Overview/FTSESET/index.vue' /* webpackChunkName: "components/market-index-market-tab-overview-f-t-s-e-s-e-t" */).then(c => wrapFunctional(c.default || c)),
  MarketIndexMarketTabOverviewSETMAI: () => import('../../components/Pages/Market/Index/Market/Tab/Overview/SETMAI/index.vue' /* webpackChunkName: "components/market-index-market-tab-overview-s-e-t-m-a-i" */).then(c => wrapFunctional(c.default || c)),
  MarketIndexMarketTabOverviewSETOther: () => import('../../components/Pages/Market/Index/Market/Tab/Overview/SETOther/index.vue' /* webpackChunkName: "components/market-index-market-tab-overview-s-e-t-other" */).then(c => wrapFunctional(c.default || c)),
  MarketIndexMarketTabOverviewTRI: () => import('../../components/Pages/Market/Index/Market/Tab/Overview/TRI/index.vue' /* webpackChunkName: "components/market-index-market-tab-overview-t-r-i" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteTabsRealtyTabsCompanyDirector: () => import('../../components/Pages/Stock/Quote/Tabs/Realty/Tabs/CompanyDirector/index.vue' /* webpackChunkName: "components/stock-quote-tabs-realty-tabs-company-director" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteTabsRealtyTabsMajorShareholder: () => import('../../components/Pages/Stock/Quote/Tabs/Realty/Tabs/MajorShareholder/index.vue' /* webpackChunkName: "components/stock-quote-tabs-realty-tabs-major-shareholder" */).then(c => wrapFunctional(c.default || c)),
  StockQuoteTabsRealtyTabsOpportunityDay: () => import('../../components/Pages/Stock/Quote/Tabs/Realty/Tabs/OpportunityDay/index.vue' /* webpackChunkName: "components/stock-quote-tabs-realty-tabs-opportunity-day" */).then(c => wrapFunctional(c.default || c)),
  StockQuotePTabsRealtyTabsMajorShareholder: () => import('../../components/Pages/Stock/QuoteP/Tabs/Realty/Tabs/MajorShareholder/index.vue' /* webpackChunkName: "components/stock-quote-p-tabs-realty-tabs-major-shareholder" */).then(c => wrapFunctional(c.default || c)),
  TsdServicesInvestorsCalculatorsRightOfferingDetailFilter: () => import('../../components/Pages/Tsd/Services/Investors/Calculators/RightOffering/Detail/Filter.vue' /* webpackChunkName: "components/tsd-services-investors-calculators-right-offering-detail-filter" */).then(c => wrapFunctional(c.default || c)),
  TsdServicesInvestorsCalculatorsRightOfferingDetailResult: () => import('../../components/Pages/Tsd/Services/Investors/Calculators/RightOffering/Detail/Result.vue' /* webpackChunkName: "components/tsd-services-investors-calculators-right-offering-detail-result" */).then(c => wrapFunctional(c.default || c)),
  TsdServicesInvestorsCalculatorsRightOfferingDetail: () => import('../../components/Pages/Tsd/Services/Investors/Calculators/RightOffering/Detail/index.vue' /* webpackChunkName: "components/tsd-services-investors-calculators-right-offering-detail" */).then(c => wrapFunctional(c.default || c)),
  TsdServicesInvestorsCalculatorsRightOfferingSearchCard: () => import('../../components/Pages/Tsd/Services/Investors/Calculators/RightOffering/Search/Card.vue' /* webpackChunkName: "components/tsd-services-investors-calculators-right-offering-search-card" */).then(c => wrapFunctional(c.default || c)),
  TsdServicesInvestorsCalculatorsRightOfferingSearch: () => import('../../components/Pages/Tsd/Services/Investors/Calculators/RightOffering/Search/Search.vue' /* webpackChunkName: "components/tsd-services-investors-calculators-right-offering-search" */).then(c => wrapFunctional(c.default || c)),
  TsdServicesInvestorsCalculatorsStockDividendDetail: () => import('../../components/Pages/Tsd/Services/Investors/Calculators/StockDividend/Detail/Detail.vue' /* webpackChunkName: "components/tsd-services-investors-calculators-stock-dividend-detail" */).then(c => wrapFunctional(c.default || c)),
  TsdServicesInvestorsCalculatorsStockDividendDetailFilter: () => import('../../components/Pages/Tsd/Services/Investors/Calculators/StockDividend/Detail/Filter.vue' /* webpackChunkName: "components/tsd-services-investors-calculators-stock-dividend-detail-filter" */).then(c => wrapFunctional(c.default || c)),
  TsdServicesInvestorsCalculatorsStockDividendDetailFullResult: () => import('../../components/Pages/Tsd/Services/Investors/Calculators/StockDividend/Detail/FullResult.vue' /* webpackChunkName: "components/tsd-services-investors-calculators-stock-dividend-detail-full-result" */).then(c => wrapFunctional(c.default || c)),
  TsdServicesInvestorsCalculatorsStockDividendDetailResult: () => import('../../components/Pages/Tsd/Services/Investors/Calculators/StockDividend/Detail/Result.vue' /* webpackChunkName: "components/tsd-services-investors-calculators-stock-dividend-detail-result" */).then(c => wrapFunctional(c.default || c)),
  TsdServicesInvestorsCalculatorsStockDividendSearchCard: () => import('../../components/Pages/Tsd/Services/Investors/Calculators/StockDividend/Search/Card.vue' /* webpackChunkName: "components/tsd-services-investors-calculators-stock-dividend-search-card" */).then(c => wrapFunctional(c.default || c)),
  TsdServicesInvestorsCalculatorsStockDividendSearchPlacehoderCard: () => import('../../components/Pages/Tsd/Services/Investors/Calculators/StockDividend/Search/PlacehoderCard.vue' /* webpackChunkName: "components/tsd-services-investors-calculators-stock-dividend-search-placehoder-card" */).then(c => wrapFunctional(c.default || c)),
  TsdServicesInvestorsCalculatorsStockDividendSearch: () => import('../../components/Pages/Tsd/Services/Investors/Calculators/StockDividend/Search/Search.vue' /* webpackChunkName: "components/tsd-services-investors-calculators-stock-dividend-search" */).then(c => wrapFunctional(c.default || c)),
  ETFQuoteTabsRealtyTabsCompanyInfoReportForm: () => import('../../components/Pages/ETF/Quote/Tabs/Realty/Tabs/CompanyInfo/ReportForm/index.vue' /* webpackChunkName: "components/e-t-f-quote-tabs-realty-tabs-company-info-report-form" */).then(c => wrapFunctional(c.default || c)),
  Author: () => import('../../components/Author.vue' /* webpackChunkName: "components/author" */).then(c => wrapFunctional(c.default || c)),
  CompanyLogo: () => import('../../components/CompanyLogo.vue' /* webpackChunkName: "components/company-logo" */).then(c => wrapFunctional(c.default || c)),
  ContentLazy: () => import('../../components/ContentLazy.vue' /* webpackChunkName: "components/content-lazy" */).then(c => wrapFunctional(c.default || c)),
  CountUp: () => import('../../components/CountUp.vue' /* webpackChunkName: "components/count-up" */).then(c => wrapFunctional(c.default || c)),
  DateAndView: () => import('../../components/DateAndView.vue' /* webpackChunkName: "components/date-and-view" */).then(c => wrapFunctional(c.default || c)),
  Divider: () => import('../../components/Divider.vue' /* webpackChunkName: "components/divider" */).then(c => wrapFunctional(c.default || c)),
  FAQ: () => import('../../components/FAQ.vue' /* webpackChunkName: "components/f-a-q" */).then(c => wrapFunctional(c.default || c)),
  FooterFactsheet: () => import('../../components/FooterFactsheet.vue' /* webpackChunkName: "components/footer-factsheet" */).then(c => wrapFunctional(c.default || c)),
  GoTop: () => import('../../components/GoTop.vue' /* webpackChunkName: "components/go-top" */).then(c => wrapFunctional(c.default || c)),
  Header: () => import('../../components/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c)),
  HeaderFactsheet: () => import('../../components/HeaderFactsheet.vue' /* webpackChunkName: "components/header-factsheet" */).then(c => wrapFunctional(c.default || c)),
  HeaderNews: () => import('../../components/HeaderNews.vue' /* webpackChunkName: "components/header-news" */).then(c => wrapFunctional(c.default || c)),
  LinkTooltip: () => import('../../components/LinkTooltip.vue' /* webpackChunkName: "components/link-tooltip" */).then(c => wrapFunctional(c.default || c)),
  LocaleLanguage: () => import('../../components/LocaleLanguage.vue' /* webpackChunkName: "components/locale-language" */).then(c => wrapFunctional(c.default || c)),
  MarketSummary: () => import('../../components/MarketSummary.vue' /* webpackChunkName: "components/market-summary" */).then(c => wrapFunctional(c.default || c)),
  Rating: () => import('../../components/Rating.vue' /* webpackChunkName: "components/rating" */).then(c => wrapFunctional(c.default || c)),
  RawHtml: () => import('../../components/RawHtml.vue' /* webpackChunkName: "components/raw-html" */).then(c => wrapFunctional(c.default || c)),
  SiteLang: () => import('../../components/SiteLang.vue' /* webpackChunkName: "components/site-lang" */).then(c => wrapFunctional(c.default || c)),
  SocialShare: () => import('../../components/SocialShare.vue' /* webpackChunkName: "components/social-share" */).then(c => wrapFunctional(c.default || c)),
  StickyBar: () => import('../../components/StickyBar.vue' /* webpackChunkName: "components/sticky-bar" */).then(c => wrapFunctional(c.default || c)),
  StringToHtml: () => import('../../components/StringToHtml.vue' /* webpackChunkName: "components/string-to-html" */).then(c => wrapFunctional(c.default || c)),
  Tags: () => import('../../components/Tags.vue' /* webpackChunkName: "components/tags" */).then(c => wrapFunctional(c.default || c)),
  XCalendarItem: () => import('../../components/XCalendarItem.vue' /* webpackChunkName: "components/x-calendar-item" */).then(c => wrapFunctional(c.default || c)),
  Accordion: () => import('../../components/Accordion/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c)),
  AccordionItem: () => import('../../components/Accordion/Item.vue' /* webpackChunkName: "components/accordion-item" */).then(c => wrapFunctional(c.default || c)),
  AdvertisementFloating: () => import('../../components/Advertisement/Floating.vue' /* webpackChunkName: "components/advertisement-floating" */).then(c => wrapFunctional(c.default || c)),
  AdvertisementLightbox: () => import('../../components/Advertisement/Lightbox.vue' /* webpackChunkName: "components/advertisement-lightbox" */).then(c => wrapFunctional(c.default || c)),
  AppBar: () => import('../../components/AppBar/AppBar.vue' /* webpackChunkName: "components/app-bar" */).then(c => wrapFunctional(c.default || c)),
  AppBarNav: () => import('../../components/AppBar/Nav.vue' /* webpackChunkName: "components/app-bar-nav" */).then(c => wrapFunctional(c.default || c)),
  BannerAds: () => import('../../components/Banner/Ads.vue' /* webpackChunkName: "components/banner-ads" */).then(c => wrapFunctional(c.default || c)),
  BannerAdvertisement: () => import('../../components/Banner/Advertisement.vue' /* webpackChunkName: "components/banner-advertisement" */).then(c => wrapFunctional(c.default || c)),
  Banner: () => import('../../components/Banner/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c)),
  BannerItems: () => import('../../components/Banner/BannerItems.vue' /* webpackChunkName: "components/banner-items" */).then(c => wrapFunctional(c.default || c)),
  BannerPersonalized: () => import('../../components/Banner/Personalized.vue' /* webpackChunkName: "components/banner-personalized" */).then(c => wrapFunctional(c.default || c)),
  BannerStandard: () => import('../../components/Banner/Standard.vue' /* webpackChunkName: "components/banner-standard" */).then(c => wrapFunctional(c.default || c)),
  Breadcrumb: () => import('../../components/Breadcrumb/Breadcrumb.vue' /* webpackChunkName: "components/breadcrumb" */).then(c => wrapFunctional(c.default || c)),
  BreadcrumbItem: () => import('../../components/Breadcrumb/Item.vue' /* webpackChunkName: "components/breadcrumb-item" */).then(c => wrapFunctional(c.default || c)),
  Button: () => import('../../components/Button/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c)),
  ButtonCheckbox: () => import('../../components/Button/ButtonCheckbox.vue' /* webpackChunkName: "components/button-checkbox" */).then(c => wrapFunctional(c.default || c)),
  ButtonQuote: () => import('../../components/Button/ButtonQuote.vue' /* webpackChunkName: "components/button-quote" */).then(c => wrapFunctional(c.default || c)),
  ButtonSymbolLink: () => import('../../components/Button/ButtonSymbolLink.vue' /* webpackChunkName: "components/button-symbol-link" */).then(c => wrapFunctional(c.default || c)),
  ButtonCloseModal: () => import('../../components/Button/CloseModal.vue' /* webpackChunkName: "components/button-close-modal" */).then(c => wrapFunctional(c.default || c)),
  ButtonFavorite: () => import('../../components/Button/Favorite.vue' /* webpackChunkName: "components/button-favorite" */).then(c => wrapFunctional(c.default || c)),
  ButtonPodcastPlatform: () => import('../../components/Button/PodcastPlatform.vue' /* webpackChunkName: "components/button-podcast-platform" */).then(c => wrapFunctional(c.default || c)),
  ButtonSettrade: () => import('../../components/Button/Settrade.vue' /* webpackChunkName: "components/button-settrade" */).then(c => wrapFunctional(c.default || c)),
  ButtonSettradeCalculate: () => import('../../components/Button/SettradeCalculate.vue' /* webpackChunkName: "components/button-settrade-calculate" */).then(c => wrapFunctional(c.default || c)),
  ButtonSocialShare: () => import('../../components/Button/SocialShare.vue' /* webpackChunkName: "components/button-social-share" */).then(c => wrapFunctional(c.default || c)),
  ButtonTabPill: () => import('../../components/Button/TabPill.vue' /* webpackChunkName: "components/button-tab-pill" */).then(c => wrapFunctional(c.default || c)),
  ButtonViewMore: () => import('../../components/Button/ViewMore.vue' /* webpackChunkName: "components/button-view-more" */).then(c => wrapFunctional(c.default || c)),
  ButtonViewMoreGradient: () => import('../../components/Button/ViewMoreGradient.vue' /* webpackChunkName: "components/button-view-more-gradient" */).then(c => wrapFunctional(c.default || c)),
  Calendar: () => import('../../components/Calendar/Calendar.vue' /* webpackChunkName: "components/calendar" */).then(c => wrapFunctional(c.default || c)),
  ChartsColumn: () => import('../../components/Charts/Column.vue' /* webpackChunkName: "components/charts-column" */).then(c => wrapFunctional(c.default || c)),
  ChartsFinancialCompare: () => import('../../components/Charts/FinancialCompare.vue' /* webpackChunkName: "components/charts-financial-compare" */).then(c => wrapFunctional(c.default || c)),
  ChartsGroupColumn: () => import('../../components/Charts/GroupColumn.vue' /* webpackChunkName: "components/charts-group-column" */).then(c => wrapFunctional(c.default || c)),
  ChartsIndexRetro: () => import('../../components/Charts/IndexRetro.vue' /* webpackChunkName: "components/charts-index-retro" */).then(c => wrapFunctional(c.default || c)),
  ChartsLineDr: () => import('../../components/Charts/LineDr.vue' /* webpackChunkName: "components/charts-line-dr" */).then(c => wrapFunctional(c.default || c)),
  ChartsLineSet: () => import('../../components/Charts/LineSet.vue' /* webpackChunkName: "components/charts-line-set" */).then(c => wrapFunctional(c.default || c)),
  ChartsMicroLine: () => import('../../components/Charts/MicroLine.vue' /* webpackChunkName: "components/charts-micro-line" */).then(c => wrapFunctional(c.default || c)),
  ChartsPie: () => import('../../components/Charts/Pie.vue' /* webpackChunkName: "components/charts-pie" */).then(c => wrapFunctional(c.default || c)),
  ChartsStock: () => import('../../components/Charts/Stock.vue' /* webpackChunkName: "components/charts-stock" */).then(c => wrapFunctional(c.default || c)),
  ChartsStockCompare: () => import('../../components/Charts/StockCompare.vue' /* webpackChunkName: "components/charts-stock-compare" */).then(c => wrapFunctional(c.default || c)),
  ChartsStockLine: () => import('../../components/Charts/StockLine.vue' /* webpackChunkName: "components/charts-stock-line" */).then(c => wrapFunctional(c.default || c)),
  ChartsTwolevelPie: () => import('../../components/Charts/TwolevelPie.vue' /* webpackChunkName: "components/charts-twolevel-pie" */).then(c => wrapFunctional(c.default || c)),
  Card: () => import('../../components/Card/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c)),
  CardContent: () => import('../../components/Card/CardContent.vue' /* webpackChunkName: "components/card-content" */).then(c => wrapFunctional(c.default || c)),
  CardDateRange: () => import('../../components/Card/CardDateRange.vue' /* webpackChunkName: "components/card-date-range" */).then(c => wrapFunctional(c.default || c)),
  CardDownload: () => import('../../components/Card/CardDownload.vue' /* webpackChunkName: "components/card-download" */).then(c => wrapFunctional(c.default || c)),
  CardInformation: () => import('../../components/Card/CardInformation.vue' /* webpackChunkName: "components/card-information" */).then(c => wrapFunctional(c.default || c)),
  CardInsight: () => import('../../components/Card/CardInsight.vue' /* webpackChunkName: "components/card-insight" */).then(c => wrapFunctional(c.default || c)),
  CardLoading: () => import('../../components/Card/CardLoading.vue' /* webpackChunkName: "components/card-loading" */).then(c => wrapFunctional(c.default || c)),
  CardLockedContent: () => import('../../components/Card/CardLockedContent.vue' /* webpackChunkName: "components/card-locked-content" */).then(c => wrapFunctional(c.default || c)),
  CardMinimal: () => import('../../components/Card/CardMinimal.vue' /* webpackChunkName: "components/card-minimal" */).then(c => wrapFunctional(c.default || c)),
  CardProduct: () => import('../../components/Card/CardProduct.vue' /* webpackChunkName: "components/card-product" */).then(c => wrapFunctional(c.default || c)),
  CardQuoteNews: () => import('../../components/Card/CardQuoteNews.vue' /* webpackChunkName: "components/card-quote-news" */).then(c => wrapFunctional(c.default || c)),
  CardQuoteNewsAlert: () => import('../../components/Card/CardQuoteNewsAlert.vue' /* webpackChunkName: "components/card-quote-news-alert" */).then(c => wrapFunctional(c.default || c)),
  CardSecurities: () => import('../../components/Card/CardSecurities.vue' /* webpackChunkName: "components/card-securities" */).then(c => wrapFunctional(c.default || c)),
  CardSetSource: () => import('../../components/Card/CardSetSource.vue' /* webpackChunkName: "components/card-set-source" */).then(c => wrapFunctional(c.default || c)),
  CardStockOverview: () => import('../../components/Card/CardStockOverview.vue' /* webpackChunkName: "components/card-stock-overview" */).then(c => wrapFunctional(c.default || c)),
  CardStockUpdate: () => import('../../components/Card/CardStockUpdate.vue' /* webpackChunkName: "components/card-stock-update" */).then(c => wrapFunctional(c.default || c)),
  CardTFEX: () => import('../../components/Card/CardTFEX.vue' /* webpackChunkName: "components/card-t-f-e-x" */).then(c => wrapFunctional(c.default || c)),
  CardUpComingEvent: () => import('../../components/Card/CardUpComingEvent.vue' /* webpackChunkName: "components/card-up-coming-event" */).then(c => wrapFunctional(c.default || c)),
  ChatBot: () => import('../../components/Chat/Bot.vue' /* webpackChunkName: "components/chat-bot" */).then(c => wrapFunctional(c.default || c)),
  ChatBotIcon: () => import('../../components/Chat/BotIcon.vue' /* webpackChunkName: "components/chat-bot-icon" */).then(c => wrapFunctional(c.default || c)),
  ChatBotPanel: () => import('../../components/Chat/BotPanel.vue' /* webpackChunkName: "components/chat-bot-panel" */).then(c => wrapFunctional(c.default || c)),
  Chat: () => import('../../components/Chat/Chat.vue' /* webpackChunkName: "components/chat" */).then(c => wrapFunctional(c.default || c)),
  ChatMediaPopup: () => import('../../components/Chat/ChatMediaPopup.vue' /* webpackChunkName: "components/chat-media-popup" */).then(c => wrapFunctional(c.default || c)),
  ChatFacebookChat: () => import('../../components/Chat/FacebookChat.vue' /* webpackChunkName: "components/chat-facebook-chat" */).then(c => wrapFunctional(c.default || c)),
  Charts2Column: () => import('../../components/Charts2/Column.vue' /* webpackChunkName: "components/charts2-column" */).then(c => wrapFunctional(c.default || c)),
  Charts2GroupColumn: () => import('../../components/Charts2/GroupColumn.vue' /* webpackChunkName: "components/charts2-group-column" */).then(c => wrapFunctional(c.default || c)),
  Charts2IndexRetro: () => import('../../components/Charts2/IndexRetro.vue' /* webpackChunkName: "components/charts2-index-retro" */).then(c => wrapFunctional(c.default || c)),
  Charts2LineMarket: () => import('../../components/Charts2/LineMarket.vue' /* webpackChunkName: "components/charts2-line-market" */).then(c => wrapFunctional(c.default || c)),
  Charts2LineMarketIntermissionDotted: () => import('../../components/Charts2/LineMarketIntermissionDotted.vue' /* webpackChunkName: "components/charts2-line-market-intermission-dotted" */).then(c => wrapFunctional(c.default || c)),
  Charts2MicroLine: () => import('../../components/Charts2/MicroLine.vue' /* webpackChunkName: "components/charts2-micro-line" */).then(c => wrapFunctional(c.default || c)),
  Charts2Stock: () => import('../../components/Charts2/Stock.vue' /* webpackChunkName: "components/charts2-stock" */).then(c => wrapFunctional(c.default || c)),
  Charts2StockCompare: () => import('../../components/Charts2/StockCompare.vue' /* webpackChunkName: "components/charts2-stock-compare" */).then(c => wrapFunctional(c.default || c)),
  Charts2StockLine: () => import('../../components/Charts2/StockLine.vue' /* webpackChunkName: "components/charts2-stock-line" */).then(c => wrapFunctional(c.default || c)),
  Charts2InvestorType: () => import('../../components/Charts2/investorType.vue' /* webpackChunkName: "components/charts2-investor-type" */).then(c => wrapFunctional(c.default || c)),
  ChatBotZwizBull: () => import('../../components/ChatBotZwiz/Bull.vue' /* webpackChunkName: "components/chat-bot-zwiz-bull" */).then(c => wrapFunctional(c.default || c)),
  ChatBotZwizPersonalizedPopup: () => import('../../components/ChatBotZwiz/PersonalizedPopup.vue' /* webpackChunkName: "components/chat-bot-zwiz-personalized-popup" */).then(c => wrapFunctional(c.default || c)),
  ChatBotZwiz: () => import('../../components/ChatBotZwiz/index.vue' /* webpackChunkName: "components/chat-bot-zwiz" */).then(c => wrapFunctional(c.default || c)),
  Collapse: () => import('../../components/Collapse/Collapse.vue' /* webpackChunkName: "components/collapse" */).then(c => wrapFunctional(c.default || c)),
  ContentChild: () => import('../../components/Content/Child.vue' /* webpackChunkName: "components/content-child" */).then(c => wrapFunctional(c.default || c)),
  Content: () => import('../../components/Content/Content.vue' /* webpackChunkName: "components/content" */).then(c => wrapFunctional(c.default || c)),
  ContentDynamic: () => import('../../components/Content/Dynamic.vue' /* webpackChunkName: "components/content-dynamic" */).then(c => wrapFunctional(c.default || c)),
  ContentElement: () => import('../../components/Content/Element.vue' /* webpackChunkName: "components/content-element" */).then(c => wrapFunctional(c.default || c)),
  ContentPreview: () => import('../../components/Content/Preview.vue' /* webpackChunkName: "components/content-preview" */).then(c => wrapFunctional(c.default || c)),
  ContentTabs: () => import('../../components/Content/Tabs.vue' /* webpackChunkName: "components/content-tabs" */).then(c => wrapFunctional(c.default || c)),
  ContentCardGrid: () => import('../../components/ContentCard/Grid.vue' /* webpackChunkName: "components/content-card-grid" */).then(c => wrapFunctional(c.default || c)),
  ContentCardItem: () => import('../../components/ContentCard/Item.vue' /* webpackChunkName: "components/content-card-item" */).then(c => wrapFunctional(c.default || c)),
  ContentCardSlide: () => import('../../components/ContentCard/Slide.vue' /* webpackChunkName: "components/content-card-slide" */).then(c => wrapFunctional(c.default || c)),
  ContentCard: () => import('../../components/ContentCard/index.vue' /* webpackChunkName: "components/content-card" */).then(c => wrapFunctional(c.default || c)),
  CoverPage: () => import('../../components/Cover/Page.vue' /* webpackChunkName: "components/cover-page" */).then(c => wrapFunctional(c.default || c)),
  CoverPage1: () => import('../../components/Cover/Page1.vue' /* webpackChunkName: "components/cover-page1" */).then(c => wrapFunctional(c.default || c)),
  DownloadDefault: () => import('../../components/Download/Default.vue' /* webpackChunkName: "components/download-default" */).then(c => wrapFunctional(c.default || c)),
  DownloadZip: () => import('../../components/Download/Zip.vue' /* webpackChunkName: "components/download-zip" */).then(c => wrapFunctional(c.default || c)),
  Dropdown: () => import('../../components/Dropdown/Dropdown.vue' /* webpackChunkName: "components/dropdown" */).then(c => wrapFunctional(c.default || c)),
  DropdownFilterMain: () => import('../../components/Dropdown/FilterMain.vue' /* webpackChunkName: "components/dropdown-filter-main" */).then(c => wrapFunctional(c.default || c)),
  DropdownListSelect: () => import('../../components/Dropdown/ListSelect.vue' /* webpackChunkName: "components/dropdown-list-select" */).then(c => wrapFunctional(c.default || c)),
  DropdownShortcut: () => import('../../components/DropdownShortcut/DropdownShortcut.vue' /* webpackChunkName: "components/dropdown-shortcut" */).then(c => wrapFunctional(c.default || c)),
  ElementAccordion: () => import('../../components/Element/Accordion.vue' /* webpackChunkName: "components/element-accordion" */).then(c => wrapFunctional(c.default || c)),
  ElementAuthor: () => import('../../components/Element/Author.vue' /* webpackChunkName: "components/element-author" */).then(c => wrapFunctional(c.default || c)),
  ElementBanner: () => import('../../components/Element/Banner.vue' /* webpackChunkName: "components/element-banner" */).then(c => wrapFunctional(c.default || c)),
  ElementBreadcrumb: () => import('../../components/Element/Breadcrumb.vue' /* webpackChunkName: "components/element-breadcrumb" */).then(c => wrapFunctional(c.default || c)),
  ElementButton: () => import('../../components/Element/Button.vue' /* webpackChunkName: "components/element-button" */).then(c => wrapFunctional(c.default || c)),
  ElementButtonImage: () => import('../../components/Element/ButtonImage.vue' /* webpackChunkName: "components/element-button-image" */).then(c => wrapFunctional(c.default || c)),
  ElementCard: () => import('../../components/Element/Card.vue' /* webpackChunkName: "components/element-card" */).then(c => wrapFunctional(c.default || c)),
  ElementContentCard: () => import('../../components/Element/ContentCard.vue' /* webpackChunkName: "components/element-content-card" */).then(c => wrapFunctional(c.default || c)),
  ElementContentHighlight: () => import('../../components/Element/ContentHighlight.vue' /* webpackChunkName: "components/element-content-highlight" */).then(c => wrapFunctional(c.default || c)),
  ElementDateAndView: () => import('../../components/Element/DateAndView.vue' /* webpackChunkName: "components/element-date-and-view" */).then(c => wrapFunctional(c.default || c)),
  ElementDivider: () => import('../../components/Element/Divider.vue' /* webpackChunkName: "components/element-divider" */).then(c => wrapFunctional(c.default || c)),
  ElementElearning: () => import('../../components/Element/Elearning.vue' /* webpackChunkName: "components/element-elearning" */).then(c => wrapFunctional(c.default || c)),
  ElementEducationSearch: () => import('../../components/Element/ElementEducationSearch.vue' /* webpackChunkName: "components/element-education-search" */).then(c => wrapFunctional(c.default || c)),
  ElementEventCalendar: () => import('../../components/Element/EventCalendar.vue' /* webpackChunkName: "components/element-event-calendar" */).then(c => wrapFunctional(c.default || c)),
  ElementFAQ: () => import('../../components/Element/FAQ.vue' /* webpackChunkName: "components/element-f-a-q" */).then(c => wrapFunctional(c.default || c)),
  ElementFileDownload: () => import('../../components/Element/FileDownload.vue' /* webpackChunkName: "components/element-file-download" */).then(c => wrapFunctional(c.default || c)),
  ElementForm: () => import('../../components/Element/Form.vue' /* webpackChunkName: "components/element-form" */).then(c => wrapFunctional(c.default || c)),
  ElementHeading: () => import('../../components/Element/Heading.vue' /* webpackChunkName: "components/element-heading" */).then(c => wrapFunctional(c.default || c)),
  ElementHtml: () => import('../../components/Element/Html.vue' /* webpackChunkName: "components/element-html" */).then(c => wrapFunctional(c.default || c)),
  ElementIconList: () => import('../../components/Element/IconList.vue' /* webpackChunkName: "components/element-icon-list" */).then(c => wrapFunctional(c.default || c)),
  ElementImage: () => import('../../components/Element/Image.vue' /* webpackChunkName: "components/element-image" */).then(c => wrapFunctional(c.default || c)),
  ElementImageGallery: () => import('../../components/Element/ImageGallery.vue' /* webpackChunkName: "components/element-image-gallery" */).then(c => wrapFunctional(c.default || c)),
  ElementInfographic: () => import('../../components/Element/Infographic.vue' /* webpackChunkName: "components/element-infographic" */).then(c => wrapFunctional(c.default || c)),
  ElementNativeAdsLongBanner: () => import('../../components/Element/NativeAdsLongBanner.vue' /* webpackChunkName: "components/element-native-ads-long-banner" */).then(c => wrapFunctional(c.default || c)),
  ElementNativeAdsThumbnailBanner: () => import('../../components/Element/NativeAdsThumbnailBanner.vue' /* webpackChunkName: "components/element-native-ads-thumbnail-banner" */).then(c => wrapFunctional(c.default || c)),
  ElementNumber: () => import('../../components/Element/Number.vue' /* webpackChunkName: "components/element-number" */).then(c => wrapFunctional(c.default || c)),
  ElementQuizChoices: () => import('../../components/Element/QuizChoices.vue' /* webpackChunkName: "components/element-quiz-choices" */).then(c => wrapFunctional(c.default || c)),
  ElementRating: () => import('../../components/Element/Rating.vue' /* webpackChunkName: "components/element-rating" */).then(c => wrapFunctional(c.default || c)),
  ElementReadTime: () => import('../../components/Element/ReadTime.vue' /* webpackChunkName: "components/element-read-time" */).then(c => wrapFunctional(c.default || c)),
  ElementRelatedContent: () => import('../../components/Element/RelatedContent.vue' /* webpackChunkName: "components/element-related-content" */).then(c => wrapFunctional(c.default || c)),
  ElementRelatedLink: () => import('../../components/Element/RelatedLink.vue' /* webpackChunkName: "components/element-related-link" */).then(c => wrapFunctional(c.default || c)),
  ElementResearchSearch: () => import('../../components/Element/ResearchSearch.vue' /* webpackChunkName: "components/element-research-search" */).then(c => wrapFunctional(c.default || c)),
  ElementRulesBooksSearch: () => import('../../components/Element/RulesBooksSearch.vue' /* webpackChunkName: "components/element-rules-books-search" */).then(c => wrapFunctional(c.default || c)),
  ElementSectionLock: () => import('../../components/Element/SectionLock.vue' /* webpackChunkName: "components/element-section-lock" */).then(c => wrapFunctional(c.default || c)),
  ElementSocialShare: () => import('../../components/Element/SocialShare.vue' /* webpackChunkName: "components/element-social-share" */).then(c => wrapFunctional(c.default || c)),
  ElementSpacer: () => import('../../components/Element/Spacer.vue' /* webpackChunkName: "components/element-spacer" */).then(c => wrapFunctional(c.default || c)),
  ElementTab: () => import('../../components/Element/Tab.vue' /* webpackChunkName: "components/element-tab" */).then(c => wrapFunctional(c.default || c)),
  ElementTabMenu: () => import('../../components/Element/TabMenu.vue' /* webpackChunkName: "components/element-tab-menu" */).then(c => wrapFunctional(c.default || c)),
  ElementTags: () => import('../../components/Element/Tags.vue' /* webpackChunkName: "components/element-tags" */).then(c => wrapFunctional(c.default || c)),
  ElementTextEditor: () => import('../../components/Element/TextEditor.vue' /* webpackChunkName: "components/element-text-editor" */).then(c => wrapFunctional(c.default || c)),
  ElementTips: () => import('../../components/Element/Tips.vue' /* webpackChunkName: "components/element-tips" */).then(c => wrapFunctional(c.default || c)),
  ElementYoutubeVideo: () => import('../../components/Element/YoutubeVideo.vue' /* webpackChunkName: "components/element-youtube-video" */).then(c => wrapFunctional(c.default || c)),
  Filters: () => import('../../components/Filters/Filters.vue' /* webpackChunkName: "components/filters" */).then(c => wrapFunctional(c.default || c)),
  FontIcon: () => import('../../components/FontAwesome/FontIcon.vue' /* webpackChunkName: "components/font-icon" */).then(c => wrapFunctional(c.default || c)),
  FooterAccordion: () => import('../../components/Footer/Accordion.vue' /* webpackChunkName: "components/footer-accordion" */).then(c => wrapFunctional(c.default || c)),
  FooterAccordionMenu: () => import('../../components/Footer/AccordionMenu.vue' /* webpackChunkName: "components/footer-accordion-menu" */).then(c => wrapFunctional(c.default || c)),
  FooterAccordionMenuMobile: () => import('../../components/Footer/AccordionMenuMobile.vue' /* webpackChunkName: "components/footer-accordion-menu-mobile" */).then(c => wrapFunctional(c.default || c)),
  Footer: () => import('../../components/Footer/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c)),
  FooterMenu: () => import('../../components/Footer/Menu.vue' /* webpackChunkName: "components/footer-menu" */).then(c => wrapFunctional(c.default || c)),
  FooterMenuTab: () => import('../../components/Footer/MenuTab.vue' /* webpackChunkName: "components/footer-menu-tab" */).then(c => wrapFunctional(c.default || c)),
  FooterMenuTabMobile: () => import('../../components/Footer/MenuTabMobile.vue' /* webpackChunkName: "components/footer-menu-tab-mobile" */).then(c => wrapFunctional(c.default || c)),
  FooterSocial: () => import('../../components/Footer/Social.vue' /* webpackChunkName: "components/footer-social" */).then(c => wrapFunctional(c.default || c)),
  FormCustomCheckBox: () => import('../../components/Form/CustomCheckBox.vue' /* webpackChunkName: "components/form-custom-check-box" */).then(c => wrapFunctional(c.default || c)),
  FormCustomDropdown: () => import('../../components/Form/CustomDropdown.vue' /* webpackChunkName: "components/form-custom-dropdown" */).then(c => wrapFunctional(c.default || c)),
  FormCustomRadioBox: () => import('../../components/Form/CustomRadioBox.vue' /* webpackChunkName: "components/form-custom-radio-box" */).then(c => wrapFunctional(c.default || c)),
  FormDateTimePicker: () => import('../../components/Form/DateTimePicker.vue' /* webpackChunkName: "components/form-date-time-picker" */).then(c => wrapFunctional(c.default || c)),
  FormFileItem: () => import('../../components/Form/FileItem.vue' /* webpackChunkName: "components/form-file-item" */).then(c => wrapFunctional(c.default || c)),
  Form: () => import('../../components/Form/Form.vue' /* webpackChunkName: "components/form" */).then(c => wrapFunctional(c.default || c)),
  FormInputInfo: () => import('../../components/Form/InputInfo.vue' /* webpackChunkName: "components/form-input-info" */).then(c => wrapFunctional(c.default || c)),
  FormInputLabel: () => import('../../components/Form/InputLabel.vue' /* webpackChunkName: "components/form-input-label" */).then(c => wrapFunctional(c.default || c)),
  FormPreview: () => import('../../components/Form/Preview.vue' /* webpackChunkName: "components/form-preview" */).then(c => wrapFunctional(c.default || c)),
  FormSubmitComplete: () => import('../../components/Form/SubmitComplete.vue' /* webpackChunkName: "components/form-submit-complete" */).then(c => wrapFunctional(c.default || c)),
  GalleryImage: () => import('../../components/Gallery/Image.vue' /* webpackChunkName: "components/gallery-image" */).then(c => wrapFunctional(c.default || c)),
  GalleryImageItem: () => import('../../components/Gallery/ImageItem.vue' /* webpackChunkName: "components/gallery-image-item" */).then(c => wrapFunctional(c.default || c)),
  Heading: () => import('../../components/Heading/Heading.vue' /* webpackChunkName: "components/heading" */).then(c => wrapFunctional(c.default || c)),
  InputBox: () => import('../../components/Input/Box.vue' /* webpackChunkName: "components/input-box" */).then(c => wrapFunctional(c.default || c)),
  InputCheckBox: () => import('../../components/Input/CheckBox.vue' /* webpackChunkName: "components/input-check-box" */).then(c => wrapFunctional(c.default || c)),
  InputDateTimePicker: () => import('../../components/Input/DateTimePicker.vue' /* webpackChunkName: "components/input-date-time-picker" */).then(c => wrapFunctional(c.default || c)),
  InputRadioBox: () => import('../../components/Input/RadioBox.vue' /* webpackChunkName: "components/input-radio-box" */).then(c => wrapFunctional(c.default || c)),
  InputRange: () => import('../../components/Input/Range.vue' /* webpackChunkName: "components/input-range" */).then(c => wrapFunctional(c.default || c)),
  InputRangeDate: () => import('../../components/Input/RangeDate.vue' /* webpackChunkName: "components/input-range-date" */).then(c => wrapFunctional(c.default || c)),
  InputRangePercent: () => import('../../components/Input/RangePercent.vue' /* webpackChunkName: "components/input-range-percent" */).then(c => wrapFunctional(c.default || c)),
  InputRangePrice: () => import('../../components/Input/RangePrice.vue' /* webpackChunkName: "components/input-range-price" */).then(c => wrapFunctional(c.default || c)),
  InputResponsible: () => import('../../components/Input/Responsible.vue' /* webpackChunkName: "components/input-responsible" */).then(c => wrapFunctional(c.default || c)),
  InputResponsibleGroup: () => import('../../components/Input/ResponsibleGroup.vue' /* webpackChunkName: "components/input-responsible-group" */).then(c => wrapFunctional(c.default || c)),
  InputResponsibleGroupAccordian: () => import('../../components/Input/ResponsibleGroupAccordian.vue' /* webpackChunkName: "components/input-responsible-group-accordian" */).then(c => wrapFunctional(c.default || c)),
  InputResponsibleSelect: () => import('../../components/Input/ResponsibleSelect.vue' /* webpackChunkName: "components/input-responsible-select" */).then(c => wrapFunctional(c.default || c)),
  InputSearch: () => import('../../components/Input/Search.vue' /* webpackChunkName: "components/input-search" */).then(c => wrapFunctional(c.default || c)),
  InputSelect: () => import('../../components/Input/Select.vue' /* webpackChunkName: "components/input-select" */).then(c => wrapFunctional(c.default || c)),
  InputSelectPeriod: () => import('../../components/Input/SelectPeriod.vue' /* webpackChunkName: "components/input-select-period" */).then(c => wrapFunctional(c.default || c)),
  InputSuggest: () => import('../../components/Input/Suggest.vue' /* webpackChunkName: "components/input-suggest" */).then(c => wrapFunctional(c.default || c)),
  InputTagInput: () => import('../../components/Input/TagInput.vue' /* webpackChunkName: "components/input-tag-input" */).then(c => wrapFunctional(c.default || c)),
  InputTags: () => import('../../components/Input/Tags.vue' /* webpackChunkName: "components/input-tags" */).then(c => wrapFunctional(c.default || c)),
  IconAddCurcle: () => import('../../components/Icon/AddCurcle.vue' /* webpackChunkName: "components/icon-add-curcle" */).then(c => wrapFunctional(c.default || c)),
  IconAddNew: () => import('../../components/Icon/AddNew.vue' /* webpackChunkName: "components/icon-add-new" */).then(c => wrapFunctional(c.default || c)),
  IconAlert: () => import('../../components/Icon/Alert.vue' /* webpackChunkName: "components/icon-alert" */).then(c => wrapFunctional(c.default || c)),
  IconAnalysis: () => import('../../components/Icon/Analysis.vue' /* webpackChunkName: "components/icon-analysis" */).then(c => wrapFunctional(c.default || c)),
  IconAppMenu: () => import('../../components/Icon/AppMenu.vue' /* webpackChunkName: "components/icon-app-menu" */).then(c => wrapFunctional(c.default || c)),
  IconArrowCircle: () => import('../../components/Icon/ArrowCircle.vue' /* webpackChunkName: "components/icon-arrow-circle" */).then(c => wrapFunctional(c.default || c)),
  IconArrowCircleLeft: () => import('../../components/Icon/ArrowCircleLeft.vue' /* webpackChunkName: "components/icon-arrow-circle-left" */).then(c => wrapFunctional(c.default || c)),
  IconArrowCircleRight: () => import('../../components/Icon/ArrowCircleRight.vue' /* webpackChunkName: "components/icon-arrow-circle-right" */).then(c => wrapFunctional(c.default || c)),
  IconArrowCircleTab: () => import('../../components/Icon/ArrowCircleTab.vue' /* webpackChunkName: "components/icon-arrow-circle-tab" */).then(c => wrapFunctional(c.default || c)),
  IconArrowDown: () => import('../../components/Icon/ArrowDown.vue' /* webpackChunkName: "components/icon-arrow-down" */).then(c => wrapFunctional(c.default || c)),
  IconArrowDownBold: () => import('../../components/Icon/ArrowDownBold.vue' /* webpackChunkName: "components/icon-arrow-down-bold" */).then(c => wrapFunctional(c.default || c)),
  IconArrowDownRight: () => import('../../components/Icon/ArrowDownRight.vue' /* webpackChunkName: "components/icon-arrow-down-right" */).then(c => wrapFunctional(c.default || c)),
  IconArrowLink: () => import('../../components/Icon/ArrowLink.vue' /* webpackChunkName: "components/icon-arrow-link" */).then(c => wrapFunctional(c.default || c)),
  IconArrowRight: () => import('../../components/Icon/ArrowRight.vue' /* webpackChunkName: "components/icon-arrow-right" */).then(c => wrapFunctional(c.default || c)),
  IconArrowRightCircle: () => import('../../components/Icon/ArrowRightCircle.vue' /* webpackChunkName: "components/icon-arrow-right-circle" */).then(c => wrapFunctional(c.default || c)),
  IconArrowRightShort: () => import('../../components/Icon/ArrowRightShort.vue' /* webpackChunkName: "components/icon-arrow-right-short" */).then(c => wrapFunctional(c.default || c)),
  IconArrowTop: () => import('../../components/Icon/ArrowTop.vue' /* webpackChunkName: "components/icon-arrow-top" */).then(c => wrapFunctional(c.default || c)),
  IconArrowUpRight: () => import('../../components/Icon/ArrowUpRight.vue' /* webpackChunkName: "components/icon-arrow-up-right" */).then(c => wrapFunctional(c.default || c)),
  IconBell: () => import('../../components/Icon/Bell.vue' /* webpackChunkName: "components/icon-bell" */).then(c => wrapFunctional(c.default || c)),
  IconBellNoti: () => import('../../components/Icon/BellNoti.vue' /* webpackChunkName: "components/icon-bell-noti" */).then(c => wrapFunctional(c.default || c)),
  IconBenefits: () => import('../../components/Icon/Benefits.vue' /* webpackChunkName: "components/icon-benefits" */).then(c => wrapFunctional(c.default || c)),
  IconBookCircle: () => import('../../components/Icon/BookCircle.vue' /* webpackChunkName: "components/icon-book-circle" */).then(c => wrapFunctional(c.default || c)),
  IconBookPocket: () => import('../../components/Icon/BookPocket.vue' /* webpackChunkName: "components/icon-book-pocket" */).then(c => wrapFunctional(c.default || c)),
  IconBookText: () => import('../../components/Icon/BookText.vue' /* webpackChunkName: "components/icon-book-text" */).then(c => wrapFunctional(c.default || c)),
  IconBoxArrowUpRight: () => import('../../components/Icon/BoxArrowUpRight.vue' /* webpackChunkName: "components/icon-box-arrow-up-right" */).then(c => wrapFunctional(c.default || c)),
  IconBoxArrowUpRight2: () => import('../../components/Icon/BoxArrowUpRight2.vue' /* webpackChunkName: "components/icon-box-arrow-up-right2" */).then(c => wrapFunctional(c.default || c)),
  IconCalculate: () => import('../../components/Icon/Calculate.vue' /* webpackChunkName: "components/icon-calculate" */).then(c => wrapFunctional(c.default || c)),
  IconCalculation: () => import('../../components/Icon/Calculation.vue' /* webpackChunkName: "components/icon-calculation" */).then(c => wrapFunctional(c.default || c)),
  IconCalculator: () => import('../../components/Icon/Calculator.vue' /* webpackChunkName: "components/icon-calculator" */).then(c => wrapFunctional(c.default || c)),
  IconCalculatorGraph: () => import('../../components/Icon/CalculatorGraph.vue' /* webpackChunkName: "components/icon-calculator-graph" */).then(c => wrapFunctional(c.default || c)),
  IconCalendar: () => import('../../components/Icon/Calendar.vue' /* webpackChunkName: "components/icon-calendar" */).then(c => wrapFunctional(c.default || c)),
  IconCalendarCheck: () => import('../../components/Icon/CalendarCheck.vue' /* webpackChunkName: "components/icon-calendar-check" */).then(c => wrapFunctional(c.default || c)),
  IconCgReportOn: () => import('../../components/Icon/CgReportOn.vue' /* webpackChunkName: "components/icon-cg-report-on" */).then(c => wrapFunctional(c.default || c)),
  IconCheck: () => import('../../components/Icon/Check.vue' /* webpackChunkName: "components/icon-check" */).then(c => wrapFunctional(c.default || c)),
  IconChevronDown: () => import('../../components/Icon/ChevronDown.vue' /* webpackChunkName: "components/icon-chevron-down" */).then(c => wrapFunctional(c.default || c)),
  IconChevronLeft: () => import('../../components/Icon/ChevronLeft.vue' /* webpackChunkName: "components/icon-chevron-left" */).then(c => wrapFunctional(c.default || c)),
  IconChevronLeftBold: () => import('../../components/Icon/ChevronLeftBold.vue' /* webpackChunkName: "components/icon-chevron-left-bold" */).then(c => wrapFunctional(c.default || c)),
  IconChevronRight: () => import('../../components/Icon/ChevronRight.vue' /* webpackChunkName: "components/icon-chevron-right" */).then(c => wrapFunctional(c.default || c)),
  IconChevronRightBold: () => import('../../components/Icon/ChevronRightBold.vue' /* webpackChunkName: "components/icon-chevron-right-bold" */).then(c => wrapFunctional(c.default || c)),
  IconChevronUp: () => import('../../components/Icon/ChevronUp.vue' /* webpackChunkName: "components/icon-chevron-up" */).then(c => wrapFunctional(c.default || c)),
  IconCircleArrowPrimary: () => import('../../components/Icon/CircleArrowPrimary.vue' /* webpackChunkName: "components/icon-circle-arrow-primary" */).then(c => wrapFunctional(c.default || c)),
  IconCircleArrowTransparent: () => import('../../components/Icon/CircleArrowTransparent.vue' /* webpackChunkName: "components/icon-circle-arrow-transparent" */).then(c => wrapFunctional(c.default || c)),
  IconCircleArrowWhite: () => import('../../components/Icon/CircleArrowWhite.vue' /* webpackChunkName: "components/icon-circle-arrow-white" */).then(c => wrapFunctional(c.default || c)),
  IconClassified: () => import('../../components/Icon/Classified.vue' /* webpackChunkName: "components/icon-classified" */).then(c => wrapFunctional(c.default || c)),
  IconClear: () => import('../../components/Icon/Clear.vue' /* webpackChunkName: "components/icon-clear" */).then(c => wrapFunctional(c.default || c)),
  IconClock: () => import('../../components/Icon/Clock.vue' /* webpackChunkName: "components/icon-clock" */).then(c => wrapFunctional(c.default || c)),
  IconClock2: () => import('../../components/Icon/Clock2.vue' /* webpackChunkName: "components/icon-clock2" */).then(c => wrapFunctional(c.default || c)),
  IconClose: () => import('../../components/Icon/Close.vue' /* webpackChunkName: "components/icon-close" */).then(c => wrapFunctional(c.default || c)),
  IconCloseAllTags: () => import('../../components/Icon/CloseAllTags.vue' /* webpackChunkName: "components/icon-close-all-tags" */).then(c => wrapFunctional(c.default || c)),
  IconCloseCircle: () => import('../../components/Icon/CloseCircle.vue' /* webpackChunkName: "components/icon-close-circle" */).then(c => wrapFunctional(c.default || c)),
  IconCloseNotice: () => import('../../components/Icon/CloseNotice.vue' /* webpackChunkName: "components/icon-close-notice" */).then(c => wrapFunctional(c.default || c)),
  IconCloseTag: () => import('../../components/Icon/CloseTag.vue' /* webpackChunkName: "components/icon-close-tag" */).then(c => wrapFunctional(c.default || c)),
  IconCloseTagDefault: () => import('../../components/Icon/CloseTagDefault.vue' /* webpackChunkName: "components/icon-close-tag-default" */).then(c => wrapFunctional(c.default || c)),
  IconCompany: () => import('../../components/Icon/Company.vue' /* webpackChunkName: "components/icon-company" */).then(c => wrapFunctional(c.default || c)),
  IconCompare: () => import('../../components/Icon/Compare.vue' /* webpackChunkName: "components/icon-compare" */).then(c => wrapFunctional(c.default || c)),
  IconContactFax: () => import('../../components/Icon/ContactFax.vue' /* webpackChunkName: "components/icon-contact-fax" */).then(c => wrapFunctional(c.default || c)),
  IconContactPhone: () => import('../../components/Icon/ContactPhone.vue' /* webpackChunkName: "components/icon-contact-phone" */).then(c => wrapFunctional(c.default || c)),
  IconDWexpire: () => import('../../components/Icon/DWexpire.vue' /* webpackChunkName: "components/icon-d-wexpire" */).then(c => wrapFunctional(c.default || c)),
  IconDisplay: () => import('../../components/Icon/Display.vue' /* webpackChunkName: "components/icon-display" */).then(c => wrapFunctional(c.default || c)),
  IconDividend: () => import('../../components/Icon/Dividend.vue' /* webpackChunkName: "components/icon-dividend" */).then(c => wrapFunctional(c.default || c)),
  IconDogTag: () => import('../../components/Icon/DogTag.vue' /* webpackChunkName: "components/icon-dog-tag" */).then(c => wrapFunctional(c.default || c)),
  IconDownload: () => import('../../components/Icon/Download.vue' /* webpackChunkName: "components/icon-download" */).then(c => wrapFunctional(c.default || c)),
  IconDownloadCircle: () => import('../../components/Icon/DownloadCircle.vue' /* webpackChunkName: "components/icon-download-circle" */).then(c => wrapFunctional(c.default || c)),
  IconDownloadLight: () => import('../../components/Icon/DownloadLight.vue' /* webpackChunkName: "components/icon-download-light" */).then(c => wrapFunctional(c.default || c)),
  IconDropdownIconThreeDots: () => import('../../components/Icon/DropdownIconThreeDots.vue' /* webpackChunkName: "components/icon-dropdown-icon-three-dots" */).then(c => wrapFunctional(c.default || c)),
  IconETF1: () => import('../../components/Icon/ETF1.vue' /* webpackChunkName: "components/icon-e-t-f1" */).then(c => wrapFunctional(c.default || c)),
  IconETF2: () => import('../../components/Icon/ETF2.vue' /* webpackChunkName: "components/icon-e-t-f2" */).then(c => wrapFunctional(c.default || c)),
  IconETF3: () => import('../../components/Icon/ETF3.vue' /* webpackChunkName: "components/icon-e-t-f3" */).then(c => wrapFunctional(c.default || c)),
  IconEarning: () => import('../../components/Icon/Earning.vue' /* webpackChunkName: "components/icon-earning" */).then(c => wrapFunctional(c.default || c)),
  IconExclamation: () => import('../../components/Icon/Exclamation.vue' /* webpackChunkName: "components/icon-exclamation" */).then(c => wrapFunctional(c.default || c)),
  IconExclamationCircleFill: () => import('../../components/Icon/ExclamationCircleFill.vue' /* webpackChunkName: "components/icon-exclamation-circle-fill" */).then(c => wrapFunctional(c.default || c)),
  IconExclamationGreen: () => import('../../components/Icon/ExclamationGreen.vue' /* webpackChunkName: "components/icon-exclamation-green" */).then(c => wrapFunctional(c.default || c)),
  IconExternalLinkCircle: () => import('../../components/Icon/ExternalLinkCircle.vue' /* webpackChunkName: "components/icon-external-link-circle" */).then(c => wrapFunctional(c.default || c)),
  IconEye: () => import('../../components/Icon/Eye.vue' /* webpackChunkName: "components/icon-eye" */).then(c => wrapFunctional(c.default || c)),
  IconFactSheet: () => import('../../components/Icon/FactSheet.vue' /* webpackChunkName: "components/icon-fact-sheet" */).then(c => wrapFunctional(c.default || c)),
  IconFavorite: () => import('../../components/Icon/Favorite.vue' /* webpackChunkName: "components/icon-favorite" */).then(c => wrapFunctional(c.default || c)),
  IconFavoriteQuote: () => import('../../components/Icon/FavoriteQuote.vue' /* webpackChunkName: "components/icon-favorite-quote" */).then(c => wrapFunctional(c.default || c)),
  IconFile: () => import('../../components/Icon/File.vue' /* webpackChunkName: "components/icon-file" */).then(c => wrapFunctional(c.default || c)),
  IconFileDocDownload: () => import('../../components/Icon/FileDocDownload.vue' /* webpackChunkName: "components/icon-file-doc-download" */).then(c => wrapFunctional(c.default || c)),
  IconFileDownload: () => import('../../components/Icon/FileDownload.vue' /* webpackChunkName: "components/icon-file-download" */).then(c => wrapFunctional(c.default || c)),
  IconFileExport: () => import('../../components/Icon/FileExport.vue' /* webpackChunkName: "components/icon-file-export" */).then(c => wrapFunctional(c.default || c)),
  IconFileExternalLink: () => import('../../components/Icon/FileExternalLink.vue' /* webpackChunkName: "components/icon-file-external-link" */).then(c => wrapFunctional(c.default || c)),
  IconFileGetQuote: () => import('../../components/Icon/FileGetQuote.vue' /* webpackChunkName: "components/icon-file-get-quote" */).then(c => wrapFunctional(c.default || c)),
  IconFileZip: () => import('../../components/Icon/FileZip.vue' /* webpackChunkName: "components/icon-file-zip" */).then(c => wrapFunctional(c.default || c)),
  IconFilter: () => import('../../components/Icon/Filter.vue' /* webpackChunkName: "components/icon-filter" */).then(c => wrapFunctional(c.default || c)),
  IconFilterFunnel: () => import('../../components/Icon/FilterFunnel.vue' /* webpackChunkName: "components/icon-filter-funnel" */).then(c => wrapFunctional(c.default || c)),
  IconFilterItem: () => import('../../components/Icon/FilterItem.vue' /* webpackChunkName: "components/icon-filter-item" */).then(c => wrapFunctional(c.default || c)),
  IconFilterTable: () => import('../../components/Icon/FilterTable.vue' /* webpackChunkName: "components/icon-filter-table" */).then(c => wrapFunctional(c.default || c)),
  IconFollowedNews: () => import('../../components/Icon/FollowedNews.vue' /* webpackChunkName: "components/icon-followed-news" */).then(c => wrapFunctional(c.default || c)),
  IconForeignListing: () => import('../../components/Icon/ForeignListing.vue' /* webpackChunkName: "components/icon-foreign-listing" */).then(c => wrapFunctional(c.default || c)),
  IconForeignSecurity: () => import('../../components/Icon/ForeignSecurity.vue' /* webpackChunkName: "components/icon-foreign-security" */).then(c => wrapFunctional(c.default || c)),
  IconGraph: () => import('../../components/Icon/IconGraph.vue' /* webpackChunkName: "components/icon-graph" */).then(c => wrapFunctional(c.default || c)),
  IconInfo: () => import('../../components/Icon/Info.vue' /* webpackChunkName: "components/icon-info" */).then(c => wrapFunctional(c.default || c)),
  IconInfoSingC: () => import('../../components/Icon/InfoSingC.vue' /* webpackChunkName: "components/icon-info-sing-c" */).then(c => wrapFunctional(c.default || c)),
  IconInvestorPortalAccount: () => import('../../components/Icon/InvestorPortalAccount.vue' /* webpackChunkName: "components/icon-investor-portal-account" */).then(c => wrapFunctional(c.default || c)),
  IconJob: () => import('../../components/Icon/Job.vue' /* webpackChunkName: "components/icon-job" */).then(c => wrapFunctional(c.default || c)),
  IconLinkCircle: () => import('../../components/Icon/LinkCircle.vue' /* webpackChunkName: "components/icon-link-circle" */).then(c => wrapFunctional(c.default || c)),
  IconLinkNewTab: () => import('../../components/Icon/LinkNewTab.vue' /* webpackChunkName: "components/icon-link-new-tab" */).then(c => wrapFunctional(c.default || c)),
  IconLinkNext: () => import('../../components/Icon/LinkNext.vue' /* webpackChunkName: "components/icon-link-next" */).then(c => wrapFunctional(c.default || c)),
  IconLinkStock: () => import('../../components/Icon/LinkStock.vue' /* webpackChunkName: "components/icon-link-stock" */).then(c => wrapFunctional(c.default || c)),
  IconList: () => import('../../components/Icon/List.vue' /* webpackChunkName: "components/icon-list" */).then(c => wrapFunctional(c.default || c)),
  IconLocation: () => import('../../components/Icon/Location.vue' /* webpackChunkName: "components/icon-location" */).then(c => wrapFunctional(c.default || c)),
  IconLocationEvent: () => import('../../components/Icon/LocationEvent.vue' /* webpackChunkName: "components/icon-location-event" */).then(c => wrapFunctional(c.default || c)),
  IconLock: () => import('../../components/Icon/Lock.vue' /* webpackChunkName: "components/icon-lock" */).then(c => wrapFunctional(c.default || c)),
  IconLock2: () => import('../../components/Icon/Lock2.vue' /* webpackChunkName: "components/icon-lock2" */).then(c => wrapFunctional(c.default || c)),
  IconLockCircle: () => import('../../components/Icon/LockCircle.vue' /* webpackChunkName: "components/icon-lock-circle" */).then(c => wrapFunctional(c.default || c)),
  IconLogOut: () => import('../../components/Icon/LogOut.vue' /* webpackChunkName: "components/icon-log-out" */).then(c => wrapFunctional(c.default || c)),
  IconLoveNotice: () => import('../../components/Icon/LoveNotice.vue' /* webpackChunkName: "components/icon-love-notice" */).then(c => wrapFunctional(c.default || c)),
  IconMapPin: () => import('../../components/Icon/MapPin.vue' /* webpackChunkName: "components/icon-map-pin" */).then(c => wrapFunctional(c.default || c)),
  IconMarket: () => import('../../components/Icon/Market.vue' /* webpackChunkName: "components/icon-market" */).then(c => wrapFunctional(c.default || c)),
  IconMarketFinancial: () => import('../../components/Icon/MarketFinancial.vue' /* webpackChunkName: "components/icon-market-financial" */).then(c => wrapFunctional(c.default || c)),
  IconMarketReportDocAcheive: () => import('../../components/Icon/MarketReportDocAcheive.vue' /* webpackChunkName: "components/icon-market-report-doc-acheive" */).then(c => wrapFunctional(c.default || c)),
  IconMarketReportDownload: () => import('../../components/Icon/MarketReportDownload.vue' /* webpackChunkName: "components/icon-market-report-download" */).then(c => wrapFunctional(c.default || c)),
  IconMaximumTurnover: () => import('../../components/Icon/MaximumTurnover.vue' /* webpackChunkName: "components/icon-maximum-turnover" */).then(c => wrapFunctional(c.default || c)),
  IconMenu: () => import('../../components/Icon/Menu.vue' /* webpackChunkName: "components/icon-menu" */).then(c => wrapFunctional(c.default || c)),
  IconMenuArrow: () => import('../../components/Icon/MenuArrow.vue' /* webpackChunkName: "components/icon-menu-arrow" */).then(c => wrapFunctional(c.default || c)),
  IconMinus: () => import('../../components/Icon/Minus.vue' /* webpackChunkName: "components/icon-minus" */).then(c => wrapFunctional(c.default || c)),
  IconMore: () => import('../../components/Icon/More.vue' /* webpackChunkName: "components/icon-more" */).then(c => wrapFunctional(c.default || c)),
  IconMostActiveValue: () => import('../../components/Icon/MostActiveValue.vue' /* webpackChunkName: "components/icon-most-active-value" */).then(c => wrapFunctional(c.default || c)),
  IconMostActiveVolume: () => import('../../components/Icon/MostActiveVolume.vue' /* webpackChunkName: "components/icon-most-active-volume" */).then(c => wrapFunctional(c.default || c)),
  IconNavArrowRight: () => import('../../components/Icon/NavArrowRight.vue' /* webpackChunkName: "components/icon-nav-arrow-right" */).then(c => wrapFunctional(c.default || c)),
  IconNearExpiration: () => import('../../components/Icon/NearExpiration.vue' /* webpackChunkName: "components/icon-near-expiration" */).then(c => wrapFunctional(c.default || c)),
  IconNewEntry: () => import('../../components/Icon/NewEntry.vue' /* webpackChunkName: "components/icon-new-entry" */).then(c => wrapFunctional(c.default || c)),
  IconOppday: () => import('../../components/Icon/Oppday.vue' /* webpackChunkName: "components/icon-oppday" */).then(c => wrapFunctional(c.default || c)),
  IconPDFDownload: () => import('../../components/Icon/PDFDownload.vue' /* webpackChunkName: "components/icon-p-d-f-download" */).then(c => wrapFunctional(c.default || c)),
  IconPencilSquare: () => import('../../components/Icon/PencilSquare.vue' /* webpackChunkName: "components/icon-pencil-square" */).then(c => wrapFunctional(c.default || c)),
  IconPerson: () => import('../../components/Icon/Person.vue' /* webpackChunkName: "components/icon-person" */).then(c => wrapFunctional(c.default || c)),
  IconPlay: () => import('../../components/Icon/Play.vue' /* webpackChunkName: "components/icon-play" */).then(c => wrapFunctional(c.default || c)),
  IconPlayVideo: () => import('../../components/Icon/PlayVideo.vue' /* webpackChunkName: "components/icon-play-video" */).then(c => wrapFunctional(c.default || c)),
  IconPlayer: () => import('../../components/Icon/Player.vue' /* webpackChunkName: "components/icon-player" */).then(c => wrapFunctional(c.default || c)),
  IconPlus: () => import('../../components/Icon/Plus.vue' /* webpackChunkName: "components/icon-plus" */).then(c => wrapFunctional(c.default || c)),
  IconPlusCircle: () => import('../../components/Icon/PlusCircle.vue' /* webpackChunkName: "components/icon-plus-circle" */).then(c => wrapFunctional(c.default || c)),
  IconPopularSearch: () => import('../../components/Icon/PopularSearch.vue' /* webpackChunkName: "components/icon-popular-search" */).then(c => wrapFunctional(c.default || c)),
  IconPowerPoint: () => import('../../components/Icon/PowerPoint.vue' /* webpackChunkName: "components/icon-power-point" */).then(c => wrapFunctional(c.default || c)),
  IconProfile: () => import('../../components/Icon/Profile.vue' /* webpackChunkName: "components/icon-profile" */).then(c => wrapFunctional(c.default || c)),
  IconProfilePerson: () => import('../../components/Icon/ProfilePerson.vue' /* webpackChunkName: "components/icon-profile-person" */).then(c => wrapFunctional(c.default || c)),
  IconProfitMargin: () => import('../../components/Icon/ProfitMargin.vue' /* webpackChunkName: "components/icon-profit-margin" */).then(c => wrapFunctional(c.default || c)),
  IconQuestion: () => import('../../components/Icon/Question.vue' /* webpackChunkName: "components/icon-question" */).then(c => wrapFunctional(c.default || c)),
  IconRead: () => import('../../components/Icon/Read.vue' /* webpackChunkName: "components/icon-read" */).then(c => wrapFunctional(c.default || c)),
  IconRegister: () => import('../../components/Icon/Register.vue' /* webpackChunkName: "components/icon-register" */).then(c => wrapFunctional(c.default || c)),
  IconRegisterError: () => import('../../components/Icon/RegisterError.vue' /* webpackChunkName: "components/icon-register-error" */).then(c => wrapFunctional(c.default || c)),
  IconResearch: () => import('../../components/Icon/Research.vue' /* webpackChunkName: "components/icon-research" */).then(c => wrapFunctional(c.default || c)),
  IconResearchAwards: () => import('../../components/Icon/ResearchAwards.vue' /* webpackChunkName: "components/icon-research-awards" */).then(c => wrapFunctional(c.default || c)),
  IconResearchDatabase: () => import('../../components/Icon/ResearchDatabase.vue' /* webpackChunkName: "components/icon-research-database" */).then(c => wrapFunctional(c.default || c)),
  IconResearchDataset: () => import('../../components/Icon/ResearchDataset.vue' /* webpackChunkName: "components/icon-research-dataset" */).then(c => wrapFunctional(c.default || c)),
  IconResearchDocument: () => import('../../components/Icon/ResearchDocument.vue' /* webpackChunkName: "components/icon-research-document" */).then(c => wrapFunctional(c.default || c)),
  IconResearchExternal: () => import('../../components/Icon/ResearchExternal.vue' /* webpackChunkName: "components/icon-research-external" */).then(c => wrapFunctional(c.default || c)),
  IconResearchFile: () => import('../../components/Icon/ResearchFile.vue' /* webpackChunkName: "components/icon-research-file" */).then(c => wrapFunctional(c.default || c)),
  IconResearchRequestFile: () => import('../../components/Icon/ResearchRequestFile.vue' /* webpackChunkName: "components/icon-research-request-file" */).then(c => wrapFunctional(c.default || c)),
  IconRulesBooksDoc: () => import('../../components/Icon/RulesBooksDoc.vue' /* webpackChunkName: "components/icon-rules-books-doc" */).then(c => wrapFunctional(c.default || c)),
  IconRulesBooksJpg: () => import('../../components/Icon/RulesBooksJpg.vue' /* webpackChunkName: "components/icon-rules-books-jpg" */).then(c => wrapFunctional(c.default || c)),
  IconRulesBooksPdf: () => import('../../components/Icon/RulesBooksPdf.vue' /* webpackChunkName: "components/icon-rules-books-pdf" */).then(c => wrapFunctional(c.default || c)),
  IconRulesBooksPtt: () => import('../../components/Icon/RulesBooksPtt.vue' /* webpackChunkName: "components/icon-rules-books-ptt" */).then(c => wrapFunctional(c.default || c)),
  IconRulesBooksUpdate: () => import('../../components/Icon/RulesBooksUpdate.vue' /* webpackChunkName: "components/icon-rules-books-update" */).then(c => wrapFunctional(c.default || c)),
  IconRulesBooksXls: () => import('../../components/Icon/RulesBooksXls.vue' /* webpackChunkName: "components/icon-rules-books-xls" */).then(c => wrapFunctional(c.default || c)),
  IconRulesBooksZip: () => import('../../components/Icon/RulesBooksZip.vue' /* webpackChunkName: "components/icon-rules-books-zip" */).then(c => wrapFunctional(c.default || c)),
  IconSearch: () => import('../../components/Icon/Search.vue' /* webpackChunkName: "components/icon-search" */).then(c => wrapFunctional(c.default || c)),
  IconSetInvestnow: () => import('../../components/Icon/SetInvestnow.vue' /* webpackChunkName: "components/icon-set-investnow" */).then(c => wrapFunctional(c.default || c)),
  IconSetSourcePopular: () => import('../../components/Icon/SetSourcePopular.vue' /* webpackChunkName: "components/icon-set-source-popular" */).then(c => wrapFunctional(c.default || c)),
  IconSetSourceRecent: () => import('../../components/Icon/SetSourceRecent.vue' /* webpackChunkName: "components/icon-set-source-recent" */).then(c => wrapFunctional(c.default || c)),
  IconShare: () => import('../../components/Icon/Share.vue' /* webpackChunkName: "components/icon-share" */).then(c => wrapFunctional(c.default || c)),
  IconShoppingCart: () => import('../../components/Icon/ShoppingCart.vue' /* webpackChunkName: "components/icon-shopping-cart" */).then(c => wrapFunctional(c.default || c)),
  IconSpeaker: () => import('../../components/Icon/Speaker.vue' /* webpackChunkName: "components/icon-speaker" */).then(c => wrapFunctional(c.default || c)),
  IconStar: () => import('../../components/Icon/Star.vue' /* webpackChunkName: "components/icon-star" */).then(c => wrapFunctional(c.default || c)),
  IconStarNotice: () => import('../../components/Icon/StarNotice.vue' /* webpackChunkName: "components/icon-star-notice" */).then(c => wrapFunctional(c.default || c)),
  IconTSIAccount: () => import('../../components/Icon/TSIAccount.vue' /* webpackChunkName: "components/icon-t-s-i-account" */).then(c => wrapFunctional(c.default || c)),
  IconTchMember: () => import('../../components/Icon/TchMember.vue' /* webpackChunkName: "components/icon-tch-member" */).then(c => wrapFunctional(c.default || c)),
  IconTchNews: () => import('../../components/Icon/TchNews.vue' /* webpackChunkName: "components/icon-tch-news" */).then(c => wrapFunctional(c.default || c)),
  IconTelephone: () => import('../../components/Icon/Telephone.vue' /* webpackChunkName: "components/icon-telephone" */).then(c => wrapFunctional(c.default || c)),
  IconTimeLine: () => import('../../components/Icon/TimeLine.vue' /* webpackChunkName: "components/icon-time-line" */).then(c => wrapFunctional(c.default || c)),
  IconTopGainer: () => import('../../components/Icon/TopGainer.vue' /* webpackChunkName: "components/icon-top-gainer" */).then(c => wrapFunctional(c.default || c)),
  IconTopLoser: () => import('../../components/Icon/TopLoser.vue' /* webpackChunkName: "components/icon-top-loser" */).then(c => wrapFunctional(c.default || c)),
  IconTreadingUp: () => import('../../components/Icon/TreadingUp.vue' /* webpackChunkName: "components/icon-treading-up" */).then(c => wrapFunctional(c.default || c)),
  IconTriangle: () => import('../../components/Icon/Triangle.vue' /* webpackChunkName: "components/icon-triangle" */).then(c => wrapFunctional(c.default || c)),
  IconTsdBullet: () => import('../../components/Icon/TsdBullet.vue' /* webpackChunkName: "components/icon-tsd-bullet" */).then(c => wrapFunctional(c.default || c)),
  IconTsdCouter: () => import('../../components/Icon/TsdCouter.vue' /* webpackChunkName: "components/icon-tsd-couter" */).then(c => wrapFunctional(c.default || c)),
  IconUnFavorite: () => import('../../components/Icon/UnFavorite.vue' /* webpackChunkName: "components/icon-un-favorite" */).then(c => wrapFunctional(c.default || c)),
  IconUnLoveNotice: () => import('../../components/Icon/UnLoveNotice.vue' /* webpackChunkName: "components/icon-un-love-notice" */).then(c => wrapFunctional(c.default || c)),
  IconUsers: () => import('../../components/Icon/Users.vue' /* webpackChunkName: "components/icon-users" */).then(c => wrapFunctional(c.default || c)),
  IconValidateNegative: () => import('../../components/Icon/ValidateNegative.vue' /* webpackChunkName: "components/icon-validate-negative" */).then(c => wrapFunctional(c.default || c)),
  IconVirtualPortfolio: () => import('../../components/Icon/VirtualPortfolio.vue' /* webpackChunkName: "components/icon-virtual-portfolio" */).then(c => wrapFunctional(c.default || c)),
  IconWarningNotice: () => import('../../components/Icon/WarningNotice.vue' /* webpackChunkName: "components/icon-warning-notice" */).then(c => wrapFunctional(c.default || c)),
  IconXcalendar: () => import('../../components/Icon/Xcalendar.vue' /* webpackChunkName: "components/icon-xcalendar" */).then(c => wrapFunctional(c.default || c)),
  IconCategoryRegister: () => import('../../components/Icon/categoryRegister.vue' /* webpackChunkName: "components/icon-category-register" */).then(c => wrapFunctional(c.default || c)),
  IconDeleteToast: () => import('../../components/Icon/deleteToast.vue' /* webpackChunkName: "components/icon-delete-toast" */).then(c => wrapFunctional(c.default || c)),
  IconFailToast: () => import('../../components/Icon/failToast.vue' /* webpackChunkName: "components/icon-fail-toast" */).then(c => wrapFunctional(c.default || c)),
  IconInformation: () => import('../../components/Icon/information.vue' /* webpackChunkName: "components/icon-information" */).then(c => wrapFunctional(c.default || c)),
  IconSucessToast: () => import('../../components/Icon/sucessToast.vue' /* webpackChunkName: "components/icon-sucess-toast" */).then(c => wrapFunctional(c.default || c)),
  LayoutColumn: () => import('../../components/Layout/Column.vue' /* webpackChunkName: "components/layout-column" */).then(c => wrapFunctional(c.default || c)),
  LayoutRow: () => import('../../components/Layout/Row.vue' /* webpackChunkName: "components/layout-row" */).then(c => wrapFunctional(c.default || c)),
  LoadingLinear: () => import('../../components/Loading/Linear.vue' /* webpackChunkName: "components/loading-linear" */).then(c => wrapFunctional(c.default || c)),
  LoadingSpinner: () => import('../../components/Loading/Spinner.vue' /* webpackChunkName: "components/loading-spinner" */).then(c => wrapFunctional(c.default || c)),
  Logo: () => import('../../components/Logo/index.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c)),
  MarketAlertsBarBell: () => import('../../components/MarketAlertsBar/Bell.vue' /* webpackChunkName: "components/market-alerts-bar-bell" */).then(c => wrapFunctional(c.default || c)),
  MarketAlertsBarBellIcon: () => import('../../components/MarketAlertsBar/BellIcon.vue' /* webpackChunkName: "components/market-alerts-bar-bell-icon" */).then(c => wrapFunctional(c.default || c)),
  MarketAlertsBarMain: () => import('../../components/MarketAlertsBar/Main.vue' /* webpackChunkName: "components/market-alerts-bar-main" */).then(c => wrapFunctional(c.default || c)),
  MarketAlertsBarNews: () => import('../../components/MarketAlertsBar/News.vue' /* webpackChunkName: "components/market-alerts-bar-news" */).then(c => wrapFunctional(c.default || c)),
  MarketAlertsBarNewsItem: () => import('../../components/MarketAlertsBar/NewsItem.vue' /* webpackChunkName: "components/market-alerts-bar-news-item" */).then(c => wrapFunctional(c.default || c)),
  MarketAlertsBar: () => import('../../components/MarketAlertsBar/index.vue' /* webpackChunkName: "components/market-alerts-bar" */).then(c => wrapFunctional(c.default || c)),
  MarketTimeUpdateAsOfDate: () => import('../../components/MarketTimeUpdate/AsOfDate.vue' /* webpackChunkName: "components/market-time-update-as-of-date" */).then(c => wrapFunctional(c.default || c)),
  MarketTimeUpdateAsOfDateFactSheet: () => import('../../components/MarketTimeUpdate/AsOfDateFactSheet.vue' /* webpackChunkName: "components/market-time-update-as-of-date-fact-sheet" */).then(c => wrapFunctional(c.default || c)),
  MarketTimeUpdateDateInfo: () => import('../../components/MarketTimeUpdate/DateInfo.vue' /* webpackChunkName: "components/market-time-update-date-info" */).then(c => wrapFunctional(c.default || c)),
  MarketTimeUpdateDateInfoLast: () => import('../../components/MarketTimeUpdate/DateInfoLast.vue' /* webpackChunkName: "components/market-time-update-date-info-last" */).then(c => wrapFunctional(c.default || c)),
  Message: () => import('../../components/Message/index.vue' /* webpackChunkName: "components/message" */).then(c => wrapFunctional(c.default || c)),
  Modal: () => import('../../components/Modal/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c)),
  ModalVideo: () => import('../../components/Modal/Video.vue' /* webpackChunkName: "components/modal-video" */).then(c => wrapFunctional(c.default || c)),
  MyQuoteContent: () => import('../../components/MyQuote/Content.vue' /* webpackChunkName: "components/my-quote-content" */).then(c => wrapFunctional(c.default || c)),
  MyQuoteFavorite: () => import('../../components/MyQuote/Favorite.vue' /* webpackChunkName: "components/my-quote-favorite" */).then(c => wrapFunctional(c.default || c)),
  MyQuote: () => import('../../components/MyQuote/MyQuote.vue' /* webpackChunkName: "components/my-quote" */).then(c => wrapFunctional(c.default || c)),
  MyQuoteRecentlyView: () => import('../../components/MyQuote/RecentlyView.vue' /* webpackChunkName: "components/my-quote-recently-view" */).then(c => wrapFunctional(c.default || c)),
  NavigationMenu: () => import('../../components/NavigationMenu/NavigationMenu.vue' /* webpackChunkName: "components/navigation-menu" */).then(c => wrapFunctional(c.default || c)),
  OppdayArchive: () => import('../../components/Oppday/Archive.vue' /* webpackChunkName: "components/oppday-archive" */).then(c => wrapFunctional(c.default || c)),
  OppdayUpcoming: () => import('../../components/Oppday/Upcoming.vue' /* webpackChunkName: "components/oppday-upcoming" */).then(c => wrapFunctional(c.default || c)),
  Oppday: () => import('../../components/Oppday/index.vue' /* webpackChunkName: "components/oppday" */).then(c => wrapFunctional(c.default || c)),
  Pagination: () => import('../../components/Pagination/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c)),
  Pdpa: () => import('../../components/Pdpa/Pdpa.vue' /* webpackChunkName: "components/pdpa" */).then(c => wrapFunctional(c.default || c)),
  Picture: () => import('../../components/Picture/Picture.vue' /* webpackChunkName: "components/picture" */).then(c => wrapFunctional(c.default || c)),
  PictureThumbnail: () => import('../../components/Picture/Thumbnail.vue' /* webpackChunkName: "components/picture-thumbnail" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderBook: () => import('../../components/Placeholder/Book.vue' /* webpackChunkName: "components/placeholder-book" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderCard: () => import('../../components/Placeholder/Card.vue' /* webpackChunkName: "components/placeholder-card" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderCardCalculators: () => import('../../components/Placeholder/CardCalculators.vue' /* webpackChunkName: "components/placeholder-card-calculators" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderCardImage: () => import('../../components/Placeholder/CardImage.vue' /* webpackChunkName: "components/placeholder-card-image" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderCardImageOnly: () => import('../../components/Placeholder/CardImageOnly.vue' /* webpackChunkName: "components/placeholder-card-image-only" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderCardQuoteNews: () => import('../../components/Placeholder/CardQuoteNews.vue' /* webpackChunkName: "components/placeholder-card-quote-news" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderEducationResearchContentCard: () => import('../../components/Placeholder/EducationResearchContentCard.vue' /* webpackChunkName: "components/placeholder-education-research-content-card" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderHomeIndexTable: () => import('../../components/Placeholder/HomeIndexTable.vue' /* webpackChunkName: "components/placeholder-home-index-table" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderIPOCard: () => import('../../components/Placeholder/IPOCard.vue' /* webpackChunkName: "components/placeholder-i-p-o-card" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderIcon: () => import('../../components/Placeholder/Icon.vue' /* webpackChunkName: "components/placeholder-icon" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderList: () => import('../../components/Placeholder/List.vue' /* webpackChunkName: "components/placeholder-list" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderMarketUpdate: () => import('../../components/Placeholder/MarketUpdate.vue' /* webpackChunkName: "components/placeholder-market-update" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderOppdayCard: () => import('../../components/Placeholder/OppdayCard.vue' /* webpackChunkName: "components/placeholder-oppday-card" */).then(c => wrapFunctional(c.default || c)),
  Placeholder: () => import('../../components/Placeholder/Placeholder.vue' /* webpackChunkName: "components/placeholder" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderQuoteChartStock: () => import('../../components/Placeholder/QuoteChartStock.vue' /* webpackChunkName: "components/placeholder-quote-chart-stock" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderQuotePriceInfo: () => import('../../components/Placeholder/QuotePriceInfo.vue' /* webpackChunkName: "components/placeholder-quote-price-info" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderQuotePriceInfoDR: () => import('../../components/Placeholder/QuotePriceInfoDR.vue' /* webpackChunkName: "components/placeholder-quote-price-info-d-r" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderQuotePriceInfoDW: () => import('../../components/Placeholder/QuotePriceInfoDW.vue' /* webpackChunkName: "components/placeholder-quote-price-info-d-w" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderQuotePriceInfoETF: () => import('../../components/Placeholder/QuotePriceInfoETF.vue' /* webpackChunkName: "components/placeholder-quote-price-info-e-t-f" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderResearchList: () => import('../../components/Placeholder/ResearchList.vue' /* webpackChunkName: "components/placeholder-research-list" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderRulebookUpdate: () => import('../../components/Placeholder/RulebookUpdate.vue' /* webpackChunkName: "components/placeholder-rulebook-update" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderRulesbooksCate: () => import('../../components/Placeholder/RulesbooksCate.vue' /* webpackChunkName: "components/placeholder-rulesbooks-cate" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderTabIndexSet: () => import('../../components/Placeholder/TabIndexSet.vue' /* webpackChunkName: "components/placeholder-tab-index-set" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderTable: () => import('../../components/Placeholder/Table.vue' /* webpackChunkName: "components/placeholder-table" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderTableFavorite: () => import('../../components/Placeholder/TableFavorite.vue' /* webpackChunkName: "components/placeholder-table-favorite" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderTableIssuers: () => import('../../components/Placeholder/TableIssuers.vue' /* webpackChunkName: "components/placeholder-table-issuers" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderTableTsdCompanyList: () => import('../../components/Placeholder/TableTsdCompanyList.vue' /* webpackChunkName: "components/placeholder-table-tsd-company-list" */).then(c => wrapFunctional(c.default || c)),
  PlaylistCard: () => import('../../components/Playlist/Card.vue' /* webpackChunkName: "components/playlist-card" */).then(c => wrapFunctional(c.default || c)),
  Playlist: () => import('../../components/Playlist/Playlist.vue' /* webpackChunkName: "components/playlist" */).then(c => wrapFunctional(c.default || c)),
  Previewer: () => import('../../components/Previewer/Previewer.vue' /* webpackChunkName: "components/previewer" */).then(c => wrapFunctional(c.default || c)),
  QuizChoices: () => import('../../components/Quiz/Choices.vue' /* webpackChunkName: "components/quiz-choices" */).then(c => wrapFunctional(c.default || c)),
  QuizIcon: () => import('../../components/Quiz/Icon.vue' /* webpackChunkName: "components/quiz-icon" */).then(c => wrapFunctional(c.default || c)),
  QuoteHeader: () => import('../../components/Quote/Header.vue' /* webpackChunkName: "components/quote-header" */).then(c => wrapFunctional(c.default || c)),
  QuoteHeaderOverview: () => import('../../components/Quote/HeaderOverview.vue' /* webpackChunkName: "components/quote-header-overview" */).then(c => wrapFunctional(c.default || c)),
  QuoteImportant: () => import('../../components/Quote/Important.vue' /* webpackChunkName: "components/quote-important" */).then(c => wrapFunctional(c.default || c)),
  QuoteItemList: () => import('../../components/Quote/ItemList.vue' /* webpackChunkName: "components/quote-item-list" */).then(c => wrapFunctional(c.default || c)),
  QuotePriceDetail: () => import('../../components/Quote/PriceDetail.vue' /* webpackChunkName: "components/quote-price-detail" */).then(c => wrapFunctional(c.default || c)),
  QuoteRelatedProduct: () => import('../../components/Quote/RelatedProduct.vue' /* webpackChunkName: "components/quote-related-product" */).then(c => wrapFunctional(c.default || c)),
  RelatedArticle: () => import('../../components/Related/Article.vue' /* webpackChunkName: "components/related-article" */).then(c => wrapFunctional(c.default || c)),
  RelatedMediaIcon: () => import('../../components/Related/MediaIcon.vue' /* webpackChunkName: "components/related-media-icon" */).then(c => wrapFunctional(c.default || c)),
  SearchInput: () => import('../../components/Search/Input.vue' /* webpackChunkName: "components/search-input" */).then(c => wrapFunctional(c.default || c)),
  SmartBanner: () => import('../../components/SmartBanner/SmartBanner.vue' /* webpackChunkName: "components/smart-banner" */).then(c => wrapFunctional(c.default || c)),
  SwiperCarousel: () => import('../../components/SwiperCarousel/SwiperCarousel.vue' /* webpackChunkName: "components/swiper-carousel" */).then(c => wrapFunctional(c.default || c)),
  TableSetting: () => import('../../components/Table/Setting.vue' /* webpackChunkName: "components/table-setting" */).then(c => wrapFunctional(c.default || c)),
  Table: () => import('../../components/Table/Table.vue' /* webpackChunkName: "components/table" */).then(c => wrapFunctional(c.default || c)),
  TableAutoMatching: () => import('../../components/Table/TableAutoMatching.vue' /* webpackChunkName: "components/table-auto-matching" */).then(c => wrapFunctional(c.default || c)),
  TableCustomField: () => import('../../components/Table/TableCustomField.vue' /* webpackChunkName: "components/table-custom-field" */).then(c => wrapFunctional(c.default || c)),
  TableDraggableColumn: () => import('../../components/Table/TableDraggableColumn.vue' /* webpackChunkName: "components/table-draggable-column" */).then(c => wrapFunctional(c.default || c)),
  TableGroupColumn: () => import('../../components/Table/TableGroupColumn.vue' /* webpackChunkName: "components/table-group-column" */).then(c => wrapFunctional(c.default || c)),
  TableIPO: () => import('../../components/Table/TableIPO.vue' /* webpackChunkName: "components/table-i-p-o" */).then(c => wrapFunctional(c.default || c)),
  TableMain: () => import('../../components/Table/TableMain.vue' /* webpackChunkName: "components/table-main" */).then(c => wrapFunctional(c.default || c)),
  TableSimple: () => import('../../components/Table/TableSimple.vue' /* webpackChunkName: "components/table-simple" */).then(c => wrapFunctional(c.default || c)),
  TableSimple2: () => import('../../components/Table/TableSimple2.vue' /* webpackChunkName: "components/table-simple2" */).then(c => wrapFunctional(c.default || c)),
  TipsCorner: () => import('../../components/Tips/Corner.vue' /* webpackChunkName: "components/tips-corner" */).then(c => wrapFunctional(c.default || c)),
  TipsIcon: () => import('../../components/Tips/Icon.vue' /* webpackChunkName: "components/tips-icon" */).then(c => wrapFunctional(c.default || c)),
  Tips: () => import('../../components/Tips/Tips.vue' /* webpackChunkName: "components/tips" */).then(c => wrapFunctional(c.default || c)),
  Toasts: () => import('../../components/Toasts/Toasts.vue' /* webpackChunkName: "components/toasts" */).then(c => wrapFunctional(c.default || c)),
  VideoPlayer: () => import('../../components/VideoPlayer/VideoPlayer.vue' /* webpackChunkName: "components/video-player" */).then(c => wrapFunctional(c.default || c)),
  AccordionHorizontal: () => import('../../components/Accordion/Horizontal/AccordionHorizontal.vue' /* webpackChunkName: "components/accordion-horizontal" */).then(c => wrapFunctional(c.default || c)),
  AccordionHorizontalContentItem: () => import('../../components/Accordion/Horizontal/ContentItem.vue' /* webpackChunkName: "components/accordion-horizontal-content-item" */).then(c => wrapFunctional(c.default || c)),
  AccordionHorizontalHeadingItem: () => import('../../components/Accordion/Horizontal/HeadingItem.vue' /* webpackChunkName: "components/accordion-horizontal-heading-item" */).then(c => wrapFunctional(c.default || c)),
  AppBarMobile: () => import('../../components/AppBar/AppBarMobile/AppBarMobile.vue' /* webpackChunkName: "components/app-bar-mobile" */).then(c => wrapFunctional(c.default || c)),
  AppBarMobileMenu: () => import('../../components/AppBar/AppBarMobile/AppBarMobileMenu.vue' /* webpackChunkName: "components/app-bar-mobile-menu" */).then(c => wrapFunctional(c.default || c)),
  AppBarMobileMenuCollapse: () => import('../../components/AppBar/AppBarMobile/AppBarMobileMenuCollapse.vue' /* webpackChunkName: "components/app-bar-mobile-menu-collapse" */).then(c => wrapFunctional(c.default || c)),
  AppBarMobileMenuItem: () => import('../../components/AppBar/AppBarMobile/AppBarMobileMenuItem.vue' /* webpackChunkName: "components/app-bar-mobile-menu-item" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationAfterLogin: () => import('../../components/Banner/Location/AfterLogin.vue' /* webpackChunkName: "components/banner-location-after-login" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationFooter: () => import('../../components/Banner/Location/Footer.vue' /* webpackChunkName: "components/banner-location-footer" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationMarketDR: () => import('../../components/Banner/Location/MarketDR.vue' /* webpackChunkName: "components/banner-location-market-d-r" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationMarketDRX: () => import('../../components/Banner/Location/MarketDRX.vue' /* webpackChunkName: "components/banner-location-market-d-r-x" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationMarketDW: () => import('../../components/Banner/Location/MarketDW.vue' /* webpackChunkName: "components/banner-location-market-d-w" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationMarketETF: () => import('../../components/Banner/Location/MarketETF.vue' /* webpackChunkName: "components/banner-location-market-e-t-f" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationPushDownTop: () => import('../../components/Banner/Location/PushDownTop.vue' /* webpackChunkName: "components/banner-location-push-down-top" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationResearchOverview: () => import('../../components/Banner/Location/ResearchOverview.vue' /* webpackChunkName: "components/banner-location-research-overview" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationSetSourceVideo: () => import('../../components/Banner/Location/SetSourceVideo.vue' /* webpackChunkName: "components/banner-location-set-source-video" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationTCH: () => import('../../components/Banner/Location/TCH.vue' /* webpackChunkName: "components/banner-location-t-c-h" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationTSD: () => import('../../components/Banner/Location/TSD.vue' /* webpackChunkName: "components/banner-location-t-s-d" */).then(c => wrapFunctional(c.default || c)),
  FooterSubMenu: () => import('../../components/Footer/SubMenu/SubMenu.vue' /* webpackChunkName: "components/footer-sub-menu" */).then(c => wrapFunctional(c.default || c)),
  HighlightMarketOverviewCard: () => import('../../components/Highlight/MarketOverview/Card.vue' /* webpackChunkName: "components/highlight-market-overview-card" */).then(c => wrapFunctional(c.default || c)),
  HighlightMarketOverview: () => import('../../components/Highlight/MarketOverview/index.vue' /* webpackChunkName: "components/highlight-market-overview" */).then(c => wrapFunctional(c.default || c)),
  IconEventCalendarTabActivity: () => import('../../components/Icon/EventCalendar/TabActivity.vue' /* webpackChunkName: "components/icon-event-calendar-tab-activity" */).then(c => wrapFunctional(c.default || c)),
  IconEventCalendarTabAll: () => import('../../components/Icon/EventCalendar/TabAll.vue' /* webpackChunkName: "components/icon-event-calendar-tab-all" */).then(c => wrapFunctional(c.default || c)),
  IconEventCalendarTabOppday: () => import('../../components/Icon/EventCalendar/TabOppday.vue' /* webpackChunkName: "components/icon-event-calendar-tab-oppday" */).then(c => wrapFunctional(c.default || c)),
  IconEventCalendarTabSeminar: () => import('../../components/Icon/EventCalendar/TabSeminar.vue' /* webpackChunkName: "components/icon-event-calendar-tab-seminar" */).then(c => wrapFunctional(c.default || c)),
  IconKnowledgeAll: () => import('../../components/Icon/Knowledge/All.vue' /* webpackChunkName: "components/icon-knowledge-all" */).then(c => wrapFunctional(c.default || c)),
  IconKnowledgeArticle: () => import('../../components/Icon/Knowledge/Article.vue' /* webpackChunkName: "components/icon-knowledge-article" */).then(c => wrapFunctional(c.default || c)),
  IconKnowledgeBook: () => import('../../components/Icon/Knowledge/Book.vue' /* webpackChunkName: "components/icon-knowledge-book" */).then(c => wrapFunctional(c.default || c)),
  IconKnowledgeEleaning: () => import('../../components/Icon/Knowledge/Eleaning.vue' /* webpackChunkName: "components/icon-knowledge-eleaning" */).then(c => wrapFunctional(c.default || c)),
  IconKnowledgeInfo: () => import('../../components/Icon/Knowledge/Info.vue' /* webpackChunkName: "components/icon-knowledge-info" */).then(c => wrapFunctional(c.default || c)),
  IconKnowledgeVideo: () => import('../../components/Icon/Knowledge/Video.vue' /* webpackChunkName: "components/icon-knowledge-video" */).then(c => wrapFunctional(c.default || c)),
  IconMediaArticle: () => import('../../components/Icon/Media/Article.vue' /* webpackChunkName: "components/icon-media-article" */).then(c => wrapFunctional(c.default || c)),
  IconMediaEBook: () => import('../../components/Icon/Media/EBook.vue' /* webpackChunkName: "components/icon-media-e-book" */).then(c => wrapFunctional(c.default || c)),
  IconMediaELearning: () => import('../../components/Icon/Media/ELearning.vue' /* webpackChunkName: "components/icon-media-e-learning" */).then(c => wrapFunctional(c.default || c)),
  IconMediaGlossary: () => import('../../components/Icon/Media/Glossary.vue' /* webpackChunkName: "components/icon-media-glossary" */).then(c => wrapFunctional(c.default || c)),
  IconMediaInfographic: () => import('../../components/Icon/Media/Infographic.vue' /* webpackChunkName: "components/icon-media-infographic" */).then(c => wrapFunctional(c.default || c)),
  IconMediaKnowledge: () => import('../../components/Icon/Media/Knowledge.vue' /* webpackChunkName: "components/icon-media-knowledge" */).then(c => wrapFunctional(c.default || c)),
  IconMediaNews: () => import('../../components/Icon/Media/News.vue' /* webpackChunkName: "components/icon-media-news" */).then(c => wrapFunctional(c.default || c)),
  IconMediaPlay: () => import('../../components/Icon/Media/Play.vue' /* webpackChunkName: "components/icon-media-play" */).then(c => wrapFunctional(c.default || c)),
  IconMediaPodcast: () => import('../../components/Icon/Media/Podcast.vue' /* webpackChunkName: "components/icon-media-podcast" */).then(c => wrapFunctional(c.default || c)),
  IconMediaResearch: () => import('../../components/Icon/Media/Research.vue' /* webpackChunkName: "components/icon-media-research" */).then(c => wrapFunctional(c.default || c)),
  IconMediaSETBook: () => import('../../components/Icon/Media/SETBook.vue' /* webpackChunkName: "components/icon-media-s-e-t-book" */).then(c => wrapFunctional(c.default || c)),
  IconMemberElearning: () => import('../../components/Icon/Member/Elearning.vue' /* webpackChunkName: "components/icon-member-elearning" */).then(c => wrapFunctional(c.default || c)),
  IconMemberEventSeminar: () => import('../../components/Icon/Member/EventSeminar.vue' /* webpackChunkName: "components/icon-member-event-seminar" */).then(c => wrapFunctional(c.default || c)),
  IconMemberInvestorPortal: () => import('../../components/Icon/Member/InvestorPortal.vue' /* webpackChunkName: "components/icon-member-investor-portal" */).then(c => wrapFunctional(c.default || c)),
  IconMemberOpportunityDay: () => import('../../components/Icon/Member/OpportunityDay.vue' /* webpackChunkName: "components/icon-member-opportunity-day" */).then(c => wrapFunctional(c.default || c)),
  IconMemberSetInvestnow: () => import('../../components/Icon/Member/SetInvestnow.vue' /* webpackChunkName: "components/icon-member-set-investnow" */).then(c => wrapFunctional(c.default || c)),
  IconMemberSetSmart: () => import('../../components/Icon/Member/SetSmart.vue' /* webpackChunkName: "components/icon-member-set-smart" */).then(c => wrapFunctional(c.default || c)),
  IconMemberSettradeApp: () => import('../../components/Icon/Member/SettradeApp.vue' /* webpackChunkName: "components/icon-member-settrade-app" */).then(c => wrapFunctional(c.default || c)),
  IconMemberStreamingClick2Win: () => import('../../components/Icon/Member/StreamingClick2Win.vue' /* webpackChunkName: "components/icon-member-streaming-click2-win" */).then(c => wrapFunctional(c.default || c)),
  IconSharedCopyLink: () => import('../../components/Icon/Shared/CopyLink.vue' /* webpackChunkName: "components/icon-shared-copy-link" */).then(c => wrapFunctional(c.default || c)),
  IconSharedFacebook: () => import('../../components/Icon/Shared/Facebook.vue' /* webpackChunkName: "components/icon-shared-facebook" */).then(c => wrapFunctional(c.default || c)),
  IconSharedLine: () => import('../../components/Icon/Shared/Line.vue' /* webpackChunkName: "components/icon-shared-line" */).then(c => wrapFunctional(c.default || c)),
  IconSharedTwitter: () => import('../../components/Icon/Shared/Twitter.vue' /* webpackChunkName: "components/icon-shared-twitter" */).then(c => wrapFunctional(c.default || c)),
  LoadingBar: () => import('../../components/Loading/Bar/Bar.vue' /* webpackChunkName: "components/loading-bar" */).then(c => wrapFunctional(c.default || c)),
  LoadingBarLarge: () => import('../../components/Loading/Bar/Large.vue' /* webpackChunkName: "components/loading-bar-large" */).then(c => wrapFunctional(c.default || c)),
  LoadingBarNormal: () => import('../../components/Loading/Bar/Normal.vue' /* webpackChunkName: "components/loading-bar-normal" */).then(c => wrapFunctional(c.default || c)),
  LoadingBarSmall: () => import('../../components/Loading/Bar/Small.vue' /* webpackChunkName: "components/loading-bar-small" */).then(c => wrapFunctional(c.default || c)),
  MiscModalAddQuote: () => import('../../components/Misc/Modal/AddQuote.vue' /* webpackChunkName: "components/misc-modal-add-quote" */).then(c => wrapFunctional(c.default || c)),
  MiscModalDropdownSearch: () => import('../../components/Misc/Modal/DropdownSearch.vue' /* webpackChunkName: "components/misc-modal-dropdown-search" */).then(c => wrapFunctional(c.default || c)),
  MiscModalSuggestion: () => import('../../components/Misc/Modal/Suggestion.vue' /* webpackChunkName: "components/misc-modal-suggestion" */).then(c => wrapFunctional(c.default || c)),
  MiscModal: () => import('../../components/Misc/Modal/index.vue' /* webpackChunkName: "components/misc-modal" */).then(c => wrapFunctional(c.default || c)),
  QuoteBenefitsCollapse: () => import('../../components/Quote/Benefits/BenefitsCollapse.vue' /* webpackChunkName: "components/quote-benefits-collapse" */).then(c => wrapFunctional(c.default || c)),
  QuoteBenefitsIndex: () => import('../../components/Quote/Benefits/BenefitsIndex.vue' /* webpackChunkName: "components/quote-benefits-index" */).then(c => wrapFunctional(c.default || c)),
  QuoteBenefitsTable: () => import('../../components/Quote/Benefits/BenefitsTable.vue' /* webpackChunkName: "components/quote-benefits-table" */).then(c => wrapFunctional(c.default || c)),
  QuoteChartPie: () => import('../../components/Quote/Chart/Pie.vue' /* webpackChunkName: "components/quote-chart-pie" */).then(c => wrapFunctional(c.default || c)),
  QuoteChartStock: () => import('../../components/Quote/Chart/Stock.vue' /* webpackChunkName: "components/quote-chart-stock" */).then(c => wrapFunctional(c.default || c)),
  QuoteCompanyInfo: () => import('../../components/Quote/CompanyInfo/index.vue' /* webpackChunkName: "components/quote-company-info" */).then(c => wrapFunctional(c.default || c)),
  QuoteInputSearch: () => import('../../components/Quote/Input/Search.vue' /* webpackChunkName: "components/quote-input-search" */).then(c => wrapFunctional(c.default || c)),
  QuoteMarketIndexFooter: () => import('../../components/Quote/MarketIndex/Footer.vue' /* webpackChunkName: "components/quote-market-index-footer" */).then(c => wrapFunctional(c.default || c)),
  QuoteMarketIndexHeader: () => import('../../components/Quote/MarketIndex/Header.vue' /* webpackChunkName: "components/quote-market-index-header" */).then(c => wrapFunctional(c.default || c)),
  QuoteMarketStaticsFooter: () => import('../../components/Quote/MarketStatics/Footer.vue' /* webpackChunkName: "components/quote-market-statics-footer" */).then(c => wrapFunctional(c.default || c)),
  QuoteNews: () => import('../../components/Quote/News/index.vue' /* webpackChunkName: "components/quote-news" */).then(c => wrapFunctional(c.default || c)),
  QuoteUpcomingEvent: () => import('../../components/Quote/UpcomingEvent/index.vue' /* webpackChunkName: "components/quote-upcoming-event" */).then(c => wrapFunctional(c.default || c)),
  RelatedContentCard: () => import('../../components/Related/Content/Card.vue' /* webpackChunkName: "components/related-content-card" */).then(c => wrapFunctional(c.default || c)),
  RelatedContent: () => import('../../components/Related/Content/index.vue' /* webpackChunkName: "components/related-content" */).then(c => wrapFunctional(c.default || c)),
  RelatedKnowledgeCard: () => import('../../components/Related/Knowledge/Card.vue' /* webpackChunkName: "components/related-knowledge-card" */).then(c => wrapFunctional(c.default || c)),
  RelatedKnowledge: () => import('../../components/Related/Knowledge/index.vue' /* webpackChunkName: "components/related-knowledge" */).then(c => wrapFunctional(c.default || c)),
  RelatedPlaylistCard: () => import('../../components/Related/Playlist/Card.vue' /* webpackChunkName: "components/related-playlist-card" */).then(c => wrapFunctional(c.default || c)),
  RelatedPlaylist: () => import('../../components/Related/Playlist/index.vue' /* webpackChunkName: "components/related-playlist" */).then(c => wrapFunctional(c.default || c)),
  SearchDropdownContent: () => import('../../components/Search/Dropdown/Content.vue' /* webpackChunkName: "components/search-dropdown-content" */).then(c => wrapFunctional(c.default || c)),
  SearchDropdownMarket: () => import('../../components/Search/Dropdown/Market.vue' /* webpackChunkName: "components/search-dropdown-market" */).then(c => wrapFunctional(c.default || c)),
  SearchDropdownSecurities: () => import('../../components/Search/Dropdown/Securities.vue' /* webpackChunkName: "components/search-dropdown-securities" */).then(c => wrapFunctional(c.default || c)),
  SearchDropdownTreadingUp: () => import('../../components/Search/Dropdown/TreadingUp.vue' /* webpackChunkName: "components/search-dropdown-treading-up" */).then(c => wrapFunctional(c.default || c)),
  SearchDropdown: () => import('../../components/Search/Dropdown/index.vue' /* webpackChunkName: "components/search-dropdown" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationHero: () => import('../../components/Banner/Location/Hero/Hero.vue' /* webpackChunkName: "components/banner-location-hero" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationHeroItem: () => import('../../components/Banner/Location/Hero/Item.vue' /* webpackChunkName: "components/banner-location-hero-item" */).then(c => wrapFunctional(c.default || c)),
  QuoteCompanyInfoReportFormIPO: () => import('../../components/Quote/CompanyInfo/ReportForm/IPO.vue' /* webpackChunkName: "components/quote-company-info-report-form-i-p-o" */).then(c => wrapFunctional(c.default || c)),
  QuoteCompanyInfoReportForm: () => import('../../components/Quote/CompanyInfo/ReportForm/index.vue' /* webpackChunkName: "components/quote-company-info-report-form" */).then(c => wrapFunctional(c.default || c)),
  QuoteMarketIndexChartCompare: () => import('../../components/Quote/MarketIndex/Chart/Compare.vue' /* webpackChunkName: "components/quote-market-index-chart-compare" */).then(c => wrapFunctional(c.default || c)),
  QuoteNewsFinanceState: () => import('../../components/Quote/News/FinanceState/index.vue' /* webpackChunkName: "components/quote-news-finance-state" */).then(c => wrapFunctional(c.default || c)),
  QuoteNewsSearch: () => import('../../components/Quote/News/Search/index.vue' /* webpackChunkName: "components/quote-news-search" */).then(c => wrapFunctional(c.default || c)),
  QuoteNewsSecurities: () => import('../../components/Quote/News/Securities/index.vue' /* webpackChunkName: "components/quote-news-securities" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
