import { render, staticRenderFns } from "./DropdownShortcut.vue?vue&type=template&id=20cccf4e&"
import script from "./DropdownShortcut.vue?vue&type=script&lang=js&"
export * from "./DropdownShortcut.vue?vue&type=script&lang=js&"
import style0 from "./DropdownShortcut.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconAppMenu: require('/app/components/Icon/AppMenu.vue').default,Button: require('/app/components/Button/Button.vue').default,Picture: require('/app/components/Picture/Picture.vue').default})
