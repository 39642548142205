/* eslint-disable */
// const { contentRenderType } = require('@/utils')

const convertToUndefined = (data) => {
  if (typeof data === 'string' && data === 'Undefined') {
    return undefined
  }
  return data
}
const convertCategoryToArray = (url, slug, locale) => {
  const _slug = decodeURIComponent(slug ?? '').trim()
  const _url = decodeURIComponent(url ?? '').replace(`/${locale}/`, '')
  const arrPath = _url.split('/').filter((v) => {return v !== ''})
  const arrCntCate = []
  arrPath.forEach((p) => {
    const _p = p.trim()
    const isSlug = _p === _slug
    if (isSlug === true) {
      const _s = _slug
      if (/^(\d+-)/i.test(_s)) { // check start with number and dash
        // Remove numbers from slug name
        // NOTE: content render type "PageBuilder" will remove numbers from slug name by CMS API
        const slugAfterReplace = _s?.replace(/^\d+/i, '').replace(/^-/i, '') // replace number | "-" with ''
        arrCntCate.push(slugAfterReplace)
      } else {
        arrCntCate.push(_s)
      }
    }
    if (isSlug === false) {
      arrCntCate.push(_p)
    }
  })
  return arrCntCate
}

const findContentGroup = (path, slug, locale, routeName) => {
  const { contentGroupList } = require('./content-group-list')
  let _path = decodeURIComponent(path ?? '').replace(`/${locale}`, '')
  let data = contentGroupList.filter((item) => {
    const { path, routeName: itemRouteName } = item
    if (itemRouteName) {
      const regx = new RegExp(itemRouteName, 'i')
      return regx.test(routeName)
    }
    return path === _path
  })[0] || undefined

  // find content group without slug
  const _slug = decodeURIComponent(slug ?? '').trim()
  if (_slug && data === undefined) {
    _path = _path.replace(`/${_slug}`, '')
    data = contentGroupList.filter(r => r.path === _path)[0]
    // console.info('found by ignore slug --->', _path)
  }
  return data || undefined
}

export default (context, inject) => {
  const { app, store, route, i18n } = context
  
  let curentRoute = route
  app.router.afterEach((to, from) => {
    curentRoute = to
  })

  /**
   * Get data content
   */
  const dataContent = () => {
    const content = store.state.contents.content
    return content || {}
  }

  /**
   * Get data head
   */
  const dataHead = () => {
    const content = dataContent()
    const { head } = content
    return head || {}
  }

  /**
   * Get data info
   */
   const dataInfo = () => {
    const content = dataContent()
    const { info } = content
    return info || {}
  }

  /**
   * Get data personalized
   */
  const contentPersonalized = () => {
    const content = dataContent()
    const { personalized } = content
    if (!personalized) { return null }

    const {
      contentType,
      contentTag,
      contentSuperGroup,
      contentGroup,
      contentCategory,
      product,
      activityType,
      activityFocus,
      teamName
    } = personalized || {}
    return {
      contentType,
      contentTag,
      contentSuperGroup,
      contentGroup,
      contentCategory,
      product,
      activityType,
      activityFocus,
      teamName
    }
  }

  /**
   * Content personalized data
   */
  const dataPersonalized = () => {
    const { 
      contentType,
      contentCategory: cntCategory,
      contentTag,
      contentSuperGroup,
      contentGroup,
      product,
      teamName
    } = contentPersonalized()
    let _contentType = contentType
    let _contentCategory = cntCategory
    let _contentTag = contentTag
    let _contentSuperGroup = contentSuperGroup
    let _contentGroup = contentGroup
    let _product = product
    let _teamName = teamName
    
    const { path, params, name: routeName } = curentRoute
    // console.log('DEBUG curent route', curentRoute)
    // console.log('DEBUG store.state.contents.contentSEO', store.state.contents.contentSEO)
    const { renderType } = dataInfo()
    const { contentRenderType } = require('@/utils')
    const isFixedLayout = renderType !== contentRenderType.pageBuilder
    const contentGroupData = findContentGroup(path, params.slug, i18n.locale, routeName)
    if (isFixedLayout && contentGroupData) {
      const { customPersonalized } = store.state.contents
      const {
        teamName: customTeamName,
        contentCategory: customContentCategory
      } = customPersonalized ?? {}
      const {
        contentCategory,
        contentTag,
        teamName
      } = contentGroupData

      // Set team name
      _teamName = customTeamName ? customTeamName : teamName
      // Set content category
      if (customContentCategory?.length) {
        _contentCategory = customContentCategory
      } else if (contentCategory?.length) {
        _contentCategory = [...contentCategory]
      } else {
        const contentCateSlug = params.slug
        _contentCategory = convertCategoryToArray(path, contentCateSlug, i18n.locale)
      }
      // End select content category

      // Select content tag
      if (contentTag?.length) {
        _contentTag = [...contentTag]
      } else {
        const { keywords } = store.state.contents.contentSEO || {}
        const arrKeywords = keywords?.split(',') || []
        _contentTag = arrKeywords.map(k => k.trim())
      }
      // End select content tag

      _contentType = contentGroupData.contentType ? contentGroupData.contentType : contentType // <-- contentType from content home
      _contentSuperGroup = contentGroupData.contentSuperGroup ? contentGroupData.contentSuperGroup : contentSuperGroup // <-- contentSuperGroup from content of home page
      _contentGroup = contentGroupData.contentGroup ? contentGroupData.contentGroup : contentGroup // <-- contentGroup from content of home page
      _product = contentGroupData.productName ? contentGroupData.productName : product // <-- product from content of home page
      // console.log('DEBUG content group data --->', contentGroupData)
    }
    return {
      contentType: _contentType,
      contentCategory: _contentCategory,
      contentTag: _contentTag,
      contentSuperGroup: _contentSuperGroup,
      contentGroup: _contentGroup,
      product: _product,
      teamName: _teamName
    }
  }

  const plugin = {
    /**
     * Push data layer event-tracked-element
     * @param {String} options.pageName
     * @param {String} options.title
     * @param {String} options.elementName
     * @param {String} options.sectionName
     */
    /**
     * event: "event-tracked-element"
     * eventAction: "click Panel Search - ค้นหาล่าสุด" -----> sectionName
     * eventCategory: "<หน่วยงานที่รับผิดชอบ (Data Category (Team) ที่ฟิกซ์ในหน้านั้น)> – <ชื่อ Title เว็บไซต์ (ตามที่ระบุใน CMS ส่วนเมนู Website)> – <ชื่อหน้า Title Page>"
     * eventLabel: "<content group> - <product name> - <คำค้นหาล่าสุดที่ผู้ใช้งานกำลังจะคลิก>"
     */
    async clickElement (options) {
      if (app.$content.isPreview()) { return }
      
      const { elementPDPA } = require('~/utils')
      const {
        contentGroup,
        product,
        teamName
      } = dataPersonalized()
      const { 
        title, 
        siteName
      } = dataHead()

      // const newTitle = store.state.contents.contentSEO?.title || newTitle
      const { title: newTitle } = store.state.contents.contentSEO ?? {}
      const _newTitle = newTitle || title

      // const newContentGroup = contentGroup // specificPageContentGroup(curentRoute) || contentGroup
      const pageName = options.pageName /* options.pageName as specific page title */ || _newTitle // title
      const pageTitle = options.title /* options.title as dataLabel */ || _newTitle // title // use content title for bookmark content
      const productName = convertToUndefined(product || 'Undefined')

      // load message i18n on click
      await this.LoadLocaleMessage(i18n, options.name)
      // set event label
      const label = []
      if (convertToUndefined(contentGroup || 'Undefined')) {
        label.push(contentGroup)
      }
      if (productName) {
        label.push(productName)
      }
      if (pageTitle) {
        if (options.name) {
          label.push(i18n.t(`eventTracking.${options.name}.title`, options.optionLang))
        } else {
          label.push(pageTitle)
        }
      }

      let dataTeamName = teamName
      // if (isSpecificPage(curentRoute)) {
      //   dataTeamName = process.env.GTM_EVENT_CATEGORY_TEAM
      // }
      // set team by element name
      if (options.elementName === elementPDPA.name) {
        const { pdpaCookie } = dataContent()
        dataTeamName = (pdpaCookie || {}).teamName
      }

      // set event category
      const websiteName = siteName || title // (dataContent.websiteInfo || { websiteName: dataContent.title /* get title from welcomepage */ }).websiteName
      let eventCategory = []
      if (dataTeamName) {
        eventCategory.push(dataTeamName)
      }
      if (websiteName) {
        eventCategory.push(websiteName)
      }
      if (pageName) {
        eventCategory.push(pageName)
      }
      const eventAction = options.name ? `click ${i18n.t(`eventTracking.${options.name}.sectionName`, options.optionLang) || 'content'}` : `click ${options.sectionName || 'content'}`
      const eventData = {
        event: 'event-tracked-element',
        eventAction,
        eventCategory: eventCategory.join(' -- '),
        eventLabel: label.join(' -- ')
      }

      // GTM push data
      if (typeof(dataLayer) !== 'undefined') {
        dataLayer.push(eventData)
        const { setLog } = require('~/helpers/event-tracking/log')
        setLog(eventData)
      }
    },

    /**
     * load message 
     * @param {*} name 
     */
    async LoadLocaleMessage (i18n, name) {
      if (name) {
        const pageEventTrackingTH = await require(`@/lang/event-tracking/${i18n.locale}.json`)
        const objEvent = await pageEventTrackingTH.find(e => (e?.name || '') === name)
        if (objEvent) {
          const etObj = { eventTracking: {} }
          etObj.eventTracking[name] = objEvent
          await i18n.mergeLocaleMessage(i18n.locale, etObj)
        }
      }
    },

    /**
     * Push data layer set_tab_element_change
     * @param {*} title 
     */
    clickTabElement (title) {
      const eventData = {
        event: 'set_tab_element_change',
        tab_header_title: title
      }
      // GTM push data
      if (typeof(dataLayer) !== 'undefined') {
        dataLayer.push(eventData)
      }
    },

    /**
     * Add site search event
     * @param {String} keyword 
     * @param {String} searchBoxName 
     * 
     */
    siteSearch (keyword, searchBoxName) {
      const textKeyword = keyword || ''
      let setSearchBoxID = 'search_box_'
      if (searchBoxName) {
        setSearchBoxID += searchBoxName
      }
      const eventData = {
        event: 'set_site_search',
        setSearchBoxID,
        setSearchTerm: textKeyword
      }

      if (typeof(dataLayer) !== 'undefined') {
        dataLayer.push(eventData)
        const { setLog } = require('~/helpers/event-tracking/log')
        setLog(eventData)
      }
    },

    pagePersonalized () {
      if (app.$content.isPreview()) { return }
      if (!contentPersonalized()) { return }
      const {
        activityType,
        activityFocus
      } = contentPersonalized()

      const {
        contentType,
        contentTag,
        contentCategory,
        product,
        contentGroup,
        contentSuperGroup
      } = dataPersonalized()

      const eventData = {
        content_type: convertToUndefined(contentType),
        content_tag: [...contentTag],
        content_supergroup: convertToUndefined(contentSuperGroup || 'Undefined'),
        content_group: contentGroup || 'Undefined',
        content_category: [...contentCategory],
        product_name: convertToUndefined(product || 'Undefined'),
        activity_type: convertToUndefined(activityType || 'Undefined'),
        activity_focus: convertToUndefined(activityFocus || 'Undefined')
      }
      // console.log('DEBUG CMS content personalized data', JSON.parse(JSON.stringify(contentPersonalized())))
      // console.log('DEBUG dataLayer personalized', eventData)
      // push datalayer
      if (typeof(dataLayer) !== 'undefined') {
        dataLayer.push(eventData)
        const { fullPath } = curentRoute
        store.commit('setContentDataLayerCurrentPath', fullPath)
      }
    },

    clickContentPersonalized () {}
  }

  inject('personalized', plugin)
  context.$personalized = plugin
}
