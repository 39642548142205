//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { customAlphabet } from 'nanoid'
// const { Dropdown } = require('bootstrap')
const nanoid = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyz', 6)
export default {
  name: 'Dropdown',
  props: {
    backdrop: {
      type: Boolean,
      default: false
    },
    display: {
      type: String,
      default: 'static'
    },
    dropdownToggleClass: {
      type: String,
      default: null
    },
    dropdownMenuClass: {
      type: String,
      default: null
    },
    dropdownActiveClass: {
      type: String,
      default: null
    },
    autoClose: {
      type: String,
      default: 'true'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showArrow: {
      type: Boolean,
      default: true
    }
  },
  data () {
    const id = nanoid()
    return {
      dropdownId: `dropdown${id}`,
      dropdownToggle: null,
      dropdownToggleId: `dropdownToggle${id}`
    }
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {
    if (this.backdrop === true) {
      this.$nextTick(() => {
        this.removeBackdropEvent()
      })
    }
  },
  methods: {
    init () {
      const { Dropdown } = require('bootstrap')
      const options = {
        display: this.display
      }
      const elementDropdownToggle = this.$refs.dropdownToggle
      this.dropdownToggle = new Dropdown(elementDropdownToggle, options)

      // set dropdown id
      // const id = nanoid()
      // this.dropdownId = `dropdown${id}`
      // this.dropdownToggleId = `dropdownToggle${id}`
      // console.log({
      //   dropdownId: this.dropdownId,
      //   dropdownToggleId: this.dropdownToggleId
      // })

      // register event
      if (this.backdrop === true) {
        this.$nextTick(() => {
          this.registerBackdropEvent()
        })
      }
    },
    registerBackdropEvent () {
      const dropdown = document.getElementById(this.dropdownId)
      if (dropdown) {
        // console.log('registerBackdropEvent', dropdown)
        dropdown.addEventListener('show.bs.dropdown', this.handleShow)
        dropdown.addEventListener('hide.bs.dropdown', this.handleHide)
      }
    },
    removeBackdropEvent () {
      const dropdown = document.getElementById(this.dropdownId)
      if (dropdown) {
        // console.log('removeBackdropEvent', dropdown)
        dropdown.removeEventListener('show.bs.dropdown', this.handleShow)
        dropdown.removeEventListener('hide.bs.dropdown', this.handleHide)
      }
    },
    showDropDown () {
      this.dropdownToggle.show()
    },
    hideDropDown () {
      this.dropdownToggle.hide()
    },
    handleShow () {
      // console.log('dropdown handleShow')
      this.$refs.dropdown.classList.add('dropdown-open')
      const elementDropdownToggle = this.$refs.dropdownToggle
      if (this.dropdownActiveClass) {
        elementDropdownToggle.classList.add(this.dropdownActiveClass.split` `)
      }

      const scrollbarWidth = this.getScrollbarWidth()
      document.body.classList.add('dropdown-open')
      document.body.style.paddingRight = `${scrollbarWidth}px`
      // document.body.style.overflowX = 'hidden'
    },
    handleHide () {
      this.$refs.dropdown.classList.remove('dropdown-open')
      document.body.classList.remove('dropdown-open')
      document.body.style.paddingRight = ''
      // document.body.style.overflowX = ''

      const elementDropdownToggle = this.$refs.dropdownToggle
      if (this.dropdownActiveClass) {
        elementDropdownToggle.classList.remove(
          this.dropdownActiveClass.split` `
        )
      }
    },
    getScrollbarWidth () {
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/innerWidth#usage_notes
      const documentWidth = document.documentElement.clientWidth
      return Math.abs(window.innerWidth - documentWidth)
    }
  }
}
