//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { customAlphabet } from 'nanoid'
import { mapGetters } from 'vuex'
export default {
  name: 'Tabs',
  props: {
    tabsClassName: {
      type: String,
      default: () => `tabs${customAlphabet('abcdefghijklmnopqrstuvwxyz0123456789', 8)()}`
    },
    tabPills: {
      type: Boolean,
      default: false
    },
    tabLink: {
      type: Boolean,
      default: false
    },
    tabSlide: {
      type: Boolean,
      default: false
    },
    freeMode: {
      type: Boolean,
      default: true
    },
    arrow: {
      type: Boolean,
      default: false
    },
    arrowObj: {
      type: Object,
      default: null
    },
    scopedClass: {
      type: String,
      default: null
    },
    slidesPerView: {
      type: Number,
      default: 0
    },
    slidesPerGroup: {
      type: Number,
      default: 1
    },
    offsetAfter: {
      type: Number,
      default: 0
    },
    offsetBefore: {
      type: Number,
      default: 0
    },
    spaceBetween: {
      type: Number,
      default: 0
    }
  },
  data () {
    const slidesPerView = this.slidesPerView ? this.slidesPerView : 'auto'
    const slidesPerGroup = this.slidesPerGroup
    const spaceBetween = this.spaceBetween
    const showNavigation = this.arrow
    const freeMode = this.freeMode
    // const nextEl = (this.scopedClass ? `${this.scopedClass}` : '') + '.tab-slide .swiper-btn-next'
    // const prevEl = (this.scopedClass ? `${this.scopedClass}` : '') + '.tab-slide .swiper-btn-prev'
    const nextEl = `.${this.tabsClassName}` + '.tab-slide .swiper-btn-next'
    const prevEl = `.${this.tabsClassName}` + '.tab-slide .swiper-btn-prev'
    const wrapperElClass = this.tabPills ? 'swiper-wrapper nav tabs nav-pills flex-nowrap' : 'swiper-wrapper nav tabs nav-tabs flex-nowrap'
    return {
      isLockSlide: false,
      tabs: [],
      tabsCarouselOption: {
        watchOverflow: true,
        showPagination: false,
        // slidesOffsetAfter: 10,
        // slidesOffsetBefore: 10,
        slidesPerView,
        slidesPerGroup,
        spaceBetween,
        freeMode,
        showNavigation,
        showNavigationOutSide: true,
        navigation: {
          nextEl,
          prevEl
        },
        wrapperEl: {
          role: 'tablist',
          class: wrapperElClass
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      tabChanged: 'tabs/getChanged'
    })
  },
  watch: {
    tabChanged () {
      setTimeout(() => {
        if (this.$refs.tabs.swiper) {
          this.$refs.tabs.swiper.update()
        }
      }, 155)
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initTab()
    })
  },
  beforeDestroy () {
    this.tabs = null
  },
  methods: {
    initTab () {
      if (!this.tabLink) {
        this.registerTab()
        this.currentTab()
      }
    },
    registerTab () {
      const selector = `.${this.tabsClassName}` + ' [data-bs-toggle="tab"]'
      this.tabs = [].slice.call(document.querySelectorAll(selector))
      const { Tab } = require('bootstrap')
      this.tabs?.forEach((tab) => {
        const triggerEl = tab // tab.$el
        // set tab style
        if (this.tabPills === true) {
          triggerEl.classList.add('btn-tab-pill')
        }

        // new trigger
        const tabTrigger = new Tab(triggerEl)
        triggerEl.addEventListener('click', (event) => {
          event.stopPropagation()
          event.preventDefault()
          tabTrigger.show()

          const { activeClassName } = tab.dataset
          this.setActiveClass(tabTrigger._element, activeClassName)
          this.$store.commit('tabs/setChanged', true)
        })
      })
    },
    setActiveClass (element, activeClassName) {
      if (this.tabLink) { return }
      this.clearActiveClass()
      if (activeClassName) {
        const classNames = activeClassName.split(' ')
        classNames.forEach((className) => {
          element.classList.add(className)
        })
      }
    },
    handleActiveClass (id) {
      const { Tab } = require('bootstrap')
      this.tabs.forEach((child) => {
        const triggerEl = child.$el
        if (child.controls && child.controls.includes(id) && triggerEl) {
          const tabTrigger = Tab.getOrCreateInstance(triggerEl)
          tabTrigger.show()
        }
      })
    },
    clearActiveClass () {
      this.tabs.forEach((tab) => {
        const triggerEl = tab
        const { activeClassName } = tab.dataset
        if (activeClassName) {
          const classNames = activeClassName.split(' ')
          classNames.forEach((className) => {
            triggerEl.classList.remove(className)
          })
        }
      })
    },
    currentTab (controlsGroup) {
      let tab = null
      // console.log(this.tabs)
      if (!this.tabSlide && this.tabs) {
        this.tabs.forEach((child) => {
          const triggerEl = child.$el
          if (child.controls) {
            if (child.controls.includes(controlsGroup) && triggerEl.classList.contains('active')) {
              tab = child.controls
            }
          }
        })
      } else {
        const children = (this.tabs && this.tabs[0] && this.tabs[0].$children) || []
        children.forEach((child) => {
          const triggerEl = child.$el
          if (child.controls) {
            if (child.controls.includes(controlsGroup) && triggerEl.classList.contains('active')) {
              tab = child.controls
            }
          }
        })
      }
      return tab
    },
    tabsSlideInit (swiper) {
      this.$emit('tabsSlideInit', swiper)
    },
    swiperInit (swiper) {
      this.isLockSlide = swiper.isLocked
      if (!swiper.isLocked) {
        this.$refs.tabs.$el.classList.add('shadow-right')
      }
      // set event listener
      swiper.on('lock', () => {
        this.isLockSlide = true
      })
      swiper.on('unlock', () => {
        this.isLockSlide = false
      })
      swiper.on('setTransition', () => {
        this.updateShadow(swiper)
      })
      swiper.on('resize', () => {
        swiper.updateSlides()
        this.updateShadow(swiper)
      })
      setTimeout(() => {
        swiper.update()
      }, 200)

      this.initTab()

      // do slide init
      this.tabsSlideInit(swiper)
    },
    updateShadow (swiper) {
      if (swiper.isLocked) {
        this.$refs.tabs.$el.classList.remove('shadow-left')
        this.$refs.tabs.$el.classList.remove('shadow-right')
        return
      }
      if (swiper.isBeginning) {
        this.$refs.tabs.$el.classList.remove('shadow-left')
        this.$refs.tabs.$el.classList.add('shadow-right')
      } else if (swiper.isEnd) {
        this.$refs.tabs.$el.classList.add('shadow-left')
        this.$refs.tabs.$el.classList.remove('shadow-right')
      } else {
        this.$refs.tabs.$el.classList.add('shadow-left')
        this.$refs.tabs.$el.classList.add('shadow-right')
      }
    },
    setPadding () {
      if (this.arrowObj && !this.arrowObj.left && this.arrowObj.right) {
        return 'pe-4'
      } else if (this.arrow && !this.isLockSlide) {
        return 'px-2rem'
      }
    }
  }
}
