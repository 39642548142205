const getDefaultState = () => {
  return {
    setIPOUpcomingType: [],
    setIPOUpcomingsummary: [],
    setIPOUpcomingTypeForeign: []
  }
}

const state = getDefaultState

const mutations = {
  setIPOUpcomingType (state, data) {
    state.setIPOUpcomingType = data
  },
  setIPOUpcomingTypeForeign (state, data) {
    state.setIPOUpcomingTypeForeign = data
  },
  setIPOUpcomingsummary (state, data) {
    state.setIPOUpcomingsummary = data
  }
}

const actions = {
  async getIPOUpcomingType ({ commit }, payload) {
    try {
      // console.log('payload', payload)
      const response = await this.$axios.$get(
          `api/set/ipo/upcoming?type=${payload}&lang=${this.$i18n.locale}`
      )
      commit('setIPOUpcomingType', response)
    } catch {
      commit('setIPOUpcomingType', null)
    }
  },
  async getIPOUpcomingTypewithoutForeign ({ commit }, payload) {
    try {
      // console.log('payload', payload)
      const response = await this.$axios.$get(
          `api/set/ipo/upcoming?type=${payload}`
      )
      commit('setIPOUpcomingTypeForeign', response)
    } catch {
      commit('setIPOUpcomingTypeForeign', null)
    }
  },
  async getIPOUpcomingsummary ({ commit }, payload) {
    try {
      // console.log('payload', payload)
      const response = await this.$axios.$get('api/set/ipo/upcoming/summary')
      commit('setIPOUpcomingsummary', response)
    } catch {
      commit('setIPOUpcomingsummary', null)
    }
  }
}

const getters = {
  getIPOUpcomingType: (state) => {
    return state.setIPOUpcomingType
  },
  getIPOUpcomingsummary: (state) => {
    return state.setIPOUpcomingsummary
  },
  getIPOUpcomingTypewithoutForeign: (state) => {
    return state.setIPOUpcomingTypeForeign
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
