const getDefaultState = () => {
  return {
    setYears: [],
    setStockList: [],
    setSummary: []
  }
}

const state = getDefaultState

const mutations = {
  setYears (state, data) {
    state.setYears = data
  },
  setStockList (state, data) {
    state.setStockList = data
  },
  setSummary (state, data) {
    state.setSummary = data
  }
}

const actions = {
  async getYears ({ commit }) {
    try {
      let response = await this.$axios.$get(
        'api/set/ipo-stat/years'
      )
      if (response) {
        response = response.splice(0, 2)
      }
      commit('setYears', response)
    } catch {
      commit('setYears', null)
    }
  },
  async getStockList ({ commit }, payload) {
    try {
      const response = await this.$axios.$get(
          `api/set/ipo-stat/stock-list?lang=th&year=${payload}`
      )
      commit('setStockList', response)
    } catch {
      commit('setStockList', null)
    }
  },
  async getSummary ({ commit }, payload) {
    try {
      const response = await this.$axios.$get(
          `api/set/ipo-stat/yearly-summary?year=${payload}&lang=${this.$i18n.locale}`
      )
      commit('setSummary', response)
    } catch {
      commit('setSummary', null)
    }
  }
}

const getters = {
  getYears: (state) => {
    return state.setYears ? state.setYears : []
  },
  getStockList: (state) => {
    return state.setStockList
  },
  getSummary: (state) => {
    return state.setSummary
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
