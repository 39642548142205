const state = () => ({
  marketReportMainData: null
})

const actions = {
  async fetchMarketReportMain ({ commit }) {
    const cateUuid = '5b41035e-c8e8-40fc-9446-80feb8d726a3'
    const apiPath = `/api/cms/v1/research-set/overview?cateUuid=${cateUuid}&language=${this.$i18n.locale}`
    await this.$apiCms.get(apiPath).then((res) => {
      commit('setMarketReportMainData', res.data)
    }).catch(err => err)
  }
}

const mutations = {
  setMarketReportMainData (state, data) {
    state.marketReportMainData = data
  }
}

const getters = {
  getMarketReportMainData (state) {
    return state.marketReportMainData
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
