const state = () => ({
  glossary_landing: [],
  glossary_detail: {},
  keyword_search_temp: '',
  alphabet_cate_temp: ''
})

const mutations = {
  /*
     ** set content setGlossarylanding
     */
  setGlossaryLanding (state, data) {
    state.glossary_landing = data
  },
  setGlossaryDetail (state, data) {
    state.glossary_detail = data
  },
  setKeywordSearchTemp (state, data) {
    state.keyword_search_temp = data
  },
  setAlphabetTemp (state, data) {
    state.alphabet_cate_temp = data
  }
}
/*
     ** get Glossarylanding
     */
const actions = {
  async getGlossaryLanding ({ commit }, payload) {
    // console.log(payload)
    const url = '/api/cms/v1/glossary'
    const options = {
      params: {
        lang: payload.lang
      }
    }
    await this.$apiCms.get(url, options).then((res) => {
      commit('setGlossaryLanding', res.data)
      // console.log('test', res)
    }).catch(err => err)
  },
  async getGlossaryDetail ({ commit }, payload) {
    const apiPath = '/glossary/details'
    const options = {
      method: 'get',
      url: apiPath,
      params: {
        lang: payload.lang,
        slug: payload.slug
      }
    }
    await this.$apiCms(options)
      .then((res) => {
        commit('setGlossaryDetail', res.data)
      })
      .catch(err => err)
  },
  setTemp ({ commit }, keySearch) {
    commit('setKeywordSearchTemp', keySearch)
  },
  setTempAlphabet ({ commit }, keySearch) {
    commit('setAlphabetTemp', keySearch)
  }
}

const getters = {
  getGlossaryLandingData: (state) => {
    // console.log(state)
    return state.glossary_landing
  },
  getGlossaryDetailData: (state) => {
    return state.glossary_detail
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
