/**
 * Select api market endpoint
 * @param {String} requestPath
 * @returns Object
 */
const selectApiEndpoint = (requestPath) => {
  const strRegx = {
    apiSet: 'api/set/',
    apiGateway: 'api/gw-api/',
    apiContent: 'api/set-content/'
  }
  const regx = {
    apiSet: new RegExp('^' + strRegx.apiSet, 'i'),
    apiGateway: new RegExp('^' + strRegx.apiGateway, 'i'),
    apiContent: new RegExp('^' + strRegx.apiContent, 'i')
  }
  let path = requestPath
  path = path.replace(/^\//, '')
  const isApiSet = regx.apiSet.test(path)
  const isApiGateway = regx.apiGateway.test(path)
  const isApiContent = regx.apiContent.test(path)
  if (isApiSet === true) {
    const newPath = path.replace(RegExp(strRegx.apiSet), '')
    // console.log('API SET new RegExp(strRegx.apiSet)', new RegExp(strRegx.apiSet))
    // console.log('API SET path ->', path)
    // console.log('API SET newPath ->', newPath)
    // console.log('API SET new base url ->', process.env.SET_API_MARKET_DATA)
    return {
      endpoint: process.env.SET_API_MARKET_DATA,
      requestPath: newPath
    }
  }
  if (isApiGateway === true) {
    const newPath = path.replace(new RegExp(strRegx.apiGateway), '')
    // console.log('API GATEWARE new RegExp(strRegx.apiGateway)', new RegExp(strRegx.apiGateway))
    // console.log('API GATEWARE path ->', path)
    // console.log('API GATEWARE newPath ->', newPath)
    // console.log('API GATEWARE new base url ->', process.env.SET_API_FUND_DATA)
    return {
      endpoint: process.env.SET_API_FUND_DATA,
      requestPath: newPath
    }
  }
  if (isApiContent === true) {
    const newPath = path.replace(new RegExp(strRegx.apiContent), '')
    // console.log('API CONTENT new RegExp(strRegx.apiContent)', new RegExp(strRegx.apiContent))
    // console.log('API CONTENT path ->', path)
    // console.log('API CONTENT newPath ->', newPath)
    // console.log('API CONTENT new base url ->', process.env.SET_API_CONTENT_DATA)
    return {
      endpoint: process.env.SET_API_CONTENT_DATA,
      requestPath: newPath
    }
  }
}

/**
 * Select mutisite enpoint by method
 * @param {String} requestMethod
 * @returns String endpoint
 * Proxy:
 * mktApi: apidev.marketdata.set
 * cmsApi: apidev.cms.set.or.th
 * cmsWriteApi: apidev.cms.set.or.th
 * get ให้ endpoint ไปที่ /api/cms/*
 * post ให้ endpoint ไปที่ /api/cms-w/*
 */
const selectMultisiteCMSEndpoint = (config) => {
  const restMethod = {
    get: 'GET', // -> /api/cms/*
    post: 'POST' // -> /api/cms-w/*
    // put: 'PUT',
    // delete: 'DELETE'
  }
  const {
    method,
    url,
    isServerToServer,
    isApiCmsV1,
    defaultBaseURL
  } = config
  const _requestMethod = method?.toUpperCase()
  const isRead = _requestMethod === restMethod.get
  const isWrite = _requestMethod === restMethod.post
  const isElasticPath = isElasticEndpoint(url)

  let baseURL = defaultBaseURL || '/' // client to client
  if (isServerToServer === true) {
    let endpoint = process.env.API_CMS_ENDPOINT // default: https://api[dev|test].cms.set.or.th/
    let endpointV1 = process.env.API_CMS_ENDPOINT_V1 // default: https://api[dev|test].cms.set.or.th/api/v1/
    if (process.env.CMS_SSR_BASE_URL) { // read CMS_SSR_BASE_URL from helm chart
      endpointV1 = `https://${process.env.CMS_SSR_BASE_URL}/api/v1/`
      endpoint = process.env.CMS_SSR_BASE_URL
    }
    if (isApiCmsV1 === true) {
      baseURL = endpointV1 // server to server endpoint of v1
    } else {
      baseURL = endpoint // server to server endpoint
    }
    // console.log('debug: CMS API base URL', {
    //   CMS_API_BaseURL: baseURL
    // })
  }
  if (isWrite === true) {
    return `${baseURL}` // Endpoint for write(POST)
  } else if (isElasticPath === true) {
    return `${baseURL}` // Endpoint for elastic
  } else if (isRead === true) {
    return `${baseURL}` // endpoint for read(GET)
  }
  return baseURL
}

/**
 * Select elastic endpoint
 * @param {String} requestUrl
 * @returns String endpoint
 */
const isElasticEndpoint = (requestUrl) => {
  const elasticApiURL = [
    'rulebooks/search',
    'search/all'
  ]
  const isElasticPath = elasticApiURL.includes(requestUrl?.toLowerCase())
  return isElasticPath
}

/**
 * Select api cms endpoint
 * @param {String} requestUrl
 * @param {String} requestMethod
 * @returns Object
 */
const selectApiCMSEndpoint = (config) => {
  const isServerToServer = config?.isServerToServer
  const defaultBaseURL = config?.defaultBaseURL
  const url = config?.url
  const path = url.replace(/^\//, '')

  const regxV1Read = /^api\/cms\/v1\//i
  const regxV1Write = /^api\/cms-w\/v1\//i
  const isApiCmsV1 = regxV1Read.test(path) || regxV1Write.test(path)

  let requestPath = path
  // server to server
  if (isServerToServer === true) {
    requestPath = path
      .replace(/api\/cms\/v1\//i, '')
      .replace(/api\/cms-w\/v1\//i, '')
  }
  const endpoint = selectMultisiteCMSEndpoint({
    method: config?.method,
    url: requestPath,
    isServerToServer,
    isApiCmsV1,
    defaultBaseURL
  })
  return {
    endpoint,
    requestPath,
    isApiCmsV1
  }
}

export {
  selectApiEndpoint,
  selectApiCMSEndpoint
}
