const disallowPageList = [
  'factsheet'
]
export default ({ store, route, i18n }) => {
  // check disallow page
  const disallowPage = disallowPageList.filter((item) => { return route.path.includes(item) })[0]
  if (disallowPage) { return }

  const { content } = store.state.contents
  const { settings } = content || {}
  const { trueHitsId } = settings || {}
  // console.log('trueHitsId', trueHitsId)
  if (!trueHitsId) {
    return
  }

  // Render tag script
  // renderScript(trueHitsId)
  const ga1 = document.createElement('script'); ga1.type = 'text/javascript'
  ga1.async = true
  ga1.src = `//lvs.truehits.in.th/dataa/${trueHitsId}.js`
  document.head.appendChild(ga1)
  // const s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga1, s)

  const { head } = content || {}
  const { title } = head || {}
  const routeName = route.fullPath.split('/')
  const pageContent = []
  for (let i = 1; i < routeName.length; i++) {
    pageContent.push(routeName[i])
  }
  if (title) {
    pageContent.push(title)
  }
  const lang = i18n.locale
  window[`__${lang}_page`] = pageContent.join(' - ')

  // Debug
  if (window.trueHitsDebugging === true) {
    // console.log('[DEBUG] trueHits script parent node', s.parentNode?.tagName)
    console.log('[DEBUG] trueHits id', trueHitsId)
    console.log('[DEBUG] trueHits page', window[`__${lang}_page`])
  }

  /*
  const renderScript = (trueHitsId) => {
    const ga1 = document.createElement('script'); ga1.type = 'text/javascript'
    ga1.async = true
    ga1.src = `//lvs.truehits.in.th/dataa/${trueHitsId}.js`
    const s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga1, s)
  }
  const truehitsInit = () => {
    const { content } = store.state.contents
    const { settings } = content || {}
    const { trueHitsId } = settings || {}
    // console.log('trueHitsId', trueHitsId)
    if (!trueHitsId) {
      return
    }
    const { head } = content || {}
    const { title } = head || {}
    const routeName = route.fullPath.split('/')
    const pageContent = []
    for (let i = 1; i < routeName.length; i++) {
      pageContent.push(routeName[i])
    }
    if (title) {
      pageContent.push(title)
    }
    const lang = i18n.locale
    window[`__${lang}_page`] = pageContent.join(' - ')

    // Render tag script
    renderScript(trueHitsId)
  }

  // Initial
  truehitsInit()
  */
}
// export default {
//   computed: {
//     setNoScript () {
//       if (this.$store.state.contents.content && this.$store.state.contents.content.advanced) {
//         // GTM
//         if (this.$store.state.contents.content.advanced.gtmTrackingId) {
//           return [
//             {
//               innerHTML: `
//               <iframe src="https://www.googletagmanager.com/ns.html?id=${this.$store.state.contents.content.advanced.gtmTrackingId}"
//               height="0" width="0" style="display:none;visibility:hidden"></iframe>
//               `
//             }
//           ]
//         }
//       }
//       return [{ innerHTML: 'This website requires JavaScript.' }]
//     },
//     /**
//      * Truehits script
//      */
//     truehitsScript () {
//       if (this.$store.state.contents.content && this.$store.state.contents.content.advanced) {
//         if (this.$store.state.contents.content.advanced.trueHitsId) {
//           const routeName = this.$route.fullPath.split('/')
//           let pageContent = ''
//           for (let i = 1; i < routeName.length; i++) {
//             pageContent += routeName[i] + ' - '
//           }
//           return [
//             {
//               body: true,
//               type: 'text/javascript',
//               innerHTML: `__th_page="${this.trackingPageName || (pageContent + this.$store.state.contents.content.title)}";`
//             },
//             {
//               body: true,
//               type: 'text/javascript',
//               innerHTML: `
//               (function() {
//                 var ga1 = document.createElement('script'); ga1.type = 'text/javascript';
//                 ga1.async = true;
//                 ga1.src = "//lvs.truehits.in.th/dataa/${this.$store.state.contents.content.advanced.trueHitsId}.js";
//                 var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga1, s);
//               })();
//               `
//             }
//           ]
//         }
//       }
//       return []
//     }
//   }
// }
