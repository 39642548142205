import { setStyleClient } from './embedded-style'
import { setLink } from './embedded-link'
import { setScript } from './embedded-script'
import { setMeta } from './meta'
import { specificPageSeo, isGrayscale } from './helpers'
import GoogleTagManager from './gtm'
export default ({ app, store, i18n, $content, $device, route }, inject) => {
  const embedded = {
    /**
   * call for row embedded
   *
   * @param {Object} content
   * @return {*}
   */
    default () {
      const content = store.state.contents.content || null
      const route = app.context.route
      // when not found content
      if (!content) {
        return {}
      }
      if (!content.head) {
        return {}
      }

      const { siteName, title, description, keywords, thumbnail } = content.head
      const seo = {
        title,
        description,
        keywords,
        thumbnail: `${thumbnail}`
      }
      const { info } = content
      const { contentRenderType } = require('@/utils')
      const isFixedLayout = info?.renderType === contentRenderType.fixedLayout
      if (isFixedLayout) {
        const customSeo = specificPageSeo(route, i18n)
        if (customSeo) {
          seo.title = customSeo.title ? customSeo.title : title // <-- title from content of home page
          seo.description = customSeo.description ? customSeo.description : description // <-- description from content of home page
          seo.keywords = customSeo.keywords ? customSeo.keywords : keywords // <-- keywords from content of home page
          seo.thumbnail = customSeo.thumbnail ? `${customSeo.thumbnail}` : `${thumbnail}` // <-- thumbnail from content of home page
        }
        const { contentSEO } = store.state.contents
        if (contentSEO) {
          seo.title = contentSEO.title || title // <-- title from content of home page
          seo.description = contentSEO.description || description // <-- description from content of home page
          seo.keywords = contentSEO.keywords || keywords // <-- keywords from content of home page
          seo.thumbnail = contentSEO.thumbnail || thumbnail // <-- thumbnail from content of home page
        }
        $content.setContentSEO(seo)
      }

      // Set page title
      // const title = `${(seo || {}).title || head.title} - ${content.websiteInfo ? content.websiteInfo.websiteName : ''}`
      const isErrorPage404 = /^error-404/i.test(route.name)
      const newPageTitle = [`${(seo || {}).title || title}`]
      if (!isErrorPage404 && siteName) {
        newPageTitle.push(siteName)
      }

      seo.title = newPageTitle.join(' - ')
      // console.log('DEBUG content seo data ->', JSON.parse(JSON.stringify(seo)))

      // set Style Client
      if (process.client) {
        setStyleClient(content)
      }

      const addPreload = route.path.includes('/home')
      const { settings } = content
      const gtm = new GoogleTagManager(settings?.gtmTrackingId)
      return {
        title: newPageTitle.join(' - '),
        htmlAttrs: {
          class: isGrayscale(content?.settings),
          lang: i18n.locale
        },
        meta: [
          ...setMeta(content, route, seo)
        ],
        style: [
          // ...setStyle(content)
        ],
        noscript: [...gtm.gtmNoScript()],
        script: [
          ...gtm.gtmScript(),
          ...setScript(content)
        ],
        link: [
          ...setLink(content, $device, addPreload)
        ],
        __dangerouslyDisableSanitizers: ['innerHTML']
      }
    }
  }
  inject('embedded', {
    embedded
  })
}
