//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MarketAlertsBarNews',
  props: {
    newsList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      ready: false,
      stop: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.registerEvent()
      this.adjustAnimationSpeed()
      this.ready = true
    })
  },
  beforeDestroy () {
    this.removeEvent()
  },
  methods: {
    adjustAnimationSpeed () {
      const marqueeContent = document.getElementById('marketAlertsBarNews')
      // const contentWidth = marqueeContent.offsetWidth // marqueeText.offsetWidth;
      // const viewportWidth = window.innerWidth
      const speed = 85 // pixels per second
      const duration = marqueeContent.offsetWidth / speed + 's' // (contentWidth / speed) + 's';
      // const duration = (contentWidth / speed) + 's'
      marqueeContent.style.animationDuration = duration
    },
    registerEvent () {
      const marqueeContainer = document.getElementById('marketAlertsBarNewsContainer')
      marqueeContainer?.addEventListener('mouseover', this.handleMouseover)
      marqueeContainer?.addEventListener('mouseout', this.handleMouseout)
    },
    removeEvent () {
      const marqueeContainer = document.getElementById('marketAlertsBarNewsContainer')
      marqueeContainer?.removeEventListener('mouseover', this.handleMouseover)
      marqueeContainer?.removeEventListener('mouseout', this.handleMouseout)
    },
    handleMouseover () {
      this.pausedAnimation()
      this.autoReplayOnMobile()
    },
    handleMouseout () {
      this.runAnimation()
    },
    pausedAnimation () {
      const marqueeContent = document.getElementById('marketAlertsBarNews')
      marqueeContent.style.animationPlayState = 'paused'
    },
    runAnimation () {
      const marqueeContent = document.getElementById('marketAlertsBarNews')
      marqueeContent.style.animationPlayState = 'running'
    },
    autoReplayOnMobile () {
      const { isDesktop } = this.$device
      if (isDesktop) {
        return
      }
      setTimeout(() => {
        this.runAnimation()
      }, 1500)
    }
  }
}
