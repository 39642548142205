// https://dev.to/alejomartinez8/how-to-load-a-hash-fragment-to-an-anchor-name-in-react-especially-in-first-loading-g3i
export default function () {
  const { scrollVerticalToElementById } = require('@/helpers/scrollToElementOnPage')
  const getHash = () => {
    const hash = window.location.hash
    return hash?.replace('#', '')
  }
  // const hideAppNavMenu = () => {
  //   if (!getHash()) { return }
  //   const appNavMenu = document.querySelector('.app-nav-menu')
  //   console.log({
  //     appNavMenu
  //   })
  //   if (!appNavMenu) { return }
  //   appNavMenu.classList.add('hide')
  //   document.body.classList.add('menu-hidden')
  // }
  const elementBoundingClientRectById = (id) => {
    const element = document.getElementById(id)
    const rectangle = element?.getBoundingClientRect()
    return rectangle
  }
  const elementBoundingClientRectByElement = (element) => {
    const rectangle = element?.getBoundingClientRect()
    return rectangle
  }
  const scrollToElementByHash = () => {
    const elementBoundingClientRect = elementBoundingClientRectById('appbar')
    const padding = elementBoundingClientRect?.height
    scrollVerticalToElementById(getHash(), padding)
  }
  const triggerTab = () => {
    const element = document.getElementById(getHash())
    const containerTab = element?.closest('[data-element="container_tab"]') ?? {}
    const containerTabId = containerTab.id
    const triggerEl = document.querySelector(`[data-bs-toggle="tab"][aria-controls="${containerTabId}"]`)
    if (triggerEl) {
      const { Tab } = require('bootstrap')
      const tab = Tab.getOrCreateInstance(triggerEl)
      tab.show()
    }
    setTimeout(() => { scrollToElementByHash() }, 200) // Do after tab animation complete.
  }

  // Images load
  const handleImagesLoaded = (images) => {
    const isComplate = images.map(image => image.complete).every(item => item === true)
    if (isComplate) {
      scrollToElementByHash()
    }
  }
  const imagesLoaded = async () => {
    const hash = window.location.hash
    if (!hash) { return false }
    const imageSelector = [
      '.site-content picture img',
      '[data-element="element_text_editor"] img',
      '[data-element="element_html"] img'
    ]
    const images = await [].slice.call(document.querySelectorAll(imageSelector.join(',')))
    const imageAfterFilter = images.filter((img) => {
      const { height, width } = elementBoundingClientRectByElement(img)
      return height > 0 && width > 0
    })
    if (imageAfterFilter.length === 0) {
      scrollToElementByHash()
      return false
    }
    imageAfterFilter.forEach((image) => {
      // console.log('image', image)
      const { src } = image.dataset
      if (src) {
        delete image.dataset.src
        image.src = src
      }
      image.addEventListener('load', (event) => {
        handleImagesLoaded(imageAfterFilter)
      }, {
        once: true
      })
      image.addEventListener('error', (event) => {
        handleImagesLoaded(imageAfterFilter)
      }, {
        once: true
      })
    })
  }

  const handleHashchange = () => {
    // hideAppNavMenu()
    imagesLoaded()
    triggerTab()
  }
  // const handleDOMContentLoaded = () => {
  //   imagesLoaded()
  //   triggerTab()
  // }
  const handleWindowLoaded = () => {
    // hideAppNavMenu()
    imagesLoaded()
    triggerTab()
  }

  window.addEventListener('hashchange', handleHashchange)
  window.addEventListener('load', handleWindowLoaded)
  // document.addEventListener('DOMContentLoaded', handleDOMContentLoaded)
}
/* async function updateStatus (images) {
  const isComplate = await images.map(image => image.complete).every(item => item === true)
  if (isComplate) {
    scrollToHashElement()
  }
}
async function imagesLoaded () {
  const { hash } = window.location
  if (!hash) { return false }
  const imageSelector = [
    '.site-content picture img',
    '[data-element="element_text_editor"] img',
    '[data-element="element_html"] img'
  ]
  const images = await [].slice.call(document.querySelectorAll(imageSelector.join(',')))
  if (images.length === 0) {
    return false
  }
  images.forEach((image) => {
    const { src } = image.dataset
    if (src) {
      delete image.dataset.src
      image.src = src
    }
    image.addEventListener('load', () => updateStatus(images), {
      once: true
    })
    image.addEventListener('error', () => updateStatus(images), {
      once: true
    })
  })
}
const scrollToHashElement = () => {
  const { hash } = window.location
  const elementToScroll = document.getElementById(hash?.replace('#', ''))
  if (!elementToScroll) { return }
  // console.log('elementToScroll', elementToScroll)
  const appbar = document.getElementById('appbar')// document.querySelector('#appbar .toolbar')
  const offsetTop = appbar ? appbar.clientHeight : 0
  // console.log('scrollToHashElement offset', offsetTop)
  // console.log('elementToScroll.offsetTop', elementToScroll.offsetTop)
  // console.log('elementToScroll.offsetTop + offset', elementToScroll.offsetTop - offsetTop)
  const offset = elementToScroll.offsetTop - offsetTop - 15
  window.scrollTo({
    top: offset,
    behavior: 'smooth'
  })
}

function triggerTab () {
  const { hash } = window.location
  const element = document.getElementById(hash?.replace('#', ''))
  if (!element) { return }
  const containerTab = element.closest('[data-element="container_tab"]')
  const { id } = containerTab
  const triggerEl = document.querySelector(`[data-bs-toggle="tab"][aria-controls="${id}"]`)
  if (!triggerEl) { return }
  const { Tab } = require('bootstrap')
  const tab = Tab.getOrCreateInstance(triggerEl)
  tab.show()
  setTimeout(() => { scrollToHashElement() }, 200)
}
function handle () {
  imagesLoaded()
  triggerTab()
}
// function initial () {
//   handle()
// }
window.addEventListener('hashchange', function () {
  setTimeout(() => {
    handle()
  }, 1000)
})
window.addEventListener('load', function () {
  handle()
})
document.addEventListener('DOMContentLoaded', function () {
  handle()
}) */
