import { defaultObserverOption } from '~/config/core'
const countUp = () => {
  const { CountUp } = require('countup.js')
  const options = {
    decimalPlaces: 2,
    useEasing: true,
    useGrouping: true,
    separator: ',',
    decimal: '.',
    prefix: '',
    suffix: ''
  }
  const sCounters = document.querySelectorAll('.number-counter')
  if (sCounters) {
    sCounters.forEach(function (cntr) {
      if (cntr.dataset.value) {
        const decimal = countDecimals(parseFloat(cntr.dataset.value))
        options.decimalPlaces = decimal
        const demo = new CountUp(cntr, cntr.dataset.value, options)
        if (!demo.error) {
          createObserver(cntr, demo)
        }
      }
    })
  }
}
const createObserver = (element, demo) => {
  const options = {
    ...defaultObserverOption
  }
  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        element.classList.add('visible')
        demo.start()
        observer.unobserve(element)
      }
    })
  }, options)
  observer.observe(element)
}
const countDecimals = (value) => {
  if (Math.floor(value) === value) { return 0 }
  return value.toString().split('.')[1].length || 0
}
export {
  countUp
}
