const personalizedBannerTrigger = (bannerUuid, fnCallback) => {
  const element = document.querySelector(`.banner-${bannerUuid}`)
  const observer = new IntersectionObserver(() => {
    // const compStyles = window.getComputedStyle(element)
    const banner = document.querySelector(`.banner-${bannerUuid} .swiper-wrapper`)
    if (banner && !banner.style.transform) {
      fnCallback()
    }
  }, { rootMargin: '250px 250px 250px 250px' })
  if (element) {
    observer.observe(element)
  }
}

async function getBannerLocation (locationCode) {
  const url = `/api/cms/v1/banners/location/${this.i18n.locale}/${locationCode}`
  const response = await this.apiCms.get(url)
    .then((res) => {
      return res
    })
    .catch(err => err)
  return response
}
export { personalizedBannerTrigger, getBannerLocation }
