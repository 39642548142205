import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import 'dayjs/locale/th'
const buddhistEra = require('dayjs/plugin/buddhistEra')
const { timezone: defaultTimezone } = require('@/config/datetime')
dayjs.extend(buddhistEra)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault(defaultTimezone)
export default dayjs
