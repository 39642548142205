//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BannerLocationFooter',
  data () {
    return {
      bannerData: null,
      locationName: 'B_FOOTER_BANNER'
    }
  },
  async fetch () {
    await this.setBannerData()
  },
  computed: {
    personalizedSpacingClassName () {
      if (this.bannerData?.isPersonalized) {
        return 'py-4'
      }
      return ''
    },
    bannerSpacingClassName () {
      if (!this.bannerData?.isPersonalized && this.bannerData?.bannerItems?.length > 0) {
        return 'py-4'
      }
      return ''
    }
  },
  methods: {
    async setBannerData () {
      await this.$content.getBannerByLocation(this.locationName)
        .then((banner) => { this.bannerData = banner })
        .catch(err => err)
    }
  }
}
