//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  head () {
    return this.$embedded.embedded.default()
  },
  watch: {
    '$route.fullPath': {
      immediate: true,
      handler (newPage, oldPage) {
        const { contentDataLayer: { currentPath } } = this.$store.state
        const isPush = process.client && currentPath !== newPage
        if (isPush) {
          this.$personalized.pagePersonalized()
        }
      }
    }
  }
}
