const state = () => ({
  certificateList: []
})

const mutations = {
  setCertificateList (state, data) {
    state.certificateList = data
  }
}

const actions = {
  async getCertificateList ({ commit }) {
    const apiPath = '/data/tsd/certloss/certloss.json'
    try {
      const response = await this.$axios.$get(apiPath)
      console.log('🚀 ~ response', response)
      commit('setCertificateList', response)
    } catch (err) {
      commit('setCertificateList', null)
    }
  }
}

const getters = {
  getCertificateList (state) {
    return state.certificateList
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
