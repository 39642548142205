//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { setAttributeLink } from '~/helpers'
export default {
  name: 'Footer',
  props: {
    showTopSection: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isMobile: this.$device.isMobile,
      locationCode: {
        setWeblinkFooter: 'M_WEBLINK_FOOTER',
        interrestingWeblinkFooter: 'M_INTERRESTING_FOOTER',
        sitemapFooter: 'M_SITEMAP_FOOTER'
      }
    }
  },
  computed: {
    ...mapGetters({
      content: 'contents/getContentData'
    }),
    contentBody () {
      return this.content.body
    },
    contactInfo () {
      const { contactInfo } = this.contentBody || {}
      return contactInfo
    },
    socialMedia () {
      const {
        facebookUrl,
        twitterUrl,
        youtubeUrl,
        instagramUrl,
        lineUrl,
        tiktokUrl,
        contactUrl
      } = this.contactInfo || {}
      return {
        facebookUrl,
        twitterUrl,
        youtubeUrl,
        instagramUrl,
        lineUrl,
        tiktokUrl,
        contactUrl
      }
    },
    footerMenuItems () {
      const menu = this.getMenuLocations('M_FOOTER') || {}
      const menuItems = menu.menuItems || []
      return menuItems
    },
    footerMenuDisclaimerAndPolicy () {
      const menu = this.getMenuLocations('M_POLICY_FOOTER') || {}
      const menuItems = menu.menuItems || []
      return menuItems
    },
    footerMenuWeblinkAndSitemap () {
      const menu = this.getMenuLocations('M_WEBLINK_SITEMAP') || {}
      const menuItems = menu.menuItems || []
      return menuItems
    },
    accMenuList () {
      const itemList = []
      const setWeblinkFooter = this.$content.getLayoutMenuLocation(this.locationCode.setWeblinkFooter) || []
      const interrestingWeblinkFooter = this.$content.getLayoutMenuLocation(this.locationCode.interrestingWeblinkFooter) || []
      const sitemapFooter = this.$content.getLayoutMenuLocation(this.locationCode.sitemapFooter) || []
      if (setWeblinkFooter && setWeblinkFooter.menuItems && setWeblinkFooter.menuItems.length) {
        itemList.push({ name: this.$t('footer.menu.setGroupWebsites'), subMenus: setWeblinkFooter?.menuItems })
      }
      if (interrestingWeblinkFooter && interrestingWeblinkFooter.menuItems && interrestingWeblinkFooter.menuItems.length) {
        itemList.push({ name: this.$t('footer.menu.links'), subMenus: interrestingWeblinkFooter?.menuItems })
      }
      if (sitemapFooter && sitemapFooter.menuItems && sitemapFooter.menuItems.length) {
        itemList.push({ name: this.$t('footer.menu.sitemap'), subMenus: sitemapFooter?.menuItems })
      }
      return itemList
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.registerEvent()
      this.handleResponsive()
    })
  },
  beforeDestroy () {
    this.removeEvent()
  },
  methods: {
    registerEvent () {
      if (window) {
        window.addEventListener('resize', this.handleResponsive)
      }
    },
    removeEvent () {
      if (window) {
        window.removeEventListener('resize', this.handleResponsive)
      }
    },
    handleResponsive () {
      const wInnerWidth = window.innerWidth
      const { deviceSizes } = require('~/config/core')
      this.isMobile = wInnerWidth <= deviceSizes.md
    },
    getMenuLocations (locationCode) {
      const content = this.contentBody
      if (!content) { return null }
      const layoutMenuLocations = content.layoutMenuLocations || []
      const m = layoutMenuLocations.find(h => h.locationCode === locationCode)
      if (m) {
        return m.menu
      }
      return null
    },
    setAttrLink (menu) {
      return setAttributeLink(menu)
    },
    handleClickTrackingPDPA (title) {
      this.$personalized.clickElement({
        name: 'all21',
        optionLang: {
          menu: title
        }
      })
    }
  }
}
