import { preloadImg } from './helpers'
/**
 * call for set raw link
 *
 * @param {Object} content
 * @return {*}
 */
const setLink = (content, device, addPreload = false) => {
  const head = content.head
  const LinkData = []
  if (head.appIcon) {
    LinkData.push(
      { hid: 'apple-touch-icon', rel: 'apple-touch-icon', href: head.appIcon },
      { hid: 'android-touch-icon', rel: 'android-touch-icon', href: head.appIcon }
    )
  }
  // set fav icon
  if (head.fav) {
    LinkData.push({ hid: 'shortcut-icon', rel: 'icon', type: 'image/x-icon', href: head.fav })
  }
  if (addPreload === true) {
    if (content.body && content.body.homeBannerLocations && content.body.homeBannerLocations.length) {
      const heroBanner = content.body.homeBannerLocations.find(banner => banner.locationCode === 'B_HOME_HERO')
      if (heroBanner.banner && heroBanner.banner.bannerItems && heroBanner.banner.bannerItems.length) {
        let img = null
        if (device.isDesktop) {
          const image = heroBanner.banner.bannerItems[0]?.desktopImage || null
          img = image?.images || null
        } else {
          const image = heroBanner.banner.bannerItems[0]?.mobileImage || null
          img = image?.images || null
        }
        if (img) {
          LinkData.push(preloadImg(img))
        }
      }
    }
  }
  return LinkData
}
export {
  setLink
}
