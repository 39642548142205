
// import cloneDeep from 'lodash/cloneDeep'
import { customAlphabet } from 'nanoid'
import { imageSizes } from '~/config/core'
export default {
  name: 'Picture',
  props: {
    sources: {
      type: Array,
      default: null
    },
    lazyImage: {
      type: Boolean,
      default: () => true
    },
    lazyImageManualTrigger: {
      type: Boolean,
      default: () => false
    },
    alt: {
      type: String,
      default: () => ''
    },
    width: {
      type: Number,
      default: null
    },
    height: {
      type: Number,
      default: null
    }
  },
  data () {
    const elementId = `${customAlphabet('0123456789abcdefghijklmnopqrstuvwxyz', 8)()}`
    return {
      imgId: `img-${elementId}`,
      pictureId: `p-${elementId}`,
      youtubeErrorImageSize: 120,
      images: []
    }
  },
  computed: {
    imgSource () {
      const sources = JSON.parse(JSON.stringify(this.sources))
      return sources || []
    },
    isYoutubeThumbnail () {
      const youtubeImage = this.imgSource[0] || {}
      return !youtubeImage.type
    }
  },
  async created () {
    await this.setImages()
  },
  mounted () {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    init () {
      this.handleYoutubeErrorImage()
    },
    setImages () {
      const imgSource = this.imgSource || []
      if (!imgSource.length) { return [] }
      let image = imgSource
      const defaultImage = image.filter((r) => { return r.size === 'default' })
      // image = image.filter((r) => { return /mqdefault.|maxresdefault./i.test(r.url) === false })
      // image = image.filter((r) => { return r.size !== 'thumbnail' && r.size })
      // image = image.filter((r) => { return r.size !== 'default' && r.size })
      image = image.filter((r) => {
        const acceptImage = r.size &&
        (r.size !== 'thumbnail' && r.size !== 'default' && /mqdefault.|maxresdefault./i.test(r.url) === false)
        return acceptImage
      })
      if (this.isYoutubeThumbnail) {
        let defaultName = ''
        if (defaultImage[0].url?.includes('/default.')) {
          defaultName = 'default'
        } else {
          defaultName = 'sddefault'
        }
        defaultImage[0].url = defaultImage[0].url?.replace(defaultName, 'hqdefault')
        this.images = [...image.reverse(), defaultImage[0]]
      }
      this.images = [...image, defaultImage[0]]
    },
    handleYoutubeErrorImage () {
      if (this.isYoutubeThumbnail === true) {
        const itemSrc = { ...this.images[0] }
        this.loadImage(itemSrc?.url)
      }
    },
    queryToString (option) {
      const { size, type } = option
      if (!type) {
        return `(min-width: ${imageSizes[size]}px)`
      }
      return `(max-width: ${imageSizes[size]}px)`
    },
    loadImage (src) {
      if (!src) {
        return
      }
      const img = new Image()
      img.src = src
      img.onload = () => {
        const isYoutubeImgErr = img.width <= this.youtubeErrorImageSize && this.isYoutubeThumbnail === true
        if (isYoutubeImgErr) {
          this.updateYoutubePicture()
        }
      }
    },
    updateYoutubePicture () {
      const pictureEl = document.getElementById(this.pictureId)
      const children = [].slice.call(pictureEl?.children || [])
      children.forEach((item) => {
        const { tagName } = item
        if (tagName.toLowerCase() === 'img') {
          const dataSrc = item.dataset.src
          if (dataSrc) {
            const newDataSrc = dataSrc?.replace('maxresdefault', 'sddefault')
            item.setAttribute('data-src', newDataSrc)
            item.src = newDataSrc
          }
        } else {
          item.remove()
        }
      })
    },
    handleImageLoaded (e) {
      // this.imageLoaded(e) // do image loaded
      this.$emit('imageLoaded', e)
    },
    handleImageError (e) {
      // this.imageError(e) // do image loaded
      this.$emit('imageError', e)
    }
  },
  render (h) {
    if (this.imgSource.length === 0) { return }
    // set source of picture
    const sources = []
    const images = this.images.filter((r) => { return r.size !== 'default' })
    images.forEach((item) => {
      let attr = null
      const { size, type } = item
      if (this.lazyImage) {
        // for lazy img
        attr = {
          'data-srcset': item.url,
          type: item.type,
          media: this.queryToString({ size, type })
        }
      } else {
        attr = {
          srcset: item.url,
          type: item.type,
          media: this.queryToString({ size, type })
        }
      }

      sources.push(h('source', {
        attrs: attr
      }))
    })
    // set img of picture
    const arrtImg = {}
    const imageClass = []
    arrtImg.alt = this.alt
    const defaultImg = this.imgSource.filter((r) => { return r.size === 'default' && r.size })
    if (defaultImg.length) {
      if (this.lazyImage) {
        arrtImg['data-src'] = defaultImg[0].url
      } else {
        arrtImg.src = defaultImg[0].url
      }
      if (this.width && this.height) {
        arrtImg.width = this.width
        arrtImg.height = this.height
        imageClass.push('img-fluid')
      }
    }
    if (this.lazyImage && !this.lazyImageManualTrigger) {
      // arrtImg.class = 'lazyload'
      imageClass.push('lazyload')
    }
    arrtImg.class = imageClass.join(' ')
    const img = h('img', {
      attrs: {
        id: `${this.imgId}`,
        ...arrtImg
      },
      on: {
        load: this.handleImageLoaded,
        error: this.handleImageError
      }
    })
    // img = null
    return h(
      'picture',
      {
        attrs: {
          id: this.pictureId,
          class: this.isYoutubeThumbnail === true ? 'crop-image' : ''
        }
      },
      [...sources, img]
    )
  }

}
