import securityChangeName from '@/store/tsd/securityChangeName'
import certificateLossList from '~/store/tsd/certificateLossList'
import debenture from '@/store/tsd/debenture'

export const modules = {
  securityChangeName,
  certificateLossList,
  debenture
}

const state = () => ({})

const actions = {
  async fetchSecurityTypList () {
    const list = await this.$axios.$get('api/gw-api/tsd/security-type/list')

    return list
  }
}

const mutations = {}

const getters = {}

export default {
  state,
  actions,
  mutations,
  getters,
  modules
}
