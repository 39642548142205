import main from '@/lang/specific-seo/en.json'
import EducationKnowledge from '@/lang/specific-seo/Education/Knowledge/en.json'
import Statistics from '@/lang/specific-seo/Market/Statistics/en.json'
import RuleBook from '@/lang/specific-seo/RuleBook/en.json'
import TSD from '@/lang/specific-seo/TSD/en.json'
import TCH from '@/lang/specific-seo/TCH/en.json'
import MarketQuote from '@/lang/specific-seo/Market/Quote/en.json'
import MarketIndex from '@/lang/specific-seo/Market/Index/en.json'
import MarketInfomation from '@/lang/specific-seo/Market/Information/en.json'
import MarketStockCalendar from '@/lang/specific-seo/Market/StockCalendar/en.json'
import MarketGetQuote from '@/lang/specific-seo/Market/GetQuote/en.json'
import News from '@/lang/specific-seo/News/en.json'
import Listing from '@/lang/specific-seo/Listing/en.json'
import SetSource from '@/lang/specific-seo/SetSource/en.json'
import Research from '@/lang/specific-seo/Education/Research/en.json'
import Member from '@/lang/specific-seo/Member/en.json'
import ErrorPage from '@/lang/specific-seo/Error/en.json'
import EventCalendar from '@/lang/specific-seo/EventCalendar/en.json'
import SetBook from '@/lang/specific-seo/SetBook/en.json'
import JoinUs from '@/lang/specific-seo/JoinUs/en.json'
export default [
  ...main,
  ...EducationKnowledge,
  ...Statistics,
  ...RuleBook,
  ...TSD,
  ...TCH,
  ...MarketQuote,
  ...MarketIndex,
  ...MarketInfomation,
  ...MarketStockCalendar,
  ...MarketGetQuote,
  ...News,
  ...Listing,
  ...SetSource,
  ...Research,
  ...Member,
  ...ErrorPage,
  ...EventCalendar,
  ...SetBook,
  ...JoinUs
]
