//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import { formatDateTimeLong, formatDateShort } from '@/helpers/dateTime'
import { integerStyle } from '@/helpers/index'
import { formatNumber, formatNumberPercent } from '@/helpers/number'
export default {
  name: 'HeaderFactsheet',
  computed: {
    ...mapGetters({
      stockInfo: 'quote/getStockInfo',
      stockFactSheet: 'quote/stock/factsheet/getFactSheet'
    }),
    info () {
      return this.stockInfo || {}
    },
    securityType () {
      return this.stockInfo && this.stockInfo.securityType
    },
    isSecurityType_S () {
      return this.securityType === 'S'
    },
    highlightData () {
      return this.stockFactSheet.highlightData || {}
    }
  },
  async mounted () {
    this.loading = true

    const payload = {
      quote: this.$route.params.slug,
      period: '5Y',
      accumulated: true
    }

    await Promise.allSettled([
      this.getFactSheet(payload)
    ])
  },
  methods: {
    formatDateTimeLong,
    integerStyle,
    formatNumber,
    formatNumberPercent,
    formatDateShort,
    ...mapActions({
      getFactSheet: 'quote/stock/factsheet/getFactSheet'
    })
  }
}
