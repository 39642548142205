//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MemberFavoriteMessageLimitFollow',
  props: {
    securityTypeName: {
      type: String,
      default: 'หุ้น'
    }
  },
  computed: {
    message () {
      if (this.setcategory(this.securityTypeName) === 'หุ้น') {
        return this.$t('member.myQuote.favoritesMaximum', { symbol: `${this.setcategory(this.securityTypeName)}` })
      } else {
        return this.$t('member.myQuote.favoritesMaximum', { symbol: ` ${this.setcategory(this.securityTypeName)} ` })
      }
    },
    favouriteUrl () {
      return this.localePath({
        name: 'member-index-favorites'
      })
    }
  },
  methods: {
    setcategory (category) {
      let stockFavoriteList = 'หุ้น'
      if (category === 'stock') {
        stockFavoriteList = 'หุ้น'
      } else if (category === 'etf') {
        stockFavoriteList = ' ETF '
      } else if (category === 'dw') {
        stockFavoriteList = ' DW '
      } else if (category === 'dr') {
        stockFavoriteList = ' DR '
      } else if (category === 'drx') {
        stockFavoriteList = ' DRx '
      }
      return stockFavoriteList
    }
  }
}
