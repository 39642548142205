const { numberWithCommas } = require('@/helpers')
/**
 * State
 */
const state = () => ({
  loading: false,
  stateType: 'SEARCH',
  paramSearch: {},
  paramClosingDate: {},
  paramCalculator: {},
  selectedSymbol: {},
  symbolLookupData: null,
  bookClosingDateData: [],
  calculateData: null,
  holdingShare: null
})

/**
 * Mutations
 */
const mutations = {
  setSymbolLookupData (state, data) {
    state.symbolLookupData = data
  },
  setBookClosingDateData (state, data) {
    state.bookClosingDateData = data
  },
  setCalculateData (state, data) {
    state.calculateData = data
  },
  setStateType (state, data) {
    state.stateType = data
  },
  setSelectedSymbol (state, data) {
    state.selectedSymbol = data
  },
  setHoldingShare (state, data) {
    state.holdingShare = data
  },
  setLoading (state, data) {
    state.loading = data
  }
}

/**
 * Actions
 */
const actions = {
  setSymbolLookupData ({ commit }, data) {
    commit('setSymbolLookupData', data)
  },
  setBookClosingDateData ({ commit }, data) {
    commit('setBookClosingDateData', data)
  },
  setCalculateData ({ commit }, data) {
    commit('setCalculateData', data)
  },
  setStateType ({ commit }, data) {
    commit('setStateType', data)
  },
  setSelectedSymbol ({ commit }, data) {
    commit('setSelectedSymbol', data)
  },
  setHoldingShare ({ commit }, data) {
    commit('setHoldingShare', data)
  },
  async getSearchSymbol ({ commit, dispatch, state }, payload) {
    commit('setLoading', true)
    const apiPath = `api/gw-api/tsd/stock-dividend/symbol-lookup?keyword=${payload.keyword}`
    await this.$axios
      .$get(apiPath)
      .then((res) => {
        commit('setSymbolLookupData', res)
      })
      .catch(err => err)

    commit('setLoading', false)
  },
  async getListBookClosingDate ({ commit, dispatch, state }, payload) {
    const apiPath = `api/gw-api/tsd/stock-dividend/book-closing-date?symbol=${payload.symbol}`
    await this.$axios
      .$get(apiPath)
      .then((res) => {
        commit('setBookClosingDateData', res)
      })
      .catch(err => err)
  },
  async getCalculate ({ commit, dispatch, state }, payload) {
    commit('setLoading', true)
    const apiPath = `api/gw-api/tsd/stock-dividend/calculate?symbol=${payload.symbol}&bookClosingDate=${payload.bookClosingDate}&taxExempt=${payload.taxExempt}&holdingShare=${payload.holdingShare}`
    await this.$axios
      .$get(apiPath)
      .then((res) => {
        res.oldRatio = numberWithCommas(res.oldRatio)
        res.newRatio = numberWithCommas(res.newRatio)
        res.stockDividendRate = numberWithCommas(res.stockDividendRate)
        res.cashDividendRate = numberWithCommas(res.cashDividendRate)
        res.totalDividendRate = numberWithCommas(res.totalDividendRate)
        res.fractionalRate = numberWithCommas(res.fractionalRate)
        res.nonBoiRate = numberWithCommas(res.nonBoiRate)
        res.boiRat = numberWithCommas(res.boiRate)
        res.grantedShare = numberWithCommas(res.grantedShare)
        res.cashFromFractionalAmount = numberWithCommas(res.cashFromFractionalAmount.toFixed(2))
        res.cashDividendAmount = numberWithCommas(res.cashDividendAmount.toFixed(2))
        res.totalCashBeforeTax = numberWithCommas(res.totalCashBeforeTax.toFixed(2))
        res.netCashAmount = numberWithCommas(res.netCashAmount.toFixed(2))
        res.dividendIncome = numberWithCommas(res.dividendIncome.toFixed(2))
        res.nonBoiTax = numberWithCommas(res.nonBoiTax.toFixed(2))
        res.boiTa = numberWithCommas(res.boiTax.toFixed(2))
        res.totalTax = numberWithCommas(res.totalTax.toFixed(2))
        res.incomeAfterTax = numberWithCommas(res.incomeAfterTax.toFixed(2))
        res.holdingShare = numberWithCommas(res.holdingShare)
        res.dividendShare = numberWithCommas(res.dividendShare)
        res.differentShare = numberWithCommas(res.differentShare)
        res.fractionalShare = numberWithCommas(res.fractionalShare)
        res.percentWithHoldingTax = numberWithCommas(res.percentWithHoldingTax)
        commit('setCalculateData', res)
      })
      .catch(err => err)
    commit('setLoading', false)
  }
}

/**
 * Getters
 */
const getters = {
  getStateType: (state) => {
    return state.stateType
  },
  getSymbolLookupData: (state) => {
    return state.symbolLookupData
  },
  getBookClosingDateData: (state) => {
    return state.bookClosingDateData || []
  },
  getCalculateData: (state) => {
    return state.calculateData
  },
  getSelectedSymbol: (state) => {
    return state.selectedSymbol
  },
  getHoldingShare: (state) => {
    return state.holdingShare ? numberWithCommas(state.holdingShare) : state.holdingShare
  },
  getLoading: (state) => {
    return state.loading
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
