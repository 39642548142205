/**
 * Get render resource
 * @param {Array} renderResource
 * @param {String} resourceKey
 * @returns Object
 */
const getResource = (renderResource, resourceKey) => {
  if (renderResource.length) {
    const resource = renderResource.filter((r) => {
      return r.key === resourceKey
    })
    if (resource.length) {
      return resource[0]
    }
  }
  return {}
}
const isPreview = (contentUrl) => {
  return /\/preview\//i.test(contentUrl)
}

const isPageBuilder = (config) => {
  const { contentRenderType, contentType } = require('@/utils')
  const { renderType, pageType } = config
  const isPageBuilder = renderType === contentRenderType.pageBuilder ||
      pageType === contentType.videoDetail ||
      pageType === contentType.podcastDetail ||
      pageType === contentType.videoPlaylist ||
      pageType === contentType.podcastPlaylist ||
      pageType === contentType.setBook
  // console.log('Is render type PageBuilder', isPageBuilder)
  return isPageBuilder
}

async function getMenuLocation (locationCode) {
  const url = `/api/cms/v1/menus/location/${locationCode}?lang=${this.i18n.locale}`
  const response = await this.apiCms.get(url)
    .then((res) => {
      return res
    })
    .catch(err => err)
  return response
}

export {
  getResource,
  isPageBuilder,
  isPreview,
  getMenuLocation
}
