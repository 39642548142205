//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * example option
 * {
      loop: false,
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 20,
      centeredSlides: false,
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        768: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        1366: {
          slidesPerView: 7,
          spaceBetween: 30
        }
      },
      pagination: {
        el: '.swiper-pagination',
        dynamicBullets: true, // for mobile
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-btn-next',
        prevEl: '.swiper-btn-prev'
      }
    }
 */
import { customAlphabet } from 'nanoid'
import { directive } from 'vue-awesome-swiper'
const { mergeObject } = require('~/helpers')
export default {
  name: 'SwiperCarousel',
  directives: {
    swiperContainer: directive
  },
  props: {
    options: {
      type: Object,
      default: Object
    },
    arrowLeft: {
      type: Boolean,
      default: true
    },
    arrowRight: {
      type: Boolean,
      default: true
    }
  },
  data () {
    const nanoid = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyz', 6)
    const swiperId = `swiper${nanoid()}`
    return {
      swiperId,
      navigationId: `navigation${nanoid()}`,
      swiperOption: {
        showPagination: false,
        showNavigation: true,
        spaceBetween: 0,
        grabCursor: true,
        showNavigationOutSide: false,
        pagination: {
          el: `#${swiperId} .swiper-pagination`,
          clickable: true
        },
        navigation: {
          // nextEl: '.swiper-btn-next',
          // prevEl: '.swiper-btn-prev'
          nextEl: `#${swiperId} .swiper-btn-next`,
          prevEl: `#${swiperId} .swiper-btn-prev`
        },
        wrapperEl: {
          // class: 'swiper-wrapper row'
        }
      }
    }
  },
  async created () {
    await this.mergeOption()
  },
  mounted () {
    this.onSwiperInit()
  },
  methods: {
    onSwiperInit () {
      this.$emit('swiperInit', this.swiper)
      this.swiper.on('sliderMove', this.clearBSTooltip)
    },
    mergeOption () {
      this.swiperOption = mergeObject(this.swiperOption, this.options)
    },
    handleMouseDown (event) {
      event.target.classList.add('click')
    },
    handleMouseUp () {
      this.handleMouseLeave()
    },
    handleMouseLeave () {
      if (this.arrowLeft) {
        this.$refs.swiperButtonPrev.classList.remove('click')
      }
      if (this.arrowRight) {
        this.$refs.swiperButtonNext.classList.remove('click')
      }
    },
    clearBSTooltip () {
      const selector = `#${this.swiperId} [data-bs-toggle="tooltip"]`
      const triggerTooltipEl = document.querySelectorAll(selector)// document.getElementById('example')
      if (!triggerTooltipEl.length) { return }
      const { Tooltip } = require('bootstrap')
      triggerTooltipEl.forEach((el) => {
        const tooltip = Tooltip.getOrCreateInstance(el)
        tooltip.hide()
      })
    }
  }
}
