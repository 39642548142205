import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/tsd/index.js'), 'tsd/index.js')
  resolveStoreModules(require('../store/tabs/index.js'), 'tabs/index.js')
  resolveStoreModules(require('../store/statistics/index.js'), 'statistics/index.js')
  resolveStoreModules(require('../store/setmember/index.js'), 'setmember/index.js')
  resolveStoreModules(require('../store/setbook/index.js'), 'setbook/index.js')
  resolveStoreModules(require('../store/searchresult/index.js'), 'searchresult/index.js')
  resolveStoreModules(require('../store/search/index.js'), 'search/index.js')
  resolveStoreModules(require('../store/rulebooks/index.js'), 'rulebooks/index.js')
  resolveStoreModules(require('../store/research/index.js'), 'research/index.js')
  resolveStoreModules(require('../store/quote/index.js'), 'quote/index.js')
  resolveStoreModules(require('../store/profile/index.js'), 'profile/index.js')
  resolveStoreModules(require('../store/news/index.js'), 'news/index.js')
  resolveStoreModules(require('../store/market/index.js'), 'market/index.js')
  resolveStoreModules(require('../store/home/index.js'), 'home/index.js')
  resolveStoreModules(require('../store/glossary/index.js'), 'glossary/index.js')
  resolveStoreModules(require('../store/getquote/index.js'), 'getquote/index.js')
  resolveStoreModules(require('../store/eventcalendar/index.js'), 'eventcalendar/index.js')
  resolveStoreModules(require('../store/contents/index.js'), 'contents/index.js')
  resolveStoreModules(require('../store/auth/index.js'), 'auth/index.js')
  resolveStoreModules(require('../store/tsd/securityChangeName/index.js'), 'tsd/securityChangeName/index.js')
  resolveStoreModules(require('../store/tsd/debenture/index.js'), 'tsd/debenture/index.js')
  resolveStoreModules(require('../store/tsd/certificateLossList/index.js'), 'tsd/certificateLossList/index.js')
  resolveStoreModules(require('../store/statistics/Shortsales/index.js'), 'statistics/Shortsales/index.js')
  resolveStoreModules(require('../store/statistics/Nvdr/index.js'), 'statistics/Nvdr/index.js')
  resolveStoreModules(require('../store/statistics/market-Statistics/index.js'), 'statistics/market-Statistics/index.js')
  resolveStoreModules(require('../store/statistics/InvestorType/index.js'), 'statistics/InvestorType/index.js')
  resolveStoreModules(require('../store/statistics/FiveDays/index.js'), 'statistics/FiveDays/index.js')
  resolveStoreModules(require('../store/setmember/favorite/index.js'), 'setmember/favorite/index.js')
  resolveStoreModules(require('../store/rulebooks/regulatory-statistic/index.js'), 'rulebooks/regulatory-statistic/index.js')
  resolveStoreModules(require('../store/research/marketreport/index.js'), 'research/marketreport/index.js')
  resolveStoreModules(require('../store/quote/stock/index.js'), 'quote/stock/index.js')
  resolveStoreModules(require('../store/quote/popular/index.js'), 'quote/popular/index.js')
  resolveStoreModules(require('../store/quote/ETF/index.js'), 'quote/ETF/index.js')
  resolveStoreModules(require('../store/quote/DW/index.js'), 'quote/DW/index.js')
  resolveStoreModules(require('../store/quote/DRX/index.js'), 'quote/DRX/index.js')
  resolveStoreModules(require('../store/quote/DR/index.js'), 'quote/DR/index.js')
  resolveStoreModules(require('../store/market/stockCalendar/index.js'), 'market/stockCalendar/index.js')
  resolveStoreModules(require('../store/market/index/index.js'), 'market/index/index.js')
  resolveStoreModules(require('../store/listing/other-instrument/index.js'), 'listing/other-instrument/index.js')
  resolveStoreModules(require('../store/listing/ipo-upcoming/index.js'), 'listing/ipo-upcoming/index.js')
  resolveStoreModules(require('../store/listing/ipo-statistics/index.js'), 'listing/ipo-statistics/index.js')
  resolveStoreModules(require('../store/home/tfex/index.js'), 'home/tfex/index.js')
  resolveStoreModules(require('../store/home/stock-calendar/index.js'), 'home/stock-calendar/index.js')
  resolveStoreModules(require('../store/home/set-mai/index.js'), 'home/set-mai/index.js')
  resolveStoreModules(require('../store/home/oppday/index.js'), 'home/oppday/index.js')
  resolveStoreModules(require('../store/home/ipo/index.js'), 'home/ipo/index.js')
  resolveStoreModules(require('../store/calculators/stockdividend/index.js'), 'calculators/stockdividend/index.js')
  resolveStoreModules(require('../store/calculators/rightoffering/index.js'), 'calculators/rightoffering/index.js')
  resolveStoreModules(require('../store/about/setsource/index.js'), 'about/setsource/index.js')
  resolveStoreModules(require('../store/about/eventCalendar/index.js'), 'about/eventCalendar/index.js')
  resolveStoreModules(require('../store/rulebooks/regulatory-statistic/signed-symbol/index.js'), 'rulebooks/regulatory-statistic/signed-symbol/index.js')
  resolveStoreModules(require('../store/quote/stock/overview/index.js'), 'quote/stock/overview/index.js')
  resolveStoreModules(require('../store/quote/stock/factsheet/index.js'), 'quote/stock/factsheet/index.js')
  resolveStoreModules(require('../store/quote/ETF/overview/index.js'), 'quote/ETF/overview/index.js')
  resolveStoreModules(require('../store/quote/DW/overview/index.js'), 'quote/DW/overview/index.js')
  resolveStoreModules(require('../store/quote/DRX/Search/index.js'), 'quote/DRX/Search/index.js')
  resolveStoreModules(require('../store/quote/DRX/overview/index.js'), 'quote/DRX/overview/index.js')
  resolveStoreModules(require('../store/market/information/member-list/index.js'), 'market/information/member-list/index.js')
  resolveStoreModules(require('../store/about/eventCalendar/holiday/index.js'), 'about/eventCalendar/holiday/index.js')
  resolveStoreModules(require('../store/about/eventCalendar/event/index.js'), 'about/eventCalendar/event/index.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
