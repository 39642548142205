const state = () => ({
  debentureList: null
})

const actions = {
  async fetchDebentureList ({ commit }, payload) {
    const apiPath = `api/gw-api/tsd/debenture-terms/list?keyword=${encodeURI(payload.keyword)}`
    await this.$axios.$get(apiPath).then((res) => {
      const mapped = res ? res.map(item => ({ symbol: item.symbol, name: item.name, expireDate: item.maturityDate, policyFile: item.downloadUrl })) : []
      commit('setDebentureList', mapped)
    })
  }
}

const mutations = {
  setDebentureList (state, data) {
    state.debentureList = data
  }
}

const getters = {
  getDebentureList (state) {
    return state.debentureList
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
