/**
 * State
 */
import { formatShortMonthYear } from '~/helpers/dateTime'
import { setMoneynessStatusLabel, setDwSearchTimeToMaturitiesLabel, setDwSearchEgListLabel } from '~/helpers/utilities'
const state = () => ({
  overview: {
    topten: {
      data: {},
      chart: {}
    },
    underlying: {
      data: {},
      chart: {}
    },
    favorite: {},
    monthlyReport: null,
    issuerList: null,
    datePerformance: null,
    liquidityPerformance: null,
    symbolList: null
  },
  search: {
    filter: {
      underlying: [],
      issuer: [],
      contractMonth: [],
      ttm: [],
      moneynessStatus: [],
      effectiveGearing: []
    },
    list: {},
    newList: {},
    nearExpireList: {},
    chart: {},
    underlyingActiveList: []
  }
})

/**
 * Mutations
 */
const mutations = {
  setDwList (state, data) {
    state.search.list = data
  },
  setDwNewList (state, data) {
    state.search.newList = data
  },
  setDwNearExpireList (state, data) {
    state.search.nearExpireList = data
  },
  setStockIntradayChart (state, data) {
    state.search.chart[`${data.symbol}`] = data.chart
  },
  setDwSearchUnderlyingList (state, data) {
    if (data) {
      state.search.filter.underlying = data.underlyingList.map((item) => {
        return {
          label: item.symbol,
          key: item.symbol,
          checked: true,
          disabled: false
        }
      })
    }
  },
  setDwSearchIssuerList (state, data) {
    if (data) {
      state.search.filter.issuer = data.issuerList.map((item) => {
        return {
          ...item,
          label: item.symbol,
          key: item.symbol,
          checked: true,
          disabled: false
        }
      })
    }
  },
  setDwSearchContractMonth (state, data) {
    if (data) {
      state.search.filter.contractMonth = data.contractMonthList.map((item) => {
        return {
          label: formatShortMonthYear(item.date, this.$i18n.locale),
          key: item.date,
          checked: true,
          disabled: false
        }
      })
    }
  },
  setDwSearchTimeToMaturities (state, data) {
    if (data) {
      state.search.filter.ttm = data.timeToMaturities.map((item) => {
        return {
          label: setDwSearchTimeToMaturitiesLabel(item.value, this.$i18n.locale),
          key: item.value,
          checked: true,
          disabled: false
        }
      })
    }
  },
  setDwSearchMoneynessPeriodList (state, data) {
    if (data) {
      state.search.filter.moneynessStatus = data.moneynessPeriodList.map((item) => {
        return {
          label: setMoneynessStatusLabel(item.id),
          key: item.id,
          checked: true,
          disabled: false
        }
      })
    }
  },

  setDwSearchEgList (state, data) {
    if (data) {
      state.search.filter.effectiveGearing = data.egList.map((item) => {
        return {
          label: setDwSearchEgListLabel(item.id), // ==>Eff. Gearing
          key: item.id,
          checked: true,
          disabled: false
        }
      })
    }
  },
  setDwOverviewMostActiveValue (state, data) {
    if (data) {
      state.overview.topten.data = data
      state.overview.topten.chart = {}
    }
  },
  setDwMostActiveValueIntradayChart (state, data) {
    if (data) {
      state.overview.topten.chart[`${data.symbol}`] = data.chart
    }
  },
  setDwMostActiveValueIntradayChartAll (state, data) {
    if (data && data.chartDataList.length) {
      const result = {}
      data.chartDataList.forEach((item, index) => {
        if (item.status === 'fulfilled') {
          result[data.symbolLists[index]] = item.value
        } else {
          result[data.symbolLists[index]] = null
        }
      })
      state.overview.topten.chart = result
      // state.overview.topten.chart[`${data.symbol}`] = data.chart
    }
  },
  setDwMonthlyReport (state, data) {
    state.overview.monthlyReport = data.reports
  },
  setDwIssuerList (state, data) {
    state.overview.issuerList = data.issuerList.map((item) => {
      return {
        ...item,
        label: item.symbol,
        key: item.symbol,
        checked: true,
        disabled: false
      }
    })
  },
  setDwDatePerformance (state, data) {
    state.overview.datePerformance = data
  },
  setDwUnderlyingToptenMostActiveValue (state, data) {
    if (data) {
      state.overview.underlying.data = data
      state.overview.underlying.chart = {}
    }
  },
  setDwUnderlyingToptenIntradayChart (state, data) {
    if (data) {
      state.overview.underlying.chart[`${data.symbol}`] = data.chart
    }
  },
  setDwSearchUnderlying (state, data) {
    if (data) {
      state.search.underlyingActiveList = data
    }
  },
  setDwLiquidityPerformance (state, data) {
    if (data) {
      state.overview.liquidityPerformance = data
    }
  },
  setDwSymbolList (state, data) {
    if (data) {
      state.overview.symbolList = data.map((item) => {
        const { symbol } = item
        return {
          text: symbol
        }
      })
    }
  }
}

/**
 * Actions
 */
const actions = {
  async getDwOverview ({ commit, dispatch }, payload) {
    await Promise.allSettled([
      // dispatch('getDwMostActiveValue', payload),
      dispatch('getDwUnderlyingMostActiveValue'),
      dispatch('getDwMonthlyReport'),
      dispatch('getDwIssuerList'),
      dispatch('getDwDatePerformance'),
      dispatch('getDwSymbolList')
    ])
  },
  async getDwList ({ commit }, payload) {
    const {
      underlyingSymbol, category, symbol, underlyingType, underlyingNative
      , dwType, issuerId, effectiveGearing, ttm, moneyness, contractMonth, minRightPrice
      , maxRightPrice, minPercentOutstanding, maxPercentOutstanding, minIV, maxIV, requestType
    } = payload
    let urlPath = category !== 'mostActiveValue' ? '/api/set/dw-info/list?' : '/api/set/dw-info/ranking/mostActiveValue'
    if (category !== undefined && category !== 'mostActiveValue' && underlyingSymbol) {
      urlPath += `?underlyingSymbol=${underlyingSymbol}`
    } else if (requestType === 'filter' && category === 'mostActiveValue') {
      let param = {
        underlyingType,
        underlyingNative,
        dwType
      }
      param = Object.fromEntries(
        Object.entries(param).filter(([_, value]) => (value !== null && value !== undefined))
      )
      const urlWithParam = new URLSearchParams(param).toString()
      urlPath += (urlWithParam) ? `?${urlWithParam}` : ''
    } else {
      urlPath += symbol ? `symbol=${symbol.toUpperCase()}&` : ''
      urlPath += underlyingType ? `underlyingType=${underlyingType}&` : ''
      urlPath += underlyingNative ? `underlyingNative=${underlyingNative}&` : ''
      urlPath += dwType ? `dwType=${dwType}&` : ''
      urlPath += underlyingSymbol ? `underlyingSymbol=${underlyingSymbol}&` : ''
      urlPath += issuerId ? `issuerId=${issuerId}&` : ''
      urlPath += effectiveGearing ? `effectiveGearing=${effectiveGearing}&` : ''
      urlPath += ttm ? `ttm=${ttm}&` : ''
      urlPath += moneyness ? `moneyness=${moneyness}&` : ''
      urlPath += contractMonth ? `contractMonth=${contractMonth}&` : ''
      urlPath += minRightPrice ? `minRightPrice=${minRightPrice}&` : ''
      urlPath += maxRightPrice ? `maxRightPrice=${maxRightPrice}&` : ''
      urlPath += minPercentOutstanding ? `minPercentOutstanding=${minPercentOutstanding}&` : ''
      urlPath += maxPercentOutstanding ? `maxPercentOutstanding=${maxPercentOutstanding}&` : ''
      urlPath += minIV ? `minIV=${minIV}&` : ''
      urlPath += maxIV ? `maxIV=${maxIV}&` : ''
    }
    try {
      const response = await this.$axios.$get(urlPath)
      commit('setDwList', response)
    } catch (err) {
      commit('setDwList', null)
    }
  },
  async getDwNewList ({ commit }, payload) {
    const { underlyingType, underlyingNative, dwType } = payload
    let param = {
      underlyingType,
      underlyingNative,
      dwType
    }
    param = Object.fromEntries(
      Object.entries(param).filter(([_, value]) => (value !== null && value !== undefined))
    )
    const urlWithParam = new URLSearchParams(param).toString()
    let urlPath = '/api/set/dw-info/new-listed'
    urlPath += (urlWithParam) ? `?${urlWithParam}` : ''
    try {
      const response = await this.$axios.$get(urlPath)
      commit('setDwNewList', response)
    } catch (err) {
      commit('setDwNewList', null)
    }
  },
  async getDwNearExpiredList ({ commit }, payload) {
    const { underlyingType, underlyingNative, dwType } = payload
    let param = {
      underlyingType,
      underlyingNative,
      dwType
    }
    param = Object.fromEntries(
      Object.entries(param).filter(([_, value]) => (value !== null && value !== undefined))
    )
    const urlWithParam = new URLSearchParams(param).toString()
    let urlPath = '/api/set/dw-info/near-expired-list'
    urlPath += (urlWithParam) ? `?${urlWithParam}` : ''
    try {
      const response = await this.$axios.$get(urlPath)
      commit('setDwNearExpireList', response)
    } catch (err) {
      commit('setDwNearExpireList', null)
    }
  },
  async getStockIntradayChart ({ commit }, payload) {
    const { quote } = payload
    try {
      const chartData = await this.$axios.$get(`/api/set/stock/${quote}/chart-quotation?period=1D`)
      const response = {
        symbol: quote,
        chart: chartData
      }
      commit('setStockIntradayChart', response)
    } catch (err) {
      commit('setStockIntradayChart', null)
    }
  },
  async getDwSearchFilter ({ commit, dispatch }) {
    await Promise.allSettled([
      dispatch('getDwSearchUnderlyingList'),
      dispatch('getDwSearchIssuerList'),
      dispatch('getDwSearchContractMonth'),
      dispatch('getDwSearchTimeToMaturities'),
      dispatch('getDwSearchMoneynessPeriodList'),
      dispatch('getDwSearchEgList')
    ])
  },
  async getDwSearchUnderlyingList ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/dw-fundamental/underlying/list')
      commit('setDwSearchUnderlyingList', response)
    } catch (err) {
      commit('setDwSearchUnderlyingList', null)
    }
  },
  async getDwSearchIssuerList ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/dw-fundamental/issuer/list')
      commit('setDwSearchIssuerList', response)
    } catch (err) {
      commit('setDwSearchIssuerList', null)
    }
  },
  async getDwSearchContractMonth ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/dw-fundamental/contract-month/list')
      commit('setDwSearchContractMonth', response)
    } catch (err) {
      commit('setDwSearchContractMonth', null)
    }
  },
  async getDwSearchTimeToMaturities ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/dw-fundamental/ttm/list')
      commit('setDwSearchTimeToMaturities', response)
    } catch (err) {
      commit('setDwSearchTimeToMaturities', null)
    }
  },
  async getDwSearchMoneynessPeriodList ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/dw-fundamental/moneyness-status/list')
      commit('setDwSearchMoneynessPeriodList', response)
    } catch (err) {
      commit('setDwSearchMoneynessPeriodList', null)
    }
  },
  async getDwSearchEgList ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/dw-fundamental/effective-gearing/list')
      commit('setDwSearchEgList', response)
    } catch (err) {
      commit('setDwSearchEgList', null)
    }
  },
  async getDwMostActiveValue ({ commit, dispatch }, payload) {
    try {
      let path = '/api/set/dw-info/ranking/mostActiveValue?limit=10&'
      if (payload && payload.underlyingType) {
        path += `underlyingType=${payload.underlyingType}&`
      }
      if (payload && payload.underlyingNative) {
        path += `underlyingNative=${payload.underlyingNative}&`
      }
      if (payload && payload.dwType) {
        path += `dwType=${payload.dwType}&`
      }
      await this.$axios.$get(path).then((res) => {
        commit('setDwOverviewMostActiveValue', res)
        dispatch('getDwMostActiveValueIntradayChartAll', res.dwList)
        // res.dwList.forEach((item, index) => {
        // })
      })
    } catch (err) {
      commit('setDwOverviewMostActiveValue', null)
    }
  },
  async getDwMostActiveValueIntradayChartAll  ({ commit }, payload) {
    const dwList = payload
    try {
      const requestDwList = []
      const symbolLists = []
      dwList.forEach((item, index) => {
        const chartData = this.$axios.$get(
          `/api/set/stock/${item.symbol}/chart-quotation?period=1D`
        )
        symbolLists.push(item.symbol)
        requestDwList.push(chartData)
      })
      const res = await await Promise.allSettled(requestDwList)
      commit('setDwMostActiveValueIntradayChartAll', { symbolLists, chartDataList: res })
    } catch (err) {
      commit('setDwMostActiveValueIntradayChart', null)
    }
  },
  async getDwMostActiveValueIntradayChart  ({ commit }, payload) {
    const { quote } = payload
    try {
      const chartData = await this.$axios.$get(
        `/api/set/stock/${quote}/chart-quotation?period=1D`
      )
      const response = {
        symbol: quote,
        chart: chartData
      }
      commit('setDwMostActiveValueIntradayChart', response)
    } catch (err) {
      commit('setDwMostActiveValueIntradayChart', null)
    }
  },
  async getDwUnderlyingMostActiveValue ({ commit, dispatch }, payload) {
    try {
      const response = await this.$axios.$get('/api/set/dw-underlying/mostactive?limit=10')
      commit('setDwUnderlyingToptenMostActiveValue', response)
    } catch (err) {
      commit('setDwUnderlyingToptenMostActiveValue', null)
    }
  },
  async getDwMonthlyReport  ({ commit }, payload) {
    try {
      const response = await this.$axios.$get('/api/set/report/monthly/product/dw')
      commit('setDwMonthlyReport', response)
    } catch (err) {
      commit('setDwMonthlyReport', null)
    }
  },
  async getDwIssuerList  ({ commit }, payload) {
    try {
      const response = await this.$axios.$get('/api/set/dw-fundamental/issuer/list')
      commit('setDwIssuerList', response)
    } catch (err) {
      commit('setDwIssuerList', null)
    }
  },
  async getDwDatePerformance  ({ commit }, payload) {
    try {
      const response = await this.$axios.$get('/api/set/dw-fundamental/mm-performance-date/list')
      commit('setDwDatePerformance', response)
    } catch (err) {
      commit('setDwDatePerformance', null)
    }
  },
  async getDwSearchUnderlying ({ commit, dispatch }, payload) {
    console.log('🚀 ~ payload:', payload)
    const { underlyingType, underlyingNative } = payload
    let param = {
      underlyingType,
      underlyingNative
    }
    param = Object.fromEntries(
      Object.entries(param).filter(([_, value]) => (value !== null && value !== undefined))
    )
    const urlWithParam = new URLSearchParams(param).toString()
    let urlPath = '/api/set/dw-underlying/mostactive'
    urlPath += (urlWithParam) ? `?${urlWithParam}` : ''
    try {
      console.log('🚀 ~ urlPath:', urlPath)
      const response = await this.$axios.$get(urlPath)
      commit('setDwSearchUnderlying', response)
    } catch (err) {
      commit('setDwSearchUnderlying', null)
    }
  },
  async getDwLiquidityPerformance ({ commit }, payload) {
    try {
      const path = `/api/set/dw-issuer/mm-performance/list?issuers=${payload.issuers}&fromDate=${payload.fromDate !== '' ? payload.fromDate : ''}&toDate=${payload.toDate !== '' ? payload.toDate : ''}&lang=${this.$i18n.locale}`
      const response = await this.$axios.$get(path)
      commit('setDwLiquidityPerformance', response)
    } catch (err) {
      commit('setDwLiquidityPerformance', null)
    }
  },
  async getDwSymbolList ({ commit }, payload) {
    try {
      const response = await this.$axios.$get('/api/set/dw/list')
      commit('setDwSymbolList', response)
    } catch (err) {
      commit('setDwSymbolList', null)
    }
  }
}

/**
 * Getters
 */
const getters = {
  getDwList: (state) => {
    return state.search.list
  },
  getDwNewList: (state) => {
    return state.search.newList
  },
  getDwExpireList: (state) => {
    return state.search.nearExpireList
  },
  getStockIntradayChart: (state) => {
    return state.search.chart
  },
  getDwOverviewMostActiveValue: (state) => {
    return state.overview.topten.data
  },
  getDwOverviewMostActiveValueChart: (state) => {
    return state.overview.topten.chart
  },
  getDwOverviewUnderlying: (state) => {
    return state.overview.underlying.data
  },
  getDwOverviewUnderlyingChart: (state) => {
    return state.overview.underlying.chart
  },
  getDwMonthlyReport: (state) => {
    return state.overview.monthlyReport
  },
  getDwIssuerList: (state) => {
    return state.overview.issuerList
  },
  getDwDatePerformance: (state) => {
    return state.overview.datePerformance
  },
  getDwSearchUnderlying: (state) => {
    return state.search.underlyingActiveList
  },
  getDwLiquidityPerformance: (state) => {
    return state.overview.liquidityPerformance
  },
  getDwSymbolList: (state) => {
    return state.overview.symbolList
  },
  // FILTER
  getDwSearchFilter: (state) => {
    return state.search.filter
  },
  getDwSearchFilterUnderlying: (state) => {
    return state.search.filter.underlying
  },
  getDwSearchFilterIssuer: (state) => {
    return state.search.filter.issuer
  },
  getDwSearchFilterContractMonth: (state) => {
    return state.search.filter.contractMonth
  },
  getDwSearchFilterTtm: (state) => {
    return state.search.filter.ttm
  },
  getDwSearchFilterMoneynessStatus: (state) => {
    return state.search.filter.moneynessStatus
  },
  getDwSearchFilterEffectiveGearing: (state) => {
    return state.search.filter.effectiveGearing
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
