import cloneDeep from 'lodash/cloneDeep'
import {
  specificPagePath as SPECIFIC_PAGE_PATH_LIST,
  specificPageTHOnly as SPECIFIC_PAGE_TH_ONLY_LIST
} from '~/config/core'
const {
  OK, // 200
  MOVED_PERMANENTLY, // 301
  NOT_FOUND // 404
} = require('http-status')

const matchSpecificPage = (config) => {
  const { route, locale, specificPagePathSource, keepSlug } = config
  const { slug, industry, sector } = route.params
  const encodeSlug = encodeURI(slug)
  const encodeIndustry = encodeURI(industry)
  const encodeSector = encodeURI(sector)

  let path = removeSlugFromPath(route.path, encodeSlug, locale)// route.path.replace('/' + encodeSlug, '')
  path = path.replace('/' + encodeIndustry, '')
  path = path.replace('/' + encodeSector, '')

  if (keepSlug === true) {
    const urlSlug = route.path.split('/').find(item => item === slug && item !== locale && (item ?? '') !== '') ?? ''
    if (urlSlug !== '') {
      path = route.path
    }
  }

  const url = path.search(locale) === -1 ? `/${locale}/${path}` : path
  const pattern = new RegExp('^' + url + '$')
  const found = specificPagePathSource.filter(function (value) {
    const newPath = `/${locale}/${value}`
    const examplePathPattern = new RegExp(`/${locale}/code-example`, 'i')
    return pattern.test(newPath) || examplePathPattern.test(route.path) // || route.path.includes('example')
  })
  return found.length > 0
}
const removeSlugFromPath = (path, slug, locale) => {
  const newPath = path.split('/').filter(item => item !== slug && item !== locale && (item ?? '') !== '')
  return `/${locale}/${newPath.join('/')}`
}
/**
 * State
 */
const state = () => ({
  breadcrumb: null,
  contentSEO: null,
  content: null,
  pageType: null,
  renderType: null,
  conflict: false,
  isTHOnly: false,
  customPersonalized: null
})

/**
 * Mutations
 */
const mutations = {
  setContent (state, data) {
    state.content = data
  },
  setPageType (state, data) {
    state.pageType = data
  },
  setRenderType (state, data) {
    state.renderType = data
  },
  setConflict (state, data) {
    state.conflict = data
  },
  setContentSEO (state, data) {
    state.contentSEO = data
  },
  setBreadcrumb (state, data) {
    state.breadcrumb = data
  },
  setIsTHOnly (state, data) {
    state.isTHOnly = data
  },
  setCustomPersonalized (state, data) {
    state.customPersonalized = data
  }
}

/**
 * Actions
 */
const actions = {
  async getPageBreadcrumb ({ commit }, pathStructure) {
    const apiPath = '/api/cms/v1/pages/breadcrumb'
    const options = {
      params: {
        path: pathStructure
      }
    }
    await this.$apiCms.get(apiPath, options).then((res) => {
      // console.log('Breadcrumb from api', JSON.parse(JSON.stringify(res.data)))
      commit('setBreadcrumb', res.data)
    }).catch(err => err)
  },
  async addPageView ({ commit }, payload) {
    const { data, config } = payload
    const { pageSourceChannelCode } = config ?? {}
    let headersWebChannel = null
    if (pageSourceChannelCode) {
      headersWebChannel = {
        'x-channel': `${pageSourceChannelCode}`
      }
    }
    const apiPath = '/api/cms-w/v1/pages/view'
    const options = {
      method: 'post',
      url: apiPath,
      data,
      headers: {
        'Content-Type': 'application/json',
        ...headersWebChannel
      }
    }
    await this.$apiCms(options)
      .then(res => res)
      .catch(err => err)
  },
  async addResearchPageView ({ commit }, payload) {
    const { data, config } = payload
    const { pageSourceChannelCode } = config ?? {}
    let headersWebChannel = null
    if (pageSourceChannelCode) {
      headersWebChannel = {
        'x-channel': `${pageSourceChannelCode}`
      }
    }
    const apiPath = '/api/cms-w/v1/research-set/view'
    const options = {
      method: 'post',
      url: apiPath,
      data,
      headers: {
        'Content-Type': 'application/json',
        ...headersWebChannel
      }
    }
    await this.$apiCms(options)
      .then(res => res)
      .catch(err => err)
  },
  async getContent ({ commit, dispatch }, payload) {
    const { fullPath, path } = payload
    const isDesktop = (payload.query.device || 'desktop').toLowerCase() === 'desktop'
    const device = this.$device.isDesktopOrTablet && isDesktop ? 'Desktop' : 'Mobile'
    const isPreview = payload.query.mode === 'preview'
    const lang = this.$i18n.locale

    const { params } = payload
    // const isSpecificPage = await matchSpecificPage({
    //   params,
    //   path
    // }, lang)
    const matchSpecificPageRoute = {
      params,
      path: path[path.length - 1] === '/' ? path.slice(0, path.length - 1) : path
    }
    const isSpecificPage = matchSpecificPage({
      route: matchSpecificPageRoute,
      locale: lang,
      specificPagePathSource: SPECIFIC_PAGE_PATH_LIST
    })

    // console.log('isSpecificPage', isSpecificPage)

    const contentPath = isPreview ? path : fullPath

    // check white list
    /*
    const wlPath = contentPath.replace(`/${lang}`, '')
    const whiteListMatch = pathWhiteList.includes(wlPath)
    console.log('whiteListMatch', whiteListMatch)
    */
    const isNotSpecificPage = isSpecificPage === false
    const errorConfig = {
      errorRedirect: isNotSpecificPage,
      isSpecificPage
    }
    const getPBContentRes = await this.$content.getPage({
      path: contentPath,
      device,
      errorConfig
    })
    const { status } = getPBContentRes
    if (status === OK) {
      const { info: { pageType, renderType } } = getPBContentRes.data
      const isConflict = renderType === 'PageBuilder' && isSpecificPage

      commit('setConflict', isConflict)
      commit('setPageType', pageType)
      commit('setRenderType', renderType)
      commit('setContent', getPBContentRes.data)
    }
    // console.log('get home page for specific page', (status === NOT_FOUND || status === MOVED_PERMANENTLY) && isSpecificPage === true)
    if ((status === NOT_FOUND || status === MOVED_PERMANENTLY) && isSpecificPage === true) {
      // Check specific page TH only
      const isSpecificPageTHOnly = matchSpecificPage({
        route: matchSpecificPageRoute,
        locale: lang,
        specificPagePathSource: SPECIFIC_PAGE_TH_ONLY_LIST,
        keepSlug: true
      })
      if (isSpecificPageTHOnly === true) {
        commit('setIsTHOnly', true)
        if (lang !== 'th') {
          const { redirect } = payload
          if (process.client) {
            window.location = '/th/error/404'
          } else {
            redirect('/th/error/404')
          }
        }
      }

      const errorConfig = {
        errorRedirect: false,
        isSpecificPage: true
      }
      const getHomeContentRes = await this.$content.getPage({
        path: `/${lang}/home`,
        device,
        errorConfig
      })
      const { status } = getHomeContentRes
      if (status === OK) {
        const { info: { pageType, renderType } } = getHomeContentRes.data
        commit('setPageType', pageType)
        commit('setRenderType', renderType)
        commit('setContent', getHomeContentRes.data)
      }
    }
  },
  /* async getContent ({ commit }, payload) {
    const { fullPath, path } = payload
    const isDesktop =
      (payload.query.device || 'desktop').toLowerCase() === 'desktop'
    const device =
      this.$device.isDesktopOrTablet && isDesktop ? 'Desktop' : 'Mobile'
    // const lang = this.$i18n.locale
    const isPreview = payload.query.mode === 'preview'
    let contentUrl = isPreview ? path : fullPath

    const { params } = payload
    const isSpecificPage = matchSpecificPage(
      {
        params,
        path:
          path[path.length - 1] === '/' ? path.slice(0, path.length - 1) : path
      },
      this.$i18n.locale
    )
    if (isSpecificPage) {
      contentUrl = `/${this.$i18n.locale}/home`
    }

    // Start get content
    // const apiPath = '/pages/path'
    const apiPath = '/api/cms/v1/pages/path'
    const options = {
      errorRedirect: true,
      params: {
        path: contentUrl,
        device
      }
    }
    await this.$apiPage
      .$get(apiPath, options)
      .then((res) => {
        commit('setContent', res.data)
      })
      .catch(() => {
        commit('setContent', {})
      })
  }, */

  async getContentMarketIndex ({ commit, dispatch, rootGetters }, payload) {
    const { fullPath, path } = payload

    const isDesktop =
      (payload.query.device || 'desktop').toLowerCase() === 'desktop'

    const device =
      this.$device.isDesktopOrTablet && isDesktop ? 'Desktop' : 'Mobile'

    // const lang = this.$i18n.locale
    const isPreview = payload.query.mode === 'preview'

    const contentUrlDefault = isPreview ? path : fullPath

    let contentUrl = contentUrlDefault

    const { params } = payload

    const market = (params.slug && params.slug.toLowerCase()) || ''

    const industry = (params.industry && params.industry.toLowerCase()) || ''

    const sector = (params.sector && params.sector.toLowerCase()) || ''

    // section ** slug **

    const payloadIndexList = {
      index: market,
      type: 'index',
      market: ''
    }

    await dispatch('market/index/getIndexList', payloadIndexList, {
      root: true
    })

    const indexListDefault = cloneDeep(rootGetters['market/index/getIndexList'])

    const indexList =
      [
        ...indexListDefault.map(item => item.symbol.toLowerCase()),
        'ftse-set',
        'ftse-asean',
        'tri'
      ] || []

    const checkValidMarket = indexList.includes(market)

    if (market && checkValidMarket) {
      contentUrl = `/${this.$i18n.locale}/home`
    } else if (market) {
      contentUrl = contentUrlDefault
    }

    // section ** industry/sector **

    if (market && checkValidMarket && industry) {
      const payloadIndexListDropDown = {
        type: 'industry',
        market
      }

      await dispatch(
        'market/index/getIndexListDropDown',
        payloadIndexListDropDown,
        { root: true }
      )

      const IndexListDropDownDefault = cloneDeep(
        rootGetters['market/index/getIndexListDropDown']
      )

      const IndexListDropDown = IndexListDropDownDefault.map(item =>
        item.symbol.toLowerCase()
      )

      const checkValidIndustry =
        IndexListDropDown.includes(industry) || industry === 'overview'

      const checkValidSector = IndexListDropDown.includes(sector)

      if (market && checkValidMarket && industry && checkValidIndustry) {
        contentUrl = `/${this.$i18n.locale}/home`
      } else if (market && industry) {
        contentUrl = contentUrlDefault
      }

      if (industry && checkValidIndustry && sector && checkValidSector) {
        contentUrl = `/${this.$i18n.locale}/home`
      } else if (market && industry && sector) {
        contentUrl = contentUrlDefault
      }
    }

    // Start get content
    // const apiPath = '/pages/path'
    const apiPath = '/api/cms/v1/pages/path'
    const errorConfig = {
      errorRedirect: true,
      isSpecificPage: true
    }
    const options = {
      errorConfig,
      params: {
        path: contentUrl,
        device
      }
    }
    await this.$apiPage
      .$get(apiPath, options)
      .then((res) => {
        commit('setContent', res.data)
        const { info: { pageType, renderType } } = res.data
        commit('setPageType', pageType)
        commit('setRenderType', renderType)
      })
      .catch(() => {
        commit('setContent', {})
      })
  },

  async getContentMemberList ({ commit, dispatch, rootGetters }, payload) {
    const { fullPath, path } = payload

    const isDesktop =
      (payload.query.device || 'desktop').toLowerCase() === 'desktop'

    const device =
      this.$device.isDesktopOrTablet && isDesktop ? 'Desktop' : 'Mobile'

    // const lang = this.$i18n.locale
    const isPreview = payload.query.mode === 'preview'

    const contentUrlDefault = isPreview ? path : fullPath

    let contentUrl = contentUrlDefault

    const { params } = payload

    const slug = (params.slug && params.slug.toLowerCase()) || ''

    await dispatch('market/information/member-list/getBrokerList', null, {
      root: true
    })

    if (!fullPath.includes('/information/member-list/main')) {
      const memberListDefault = cloneDeep(
        rootGetters['market/information/member-list/getBrokerList']
      )

      const checkValidMemberList =
        memberListDefault.find(
          item => item.symbol.toLowerCase() === slug.toLowerCase()
        )?.isListedCompany || false

      if (slug && checkValidMemberList) {
        contentUrl = `/${this.$i18n.locale}/home`
      } else if (slug) {
        contentUrl = contentUrlDefault
      }
    } else if (fullPath.includes('/information/member-list/main')) {
      contentUrl = `/${this.$i18n.locale}/home`
    }

    // Start get content
    // const apiPath = '/pages/path'
    const apiPath = '/api/cms/v1/pages/path'
    const errorConfig = {
      errorRedirect: true,
      isSpecificPage: true
    }
    const options = {
      errorConfig,
      params: {
        path: contentUrl,
        device
      }
    }
    await this.$apiPage
      .$get(apiPath, options)
      .then((res) => {
        commit('setContent', res.data)
        const { info: { pageType, renderType } } = res.data
        commit('setPageType', pageType)
        commit('setRenderType', renderType)
      })
      .catch(() => {
        commit('setContent', {})
      })
  }
}

/**
 * Getters
 */
const getters = {
  getPageTypeData: (state) => {
    return state.pageType ?? ''
  },
  getRenderTypeData: (state) => {
    return state.renderType ?? ''
  },
  getContentData: (state) => {
    return state.content ? state.content : {}
  },
  getPageBreadcrumbData: (state) => {
    return state.breadcrumb ? state.breadcrumb : []
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
