/**
 * State
 */
const state = () => ({
  listIndex: [],
  indexInfo: {
    set: null,
    mai: null
  },
  indexChartData: null,
  indexSummarry: null,
  topRanking: null,
  rankingChartData: null,
  setFundamental: null,
  maiFundamental: null,
  summarryRetro: null,
  tempIndexInfo: {
    set: null,
    mai: null
  }
})

/**
 * Mutations
 */
const mutations = {
  setListIndex (state, data) {
    if (data) {
      state.listIndex = data.indices
    }
  },
  setIndexInfo (state, data) {
    state.indexInfo[data.index] = data.data
  },
  setIndexChartData (state, data) {
    state.indexChartData = data
  },
  setIndexSummarry (state, data) {
    state.index = data
  },
  setTopRanking (state, data) {
    state.topRanking = data
  },
  setRankingChartData (state, data) {
    state.rankingChartData = data
  },
  setSetFundamental (state, data) {
    state.setFundamental = data
  },
  setMaiFundamental (state, data) {
    state.maiFundamental = data
  },
  setSummarryRetro (state, data) {
    state.summarryRetro = data
  },
  setTempIndexInfo (state, data) {
    state.tempIndexInfo[data.index] = data.data
  }
}

/**
 * Actions
 */
const actions = {
  async getListIndex ({ commit }) {
    await this.$axios.get('api/set/market/SET/index-list').then((res) => {
      commit('setListIndex', res.data)
    }).catch(err => err)
  },
  async getIndexInfo ({ state, commit }, payload) {
    if (state.tempIndexInfo[`${payload.index.toLowerCase()}`]) {
      const temp = state.tempIndexInfo[`${payload.index.toLowerCase()}`]
      commit('setIndexInfo', { data: temp, index: payload.index.toLowerCase() })
    } else {
      await this.$axios.get(`api/set/index/${payload.index}/info`).then((res) => {
        if (res.data && payload.index) {
          const index = payload.index.toLowerCase()
          commit('setTempIndexInfo', { data: res.data, index })
          commit('setIndexInfo', { data: res.data, index })
        }
      }).catch(err => err)
    }
  },
  async getIndexChartData ({ commit }, payload) {
    await this.$axios.get(`api/set/index/${payload.index}/chart-quotation?period=1D`).then((res) => {
      // console.log('res data chart index', res.data)
      commit('setIndexChartData', res.data)
    }).catch(err => err)
  },
  async getIndexSummarry ({ commit }, payload) {
    await this.$axios.get(`api/set/market/${payload.index}/investor-type`).then((res) => {
      commit('setIndexSummarry', res.data)
    }).catch(err => err)
  },
  async getTopRanking ({ commit }, payload) {
    try {
      if (payload.rankingType === 'popularSearch') {
        await this.$apiCms
          .get(`api/cms/v1/popularquote/getpopular?market=${payload.index}&securityType=${payload.securityType}&limit=10`)
          .then((res) => {
            if (res && res.data && res.data.stocks) {
              commit('setTopRanking', res.data)
            } else {
              commit('setTopRanking', null)
            }
          })
      } else {
        await this.$axios.get(`api/set/ranking/${payload.rankingType}/${payload.index}/${payload.securityType}?count=${payload.count}`).then((res) => {
          if (res && res.data && res.data.stocks) {
            commit('setTopRanking', res.data)
          } else {
            commit('setTopRanking', null)
          }
        })
      }
    } catch (ex) {
      commit('setTopRanking', null)
    }
  },
  async getRankingChartData ({ commit }, payload) {
    await this.$axios.get(`api/set/stock/${payload.symbol}/chart-quotation?period=1D`).then((res) => {
      commit('setRankingChartData', res.data)
    }).catch(err => err)
  },
  async getSetFundamental ({ commit }) {
    await this.$axios.get('api/set/market/SET/fundamental').then((res) => {
      commit('setSetFundamental', res.data)
    }).catch(err => err)
  },
  async getMaiFundamental ({ commit }) {
    await this.$axios.get('api/set/market/MAI/fundamental').then((res) => {
      commit('setMaiFundamental', res.data)
    }).catch(err => err)
  },
  async getSummarryRetro ({ commit }, payload) {
    await this.$axios.get(`api/set/market/${payload.index}/investor-type-chart?period=5D`).then((res) => {
      commit('setSummarryRetro', res.data)
    }).catch(err => err)
  }
}

const getters = {
  getListIndex: (state) => {
    return state.listIndex
  },
  getIndexInfo: (state) => {
    return state.indexInfo
  },
  getIndexChartData: (state) => {
    return state.indexChartData
  },
  getIndexSummarry: (state) => {
    return state.indexSummarry
  },
  getTopRanking: (state) => {
    return state.topRanking
  },
  getRankingChartData: (state) => {
    return state.rankingChartData
  },
  getSetFundamental: (state) => {
    return state.setFundamental
  },
  getMaiFundamental: (state) => {
    return state.maiFundamental
  },
  getSummarryRetro: (state) => {
    return state.summarryRetro
  },
  getTempIndexInfo: (state) => {
    return state.tempIndexInfo
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
