const logger = {
  /**
   * Error
   * @param {*} err
   */
  error (err) {
    const httpStatus = require('http-status')
    const { env } = require('../config/vars')

    const response = err.response || {}
    const status = response.status || err.status
    const message = response.data || err.message || httpStatus[status]

    /* eslint-disable no-console */
    // Display error
    console.log('error code:', status)
    console.log('error message:', message)
    console.log('errors:', err.errors)
    if (env === 'development') {
      console.log('error stack:', err.stack)
    }
  },

  /**
   * LOG download file
   * @param {*} err
   * @param {string} file.url
   * @param {string} file.name
   * @param {string} file.extname
   */
  errorDownloadFile (err, file) {
    console.error('Problem with download')
    console.log('FileUrl:', file.url)
    console.log('FileName:', file.name)
    console.log('FileExtname:', file.extname)
    console.error(err)
  }
}

module.exports = logger
