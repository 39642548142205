//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'IconStar'
}
