/**
 * State
 */

const state = () => ({
  recentSearch: [],
  KeyWord: null,
  ActiveDropDwon: false,
  Hashtag: null,
  Stocks: []
})

/**
 * Mutations
 */
const mutations = {
  setState (state, opts) {
    state[opts.key] = opts.data
  },
  setRecentSearch (state, data) {
    state.recentSearch = data
  }
}

/**
 * Actions
 */
const actions = {
  setKeyWordSearch ({ state, commit, dispatch }, payload) {
    commit('setState', { key: 'KeyWord', data: payload })
  },
  async setActiveDropDown ({ state, commit, dispatch }, payload) {
    commit('setState', { key: 'ActiveDropDwon', data: payload })
    if (payload) {
      dispatch('getRecentSearch')
    } else {
      dispatch('setKeyWordSearch', '')
    }
    if (!state.Stocks.length) {
      await dispatch('getStockList')
    }
    if (state.Hashtag === null || (state.Hashtag && !state.Hashtag.length)) {
      dispatch('getPopularKeywords')
    }
  },
  async getStockList ({ state, commit }) {
    if (!state.Stocks.length) {
      await this.$axios.get('/api/set/stock/list').then((res) => {
        if (res && res.data && res.data.securitySymbols.length) {
          commit('setState', { key: 'Stocks', data: res.data.securitySymbols })
        }
      }).catch(err => err)
    }
  },
  async setCookieRecentSearch ({ commit, dispatch, state }, symbol) {
    const { cookiesExpires } = require('@/config/cookies')
    const cloneDeep = require('lodash/cloneDeep')
    await dispatch('getRecentSearch')
    let tempQuote = cloneDeep(state.recentSearch)
    const quoteNow = []
    if (symbol) {
      quoteNow.push(symbol)
    }
    if (tempQuote.length) {
      if (quoteNow.length) {
        const isSame = tempQuote.filter(q => q.toUpperCase() === quoteNow[0].toUpperCase())
        if (isSame.length) {
          const index = tempQuote.indexOf(isSame[0])
          if (index > -1) {
            tempQuote.splice(index, 1)
          }
          tempQuote = [...quoteNow, ...tempQuote]
          // tempQuote.splice(5, 1)
        } else {
          tempQuote = [...quoteNow, ...tempQuote]
          // tempQuote.splice(5, 1)
        }
      }
      this.$cookies.set('recent-search', JSON.stringify(tempQuote), { path: '/', expires: cookiesExpires })
      dispatch('setRecentSearchSet', tempQuote)
    } else {
      this.$cookies.set('recent-search', JSON.stringify(quoteNow), { path: '/', expires: cookiesExpires })
      dispatch('setRecentSearchSet', quoteNow)
    }
  },
  async getPopularKeywords ({ commit }) {
    commit('setState', { key: 'Hashtag', data: null })
    const type = 'PathStructure|ArticleDetail|ManageInfographic|VideoDetail|PodcastDetail|ELearning|EBookDetail|ManageContent|ResearchSET|News|ExternalPage'
    try {
      const res = await this.$apiCms.get(`/api/cms/v1/search/keywords-popular?types=${type}&language=${this.$i18n.locale}&pageSize=10&frequency=Weekly`)
      commit('setState', { key: 'Hashtag', data: res.data || [] })
    } catch (err) {
      commit('setState', { key: 'Hashtag', data: [] })
    }
  },
  getRecentSearch ({ state, commit, dispatch }) {
    if (this.$cookies.get('recent-search')) {
      const recentSearch = this.$cookies.get('recent-search')
      dispatch('setRecentSearchSet', recentSearch)
    } else {
      dispatch('setRecentSearchSet', [])
    }
  },
  setRecentSearchSet ({ commit }, data) {
    commit('setRecentSearch', data)
  }
}

/**
 * Getters
 */
const getters = {
  getKeyWordSearch: (state) => {
    return state.KeyWord ? state.KeyWord : ''
  },
  getActiveDropDown: (state) => {
    return state.ActiveDropDwon ? state.ActiveDropDwon : false
  },
  getHashtag: (state) => {
    return state.Hashtag
  },
  getStocks: (state) => {
    return state.Stocks ? state.Stocks : null
  },
  getRecentSearch (state) {
    return state.recentSearch
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
