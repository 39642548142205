//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { customAlphabet } from 'nanoid'
import { setBannerItemAttrLink } from '@/helpers'
import { personalizedBannerTrigger } from '@/helpers/banner'
export default {
  name: 'BannerItems',
  inject: ['showPaginationOutSide', 'slidesPerView', 'widthAuto', 'loop', 'eventTrackingName'],
  props: {
    width: {
      type: Number,
      default: 0
    },
    items: {
      type: Array,
      default: () => []
    },
    pagination: {
      type: Boolean,
      default: false
    },
    navigation: {
      type: Boolean,
      default: false
    },
    transitionSec: {
      type: Number,
      default: 0
    },
    bannerName: {
      type: String,
      default: ''
    },
    bannerUuid: {
      type: String,
      default: ''
    }
  },
  data () {
    const nanoId = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyz', 10)()
    return {
      ready: false,
      bannerId: `banner${nanoId}`,
      bannerSlideClass: `banner${this.bannerUuid}-slide`,
      bannerCarouselClass: `carousel-banner${this.bannerUuid}`,
      bannerPaginationClass: `banner${this.bannerUuid}-swiper-pagination`,
      swiperOptions: {}
    }
  },
  computed: {
    autoplay () {
      if (this.transitionSec) {
        return {
          delay: this.transitionSec * 1000,
          disableOnInteraction: false
        }
      }
      return false
    },
    styleWidth () {
      if (this.widthAuto) { return null }
      if (this.width > 0) {
        return {
          maxWidth: `${this.width}px`
        }
      }
      return null
    }
  },
  mounted () {
    this.setOptions()
  },
  methods: {
    setBannerItemAttrLink,
    initSwiper (swiper) {
      this.handleSwiperResize(swiper)
      swiper.on('resize', () => {
        this.handleSwiperResize(swiper)
      })
      // personalizedBanner
      personalizedBannerTrigger(this.bannerUuid, () => {
        swiper.update()
      })
    },
    handleSwiperResize (swiper) {
      swiper.allowTouchMove = !swiper.isLocked
    },
    setOptions () {
      let options = {
        watchOverflow: true,
        loop: this.items.length > 1 && this.loop,
        autoplay: this.autoplay,
        showPagination: this.pagination && this.items.length > 1,
        showNavigation: this.navigation && this.items.length > 1,
        showNavigationOutSide: true,
        slidesPerView: this.slidesPerView,
        navigation: {
          nextEl: `#${this.bannerId} .swiper-btn-next`,
          prevEl: `#${this.bannerId} .swiper-btn-prev`
        },
        breakpoints: {
          320: {
            slidesPerView: 1
          },
          768: {
            slidesPerView: this.slidesPerView
          }
        }
      }
      if (this.pagination && this.showPaginationOutSide === true) {
        const pagination = {
          pagination: {
            el: `#${this.bannerId} .banner-slide-pagination`,
            clickable: true,
            renderBullet: (index, className) => {
              return '<span class="' + className + '"><div class="circle"></div></span>'
            }
          }
        }
        options = { ...options, ...pagination }
      }
      this.swiperOptions = options
      this.ready = true
    },
    // sliderClicked (event) {
    //   if (
    //     !event.target.classList.contains('swiper-pagination-bullet') &&
    //     !event.target.classList.contains('swiper-button-next') &&
    //     !event.target.classList.contains('swiper-button-prev')
    //   ) {
    //     const swiper = this.$refs.bannerCarousel.swiper
    //     const { realIndex } = swiper
    //     const item = this.items[realIndex]
    //     if (item.url) {
    //       this.handleClickTracking({
    //         title: item.name,
    //         eventTracking: item.event_tracking
    //       })
    //     }
    //     this.$content.updateClickCounter(item.bannerItemUuid)
    //   }
    // },
    handleClickSlideItem (itemIndex) {
      const item = this.items[itemIndex] || {}
      let eventTracking = item.eventTracking
      if ('event_tracking' in item) {
        const isEtk = item.event_tracking.is_etk
        eventTracking = { ...item.event_tracking, isEtk }
      }
      if (item.url) {
        this.handleClickTracking({
          title: item.name,
          eventTracking
        })
      }
      this.$content.updateClickCounter(item.bannerItemUuid)
    },
    handleClickTracking (data) {
      const eventTracking = data.eventTracking || {}
      const isEtk = eventTracking.isEtk
      if (isEtk) {
        const title = data.title
        if (this.eventTrackingName) {
          this.$personalized.clickElement({
            name: this.eventTrackingName,
            optionLang: {
              bannerName: this.bannerName,
              bannerItemName: title
            }
          })
        } else {
          const sectionName = this.bannerName ? `Banner - ${this.bannerName}` : ''
          this.$personalized.clickElement({
            title,
            sectionName
          })
        }
      }
    }
  }
}
