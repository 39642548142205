/**
 * State
 */
const state = () => ({
  companyProfile: null,
  highlightData: null,
  highlightChart: null,
  price: {
    industry: null,
    relatedProduct: null
  },
  realty: {
    latestFullFinancialStatement: null,
    oneReport: [],
    annualReport: [],
    form56Report: [],
    shareholder: null
  },
  factsheet: {
    pricePerformance: null,
    tradingStat: null,
    historical: null,
    balance: null,
    income: null,
    cashFlow: null,
    highlightData: null
  }
})

/**
 * Mutations
 */
const mutations = {
  /* CompanyPrice - Price */

  setStockIndustry (state, data) {
    state.price.industry = data
  },

  /* CompanyPrice - Product */

  setStockRelatedProduct (state, data) {
    state.price.relatedProduct = data
  },

  /* CompanyProfile */

  setStockOneReport (state, data) {
    state.realty.oneReport = data
  },
  setStockLatestFullFinancialStatement (state, data) {
    state.realty.latestFullFinancialStatement = data
  },
  setStockAnnualReport (state, data) {
    state.realty.annualReport = data
  },
  setStockForm56Report (state, data) {
    state.realty.form56Report = data
  },
  setStockShareholder (state, data) {
    state.realty.shareholder = data
  },
  setStockEtfCompanyProfile (state, data) {
    state.companyProfile = data
  },
  setStockEtfHighlightData (state, data) {
    state.highlightData = data
  },
  setStockEtfHighlightChart (state, data) {
    state.highlightChart = data
  },
  setEtfFactSheetPricePerformance (state, data) {
    // const result = []
    // const key = Object.keys(data)
    // key.forEach((item) => {
    //   if (item === 'stockPerformance' || item === 'underlyingPerformance') {
    //     result.push(data[item])
    //   }
    // })
    // console.logf('result=>>', result)
    state.factsheet.pricePerformance = data
  },
  setEtfFactSheetTradingStat (state, data) {
    state.factsheet.tradingStat = data
  },
  setEtfFactSheetHistorical (state, data) {
    state.factsheet.historical = data
  },
  setEtfFactSheetHighlightData (state, data) {
    state.factsheet.highlightData = data
  },
  setEtfFactSheetFinancial (state, { balanceSheet, incomeStatement, cashFlow }) {
    state.factsheet.balance = balanceSheet
    state.factsheet.income = incomeStatement
    state.factsheet.cashFlow = cashFlow
  }
}

/**
 * Actions
 */
const actions = {
  async searchSymbol ({ dispatch }, payload) {
    await Promise.allSettled([
      dispatch('quote/getStockIntradayChart', payload, { root: true }),
      dispatch('quote/getStockIndexList', payload, { root: true }),
      dispatch('quote/getStockHighlightData', payload, { root: true }),
      dispatch('quote/getStockFinancialDataChart', payload, { root: true }),
      dispatch('getStockEtfHighlightData', payload),
      dispatch('getStockEtfHighlightChart', payload),
      dispatch('quote/getStockKeyFinancialData', payload, { root: true }),
      dispatch('quote/getStockNewsList', payload, { root: true }),
      dispatch('quote/getStockOtherNewsList', payload, { root: true }),
      dispatch('quote/getStockUpcomingEvent', payload, { root: true }),
      dispatch('quote/getStockHistoricalTrading', payload, { root: true }),
      dispatch('getStockEtfCompanyProfile', payload)
    ])
  },

  async getFactSheet ({ dispatch }, payload) {
    await Promise.allSettled([
      dispatch('quote/getStockIntradayChart', payload, { root: true }),
      dispatch('quote/getStockPricePerformanceChart', payload, { root: true }),
      dispatch('quote/getStockIndexList', payload, { root: true }),
      dispatch('quote/getStockInfo', payload, { root: true }),
      dispatch('getStockEtfCompanyProfile', payload),
      dispatch('getStockShareholder', payload),
      dispatch('getEtfFactSheetHighlightData', payload),
      dispatch('getEtfFactSheetPricePerformance', payload),
      dispatch('getEtfFactSheetTradingStat', payload),
      dispatch('getEtfFactSheetHistorical', payload),
      dispatch('getEtfFactSheetFinancial', payload)

    ])
  },

  /* CompanyPrice - Product */

  async getStockRelatedProduct ({ commit }, payload) {
    const { quote } = payload
    try {
      const relatedProduct = await this.$axios.$get(
        `/api/set/stock/${quote}/related-product/o`,
        {
          params: { lang: this.$i18n.locale }
        }
      )

      const setRelatedStockData = {
        ...relatedProduct,
        relatedProducts: relatedProduct.relatedProducts.filter(
          item => item.symbol.toLowerCase() !== quote.toLowerCase()
        )
      }

      commit('setStockRelatedProduct', setRelatedStockData)
    } catch (err) {
      commit('setStockRelatedProduct', null)
    }
  },

  /* CompanyProfile */

  // async getStockReally ({ dispatch }, payload) {
  //   await Promise.allSettled([
  //     dispatch('quote/getStockCompanyProfile', payload, { root: true }),
  //     dispatch('getStockLatestFullFinancialStatement', payload),
  //     dispatch('getStockAnnualReport', payload)
  //   ])
  // },

  async getStockLatestFullFinancialStatement ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/financialstatement/latest-full-financialstatement?lang=${this.$i18n.locale}`
      )
      commit('setStockLatestFullFinancialStatement', response)
    } catch (err) {
      commit('setStockLatestFullFinancialStatement', null)
    }
  },
  // async getStockOneReport ({ commit }, payload) {
  //   const { quote } = payload
  //   try {
  //     const response = await this.$axios.$get(
  //       `/api/set/company/${quote}/report/one`,
  //       {
  //         params: { lang: this.$i18n.locale }
  //       }
  //     )
  //     commit('setStockOneReport', response)
  //   } catch (err) {
  //     commit('setStockOneReport', null)
  //   }
  // },
  async getStockAnnualReport ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/company/${quote}/report/annual`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setStockAnnualReport', response)
    } catch (err) {
      commit('setStockAnnualReport', null)
    }
  },
  async getStockForm56Report ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/company/${quote}/report/form56`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setStockForm56Report', response)
    } catch (err) {
      commit('setStockForm56Report', null)
    }
  },
  async getStockShareholder ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/shareholder`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setStockShareholder', response)
    } catch (err) {
      commit('setStockShareholder', null)
    }
  },
  async getStockEtfCompanyProfile ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/etf/${quote}/profile`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setStockEtfCompanyProfile', response)
    } catch (err) {
      commit('setStockEtfCompanyProfile', null)
    }
  },
  async getStockEtfHighlightData ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/etf/${quote}/highlight-data`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setStockEtfHighlightData', response)
    } catch (err) {
      commit('setStockEtfHighlightData', null)
    }
  },
  async getStockEtfHighlightChart ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/company-highlight/trading-stat?adjustedPrice=true`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setStockEtfHighlightChart', response)
    } catch (err) {
      commit('setStockEtfHighlightChart', null)
    }
  },
  async getEtfFactSheetPricePerformance ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/etf/${quote}/price-performance`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setEtfFactSheetPricePerformance', response)
    } catch (err) {
      commit('setEtfFactSheetPricePerformance', null)
    }
  },
  async getEtfFactSheetTradingStat ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/factsheet/${quote}/trading-stat`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setEtfFactSheetTradingStat', response)
    } catch (err) {
      commit('setEtfFactSheetTradingStat', null)
    }
  },
  async getEtfFactSheetHistorical ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/corporate-action/historical?caType=XD&lang=${this.$i18n.locale}`
      )
      commit('setEtfFactSheetHistorical', response)
    } catch (err) {
      commit('setEtfFactSheetHistorical', null)
    }
  },
  async getEtfFactSheetFinancial ({ commit }, payload) {
    const { quote } = payload
    try {
      const balance = await this.$axios.$get(
        `/api/set/factsheet/${quote}/financialstatement?accountType=balance_sheet`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      const income = await this.$axios.$get(
        `/api/set/factsheet/${quote}/financialstatement?accountType=income_statment`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      const cashflow = await this.$axios.$get(
        `/api/set/factsheet/${quote}/financialstatement?accountType=cash_flow`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      const [
        { value: balanceSheet },
        { value: incomeStatement },
        { value: cashFlow }
      ] = await Promise.allSettled([
        balance,
        income,
        cashflow
      ])
      commit('setEtfFactSheetFinancial', {
        balanceSheet,
        incomeStatement,
        cashFlow
      })
    } catch (err) {
      commit('setEtfFactSheetFinancial', null)
    }
  },
  async getEtfFactSheetHighlightData ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/factsheet/${quote}/highlight-data`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setEtfFactSheetHighlightData', response)
    } catch (err) {
      commit('setEtfFactSheetHighlightData', null)
    }
  }
}

/**
 * Getters
 */
const getters = {

  /* CompanyPrice - Product */

  getStockRelatedProduct: (state) => {
    return state.price.relatedProduct
      ? state.price.relatedProduct.relatedProducts
      : []
  },
  getStockEtfHighlightData: (state) => {
    return state.highlightData
  },
  getStockEtfHighlightChart: (state) => {
    return state.highlightChart
  },
  /* CompanyProfile */
  getStockEtfCompanyProfile: (state) => {
    return state.companyProfile
  },
  // getStockOneReport: (state) => {
  //   return state.realty.oneReport
  // },
  getStockLatestFullFinancialStatement: (state) => {
    return state.realty.latestFullFinancialStatement
  },
  getStockAnnualReport: (state) => {
    return state.realty.annualReport
  },
  getStockForm56Report: (state) => {
    return state.realty.form56Report
  },
  getStockShareholder: (state) => {
    return state.realty.shareholder
  },
  /* factsheet */
  getEtfFactSheetPricePerformance: (state) => {
    return state.factsheet.pricePerformance
  },
  getEtfFactSheetTradingStat: (state) => {
    return state.factsheet.tradingStat
  },
  getEtfFactSheetHistorical: (state) => {
    return state.factsheet.historical
  },
  getEtfFactSheetFinancialBalance: (state) => {
    return state.factsheet.balance
  },
  getEtfFactSheetFinancialIncome: (state) => {
    return state.factsheet.income
  },
  getEtfFactSheetFinancialCashFlow: (state) => {
    return state.factsheet.cashFlow
  },
  getEtfFactSheetHighlightData: (state) => {
    return state.factsheet.highlightData
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
