/**
 * State
 */
const state = () => ({
  price: {
    industry: null,
    relatedProductStock: null,
    relatedProductDW: null,
    relatedProductWarrant: null,
    relatedProductTFEX: null
  },
  news: {
    newsInfoList: null
  },
  financial: {
    balanceSheet: null,
    incomeStatement: null,
    cashFlow: null,
    financialHighlightData: null,
    tradingstatData: null,
    lastestFinancial: null
  },
  realty: {
    oneReport: [],
    annualReport: [],
    form56Report: [],
    boardDirector: null,
    shareholder: null,
    shareholderNvdr: null,
    companySnapshot: null,
    companyOppdayUpcoming: null,
    companyOppdayArchive: null
  }
})

/**
 * Mutations
 */
const mutations = {
  /* CompanyPrice - Price */

  setStockIndustry (state, data) {
    state.price.industry = data
  },

  /* CompanyPrice - Product */

  setStockRelatedProduct (
    state,
    [relatedStockData, relatedDWData, relatedWarrantData, relatedTFEXData]
  ) {
    state.price.relatedProductStock = relatedStockData
    state.price.relatedProductDW = relatedDWData
    state.price.relatedProductWarrant = relatedWarrantData
    state.price.relatedProductTFEX = relatedTFEXData
  },

  /* CompanyFinancial */

  setStockFinancialStatement (
    state,
    { balanceSheet, incomeStatement, cashFlow }
  ) {
    state.financial.balanceSheet = balanceSheet
    state.financial.incomeStatement = incomeStatement
    state.financial.cashFlow = cashFlow
  },
  setStockFinancialBalance (state, data) {
    state.financial.balanceSheet = data
  },
  setFinancialHighlightData (state, data) {
    state.financial.financialHighlightData = data
  },

  setTradingstatData (state, data) {
    state.financial.tradingstatData = data
  },

  /* CompanyNews */

  setStockNewsInfoList (state, data) {
    state.news.newsInfoList = data
  },

  /* CompanyProfile */

  setStockOneReport (state, data) {
    state.realty.oneReport = data
  },

  setStockAnnualReport (state, data) {
    state.realty.annualReport = data
  },

  setStockForm56Report (state, data) {
    state.realty.form56Report = data
  },

  seStockBoardDirector (state, data) {
    state.realty.boardDirector = data
  },

  setStockShareholder (state, data) {
    state.realty.shareholder = data
  },

  setStockShareholderNvdr (state, data) {
    state.realty.shareholderNvdr = data
  },

  setStockCompanySnapshot (state, data) {
    state.realty.companySnapshot = data
  },

  setStockCompanyOppdayUpcoming (state, data) {
    state.realty.companyOppdayUpcoming = data
  },

  setStockCompanyOppdayArchive (state, data) {
    state.realty.companyOppdayArchive = data
  },

  setStocklastestFinancial (state, data) {
    state.financial.lastestFinancial = data
  }
}

/**
 * Actions
 */
const actions = {
  async searchSymbol ({ dispatch }, payload) {
    // dispatch('quote/clearState', null, { root: true })
    await Promise.allSettled([
      dispatch('quote/getStockIndexList', payload, { root: true }),
      dispatch('quote/getStockIntradayChart', payload, { root: true }),
      dispatch('quote/getStockHighlightData', payload, { root: true }),
      dispatch('quote/getStockFinancialDataChart', payload, { root: true }),
      dispatch('quote/getStockKeyFinancialData', payload, { root: true }),
      dispatch('quote/getStockNewsList', payload, { root: true }),
      dispatch('quote/getStockProfile', payload, { root: true }),
      dispatch('quote/getStockCompanyProfile', payload, { root: true }),
      // dispatch('quote/getStockUpcomingEvent', payload, { root: true }),
      dispatch('quote/getStockHistoricalTrading', payload, { root: true })
    ])
  },

  async searchSymbolOtherType ({ dispatch }, payload) {
    await Promise.allSettled([
      dispatch('quote/getStockIndexList', payload, { root: true }),
      dispatch('quote/getStockIntradayChart', payload, { root: true }),
      dispatch('quote/getStockHighlightData', payload, { root: true }),
      // dispatch('quote/getStockNewsList', payload, { root: true }),
      dispatch('quote/getStockCompanyProfile', payload, { root: true }),
      dispatch('quote/getStockProfile', payload, { root: true }),
      // dispatch('quote/getStockUpcomingEvent', payload, { root: true }),
      dispatch('quote/getStockHistoricalTrading', payload, { root: true })
    ])
  },

  /* CompanyPrice - Price */

  async getStockIndustry ({ commit }, payload) {
    const { index } = payload
    try {
      const response = await this.$axios.$get(`/api/set/index/${index}/info`, {
        params: { lang: this.$i18n.locale }
      })
      commit('setStockIndustry', response)
    } catch (err) {
      commit('setStockIndustry', null)
    }
  },

  /* CompanyPrice - Product */

  async getStockRelatedProduct ({ commit }, payload) {
    const { quote } = payload
    try {
      const relatedStock = this.$axios.$get(
        `/api/set/stock/${quote}/related-product/o`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      const relatedDW = this.$axios.$get(
        `/api/set/stock/${quote}/related-product/v`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      const relatedWarrant = this.$axios.$get(
        `/api/set/stock/${quote}/related-product/w`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      const relatedTFEX = this.$axios.$get(
        `/api/set/stock/${quote}/related-product/sf`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      const [
        { value: relatedStockData },
        { value: relatedDWData },
        { value: relatedWarrantData },
        { value: relatedTFEXData }
      ] = await Promise.allSettled([
        relatedStock,
        relatedDW,
        relatedWarrant,
        relatedTFEX
      ])
      const setRelatedStockData = {
        ...relatedStockData,
        relatedProducts: relatedStockData.relatedProducts.filter(
          item => item.symbol.toLowerCase() !== quote.toLowerCase()
        )
      }
      commit('setStockRelatedProduct', [
        setRelatedStockData,
        relatedDWData,
        relatedWarrantData,
        relatedTFEXData
      ])
    } catch (err) {
      commit('setStockRelatedProduct', null)
    }
  },

  /* CompanyFinancial */
  async getStockFinancialBalance ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/financialstatement`,
        {
          params: { accountType: 'balance_sheet', lang: this.$i18n.locale }
        }
      )
      commit('setStockFinancialBalance', response)
    } catch (err) {
      commit('setStockFinancialBalance', null)
    }
  },
  async getStockFinancialStatement ({ commit }, payload) {
    const { quote } = payload
    try {
      const balanceSheetAPI = this.$axios.$get(
        `/api/set/stock/${quote}/financialstatement`,
        {
          params: { accountType: 'balance_sheet', lang: this.$i18n.locale }
        }
      )
      const incomeStatementAPI = this.$axios.$get(
        `/api/set/stock/${quote}/financialstatement`,
        {
          params: {
            accountType: 'income_statement',
            lang: this.$i18n.locale
          }
        }
      )
      const cashFlowAPI = this.$axios.$get(
        `/api/set/stock/${quote}/financialstatement`,
        {
          params: { accountType: 'cash_flow', lang: this.$i18n.locale }
        }
      )
      const [
        { value: balanceSheet },
        { value: incomeStatement },
        { value: cashFlow }
      ] = await Promise.allSettled([
        balanceSheetAPI,
        incomeStatementAPI,
        cashFlowAPI
      ])
      commit('setStockFinancialStatement', {
        balanceSheet,
        incomeStatement,
        cashFlow
      })
    } catch (err) {}
  },

  async getFinancialHighlightData ({ commit }, payload) {
    await this.$axios
      .get(`api/set/stock/${payload.quote}/company-highlight/financial-data`, {
        params: { lang: this.$i18n.locale }
      })
      .then((res) => {
        commit('setFinancialHighlightData', res.data)
      })
      .catch(err => err)
  },

  async getTradingstatData ({ commit }, payload) {
    await this.$axios
      .get(`api/set/stock/${payload.quote}/company-highlight/trading-stat`, {
        params: { lang: this.$i18n.locale }
      })
      .then((res) => {
        commit('setTradingstatData', res.data)
      })
      .catch(err => err)
  },

  /* CompanyNews */

  async getStockNewsSearch ({ commit }, payload) {
    // commit('setStockNewsInfoList', null)
    const { symbol, perPage, page, fromDate, toDate, keyword } = payload
    try {
      const response = await this.$axios.$get('api/set/news/search', {
        params: {
          symbol,
          perPage,
          page,
          fromDate,
          toDate,
          keyword,
          lang: this.$i18n.locale
        }
      })
      if (response && response.newsInfoList) {
        commit('setStockNewsInfoList', response.newsInfoList)
        // return response.newsInfoList
      }
    } catch (err) {
      commit('setStockNewsInfoList', null)
      // return []
    }
  },

  /* CompanyProfile */

  async getStockReallyProfile ({ dispatch }, payload) {
    await Promise.allSettled([
      dispatch('quote/getStockCompanyProfile', payload, { root: true }),
      dispatch('quote/getStockProfile', payload, { root: true }),
      dispatch('getStockOneReport', payload),
      dispatch('getStockAnnualReport', payload),
      dispatch('getStockForm56Report', payload)
    ])
  },

  async getStockOneReport ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/company/${quote}/report/one`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setStockOneReport', response)
    } catch (err) {
      commit('setStockOneReport', null)
    }
  },

  async getStockAnnualReport ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/company/${quote}/report/annual`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setStockAnnualReport', response)
    } catch (err) {
      commit('setStockAnnualReport', null)
    }
  },

  async getStockForm56Report ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/company/${quote}/report/form56`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setStockForm56Report', response)
    } catch (err) {
      commit('setStockForm56Report', null)
    }
  },

  async getStockBoardDirector ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/company/${quote}/board-of-director`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('seStockBoardDirector', response)
    } catch (err) {
      commit('seStockBoardDirector', null)
    }
  },

  async getStockShareholder ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/shareholder`,
        {
          params: { lang: this.$i18n.locale }
        }
      )

      const transformed = response
        ? {
            bookCloseDate: response.bookCloseDate,
            caType: response.caType,
            freeFloat: response.freeFloat,
            majorShareholders:
              response.majorShareholders &&
              response.majorShareholders.length > 0
                ? response.majorShareholders.sort(
                  (first, second) =>
                    second.percentOfShare - first.percentOfShare
                )
                : response.majorShareholders,
            percentScriptless: response.percentScriptless,
            symbol: response.symbol,
            totalShareholder: response.totalShareholder
          }
        : null

      const data = transformed
        ? {
            bookCloseDate: response.bookCloseDate,
            caType: response.caType,
            freeFloat: response.freeFloat,
            majorShareholders: response.majorShareholders && response.majorShareholders.length > 0 && response.majorShareholders.length > 10 ? response.majorShareholders : response.majorShareholders,
            percentScriptless: response.percentScriptless,
            symbol: response.symbol,
            totalShareholder: response.totalShareholder
          }
        : transformed

      commit('setStockShareholder', data)
    } catch (err) {
      commit('setStockShareholder', null)
    }
  },

  async getStockShareholderNvdr ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/nvdr-holder`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setStockShareholderNvdr', response)
    } catch (err) {
      commit('setStockShareholderNvdr', null)
    }
  },

  async getStockCompanySnapshot ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/company-snapshot?lang=${this.$i18n.locale}`
      )
      commit('setStockCompanySnapshot', response)
    } catch (err) {
      commit('setStockCompanySnapshot', null)
    }
  },

  async getStockCompanyOppdayUpcoming ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set-content/company-oppday/${quote}?status=UPCOMING&lang=${this.$i18n.locale}`
      )
      if (response) {
        commit('setStockCompanyOppdayUpcoming', response[0])
      }
    } catch (err) {
      commit('setStockCompanyOppdayUpcoming', null)
    }
  },

  async getStockCompanyOppdayArchive ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set-content/company-oppday/${quote}?status=ARCHIVE&lang=${this.$i18n.locale}`
      )
      commit('setStockCompanyOppdayArchive', response)
    } catch (err) {
      commit('setStockCompanyOppdayArchive', null)
    }
  },
  async getStocklastestFinancial ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/financialstatement/latest-full-financialstatement`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setStocklastestFinancial', response)
    } catch (err) {
      commit('setStocklastestFinancial', null)
    }
  }
}

/**
 * Getters
 */
const getters = {
  /* CompanyPrice - Price */

  getStockIndustry: (state) => {
    return state.price.industry
  },

  /* CompanyPrice - Product */

  getStockRelatedProductStock: (state) => {
    return state.price.relatedProductStock
      ? state.price.relatedProductStock.relatedProducts
      : []
  },

  getStockRelatedProductDW: (state) => {
    return state.price.relatedProductDW ? state.price.relatedProductDW : {}
  },

  getStockRelatedProductWarrant: (state) => {
    return state.price.relatedProductWarrant
      ? state.price.relatedProductWarrant.relatedProducts
      : []
  },

  getStockRelatedProductTFEX: (state) => {
    return state.price.relatedProductTFEX
      ? state.price.relatedProductTFEX.relatedProducts
      : []
  },

  /* CompanyFinancial */
  getStocklastestFinancial (state, data) {
    return state.financial.lastestFinancial
  },

  getStockFinancialStatement: (state) => {
    return state.financial
  },

  getFinancialHighlightData: (state) => {
    return state.financial.financialHighlightData
  },

  getTradingstatData: (state) => {
    return state.financial.tradingstatData
  },

  /* CompanyNews */

  getStockNewsSearch: (state) => {
    return state.news.newsInfoList || []
  },

  /* CompanyProfile */

  getStockOneReport: (state) => {
    return state.realty.oneReport
  },

  getStockAnnualReport: (state) => {
    return state.realty.annualReport
  },

  getStockForm56Report: (state) => {
    return state.realty.form56Report
  },

  getStockBoardDirector: (state) => {
    return state.realty.boardDirector
  },

  getStockShareholder: (state) => {
    return state.realty.shareholder
  },

  getStockShareholderNvdr: (state) => {
    return state.realty.shareholderNvdr
  },

  getStockCompanySnapshot: (state) => {
    return state.realty.companySnapshot || []
  },

  getStockCompanyOppdayUpcoming: (state) => {
    return state.realty.companyOppdayUpcoming
  },

  getStockCompanyOppdayArchive: (state) => {
    return state.realty.companyOppdayArchive || []
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
