//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
export default {
  name: 'MiscModal',
  props: {
    quoteModal: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      category: ''
    }
  },
  computed: {
    ...mapGetters({
      stockList: 'setmember/favorite/getStock',
      etfList: 'setmember/favorite/getETF',
      dwList: 'setmember/favorite/getDW',
      drList: 'setmember/favorite/getDR',
      drxList: 'setmember/favorite/getDRx',
      categoryOption: 'setmember/favorite/getCategoryOption'
    })
  },
  watch: {
    quoteModal () {
      if (this.displayModalAlert()) {
        this.$refs.divModalAddQuote.openModal()
      } else {
        this.openModalLimitFavorite(this.category)
      }
    }
  },
  methods: {
    openModalLimitFavorite (category) {
      // this.$refs.modalLimitFavoriteStock.show()
      const { Modal } = require('bootstrap')
      const myModalEl = document.querySelector('#modalLimitFavoriteStock2')
      const modal = Modal.getOrCreateInstance(myModalEl)
      modal.show({ content: category })
    },
    displayModalAlert () {
      if ((this.categoryOption === 'หุ้น' || this.categoryOption === 'Stock') && this.stockList.length >= 20) {
        this.category = 'stock'
        return false
      }
      if (this.categoryOption === 'ETF' && this.etfList.length >= 20) {
        this.category = 'etf'
        return false
      }
      if (this.categoryOption === 'DR' && this.drList.length >= 20) {
        this.category = 'dr'
        return false
      }
      if (this.categoryOption === 'DRx' && this.drxList.length >= 20) {
        this.category = 'drx'
        return false
      }
      if (this.categoryOption === 'DW' && this.dwList.length >= 20) {
        this.category = 'dw'
        return false
      }
      return true
    }
  }
}
