/**
 * State
 */

const state = () => ({
  favorite: {
    stock: [],
    etf: [],
    dw: [],
    dr: [],
    drx: []
  },
  closeModal: 0,
  favoriteDw: [],
  dwInfoListBySymbols: [],
  categoryOption: 'หุ้น',
  etfInfoListBySymbols: []
})

/**
 * Mutations
 */
const mutations = {
  setFavoriteList (state, data) {
    state.favoriteList = data
  },
  setStock (state, data) {
    state.favorite.stock = data
  },
  setETF (state, data) {
    state.favorite.etf = data
  },
  setDW (state, data) {
    state.favorite.dw = data
  },
  setDR (state, data) {
    state.favorite.dr = data
  },
  setDRx (state, data) {
    state.favorite.drx = data
  },
  setCloseModal (state, data) {
    state.closeModal = state.closeModal + data
  },
  setFavoriteDw (state, data) {
    state.favoriteDw = data
  },
  setdwInfoListBySymbols (state, data) {
    state.dwInfoListBySymbols = data
  },
  setETFInfoListBySymbols (state, data) {
    state.etfInfoListBySymbols = data
  },
  setCategoryOption  (state, data) {
    state.categoryOption = data
  }
}

/**
 * Actions
 */
const actions = {
  async addFavorite ({ commit, state }, data) {
    let result = null

    result = await this.$apiMember
      .post(`/api/gw-api/favorite/${data.category}/${data.symbols}`)
      .then((res) => {
        if (res && res.status === 201) {
          if (data.category === 'stock') {
            const _List = JSON.parse(JSON.stringify(state.favorite.stock))
            _List.push(data.symbols)
            commit('setStock', _List)
            localStorage.setItem('setStock', _List)
          } else if (data.category === 'etf') {
            const _List = JSON.parse(JSON.stringify(state.favorite.etf))
            _List.push(data.symbols)
            commit('setETF', _List)
            localStorage.setItem('setETF', _List)
          } else if (data.category === 'dw') {
            const _List = JSON.parse(JSON.stringify(state.favorite.dw))
            _List.push(data.symbols)
            commit('setDW', _List)
            localStorage.setItem('setDW', _List)
          } else if (data.category === 'dr') {
            const _List = JSON.parse(JSON.stringify(state.favorite.dr))
            _List.push(data.symbols)
            commit('setDR', _List)
            localStorage.setItem('setDR', _List)
          } else if (data.category === 'drx') {
            const _List = JSON.parse(JSON.stringify(state.favorite.drx))
            _List.push(data.symbols)
            commit('setDRx', _List)
            localStorage.setItem('setDRx', _List)
          }
        }
        return res
      })
      .catch(err => err)
    // console.log('result==>>', result)
    return result
  },
  async delFavorite ({ commit, state }, data) {
    const resData = await this.$apiMember
      .delete(`/api/gw-api/favorite/${data.category}/${data.symbols}`)
      .then((res) => {
        if (res && res.status === 202) {
          const quoteType = data.category
          let list = []
          let index = null
          switch (quoteType) {
            case 'stock':
              list = JSON.parse(JSON.stringify(state.favorite.stock))
              index = list.indexOf(data.symbols)
              list.splice(index, 1)
              commit('setStock', list)
              localStorage.setItem('setStock', list)
              // console.log('State : ', state.favorite.stock)
              break
            case 'etf':
              list = JSON.parse(JSON.stringify(state.favorite.etf))
              index = list.indexOf(data.symbols)
              list.splice(index, 1)
              commit('setETF', list)
              localStorage.setItem('setETF', list)
              break
            case 'dr':
              list = JSON.parse(JSON.stringify(state.favorite.dr))
              index = list.indexOf(data.symbols)
              list.splice(index, 1)
              commit('setDR', list)
              localStorage.setItem('setDR', list)
              break
            case 'drx':
              list = JSON.parse(JSON.stringify(state.favorite.drx))
              index = list.indexOf(data.symbols)
              list.splice(index, 1)
              commit('setDRx', list)
              localStorage.setItem('setDRx', list)
              break
            case 'dw':
              list = JSON.parse(JSON.stringify(state.favorite.dw))
              index = list.indexOf(data.symbols)
              list.splice(index, 1)
              commit('setDW', list)
              localStorage.setItem('setDW', list)
              break
          }
        }
        return res
      })
      .catch(err => err)
    return resData
  },
  async getStock ({ commit }) {
    const { $apiMember } = this
    const response = await getFavouriteByCategory({ $apiMember }, 'stock')
    commit('setStock', response)
  },
  async getETF ({ commit }) {
    const { $apiMember } = this
    const response = await getFavouriteByCategory({ $apiMember }, 'etf')
    commit('setETF', response)
  },

  async getDW ({ commit }) {
    const { $apiMember } = this
    const response = await getFavouriteByCategory({ $apiMember }, 'dw')
    commit('setDW', response)
  },

  async getDR ({ commit }) {
    const { $apiMember } = this
    const response = await getFavouriteByCategory({ $apiMember }, 'dr')
    commit('setDR', response)
  },

  async getDRx ({ commit }) {
    const { $apiMember } = this
    const response = await getFavouriteByCategory({ $apiMember }, 'drx')
    commit('setDRx', response)
  },
  setCategoryOption ({ commit }, data) {
    commit('setCategoryOption', data)
  },
  setCloseModal ({ commit, state }) {
    const number = 1
    commit('setCloseModal', number)
  },

  async getFavoriteDw ({ commit, dispatch }) {
    await this.$apiMember.get('/api/gw-api/favorite/dw/price')
      .then((response) => {
        if (response.status === 200) {
          const data = response.data
          commit('setFavoriteDw', data)
          data.favorites.forEach((item, index) => {
            dispatch('quote/DW/overview/getDwMostActiveValueIntradayChart', { quote: item.symbol }, { root: true })
          })
        }
      })
      .catch(err => err)
  },
  async getdwInfoListBySymbols ({ commit, dispatch }, payload) {
    const { dwList } = payload
    try {
      const response = await this.$axios.$get(`api/set/dw-info/list-by-symbols?symbols=${dwList}`)
      const data = response
      commit('setdwInfoListBySymbols', data)
      data.dwList.forEach((item, index) => {
        dispatch('quote/DW/overview/getDwMostActiveValueIntradayChart', { quote: item.symbol }, { root: true })
      })
    } catch (err) {
      commit('setdwInfoListBySymbols', null)
    }
  },
  async getETFInfoListBySymbols ({ commit }, payload) {
    const { symbolsList } = payload
    try {
      const response = await this.$axios.$get(`api/set/etf/search?symbols=${symbolsList}`)
      // console.log('setETFInfoListBySymbols', response)
      commit('setETFInfoListBySymbols', response)
    } catch (err) {
      commit('setETFInfoListBySymbols', [])
    }
  }
}
function getFavouriteByCategory ({ $apiMember }, category) {
  return $apiMember.$get(`/api/gw-api/favorite/${category}`)
    .then(result => result)
    .catch(_error => ([]))
}
const getters = {
  getFavoriteSymbols: (state) => {
    return state.favorite
  },
  getStock: (state) => {
    return state.favorite.stock || []
  },
  getETF: (state) => {
    return state.favorite.etf || []
  },
  getDW: (state) => {
    return state.favorite.dw || []
  },
  getDR: (state) => {
    return state.favorite.dr || []
  },
  getDRx: (state) => {
    return state.favorite.drx || []
  },
  getCloseModal: (state) => {
    return state.closeModal
  },
  getFavoriteDw: (state) => {
    return state.favoriteDw
  },
  getdwInfoListBySymbols: (state) => {
    return state.dwInfoListBySymbols
  },
  getETFInfoListBySymbols (state, data) {
    state.etfInfoListBySymbols = data
  },
  getCategoryOption: (state) => {
    return state.categoryOption
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
