import { render, staticRenderFns } from "./dynamic.vue?vue&type=template&id=51c7dfc8&"
import script from "./dynamic.vue?vue&type=script&lang=js&"
export * from "./dynamic.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MarketSummary: require('/app/components/MarketSummary.vue').default,LocaleLanguage: require('/app/components/LocaleLanguage.vue').default,Header: require('/app/components/Header.vue').default,BannerLocationFooter: require('/app/components/Banner/Location/Footer.vue').default,Footer: require('/app/components/Footer/Footer.vue').default,AdvertisementLightbox: require('/app/components/Advertisement/Lightbox.vue').default,ChatBotZwiz: require('/app/components/ChatBotZwiz/index.vue').default,MyQuote: require('/app/components/MyQuote/MyQuote.vue').default,Pdpa: require('/app/components/Pdpa/Pdpa.vue').default,MiscModal: require('/app/components/Misc/Modal/index.vue').default})
