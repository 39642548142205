import { render, staticRenderFns } from "./AccordionMenu.vue?vue&type=template&id=03a2c22b&"
import script from "./AccordionMenu.vue?vue&type=script&lang=js&"
export * from "./AccordionMenu.vue?vue&type=script&lang=js&"
import style0 from "./AccordionMenu.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconArrowDown: require('/app/components/Icon/ArrowDown.vue').default,Button: require('/app/components/Button/Button.vue').default,Picture: require('/app/components/Picture/Picture.vue').default,ContentLazy: require('/app/components/ContentLazy.vue').default})
