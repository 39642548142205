const state = () => ({
  securityChangeList: null
})

const actions = {
  async fetchSecurityChangeList ({ commit }, payload) {
    // const apiPath1 = `/api/gw-api/tsd/security-change/list?secNameEng=${payload.nameENG}&secNameThai=${payload.nameTH}&securityAbbr=${payload.symbol}&secTypeID=${secType}`
    const { nameENG, nameTH, symbol, secType } = payload
    const _secType = secType === 'all' ? '' : secType
    const apiPath = '/api/gw-api/tsd/security-change/list'
    const option = {
      params: {
        secNameEng: nameENG,
        secNameThai: nameTH,
        securityAbbr: symbol,
        secTypeID: _secType
      }
    }
    await this.$axios.$get(apiPath, option).then((res) => {
      commit('setSecurityChangeList', res)
      return res
    }).catch(err => err)
  },

  async fetchSecurityChangeNameDetail ({ commit }, symbol) {
    const resData = await this.$axios.$get(`api/gw-api/tsd/security-change/${symbol}/detail`).then((res) => {
      return res
    })

    return resData
  }
}

const mutations = {
  setSecurityChangeList (state, data) {
    state.securityChangeList = data
  }
}

const getters = {
  getSecurityChangeList (state) {
    return state.securityChangeList
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
