//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'IconChevronLeft'
  // serverCacheKey: () => 'IconChevronLeft-v1'
}
