/**
 * State
 */
const state = () => ({
  loading: false,
  stateType: 'SEARCH',
  paramSearch: {},
  paramClosingDate: {},
  paramCalculator: {},
  selectedSymbol: {},
  symbolLookupData: null,
  bookClosingDateData: [],
  calculateData: null
})

/**
 * Mutations
 */
const mutations = {
  setSymbolLookupData (state, data) {
    state.symbolLookupData = data
  },
  setBookClosingDateData (state, data) {
    state.bookClosingDateData = data
  },
  setCalculateData (state, data) {
    state.calculateData = data
  },
  setStateType (state, data) {
    state.stateType = data
  },
  setSelectedSymbol (state, data) {
    state.selectedSymbol = data
  },
  setLoading (state, data) {
    state.loading = data
  }
}

/**
 * Actions
 */
const actions = {
  setSymbolLookupData ({ commit }, data) {
    commit('setSymbolLookupData', data)
  },
  setBookClosingDateData ({ commit }, data) {
    commit('setBookClosingDateData', data)
  },
  setCalculateData ({ commit }, data) {
    commit('setCalculateData', data)
  },
  setStateType ({ commit }, data) {
    commit('setStateType', data)
  },
  setSelectedSymbol ({ commit }, data) {
    commit('setSelectedSymbol', data)
  },
  async getSearchSymbol ({ commit, dispatch, state }, payload) {
    commit('setLoading', true)
    try {
      const apiPath = 'api/gw-api/tsd/rights-offering/symbol-lookup'
      const options = {
        params: {
          ...payload
        }
      }
      const response = await this.$axios.$get(apiPath, options)
      commit('setSymbolLookupData', response)
    } catch (error) {
      commit('setSymbolLookupData', null)
    }
    commit('setLoading', false)
  },
  async getCalculate ({ commit, dispatch, state }, payload) {
    commit('setLoading', true)
    try {
      const apiPath = 'api/gw-api/tsd/rights-offering/calculate'
      const options = {
        params: {
          ...payload
        }
      }
      const response = await this.$axios.$get(apiPath, options)
      commit('setCalculateData', response.subscriptions[0])
    } catch (error) {
      commit('setCalculateData', null)
    }
    commit('setLoading', false)
  }
}

/**
 * Getters
 */
const getters = {
  getStateType: (state) => {
    return state.stateType
  },
  getSymbolLookupData: (state) => {
    return state.symbolLookupData
  },
  getBookClosingDateData: (state) => {
    return state.bookClosingDateData || []
  },
  getCalculateData: (state) => {
    return state.calculateData
  },
  getSelectedSymbol: (state) => {
    return state.selectedSymbol
  },
  getLoading: (state) => {
    return state.loading
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
