// import { isValidUrl } from '~/plugins/url-validator'
import { setClientId, isInvalidPath } from '@/helpers'
import isFunctionalPage from '~/helpers/functionalPage'

export default async function ({
  store,
  route,
  $cookies,
  $userAgent,
  redirect,
  i18n
}) {
  try {
    // Validate url
    // const validUrl = await isValidUrl(route.path)
    // if (validUrl === false) {
    //   redirect('/th/error/404')
    // }

    // Check user agent
    /* $userAgent.checkUserAgent() */

    // handle invalid path | example: /th/?fbclid=
    const invalidPath = isInvalidPath(route.fullPath)
    if (invalidPath === true) {
      redirect('/')
    }

    // Some pages do not require content data
    const _isFunctionalPage = isFunctionalPage(route.name)
    if (_isFunctionalPage) { return }

    // Set client id
    if (process.server) {
      const clientUuidFromCookie = $cookies.get('clientUuid')
      setClientId(clientUuidFromCookie).then((uuid) => {
        const { options } = require('@/config/cookies')
        store.commit('setClientId', uuid)
        $cookies.set('clientUuid', uuid, options)
      })
    }

    // const stockListService = store.dispatch('quote/getSearchStockList')

    // const contentService = store.dispatch('contents/getContent', route)

    // await Promise.allSettled([stockListService, contentService])
    if (route.fullPath.includes('/market/index') && !route.fullPath.includes('/gms-exchanges/trading')) {
      await store.dispatch('contents/getContentMarketIndex', route)
    } else if (route.fullPath.includes('/information/member-list')) {
      await store.dispatch('contents/getContentMemberList', route)
    } else {
      await store.dispatch('contents/getContent', {
        ...route,
        redirect
      })
    }
  } catch (error) {
  }
}
