const httpStatus = require('http-status')
const logger = require('./logger')

/**
 * error handler
 * @param {*} error
 */
const handler = (error) => {
  logger.error(error)
  const response = error.response || {}
  const status = response.status || error.status || httpStatus.SERVICE_UNAVAILABLE
  const message = response.data || error.message || httpStatus[status]
  return {
    status,
    message
  }
}

module.exports.handler = handler
