// import dayjs from 'dayjs'
import { dayjs } from '@/helpers/dateTime'
/**
 * State
 */
const state = () => ({
  stockCalendar: {
    xCalendar: [],
    meetingCalendar: [],
    holidayCalendar: []
  },
  eventCalendar: [],
  eventOppDay: []
})

/**
 * Mutations
 */
const mutations = {
  setStockCalendar (state, [xCalendarData, meetingCalendarData, holidayCalendarData]) {
    state.stockCalendar.xCalendar = xCalendarData
    state.stockCalendar.meetingCalendar = meetingCalendarData
    state.stockCalendar.holidayCalendar = holidayCalendarData
  },
  setEventCalendar (state, data) {
    if (data) {
      let date = data.map(e => e.startDateTime)
      date = date.map((item, index) => {
        return {
          date: item,
          events: data.filter(v => v.startDateTime === item)
        }
      })

      // Mock new data
      let itemIndex = 0
      date.forEach((item) => {
        if (item.events.length > 1) {
          item.events = item.events[itemIndex]
          itemIndex++
        } else {
          itemIndex = 0
          item.events = item.events[itemIndex]
        }
      })

      // date.push({
      //   date: '2023-11-01T19:00:00+07:00',
      //   events: {
      //     eventId: 83957,
      //     eventType: 'activity',
      //     courseCode: 'D230001',
      //     name: 'สัมมนาออนไลน์ หัวข้อ TFEX',
      //     sessionNumber: '0006',
      //     courseType: 'Z',
      //     courseTypeName: 'Public Seminar',
      //     startDateTime: '2023-10-31T00:00:00+07:00',
      //     endDateTime: '2023-10-31T16:00:00+07:00',
      //     startRegistrationDateTime: '2023-09-01T00:00:00+07:00',
      //     endRegistrationDateTime: '2023-09-30T23:59:00+07:00',
      //     publishDate: '2023-09-06T00:00:00+07:00',
      //     fee: null,
      //     venue: 'Online',
      //     totalSeat: 1,
      //     imageUrl: 'https://scm.set.or.th/registration/course/getImage?name=SD230001-0006.png'
      //   }
      // })
      state.eventCalendar = date
    }
  },
  setEventOppDay (state, data) {
    state.eventOppDay = data
  }
}

/**
 * Actions
 */
const actions = {
  async getStockCalendar ({ commit }, payload) {
    const { year, month } = payload
    const xCalendar = this.$axios.$get(
      `/api/set/stock-calendar/${year}/${month}/x-calendar?lang=${this.$i18n.locale}`
    )
    const meetingCalendar = this.$axios.$get(
      `/api/set/stock-calendar/${year}/${month}/meeting?lang=${this.$i18n.locale}`
    )
    const holidayCalendar = this.$axios.$get(
      `/api/set/stock-calendar/${year}/${month}/holiday?lang=${this.$i18n.locale}`
    )
    const [
      { value: xCalendarData },
      { value: meetingCalendarData },
      { value: holidayCalendarData }
    ] = await Promise.allSettled([xCalendar, meetingCalendar, holidayCalendar])
    // calendarArray = [
    //   ...calendarArray
    //     .reduce(
    //       (cur, acc) =>
    //         cur.set(
    //           acc.date,
    //           Object.assign(
    //             cur.get(acc.date) || {
    //               date: null,
    //               types: null,
    //               meetings: null,
    //               description: null
    //             },
    //             acc
    //           )
    //         ),
    //       new Map()
    //     )
    //     .values()
    // ]
    commit('setStockCalendar', [xCalendarData, meetingCalendarData, holidayCalendarData])
  },
  async getEventCalendar ({ commit }) {
    try {
      const fromDate = dayjs().format('DD/MM/YYYY')
      // const toDate = dayjs(new Date(new Date().setMonth(new Date().getMonth() + 6))).format('DD/MM/YYYY')
      const toDate = dayjs().add(6, 'month').format('DD/MM/YYYY')
      const response = await this.$axios.$get(`/api/gw-api/event/public/list?fromDate=${fromDate}&toDate=${toDate}`)
      commit('setEventCalendar', response)
    } catch (e) {
      commit('setEventCalendar', null)
    }
  },
  async getEventOppDay ({ commit }, payload) {
    const { year, month } = payload
    try {
      const response = await this.$axios.$get(`/api/set-content/calendar/${year}/${month}/oppday`)
      commit('setEventOppDay', response)
    } catch (e) {
      commit('setEventOppDay', null)
    }
  }
}

/**
 * Getters
 */
const getters = {
  getStockCalendar: (state) => {
    return state.stockCalendar
  },
  getEventCalendar: (state) => {
    return state.eventCalendar
  },
  getEventOppDay: (state) => {
    return state.eventOppDay
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
