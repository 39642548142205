import isFunctionalPage from '~/helpers/functionalPage'
async function getDataMember () {
  const { dispatch } = this
  await Promise.allSettled([
    dispatch('setmember/favorite/getStock'),
    dispatch('setmember/favorite/getETF'),
    dispatch('setmember/favorite/getDW'),
    dispatch('setmember/favorite/getDR'),
    dispatch('setmember/favorite/getDRx')
  ])
}

// List of route names that do not need stock list.
const pageDoNotNeedStockList = (routeName) => {
  return routeName?.includes('market-news-and-alert-newsdetails_')
}
/**
 * State
 */
const state = () => ({
  clientId: null,
  date: null,
  windowLoaded: false,
  contentDataLayer: {
    currentPath: ''
  }
})

/**
 * Mutations
 */
const mutations = {
  setClientId (state, data) {
    state.clientId = data
  },
  setDate (state, data) {
    state.date = data
  },
  setWindowLoaded (state, data) {
    state.windowLoaded = data
  },
  setContentDataLayerCurrentPath (state, data) {
    state.contentDataLayer.currentPath = data
  }
}

const actions = {
  nuxtServerInit ({ commit }) {
    commit('setDate', new Date())
  },
  async nuxtClientInit ({ commit, dispatch }, context) {
    const { route } = context
    const { name: routeName } = route

    // Some pages do not require data
    const _isFunctionalPage = isFunctionalPage(routeName)
    const doNotNeedStockList = pageDoNotNeedStockList(routeName)
    if (_isFunctionalPage || doNotNeedStockList) { return }

    // if (route.fullPath.includes('/home')) {
    //   dispatch('quote/getSearchStockList')
    // } else {
    // }
    await dispatch('quote/getSearchStockList')
    // await dispatch('quote/getSearchStockList')
    if (!route.fullPath.includes('/authorization')) {
      this.$authUser.authCode()
    }
    if (this.$authUser.permissionUser()) {
      // keep remember Fev Quote
      const favoriteQuoteRemember = this.$cookies.get('favorite-quote-remember')
      // console.log('favoriteQuoteRemember=>', favoriteQuoteRemember)
      if (favoriteQuoteRemember) {
        await dispatch('setmember/favorite/addFavorite', favoriteQuoteRemember)
        this.$cookies.remove('favorite-quote-remember', { path: '/' })
      }
      // await dispatch('setmember/favorite/getStock')
      // await dispatch('setmember/favorite/getETF')
      // await dispatch('setmember/favorite/getDW')
      // await dispatch('setmember/favorite/getDR')
      // await dispatch('setmember/favorite/getDRx')
      await getDataMember.call({ dispatch })
    }
    // await dispatch('auth/setLocalStorage')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
