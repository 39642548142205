//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MarketAlertsBarNewsItem',
  props: {
    date: {
      type: String,
      default: null
    },
    symbol: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    link: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      mouseOver: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.registerEvent()
    })
  },
  beforeDestroy () {
    this.removeEvent()
  },
  methods: {
    handleClick () {
      this.addEventTracking()
    },
    addEventTracking () {
      this.$personalized.clickElement({
        title: `${this.symbol} : ${this.title}`,
        sectionName: 'ตัววิ่งข่าว Market Alert'
      })
    },
    autoRemoveActiveClass () {
      const { isDesktop } = this.$device
      if (isDesktop) {
        return
      }
      setTimeout(() => {
        this.mouseOver = false
      }, 1500)
    },
    registerEvent () {
      const newsItemEl = this.$refs.newsItem
      newsItemEl?.addEventListener('mouseover', this.handleMouseover)
      newsItemEl?.addEventListener('mouseout', this.handleMouseout)
    },
    removeEvent () {
      const newsItemEl = this.$refs.newsItem
      newsItemEl?.removeEventListener('mouseover', this.handleMouseover)
      newsItemEl?.removeEventListener('mouseout', this.handleMouseout)
    },
    handleMouseover () {
      this.mouseOver = true
      this.autoRemoveActiveClass()
    },
    handleMouseout () {
      this.mouseOver = false
    }
  }
}
