//
//
//
//

export default {
  head () {
    const lang = this.$i18n.locale
    const indexTH = require('@/lang/specific-seo/index-th.js').default
    let langDataList = indexTH
    if (lang === 'en') {
      const indexEN = require('@/lang/specific-seo/index-en.js').default
      langDataList = indexEN
    }
    const currentPath = this.$route.path.replace('/' + lang, '')
    const data = langDataList.find(item => item.routePath === currentPath)
    if (data) {
      const { title, description, keywords } = data
      return {
        title,
        meta: [
          { hid: 'description', name: 'description', content: description },
          { name: 'keywords', content: keywords }
        ]
      }
    }
  }
}
