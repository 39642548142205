//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { setAttributeLink } from '@/helpers'
export default {
  name: 'DropdownShortcut',
  data () {
    return {
      open: false,
      menuData: [],
      locationName: 'M_SHORTCUT'
    }
  },
  beforeDestroy () {
    // his.removeEvent()
  },
  methods: {
    setAttributeLink,
    init () {
      // this.registerEvent()
    },
    async toggle () {
      if (this.open === false) {
        await this.setMenuData()
      }
      this.open = !this.open
    },
    registerEvent () {
      const dropdown = this.$refs.dropdown
      if (!dropdown) { return }
      dropdown.addEventListener('click', this.handleClickDropdown)
    },
    removeEvent () {
      const dropdown = this.$refs.dropdown
      if (!dropdown) { return }
      dropdown.removeEventListener('click', this.handleClickDropdown)
    },
    onLeave () {
      this.open = false
    },
    async setMenuData () {
      await this.$content.getMenuByLocation(this.locationName)
        .then((menu) => {
          this.menuData = menu.menuItems || []
        })
        .catch(err => err)

      // const isLogin = await this.$authUser.isLogin()
      // this.menuData = this.menuData.map(item => ({ ...item, url: item.name === 'settrade' ? this.$authUser.singleSignOn(isLogin, '') : item.url })) || []
    },
    handleClickItem (menuTitle, url) {
      if (url && url !== '#') {
        this.$personalized.clickElement({
          name: 'all13',
          optionLang: {
            menuTitle
          }
        })
      }
    }
  }
}
