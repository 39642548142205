const state = () => ({
  StockInfo: null
})

const actions = {
  async countPopular ({ commit }, payload) {
    const apiPath = '/api/cms-w/v1/popularquote/countPopular'
    const options = {
      method: 'post',
      url: apiPath,
      data: payload,
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const data = await this.$apiCms(options)
      .then((res) => {
        return res.data
      })
      .catch(err => err)
    return data
  }
}

const mutations = {
  setStockInfo (state, data) {
    state.StockInfo = data
  }
}

const getters = {
  getStockInfo (state) {
    return state.StockInfo
  }
}

export default {
  actions,
  mutations,
  state,
  getters
}
