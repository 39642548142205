/**
 * State
 */
const state = () => ({
  set_activity_calendar: null
})

/**
 * Mutations
 */
const mutations = {
  /*
   ** set content ActivityCalendar
   */
  setActivityCalendar (state, data) {
    state.set_activity_calendar = data
  }
}

/**
 * Actions
 */
const actions = {
  /**
   * Content CalendarList
   */
  async getSETActivityCalendarList ({ commit, rootState }, payload) {
    const { dayjs } = require('@/helpers/dateTime') // require('dayjs')
    const dateNow = dayjs().$d
    this.dateNow = dateNow.setFullYear(dayjs().year())
    const ed = dayjs().add(6, 'month').$d // new Date(dateNow).setMonth(dateNow.getMonth() + 6)
    await this.$apiCms
      .get(`api/cms/v1/set-events/calendar-list?targetAudience=${'P'}&lang=${this.$i18n.locale}&month=${payload.month}&year=${payload.year}&tabType=${payload.tabType}&pageIndex=${payload.pageIndex - 1}&pageSize=${payload.pageSize}&startDate=${new Date(dateNow).toISOString().slice(0, 10)}&endDate=${new Date(ed).toISOString().slice(0, 10)}`)
      .then((res) => {
        commit('setActivityCalendar', res.data)
      })
      .catch(commit('setActivityCalendar', false))
  }
}

/**
 * Getters
 */
const getters = {
  getCalendarList: (state) => {
    return state.set_activity_calendar || null
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
