//
//
//
//
//
//
//

export default {
  name: 'BannerLocationPushDownTop',
  data () {
    return {
      bannerData: null,
      locationName: 'B_PUSH_DOWN_TOP_BANNER'
    }
  },
  async fetch () {
    await this.setBannerData()
  },
  methods: {
    async setBannerData () {
      await this.$content
        .getBannerByLocation(this.locationName)
        .then((banner) => {
          this.bannerData = banner
        })
        .catch(err => err)
    }
  }
}
