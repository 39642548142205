/* Helper gobal */
/**
 * get only domain
 * @param {String} url
 * @return {String} host name
 */
/**
 * check type quote
 * @param {String} securityType
 * @returns quote string
 */

const getDomainFromURL = (url) => {
  if (!url) {
    return ''
  }
  const d = new URL(url)
  return d.hostname || ''
}
/**
 * Handle invalid path
 * @param {String} path
 * @returns Boolean
 */
const isInvalidPath = (path) => {
  const splitPath = path.split('/')
  let inValid = false
  if (splitPath.length >= 3) {
    inValid = /^\?/i.test(splitPath[2]) || !splitPath[2]
  }
  return inValid
}

/**
 * clone data like a cloneDeep
 * @param {any} data
 * @returns {any}
 */
const cloneObject = (data) => {
  return JSON.parse(JSON.stringify(data))
}

/**
 * Return a new object
 * @param {object} base
 * @param {object} extra
 * @returns {object}
 */
const mergeObject = function (base, extra) {
  if (extra && typeof extra === 'object') {
    Object.assign(base, extra)
  }
  return base
}

/**
 * Number with commas
 * @param {number} number
 * @returns number with commas
 */
const numberWithCommas = function (number) {
  const parts = number.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

/**
 * set attr tag a
 * @param {Object} data // menu
 * @returns object attr
 */
const setAttributeLink = function (data) {
  const attr = {
    href: null,
    target: null,
    rel: null
  }
  if (data) {
    let openNewTab = false
    if ('openNewTab' in data) {
      openNewTab = data.openNewTab
    } else if ('link_external' in data) {
      openNewTab = data.link_external // type number(1 = '_blank', 0 = '_self')
    }

    let rel = null
    let target = '_self'
    if (openNewTab) {
      rel = 'noopener noreferrer'
      target = '_blank'
    }
    attr.rel = rel
    attr.target = target
    if (data.url && data.url !== '#') {
      attr.href = data.url
    }
  }
  // if (menu) {
  //   if (menu.openNewTab) {
  //     attr.rel = 'noopener noreferrer'
  //   }
  //   attr.target = menu.openNewTab ? '_blank' : '_self'
  //   if (menu.url) {
  //     attr.href = menu.url
  //   }
  // }
  return attr
}
/**
 * set attr tag a
 * @param {Object} data // banner
 * @returns object attr
 */
const setBannerItemAttrLink = function (data) {
  const attr = {
    href: null,
    target: null,
    rel: null
  }
  if (data) {
    let openNewTab = false
    if ('openNewTab' in data) {
      openNewTab = data.openNewTab
    } else if ('link_external' in data) {
      openNewTab = data.link_external // type number(1 = '_blank', 0 = '_self')
    }

    let rel = null
    let target = '_self'
    if (openNewTab) {
      rel = 'noopener noreferrer'
      target = '_blank'
    }
    attr.rel = rel
    attr.target = target
    if (data.url && data.url !== '#') {
      attr.href = data.url
    }
  }
  return attr
}

const integerStyle = (number) => {
  if (number && Number(number) === 0) {
    return 'theme-normal'
  } else if (number && Number(number) < 0) {
    return 'theme-danger'
  } else if (number && Number(number) > 0) {
    return 'theme-success'
  } else {
    return 'theme-normal'
  }
}

const integerLevelStyle = (number) => {
  if (number < -2) {
    return 'theme-danger-3'
  } else if (number < -1 && number > -2) {
    return 'theme-danger-2'
  } else if (number < 0 && number > -1) {
    return 'theme-danger-1'
  } else if (number < 1 && number > 0) {
    return 'theme-success-1'
  } else if (number < 2 && number > 1) {
    return 'theme-success-2'
  } else if (number > 2) {
    return 'theme-success-3'
  } else {
    return 'theme-normal'
  }
}

const openTab = (url, target) => {
  // console.log('debug openTab', {
  //   url, target
  // })
  // Create link in memory
  const a = window.document.createElement('a')
  document.body.appendChild(a)
  a.target = target || '_blank'
  a.href = url

  // Dispatch fake click
  const e = window.document.createEvent('MouseEvents')
  e.initMouseEvent(
    'click',
    true,
    true,
    window,
    0,
    0,
    0,
    0,
    0,
    false,
    false,
    false,
    false,
    0,
    null
  )
  a.dispatchEvent(e)
  setTimeout(() => {
    document.body.removeChild(a)
  }, 100)
}

/**
 * Set client id
 * @param {String} oldClientUuid
 * @returns clientUuid
 */
const setClientId = async (oldClientUuid) => {
  const { uuid } = await require('@/utils')
  const clientUuid = oldClientUuid
  const already = !!clientUuid
  let newClientUuid = clientUuid
  if (already === false) {
    newClientUuid = uuid()
  }
  return newClientUuid
}
const quoteTypeString = (securityType, i18n) => {
  const type = securityType.toUpperCase()
  switch (type) {
    case 'S':
      return i18n.t('quote.stock')
    case 'F':
      return i18n.t('quote.commonForeignShares')
    case 'P':
      return i18n.t('quote.preferredShares')
    case 'Q':
      return i18n.t('quote.preferredForeignShares')
    case 'W':
      return 'Warrants'
    case 'V':
      return 'DW'
    case 'L':
      return 'ETF'
    case 'X':
      return 'DR'
    case 'XF':
      return 'DRx'
    case 'U':
      return i18n.t('quote.unitTrust')
    default:
      return ''
  }
  // if (securType && securType !== '') {
  //   if (
  //     securType === 'S' ||
  //       securType === 'F' ||
  //       securType === 'Q' ||
  //       securType === 'P'
  //   ) {
  //     return 'หุ้น'
  //   } else if (securType === 'V') {
  //     return 'DW'
  //   } else if (securType === 'X') {
  //     return 'DR'
  //   } else if (securType === 'L') {
  //     return 'ETF'
  //   } else if (securType === 'W') {
  //     return 'Warrants'
  //   } else if (securType === 'U') {
  //     return 'หน่วยลงทุน'
  //   }
  // } else {
  //   return '-'
  // }
}

/**
 * get youtube id
 * @param {String} youtubeUrl
 * @returns youtubeId string
 */
const getYoutubeId = (youtubeUrl) => {
  if (!youtubeUrl) { return '' }
  let youtubeId = ''
  youtubeUrl = youtubeUrl
    .replace(/(>|<)/gi, '')
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
  if (youtubeUrl[2] !== undefined) {
    youtubeId = youtubeUrl[2].split(/[^0-9a-z_\\-]/i)
    youtubeId = youtubeId[0]
  } else {
    youtubeId = youtubeUrl
  }
  return youtubeId
}

const videoStatusLive = (currentDate, startDate, endDate, title) => {
  const cDate = currentDate ?? ''
  const sDate = startDate ?? ''
  const eDate = endDate ?? ''
  if (!cDate || !sDate || !eDate) { return false }
  const dayjs = require('dayjs')
  const isBetween = require('dayjs/plugin/isBetween')
  dayjs.extend(isBetween)
  const _currentDate = dayjs(currentDate)
  const _startDate = dayjs(sDate)
  const _endDate = dayjs(eDate)
  return _currentDate.isBetween(_startDate, _endDate, null, '[]')
}

const removeTrailingSlash = (str) => {
  return str.replace(/\/+$/, '')
}
export {
  getDomainFromURL,
  mergeObject,
  numberWithCommas,
  setAttributeLink,
  setBannerItemAttrLink,
  integerStyle,
  integerLevelStyle,
  openTab,
  setClientId,
  isInvalidPath,
  quoteTypeString,
  cloneObject,
  getYoutubeId,
  videoStatusLive,
  removeTrailingSlash
}
