//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { setAttributeLink } from '~/helpers'
export default {
  name: 'SubMenuItem',
  props: {
    lastUpdate: {
      type: Date,
      default: null
    },
    menuItemUuid: {
      type: String,
      default: null
    },
    menuList: {
      type: Array,
      default: () => []
    },
    menuParent: {
      type: Object,
      default: Object
    },
    menuSubParent: {
      type: Object,
      default: Object
    }
  },
  serverCacheKey: props => `SubMenuItem::${props.menuItemUuid}::${props.lastUpdate?.toISOString()}`,
  methods: {
    attr (menu) {
      return setAttributeLink(menu)
    },
    handleClickItem (item) {
      if (item && item.url !== '' && item.url !== '#') {
        let title = ''
        if (this.menuParent && this.menuParent.name) {
          title = this.menuParent.name
        }
        if (title) {
          title = `${title},`
        }
        if (this.menuSubParent && this.menuSubParent.name) {
          title = `${title}${this.menuSubParent.name}`
        }
        if (title) {
          title = `${title},`
        }
        if (item) {
          title = `${title}${item.name}`
        }
        this.$personalized.clickElement({
          name: 'all15',
          optionLang: {
            menu: title
          }
        })
      }
    }
  }
}
