/**
 * State
 */
const state = () => ({
  index: {
    overview: null,
    issuer: null,
    topten: {
      data: {},
      chart: {}
    },
    marketStatus: null
  },
  news: {
    company: null,
    issuer: null,
    underlying: null,
    drxList: null
  }
})

/**
   * Mutations
   */
const mutations = {
  setDRxMarketOverview (state, data) {
    state.index.overview = data
  },
  setDRxIssuer (state, data) {
    state.index.issuer = data
  },
  setDRxIndexTop10 (state, data) {
    if (data) {
      state.index.topten.data = data.slice(0, 10)
      state.index.topten.chart = {}
    }
  },
  setDRxToptenIntradayChart (state, data) {
    state.index.topten.chart[data.symbol] = data.chart
  },
  setDRxNewsCompany (state, data) {
    state.news.company = data
  },
  setDRxNewsIssuer (state, data) {
    state.news.issuer = data
  },
  setDRxNewsUnderlying (state, data) {
    state.news.underlying = data
  },
  setDRxSymbolList (state, data) {
    state.news.drxList = data.securitySymbols.map((item) => {
      const { symbol } = item
      return {
        text: symbol
      }
    })
  },
  setDRxOverviewMarketStatus (state, data) {
    state.index.marketStatus = data
  }
}

/**
   * Actions
   */
const actions = {
  async getDrxIndex ({ dispatch }, payload) {
    await Promise.allSettled([
      dispatch('getDRxIndexTop10', payload),
      dispatch('getDRxOverviewMarketStatus', payload),
      dispatch('getDRxMarketOverview', payload),
      dispatch('getDRxIssuer', payload)
    ])
  },
  async getDRxIndexTop10 ({ commit, dispatch }, payload) {
    const { rankingType } = payload
    try {
      let data = ''
      if (rankingType === 'popular') {
        const response = await this.$apiCms.get('/api/cms/v1/popularquote/getpopular?market=SET&securityType=XF&limit=10')
        commit('setDRxIndexTop10', response.data.stocks)
        data = response.data.stocks
      } else {
        const response = await this.$axios.$get(`/api/set/drx/top-ranking/${rankingType}?limit=10`)
        commit('setDRxIndexTop10', response)
        data = response
      }
      if (data) {
        data.forEach((item, index) => {
          dispatch('getDRxToptenIntradayChart', { quote: item.symbol })
        })
      }
    } catch (err) {
      commit('setDRxIndexTop10', null)
    }
  },
  async getDRxToptenIntradayChart ({ commit }, payload) {
    const { quote } = payload
    try {
      const chartData = await this.$axios.$get(`/api/set/drx/${quote}/intraday-chart?period=1D&accumulated=true`)
      const response = {
        symbol: quote,
        chart: chartData
      }
      commit('setDRxToptenIntradayChart', response)
    } catch (err) {
      // commit('setDRxToptenIntradayChart', null)
    }
  },
  async getDRxMarketOverview ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/drx/market-overview')
      commit('setDRxMarketOverview', response)
    } catch (err) {
      commit('setDRxMarketOverview', null)
    }
  },
  async getDRxIssuer ({ commit }) {
    try {
      const response = await this.$axios.$get(`/api/set/drx/issuers?lang=${this.$i18n.locale}`)
      commit('setDRxIssuer', response)
    } catch (err) {
      commit('setDRxIssuer', null)
    }
  },
  async getDrxNews ({ dispatch }, payload) {
    await Promise.allSettled([
      dispatch('getDRxNewsCompany', payload),
      dispatch('getDRxNewsIssuer', payload),
      dispatch('getDRxNewsUnderlying', payload)
    ])
  },
  async getDRxNewsCompany ({ commit }, payload) {
    const { symbol, headline, fromDate, toDate, perPage, page } = payload
    try {
      const response = await this.$axios.$get(`/api/set/drx/news/company?lang=${this.$i18n.locale}&fromDate=${fromDate}&toDate=${toDate}${symbol ? `&symbols=${symbol}` : ''}${headline ? `&keyword=${headline}` : ''}${page ? `&page=${page - 1}` : ''}${perPage ? `&perPage=${perPage}` : ''}`)
      commit('setDRxNewsCompany', response)
    } catch (err) {
      commit('setDRxNewsCompany', null)
    }
  },
  async getDRxNewsIssuer ({ commit }, payload) {
    const { symbol, headline, fromDate, toDate, perPage, page } = payload
    try {
      const response = await this.$axios.$get(`/api/set/drx/news/issuer?lang=${this.$i18n.locale}&fromDate=${fromDate}&toDate=${toDate}${symbol ? `&symbols=${symbol}` : ''}${headline ? `&keyword=${headline}` : ''}${page ? `&page=${page - 1}` : ''}${perPage ? `&perPage=${perPage}` : ''}`)
      commit('setDRxNewsIssuer', response)
    } catch (err) {
      commit('setDRxNewsIssuer', null)
    }
  },
  async getDRxNewsUnderlying ({ commit }, payload) {
    const { symbol, headline, fromDate, toDate, perPage, page } = payload
    try {
      const response = await this.$axios.$get(`/api/set/drx/news/underlying?lang=${this.$i18n.locale}&fromDate=${fromDate}&toDate=${toDate}${symbol ? `&symbols=${symbol}` : ''}${headline ? `&keyword=${headline}` : ''}${page ? `&page=${page - 1}` : ''}${perPage ? `&perPage=${perPage}` : ''}`)
      commit('setDRxNewsUnderlying', response)
    } catch (err) {
      commit('setDRxNewsUnderlying', null)
    }
  },
  async getDRxSymbolList ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/stock/list?securityType=XF')
      commit('setDRxSymbolList', response)
    } catch (err) {
      commit('setDRxSymbolList', null)
    }
  },
  async getDRxOverviewMarketStatus ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/drx/market-status')
      commit('setDRxOverviewMarketStatus', response)
    } catch (error) {
      commit('setDRxOverviewMarketStatus', null)
    }
  }
}

/**
   * Getters
   */
const getters = {
  getDRxMarketOverview: (state) => {
    return state.index.overview
  },
  getDRxIssuer: (state) => {
    return state.index.issuer
  },
  getDRxIndexTopten: (state) => {
    return state.index.topten.data
  },
  getDRxIndexTop10Chart: (state) => {
    return state.index.topten.chart
  },
  getDRxNewsCompany: (state) => {
    return state.news.company
  },
  getDRxNewsIssuer: (state) => {
    return state.news.issuer
  },
  getDRxNewsUnderlying: (state) => {
    return state.news.underlying
  },
  getDRxSymbolList: (state) => {
    return state.news.drxList
  },
  getDRxOverviewMarketStatus: (state) => {
    return state.index.marketStatus
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
