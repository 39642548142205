
import { convertFormatMeta } from './helpers'
import { getDomainFromURL } from '~/helpers'
import { staticMeta } from '~/config/core'
/**
 * call for raw Meta
 *
 * @param {Object} content
 * @param {Object} route
 * @param {Object} seo
 * @return {*}
 */
const setMeta = (content, route, seo) => {
  // Section variable
  let meta = []

  const websiteUrl = content.info.url
  const domainName = getDomainFromURL(websiteUrl)
  // Section set SEO
  meta = [
    ...setSeo(seo),
    ...setTwitterMeta(seo, domainName),
    ...setOgMeta(seo, route)
  ]

  // Section codition set meta
  if (content.head.metaTags) {
    const metaTags = content.head.metaTags
    // set list meta
    meta = [...meta, ...convertFormatMeta(metaTags)]
    // set add to home screen
    // meta = [...meta, ...setAddIconToHomeScreen(content.head)]
  }
  // set add to home screen
  if (content.head) {
    meta = [...meta, ...setAddIconToHomeScreen(content.head)]
  }
  // App suggestion
  if (content.head.appSuggest) {
    const appSuggest = content.head.appSuggest
    if (appSuggest.appStore) {
      meta.push(
        { name: 'apple-itunes-app', content: appSuggest.appStore }
      )
    }
    if (appSuggest.playStore) {
      meta.push(
        { name: 'google-play-app', content: appSuggest.playStore }
      )
    }
  }
  if (meta.length) {
    return meta
  }
  return []
}

/**
 * setAddIconToHomeScreen
 *
 * @param {Object} advanced
 * @return {*}
 */
const setAddIconToHomeScreen = (head) => {
  let HomeScreen = [
    // for chrom on android
    { hid: 'mobile-web-app-capable', name: 'mobile-web-app-capable', content: 'yes' },
    // for safari on ios
    { hid: 'apple-mobile-web-app-capable', name: 'apple-mobile-web-app-capable', content: 'yes' },
    { hid: 'apple-mobile-web-app-status-bar-style', name: 'apple-mobile-web-app-status-bar-style', content: 'black' }
  ]
  if (head.appTitle) {
    const appTitle = [
      { hid: 'application-name', name: 'application-name', content: head.appTitle },
      { hid: 'apple-mobile-web-app-title', name: 'apple-mobile-web-app-title', content: head.appTitle }
    ]
    HomeScreen = [
      ...HomeScreen,
      ...appTitle
    ]
  }
  return HomeScreen
}

/**
 * set SEO initdata
 *
 * @param {Object} advanced
 * @return {*}
 */
const setSeo = (seo) => {
  return [
    {
      hid: 'description',
      name: 'description',
      content: seo.description
    },
    { name: 'keywords', content: seo.keywords }
  ]
}
const setTwitterMeta = (seo, domainName) => {
  return [
    { hid: 'twitter:card', name: 'twitter:card', content: staticMeta.TWITTER_CARD },
    { hid: 'twitter:site', name: 'twitter:site', content: staticMeta.TWITTER_SITE },
    { hid: 'twitter:creator', name: 'twitter:creator', content: staticMeta.TWITTER_CREATOR },
    { hid: 'twitter:title', name: 'twitter:title', content: seo.title },
    { hid: 'twitter:description', name: 'twitter:description', content: seo.description },
    { hid: 'twitter:image:src', name: 'twitter:image:src', content: seo.thumbnail },
    { hid: 'twitter:domain', name: 'twitter:domain', content: domainName }
  ]
}
const setOgMeta = (seo, route) => {
  return [
    { hid: 'og:type', property: 'og:type', content: staticMeta.OG_TYPE },
    { hid: 'og:title', property: 'og:title', content: seo.title },
    { hid: 'og:description', property: 'og:description', content: seo.description },
    { hid: 'og:image', property: 'og:image', content: seo.thumbnail },
    { hid: 'og:url', property: 'og:url', content: route.fullPath }
  ]
}

export {
  setMeta
}
