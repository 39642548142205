/**
 * State
 */

const state = () => ({
  eventCalendarList: null,
  eventDetail: null,
  receiptTypeList: null,
  receiptCompanyList: null,
  memberInformation: null,
  myEventList: null,
  memberStatus: null
})

/**
   * Mutations
   */
const mutations = {
  setState (state, opts) {
    state[opts.key] = opts.data
  }
}

/**
   * Actions
   */
const actions = {
  async geEventCalendarList ({ commit }, payload) {
    try {
      const apiPath = '/api/gw-api/event/public/list'
      const { eventType, fromDate, toDate } = payload
      const options = {
        params: {
          eventType,
          fromDate,
          toDate
        }
      }
      const response = await this.$axios.$get(apiPath, options)
      commit('setState', { key: 'eventCalendarList', data: response })
    } catch (error) {
      commit('setState', { key: 'eventCalendarList', data: null })
    }
  },
  async getEventDetails ({ commit }, payload) {
    try {
      const apiPath = `/api/gw-api/event/event-detail?eventId=${payload.eventId}`
      const response = await this.$apiMember.get(apiPath)
      commit('setState', { key: 'eventDetail', data: response.data })
    } catch (error) {
      commit('setState', { key: 'eventDetail', data: null })
    }
  },
  async getMyEvent ({ commit }, payload) {
    try {
      const apiPath = '/api/gw-api/member/event/upcoming'
      const response = await this.$apiMember.get(apiPath)
      commit('setState', { key: 'myEventList', data: response.data })
    } catch (error) {
      commit('setState', { key: 'myEventList', data: null })
    }
  },
  async getReceiptCompanyTypeList ({ commit }, token) {
    try {
      const apiPath = '/api/gw-api/receipt/type'
      const response = await this.$axios.$get(apiPath)
      commit('setState', { key: 'receiptTypeList', data: response })
    } catch (error) {
      commit('setState', { key: 'receiptTypeList', data: null })
    }
  },
  async getReceiptCompanyList ({ commit }, payload) {
    try {
      const apiPath = '/api/gw-api/receipt/company'
      const { code, name } = payload
      const options = {
        params: {
          code,
          name
        }
      }
      const response = await this.$axios.$get(apiPath, options)
      commit('setState', { key: 'receiptCompanyList', data: response })
    } catch (error) {
      commit('setState', { key: 'receiptCompanyList', data: null })
    }
  },
  async getMemberInformation ({ commit }, token) {
    try {
      const apiPath = '/api/gw-api/receipt/member'
      const response = await this.$apiMember.get(apiPath)
      commit('setState', { key: 'memberInformation', data: response.data })
    } catch (error) {
      commit('setState', { key: 'memberInformation', data: null })
    }
  },
  async submitMember ({ commit }, payload) {
    let response = null
    const apiPath = '/api/gw-api/member/event/enroll'
    response = await this.$apiMember
      .post(apiPath, payload)
      .then((res) => {
        if (res && res.status === 200) {
          commit('setState', { key: 'memberStatus', data: res.data })
        }
        return res
      }).catch(err => err)
    return response
  }
}

/**
   * Getters
   */
const getters = {
  getEventCalendarList: (state) => {
    return state.eventCalendarList && state.eventCalendarList.length > 0 ? state.eventCalendarList : []
  },
  getEventDetails: (state) => {
    return state.eventDetail ? state.eventDetail : null
  },
  getReceiptCompanyTypeList: (state) => {
    return state.receiptTypeList && state.receiptTypeList.length > 0 ? state.receiptTypeList : []
  },
  getReceiptCompanyList: (state) => {
    return state.receiptCompanyList && state.receiptCompanyList.length > 0 ? state.receiptCompanyList : []
  },
  getMemberInformation (state) {
    return state.memberInformation ? state.memberInformation : null
  },
  getMyEvent (state) {
    return state.myEventList
  },
  getMemberStatus (state) {
    return state.memberStatus
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
