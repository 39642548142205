// import { formatNumber } from '@/helpers/number'

// const pathFormat = (sign, securityType, fromDate, toDate, lang) => {
//   const param = `?securityType=${securityType}&fromDate=${fromDate}&toDate=${toDate}&lang=${lang}`
//   const apiPath = sign === 'SP' ? `api/set/trading-sign/${sign}/cause-list${param}` : `api/set/trading-sign/${sign}/list${param}`

//   return apiPath
// }

const state = () => ({
  // signedHaltList: null,
  // signedCautionList: null,
  // signedSuspensionList: null,
  // signedNoticePendingList: null
})

const actions = {
  // async fetchSignedHaltList ({ commit }, payload) {
  //   const baseUrl = pathFormat('H', payload.securityType, payload.fromDate, payload.toDate, payload.lang)
  //   let result = []

  //   if (payload.fromDate != null && payload.toDate != null) {
  //     const res = await this.$axios.$get(baseUrl)
  //     if (res && res.data) {
  //       if (res.data.length > 0) {
  //         result = res.data.map(item => ({
  //           symbol: item.symbol,
  //           sign: item.sign,
  //           registrationDate: item.postDate,
  //           cancelDate: item.releaseDate,
  //           registrationFilename: item.postFilename,
  //           cancelFilename: item.releaseFilename
  //         }))
  //       }
  //     }
  //   }

  //   commit('setSignedHaltList', result)
  // },
  // async fetchSignedCautionList ({ commit }, payload) {
  //   const baseUrl = pathFormat('C', payload.securityType, payload.fromDate, payload.toDate, payload.lang)
  //   let result = []

  //   if (payload.fromDate != null && payload.toDate != null) {
  //     const res = await this.$axios.$get(baseUrl)
  //     if (res && res.data) {
  //       if (res.data.length > 0) {
  //         result = res.data.map(item => ({
  //           symbol: item.symbol,
  //           sign: item.sign,
  //           registrationDate: item.postDate,
  //           cancelDate: item.releaseDate,
  //           registrationFilename: item.postFilename,
  //           cancelFilename: item.releaseFilename
  //         }))
  //       }
  //     }
  //   }

  //   commit('setSignedCautiontList', result)
  // },
  // async fetchSignedNoticePendingList ({ commit }, payload) {
  //   const baseUrl = pathFormat('NP', payload.securityType, payload.fromDate, payload.toDate, payload.lang)
  //   let result = []

  //   if (payload.fromDate != null && payload.toDate != null) {
  //     const res = await this.$axios.$get(baseUrl)
  //     if (res && res.data) {
  //       if (res.data.length > 0) {
  //         result = res.data.map(item => ({
  //           symbol: item.symbol,
  //           sign: item.sign,
  //           registrationDate: item.postDate,
  //           cancelDate: item.releaseDate,
  //           registrationFilename: item.postFilename,
  //           cancelFilename: item.releaseFilename
  //         }))
  //       }
  //     }
  //   }

  //   commit('setSignedNoticePendingList', result)
  // },
  // async fetchSignedSuspensionList ({ commit }, payload) {
  //   const baseUrl = pathFormat('SP', payload.securityType, payload.fromDate, payload.toDate, payload.lang)
  //   let result = []

  //   if (payload.fromDate != null && payload.toDate != null) {
  //     const res = await this.$axios.$get(baseUrl)
  //     if (res && res.groups) {
  //       if (res.groups) {
  //         result = res.groups.map((item, index) => ({
  //           label: item.cause && item.numberOfTime ? `${index + 1}. ${item.cause} : ${formatNumber(item.numberOfTime, 0)} ${this.$i18n.t('rulebooks.times')}` : '',
  //           key: `cause${index}`,
  //           class: `tr-bg-light-gray heading-font-family fs-20px py-2 ${item.cause && item.numberOfTime ? '' : 'd-none'}`,
  //           stickyColumn: false,
  //           items: item.data && item.data.length > 0
  //             ? item.data.map(item => ({
  //               symbol: item.symbol,
  //               sign: item.sign,
  //               registrationDate: item.postDate,
  //               cancelDate: item.releaseDate,
  //               registrationFilename: item.postFilename,
  //               cancelFilename: item.releaseFilename
  //             }))
  //             : []
  //         }))
  //       }
  //     }
  //   }

  //   commit('setSignedSuspensionList', result)
  // },
  async fetchNewsFileDetail ({ commit }, filename) {
    const apiPath = filename ? `api/set/news/file/${filename}/detail` : ''
    let result = null

    if (apiPath) {
      result = await this.$axios.$get(apiPath)
    }

    return result
  }
}

const mutations = {
  // setSignedHaltList (state, data) {
  //   state.signedHaltList = data
  // },
  // setSignedCautiontList (state, data) {
  //   state.signedCautionList = data
  // },
  // setSignedNoticePendingList (state, data) {
  //   state.signedNoticePendingList = data
  // },
  // setSignedSuspensionList (state, data) {
  //   state.signedSuspensionList = data
  // }
}

const getters = {
  // getSignedHaltList (state) {
  //   return state.signedHaltList
  // },
  // getSignedCautiontList (state) {
  //   return state.signedCautionList
  // },
  // getSignedNoticePendingList (state) {
  //   return state.signedNoticePendingList
  // },
  // getSignedSuspensionList (state) {
  //   return state.signedSuspensionList
  // }
}

export default {
  state,
  actions,
  mutations,
  getters
}
