
import { defaultObserverOption } from '~/config/core'
export default {
  name: 'ContentLazy',
  props: {
    tagName: {
      type: String,
      default: 'div'
    },
    observerOption: {
      type: Object,
      default: () => {}
    },
    await: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      inViewport: false,
      isLoaded: false
    }
  },
  computed: {
    element () {
      return this.$el
    }
  },
  mounted () {
    if (window.IntersectionObserver) {
      this.createObserver()
    } else {
      this.inViewport = false
    }
  },
  methods: {
    createObserver () {
      const options = {
        ...this.observerOption,
        ...defaultObserverOption
      }
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.inViewport = true
            this.$emit('inViewport')
            observer.unobserve(this.element)
            // console.log('54321')
            // entry.target.classList.add('animate-in')
          }
        })
      }, options)
      observer.observe(this.element)
    },
    awaitLoadData () {
      this.isLoaded = true
    }
  },
  render (h) {
    const placeholder = this.$slots.placeholder || []
    let defaultContent = null
    if (typeof this.$scopedSlots.default === 'function') {
      defaultContent = this.await ? this.$scopedSlots.default(this.awaitLoadData) : this.$scopedSlots.default()
    }
    let childrenNode = this.placeholder ? placeholder : defaultContent
    if (this.inViewport) {
      childrenNode = this.await ? [...childrenNode, ...defaultContent] : defaultContent
    }
    if (this.isLoaded) {
      childrenNode = defaultContent
    }
    return h(this.tagName, {}, childrenNode)
  }

}
// <LazyComponent>
//   <Banner :banner="bannerOne" />
// </LazyComponent>
// <LazyComponent>
//     <ChartsPie />
//     <template #skeleton>
//         <PlaceholderTable />
//     </template>
// </LazyComponent>
// <LazyComponent :await="true" animate-in="true">
//     <template #default="isSkeletonLoaded">
//         <DataStream :ready="isStreamStriptLoaded && isFastStriptLoaded" :is-skeleton-loaded="isSkeletonLoaded" />
//     </template>
//     <template #skeleton>
//         <PlaceholderTable />
//     </template>
// </LazyComponent>
