//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'GoTop',
  data () {
    return {
      show: false,
      offsetY: 1000
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.init()
    })
  },
  beforeDestroy () {
    this.removeEvent()
  },
  methods: {
    init () {
      this.registerEvent()
      setTimeout(() => {
        this.setPosition()
      }, 200)
      this.showButton()
    },
    registerEvent () {
      window.addEventListener('scroll', this.handleScroll)
    },
    removeEvent () {
      window.removeEventListener('scroll', this.handleScroll)
    },
    handleScroll () {
      this.setPosition()
      this.showButton()
    },
    showButton () {
      const element = document.querySelector('footer')
      if (!element) { return }
      const options = {
        rootMargin: '0px',
        threshold: 0
      }
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.show = true
          } else {
            this.show = false
          }
        })
      }, options)
      observer.observe(element)
    },
    getScrollTop () {
      return document.body.scrollTop || document.documentElement.scrollTop
    },
    setPosition () {
      if (this.$device.isMobile === true) { return }
      const button = this.$refs.buttonGoTop.$el
      if (!button) { return }
      const chatIcon = document.getElementById('zwiz-web-chat')
      const chatIconHeight = chatIcon?.clientHeight // Bull icon size 80px
      if (chatIconHeight > 80) {
        return
      }
      const buttonQuote = document.getElementById('btn-my-quote')
      const chatIconOffsetTop = chatIcon?.offsetTop || 0
      const buttonQuoteOffsetTop = buttonQuote?.offsetTop || 0
      const offsetTop = chatIconOffsetTop || buttonQuoteOffsetTop
      let bottom = 32
      if (offsetTop) {
        bottom = window.innerHeight - offsetTop + 8
      }
      button.style.bottom = bottom + 'px'
    },
    goTop () {
      const rootElement = document.documentElement
      rootElement.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
