//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import { setCookieMyQuote } from '@/helpers/quote'
export default {
  name: 'SearchInput',
  props: {
    isShowMenuMobile: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      text: '',
      placeholderStatus: false,
      timer: 0
    }
  },
  computed: {
    ...mapGetters({
      activeDropDown: 'search/getActiveDropDown',
      keyWord: 'search/getKeyWordSearch',
      stocks: 'search/getStocks'
    })
  },
  watch: {
    text () {
      // console.log('test', this.text)
    },
    activeDropDown () {
      if (!this.activeDropDown) {
        this.text = ''
        document.getElementById('inputSearchSpan').style.display = null
      }
    }
  },
  methods: {
    ...mapActions('search', [
      'getStockList',
      'setKeyWordSearch',
      'setActiveDropDown',
      'setCookieRecentSearch'
    ]),
    onChange (event) {
      this.text = event.target.value
      this.$root.$emit('searchInputOnChange', event.target.value)
      this.handleSuggest()
    },
    deleteText (e) {
      e.stopPropagation()
      this.text = ''
      this.setKeyWordSearch(this.text)
    },
    async handleClickInputSearch () {
      document.getElementById('inputSearchSpan').style.display = 'none'
      await this.updateActiveDropDown(true)
      this.focusInput('inputSearch')
    },
    focus (status) {
      this.placeholderStatus = status
      this.updateActiveDropDown(status) // working on desktop
    },
    focusOut (status) {
      this.placeholderStatus = status
    },
    updateActiveDropDown (status) {
      if (this.activeDropDown === true) { return }
      this.setActiveDropDown(status)
    },
    async focusResponsive (status) {
      await this.updateActiveDropDown(status)
      this.focusInput('inputSearchMobile')
      // this.focusInputSearch()
    },
    // focus input 👍
    // focusInputSearch () {
    //   this.focusInput('inputSearch')
    //   this.focusInput('inputSearchMobile')
    // },
    focusInput (inputId) {
      const elInput = document.getElementById(inputId)
      if (elInput?.clientHeight <= 0) {
        return
      }
      elInput.classList.add('focus-input')
      elInput.focus()
    },
    handleFilter () {
      this.handleKeyUpEnter()
    },
    async handleKeyUpEnter () {
      const text = this.text.trim()
      if (text) {
        let listStock = null
        if (this.stocks.length) {
          listStock = this.stocks
        } else {
          await this.getStockList()
          listStock = this.stocks
        }
        // find quote
        const isHave = listStock.find(
          s => s.symbol.toLowerCase() === this.text.trim().toLowerCase()
        )
        // case have qoute go to page qoute
        if (isHave) {
          // this.$quote.goToQuote({
          //   securities: '',
          //   symbol: isHave.symbol,
          //   securityType: isHave.securityType
          // })
          const { goToQuote } = await import('@/helpers/quote')
          goToQuote.call({ router: this.$router, localeRoute: this.localeRoute },
            {
              securities: '',
              symbol: isHave.symbol,
              securityType: isHave.securityType
            }
          )
          setCookieMyQuote(this.$cookies, isHave.symbol)
        } else {
          this.$personalized.siteSearch(text, 'panel')
          this.goSearchResult()
          // this.$router.push(
          //   this.localePath({
          //     path: `/search-result?keyword=${this.text}`
          //   })
          // )
        }
        this.setCookieRecentSearch(this.text.trim())
        this.text = ''
        this.focus(false)
        // this.$refs.inputSearch.blur()
      }
    },
    handleSuggest () {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.setKeyWordSearch(this.text)
      }, 300)
    },
    goSearchResult () {
      const newPath = `/search-result?keyword=${encodeURIComponent(this.text)}`
      const url = this.localePath(newPath)
      window.location.href = url
    }
  }
}
