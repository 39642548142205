/**
 * State
 */
const state = () => ({
  companyCapitalRaise: null,
  ipoYearList: null,
  yearSummary: null,
  ipoStockList: null
})

/**
   * Mutations
   */
const mutations = {
  setCompanyCapitalRaise (state, data) {
    state.companyCapitalRaise = data
  },
  setIpoYear (state, data) {
    state.ipoYearList = data
  },
  setYearlySummary (state, data) {
    state.yearSummary = data
  },
  setIpoStockList (state, data) {
    state.ipoStockList = data
  }
}

/**
   * Actions
   */
const actions = {
  async getCompanyCapitalRaise ({ commit }) {
    try {
      const response = await this.$axios.get('/api/set/ca/capital-raise')
      commit('setCompanyCapitalRaise', response.data)
      return response.data
    } catch (err) {
      commit('setCompanyCapitalRaise', null)
    }
  },
  async getIpoYear ({ commit }) {
    try {
      const response = await this.$axios.get('/api/set/ipo-stat/years')
      commit('setIpoYear', response.data)
      return response.data
    } catch (err) {
      commit('setIpoYear', null)
      return null
    }
  },
  async getYearlySummary ({ commit }, payload) {
    try {
      const apiPath = '/api/set/ipo-stat/yearly-summary'
      const { year } = payload
      const options = {
        params: {
          year
        }
      }
      const response = await this.$axios.$get(apiPath, options)
      commit('setYearlySummary', response)
      return response
    } catch (err) {
      commit('setYearlySummary', null)
    }
  },
  async getIpoStockList ({ commit }, payload) {
    try {
      const apiPath = '/api/set/ipo-stat/stock-list'
      const { year, lang } = payload
      const options = {
        params: {
          year,
          lang
        }
      }
      const response = await this.$axios.$get(apiPath, options)
      commit('setIpoStockList', response)
      return response
    } catch (err) {
      commit('setIpoStockList', null)
    }
  }
}

/**
   * Getters
   */
const getters = {
  getCompanyCapitalRaise: (state) => {
    return state.companyCapitalRaise ? state.companyCapitalRaise : []
  },
  getIpoYear: (state) => {
    return state.ipoYearList ? state.ipoYearList : []
  },
  getYearlySummary: (state) => {
    return state.yearSummary ? state.yearSummary : null
  },
  getIpoStockList: (state) => {
    return state.ipoStockList ? state.ipoStockList : null
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
