// const { formatNumber, formatNumberInteger } = require('@/helpers/number')

/**
 * State
 */
const state = () => ({
  futureOption: [],
  futureDateTime: null,
  futureInvestorVolume: null,
  optionInvestorVolume: null
})

/**
 * Mutations
 */
const mutations = {
  setTFEXFutureOption (state, data) {
    const list = [{ name: 'All Futures', instrumentId: '' }, ...data]
    state.futureOption = list.map((item) => {
      return {
        name: item.name,
        value: item.instrumentId
      }
    })
  },
  setTFEXFutureInvestorVolume (state, data) {
    state.futureDateTime = data.asOfDate
    state.futureInvestorVolume = data.investors.map((item) => {
      return {
        name: item.type,
        buy: item.buyValue,
        sell: item.sellValue,
        net: item.netValue
      }
    })
  },
  setTFEXOptionInvestorVolume (state, data) {
    state.optionInvestorVolume = data.investors.map((item) => {
      return {
        name: item.type,
        buy: item.buyValue,
        sell: item.sellValue,
        net: item.netValue
      }
    })
  }
}

/**
 * Actions
 */
const actions = {
  async getTFEXFutureOption ({ commit }) {
    await this.$axios.get('api/set/tfex/market/list?type=futures').then((res) => {
      commit('setTFEXFutureOption', res.data)
    }).catch(err => err)
  },
  async getTFEXFutureInvestorVolume ({ commit }, payload) {
    await this.$axios.get('api/set/tfex/market/investor-type?type=futures&marketList=' + payload).then((res) => {
      commit('setTFEXFutureInvestorVolume', res.data)
    }).catch(err => err)
  },
  async getTFEXOptionInvestorVolume ({ commit }, payload) {
    await this.$axios.get('api/set/tfex/market/investor-type?type=' + payload).then((res) => {
      commit('setTFEXOptionInvestorVolume', res.data)
    }).catch(err => err)
  }
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
