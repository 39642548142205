import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=d1534454&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"
import style0 from "./Footer.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GoTop: require('/app/components/GoTop.vue').default,FooterSocial: require('/app/components/Footer/Social.vue').default,Divider: require('/app/components/Divider.vue').default,FooterMenu: require('/app/components/Footer/Menu.vue').default,FooterAccordionMenuMobile: require('/app/components/Footer/AccordionMenuMobile.vue').default,ContentLazy: require('/app/components/ContentLazy.vue').default,FooterAccordionMenu: require('/app/components/Footer/AccordionMenu.vue').default,Footer: require('/app/components/Footer/Footer.vue').default})
