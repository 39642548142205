/**
 * State
 */
const state = () => ({
  live: null,
  upcoming: null,
  archive: null,
  period: null,
  liveAndUpcoming: null
})

/**
   * Mutations
   */
const mutations = {
  setHomeOppdayLiveUpcoming (state, data) {
    state.liveAndUpcoming = data.slice(0, 7)
  },
  setHomeOppdayLive (state, data) {
    state.live = data
  },
  setHomeOppdayUpcoming (state, data) {
    state.upcoming = data.slice(0, 7)
  },
  setHomeOppdayArchive (state, data) {
    state.archive = data.slice(0, 7)
  },
  setHomeOppdayPeriod (state, data) {
    if (data) {
      state.period = data[0]
    }
  }
}

/**
   * Actions
   */
const actions = {
  async getHomeOppday ({ dispatch }, payload) {
    await Promise.allSettled([
      dispatch('getHomeOppdayLiveUpcoming'),
      dispatch('getHomeOppdayPeriod'),
      dispatch('getHomeOppdayArchive')
    ])
  },
  async getHomeOppdayLiveUpcoming ({ commit }, payload) {
    try {
      const arr = []
      const resLive = await this.$axios.$get(`/api/set-content/vdo/${this.$i18n.locale === 'th' ? 'oppday' : 'digitalroadshow'}/list?status=LIVE`)
      Array.prototype.push.apply(arr, resLive)
      const resUpcoming = await this.$axios.$get(`/api/set-content/vdo/${this.$i18n.locale === 'th' ? 'oppday' : 'digitalroadshow'}/list?status=UPCOMING`)
      Array.prototype.push.apply(arr, resUpcoming)
      commit('setHomeOppdayLiveUpcoming', arr)
    } catch (error) {
      commit('setHomeOppdayLiveUpcoming', null)
    }
  },
  async getHomeOppdayUpcoming ({ commit }, payload) {
    try {
      const response = await this.$axios.$get(`/api/set-content/vdo/${this.$i18n.locale === 'th' ? 'oppday' : 'digitalroadshow'}/list?status=UPCOMING`)
      commit('setHomeOppdayLiveUpcoming', response)
    } catch (error) {
      commit('setHomeOppdayUpcoming', null)
    }
  },
  async getHomeOppdayArchive ({ commit }, payload) {
    try {
      const response = await this.$axios.$get(`/api/set-content/vdo/${this.$i18n.locale === 'th' ? 'oppday' : 'digitalroadshow'}/list?status=ARCHIVE`)
      commit('setHomeOppdayArchive', response)
    } catch (error) {
      commit('setHomeOppdayArchive', null)
    }
  },
  async getHomeOppdayPeriod ({ commit }, payload) {
    try {
      const response = await this.$axios.$get(`/api/set-content/vdo/${this.$i18n.locale === 'th' ? 'oppday' : 'digitalroadshow'}/period`)
      commit('setHomeOppdayPeriod', response)
    } catch (error) {
      commit('setHomeOppdayPeriod', null)
    }
  }
}

const getters = {
  getHomeOppdayLiveUpcoming: (state) => {
    return state.liveAndUpcoming || []
  },
  getHomeOppdayLive: (state) => {
    return state.live || []
  },
  getHomeOppdayUpcoming: (state) => {
    return state.upcoming || []
  },
  getHomeOppdayArchive: (state) => {
    return state.archive || []
  },
  getHomeOppdayPeriod: (state) => {
    return state.period
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
