const getDefaultState = () => {
  return {
    setProfile: [],
    setShareholder: [],
    setDirector: [],
    setBrokerOverview: [],
    setBrokerList: []
  }
}

const state = getDefaultState

const mutations = {
  setProfile (state, data) {
    state.setProfile = data
  },
  setShareholder (state, data) {
    state.setShareholder = data
  },
  setDirector (state, data) {
    state.setDirector = data
  },
  setBrokerOverview (state, data) {
    state.setBrokerOverview = data
  },
  setBrokerList (state, data) {
    state.setBrokerList = data
  }
}

const actions = {
  async getProfile ({ commit }, payload) {
    try {
      const response = await this.$axios.$get(
        `api/set/broker/${payload}/profile?lang=${this.$i18n.locale}`
      )
      commit('setProfile', response)
    } catch {
      commit('setProfile', null)
    }
  },
  async getShareholder ({ commit }, payload) {
    try {
      const response = await this.$axios.$get(
        `api/set/broker/${payload}/shareholder?lang=${this.$i18n.locale}`
      )
      commit('setShareholder', response)
    } catch {
      commit('setShareholder', null)
    }
  },
  async getDirector ({ commit }, payload) {
    try {
      let response = await this.$axios.$get(
        `api/set/broker/${payload}/board-of-director?lang=${this.$i18n.locale}`
      )
      if (response) {
        response = response.map((data, index) => {
          return {
            index,
            ...data
          }
        })
      }
      commit('setDirector', response)
    } catch {
      commit('setDirector', null)
    }
  },
  async getBrokerOverview ({ commit }) {
    try {
      const response = await this.$axios.$get(
        `api/set/broker/overview?lang=${this.$i18n.locale}`
      )
      commit('setBrokerOverview', response)
    } catch {
      commit('setBrokerOverview', null)
    }
  },
  async getBrokerList ({ commit }) {
    try {
      const response = await this.$axios.$get(
        `api/set/broker/list?lang=${this.$i18n.locale}`
      )
      commit('setBrokerList', response)
    } catch {
      commit('setBrokerList', null)
    }
  }
}

const getters = {
  getProfile: (state) => {
    return state.setProfile
  },
  getShareholder: (state) => {
    return state.setShareholder
  },
  getDirector: (state) => {
    return state.setDirector
  },
  getBrokerOverview: (state) => {
    return state.setBrokerOverview
  },
  getBrokerList: (state) => {
    return state.setBrokerList
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
