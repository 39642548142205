//
//
//
//
//
//
//

function createScriptSDK (callback) {
  const script = document.createElement('script')
  if (typeof callback === 'function') {
    script.onload = callback
  }
  script.id = 'zwiz-web-chat-sdk'
  script.type = 'text/javascript'
  script.src = process.env.CHATBOT_SDK_SCRIPT
  script.setAttribute('data-client-id', 'set')
  script.setAttribute('data-client-secret', process.env.CHATBOT_CLIENT_SECRET)
  script.setAttribute('data-env', process.env.CHATBOT_ENV)
  const s = document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(script, s)
}
function createChatBotScript (callback) {
  const cssSrc = process.env.CHATBOT_CSS
  const jsSrc = process.env.CHATBOT_JS

  // Render js
  const script = document.createElement('script')
  if (typeof callback === 'function') {
    script.onload = callback
  }
  script.type = 'text/javascript'
  script.src = jsSrc
  script.async = true
  const s = document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(script, s)

  // Render css
  const linkCSS = document.createElement('link')
  linkCSS.rel = 'stylesheet'
  linkCSS.type = 'text/css'
  linkCSS.href = cssSrc
  const l = document.getElementsByTagName('link')[0]
  l.parentNode.insertBefore(linkCSS, l)
}
function initChatBot () {
  window.u = null
  window.chatbot = {
    url: 'https://cubikachatuiuat.azurefd.net/cubikbot/webchat/botv4.jsp',
    color: 'rgba(251,176,52,1.0)',
    authen_jwt: '',
    branding: 'SET',

    calltoaction: 'Chatbot',
    calltoaction_callout: true,
    calltoaction_text: 'CHAT NOW',
    hover_callout: true,
    tokenExpiredBroardcast: true,
    secureSSL: true,
    userId: window.u,
    // agentId : "a192d7f7-19ba-41f7-8ed5-c106b7ddf033", // (PROD)
    agentId: process.env.CHATBOT_AGENTID, // (UAT)
    channel: 'ISP',
    mobileTab: false,
    direct_connect: true,
    // PRD : UFTiKoriQhY.O0QtW6QaCLnxhpGKIxfYIWKYw33IfJDlB77SXJ9h8gU
    // UAT : 7k2wcJYhJ1A.f_sqlkdZPyvtTOXY5_-LmOSbx6LLpr9gWTzsWI55HgQ
    dl_key: process.env.CHATBOT_DL_KEY,
    hideNamePlate: true,
    browser: 'full',
    hideAttachments: false,
    hideUploadUI: true,

    logo: 'https://www.set.or.th/images/chat/icon_chat_b.png',
    botName: 'TSD',
    preLogin: false,

    // startFullscreen : false,
    // autoStartBotWidget : false,

    position: 'right: 25px; bottom: 100px;',
    primaryFontSet: 'DB HelvethaicaMon X Reg,Helvetica,Arial,sans-serif',

    chat_icon: {
      icon_img: '<img src="https://www.set.or.th/images/chat/icon_chat_b.png" style="width: 57px; height: 57px; border-radious: 50%" />',
      drop_shadow: false
    },

    // headerIcon : '<img src="https://www.set.or.th/images/chat/icon_chat_b.png" style="width: 35px; height: 35px; border-radious: 50%" />',
    onClickHideIcon: true,
    displayChatIcon: false, // ซ่อนหรือแสดง icon bot
    initialed: false // มีการ initial Bot แล้วหรือเปล่า
  }

  window.openChatbot = function () {
    if (!window.chatbot.initialed) {
      // eslint-disable-next-line no-undef
      initBot('botGoesHere', window.chatbot)
      window.chatbot.initialed = true
    }

    // eslint-disable-next-line no-undef
    openBot()
    // eslint-disable-next-line no-undef
    closeLivechat()
  }
}
export default {
  name: 'ChatBotZwiz',
  data () {
    return {
      cookiesName: 'bull-popup-hidden'
    }
  },
  computed: {
    bullPopupHidden () {
      return this.$cookies.get(this.cookiesName)
    },
    isAutoOpenLiveChat () {
      const { query: { open_livechat: openLiveChat } } = this.$route
      return openLiveChat === 'true'
    },
    isAutoOpenChatBot () {
      const { query: { open_chatbot: openChatBot } } = this.$route
      return openChatBot === 'true'
    }
  },
  mounted () {
    this.init()
    this.registerEvent()
  },
  methods: {
    init () {
      this.initChat()
    },
    initChat () {
      if (this.bullPopupHidden && !(this.isAutoOpenLiveChat || this.isAutoOpenChatBot)) { return }
      createChatBotScript(() => {
        createScriptSDK(() => {
          this.initWebChat()
          initChatBot()
          this.autoOpenChat()
        })
      })
    },
    initWebChat () {
      // if (!window.ZWIZ || (this.bullPopupHidden && this.isAutoOpenChat)) { return }
      if (!window.ZWIZ) { return }
      window.ZWIZ.initWebChat()
    },
    autoOpenChat () {
      this.autoOpenLiveChat()
      this.autoOpenChatBot()
    },
    autoOpenChatBot () {
      if (this.isAutoOpenChatBot) {
        this.$cookies.remove(this.cookiesName)
        window.openChatbot()
      }
    },
    autoOpenLiveChat () {
      if (this.isAutoOpenLiveChat) {
        this.$cookies.remove(this.cookiesName)
        window.ZWIZ.initWebChat({
          auto_login: true // default false, optional
        })
      }
    },
    registerEvent () {
      window.addEventListener('eventRequestAuthToken', () => {
        if (window.ZWIZ) {
          const accessToken = this.$cookies.get('id') || ''
          window.ZWIZ.handleUpdateToken({ token: accessToken })
        }
      })
    }
  }
}
