/**
 * State
 */
const state = () => ({
  info: null,
  profile: null,
  newsUnderlying: null,
  underlying: null,
  related: null,
  industry: null
})

/**
 * Mutations
 */
const mutations = {
  /* CompanyPrice - Price */

  setDWProfile (state, data) {
    state.profile = data
  },

  setDWInfo (state, data) {
    state.info = data
  },

  setDWIndustry (state, data) {
    state.industry = data
  },

  /* CompanyPrice - News */

  setNewsUnderlying (state, data) {
    state.newsUnderlying = data
  },

  setNewsIssue (state, data) {
    state.newsIssue = data
  },

  /* CompanyPrice - Product */

  setDWUnderlying (state, data) {
    state.underlying = data
  },

  /* CompanyPrice - Document */

  setDWDocument (state, data) {
    state.related = data
  }
}

/**
 * Actions
 */
const actions = {
  async searchSymbol ({ dispatch }, payload) {
    await Promise.allSettled([
      dispatch('quote/getStockIntradayChart', payload, { root: true }),
      dispatch('quote/getStockNewsList', payload, { root: true }),
      dispatch('quote/getStockIssuerNews', payload, { root: true }),
      dispatch('getNewsUnderlying', payload),
      dispatch('quote/getStockHistoricalTrading', payload, { root: true }),
      dispatch('getDWDocument', payload)
    ])
  },

  /* CompanyPrice - Price */

  async getDWProfile ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/dw/${quote}/profile?lang=${this.$i18n.locale}`
      )
      commit('setDWProfile', response)
    } catch (err) {
      commit('setDWProfile', null)
    }
  },

  async getDWInfo ({ commit, dispatch }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/dw/${quote}/info`, {
        params: { lang: this.$i18n.locale }
      })
      if (
        (response && response.underlyingType === 'S') ||
        (response && response.underlyingNative === 'F')
      ) {
        const { change, percentChange } = await dispatch(
          'quote/getStockInfoData',
          {
            quote:
              response.underlyingNative === 'F' ? quote : response.underlying
          },
          { root: true }
        )
        commit('setDWInfo', {
          ...response,
          underlyingChange: change,
          underlyingPercentChange: percentChange
        })
      } else if (response && response.underlyingType === 'I') {
        const { change, percentChange } = await dispatch('getDWIndustryData', {
          quote: response.underlying
        })
        commit('setDWInfo', {
          ...response,
          underlyingChange: change,
          underlyingPercentChange: percentChange
        })
      }
    } catch (err) {
      commit('setDWInfo', null)
    }
  },

  async getDWIndustry ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/index/${quote}/info`, {
        params: { lang: this.$i18n.locale }
      })
      commit('setDWIndustry', response)
    } catch (err) {
      commit('setDWIndustry', null)
    }
  },

  async getDWIndustryData ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/index/${quote}/info`, {
        params: { lang: this.$i18n.locale }
      })
      return response
    } catch (err) {
      return null
    }
  },

  /* CompanyPrice - News */

  async getNewsUnderlying ({ commit }, payload) {
    const { quote, limit = '5' } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/news/${quote}/underlying-news?lang=${this.$i18n.locale}&limit=${limit}`
      )
      if (response) {
        commit('setNewsUnderlying', response.newsInfoList)
      }
    } catch (err) {
      commit('setNewsUnderlying', null)
    }
  },

  /* CompanyPrice - Product */

  async getDWUnderlying ({ commit }, payload) {
    const { symbol, underlying } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/dw-info/list?underlyingSymbol=${underlying}&lang=${this.$i18n.locale}`
      )

      if (response) {
        const setRelatedStockData = {
          ...response,
          dwList: response.dwList.filter(
            item => item.symbol.toLowerCase() !== symbol.toLowerCase()
          )
        }
        commit('setDWUnderlying', setRelatedStockData.dwList)
      }
    } catch (err) {
      commit('setDWUnderlying', null)
    }
  },

  /* CompanyPrice - Document */

  async getDWDocument ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/dw/${quote}/related-document?lang=${this.$i18n.locale}`
      )
      commit('setDWDocument', response)
    } catch (err) {
      commit('setDWDocument', null)
    }
  }
}

/**
 * Getters
 */
const getters = {
  /* CompanyPrice - Price */

  getDWProfile: (state) => {
    return state.profile
  },

  getDWInfo: (state) => {
    return state.info
  },

  getDWIndustry: (state) => {
    return state.industry
  },

  /* CompanyPrice - News */

  getNewsUnderlying: (state) => {
    return state.newsUnderlying || []
  },

  /* CompanyPrice - Product */

  getDWUnderlying: (state) => {
    return state.underlying
  },

  /* CompanyPrice - Document */

  getDWDocument: (state) => {
    return state.related
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
