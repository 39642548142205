import dayjs from 'dayjs'
/**
 * State
 */
const state = () => ({
  price: {
    info: null,
    profile: null,
    intradayChart: null,
    highlightData: null,
    newsUnderLying: []
  },
  realty: {
    oneReport: [],
    annualReport: [],
    form56Report: [],
    shareholder: null,
    drShareholder: []
  }
})

/**
 * Mutations
 */
const mutations = {
  /* CompanyPrice - Price */

  setStockInfo (state, data) {
    state.price.info = data
  },

  setStockProfile (state, data) {
    state.price.profile = data
  },

  setStockIntradayChart (state, data) {
    state.price.intradayChart = data
  },

  setStockHighlightData (state, data) {
    state.price.highlightData = data
  },

  setNewsUnderLying (state, data) {
    state.price.newsUnderLying = data
  },

  /* CompanyProfile */

  setStockOneReport (state, data) {
    state.realty.oneReport = data
  },
  setStockAnnualReport (state, data) {
    state.realty.annualReport = data
  },
  setStockForm56Report (state, data) {
    state.realty.form56Report = data
  },
  setStockShareholder (state, data) {
    state.realty.shareholder = data
  },
  setDrShareholder (state, data) {
    state.realty.drShareholder = data
  }
}

/**
 * Actions
 */
const actions = {
  async searchSymbol ({ dispatch }, payload) {
    await Promise.allSettled([
      dispatch('getStockIntradayChart', payload),
      dispatch('getDrShareholder', payload),
      dispatch('getStockProfile', payload),
      dispatch('quote/getStockIndexList', payload, { root: true }),
      dispatch('quote/getStockHighlightData', payload, { root: true }),
      dispatch('quote/DR/getDrChartMarketCap', payload, { root: true }),
      dispatch('quote/DR/getDrChartOutStanding', payload, { root: true }),
      dispatch('quote/getStockNewsList', payload, { root: true }),
      dispatch('quote/getStockIssuerNews', payload, { root: true }),
      dispatch('getStockNewsUnderLying', {
        ...payload,
        fromDate: dayjs(
          new Date(new Date().setFullYear(new Date().getFullYear() - 1))
        ).format('DD/MM/YYYY'),
        toDate: dayjs().format('DD/MM/YYYY')
      }),
      dispatch('quote/getStockCompanyProfile', payload, { root: true }),
      dispatch('quote/getStockHistoricalTrading', payload, { root: true })
    ])
  },

  /* CompanyPrice - Price */

  async getStockInfo ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/drx/${quote}/info`, {
        params: { lang: this.$i18n.locale }
      })
      commit('setStockInfo', response)
    } catch (err) {
      commit('setStockInfo', null)
    }
  },

  async getStockIntradayChart ({ commit }, payload) {
    const { quote, period, accumulated } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/drx/${quote}/intraday-chart?period=${period}&accumulated=${accumulated}&lang=${this.$i18n.locale}`
      )
      commit('setStockIntradayChart', response)
    } catch (err) {
      commit('setStockIntradayChart', null)
    }
  },

  async getStockNewsUnderLying ({ commit }, payload) {
    const { quote, fromDate, toDate } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/drx/news/underlying?symbols=${quote}&fromDate=${fromDate}&toDate=${toDate}&lang=${this.$i18n.locale}`
      )
      commit('setNewsUnderLying', response)
    } catch (err) {
      commit('setNewsUnderLying', null)
    }
  },

  /* CompanyProfile */

  async getStockProfile ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/dr/${quote}/profile`, {
        params: { lang: this.$i18n.locale }
      })
      commit('setStockProfile', response)
    } catch (err) {
      commit('setStockProfile', null)
    }
  },
  async getDrShareholder ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/shareholder`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setDrShareholder', response)
    } catch (err) {
      commit('setDrShareholder', null)
    }
  }
}

/**
 * Getters
 */
const getters = {
  /* CompanyPrice - Price */

  getStockInfo: (state) => {
    return state.price.info
  },

  getStockProfile: (state) => {
    return state.price.profile
  },

  getStockIntradayChart: (state) => {
    return state.price.intradayChart
  },

  getStockHighlightData: (state) => {
    return state.price.highlightData
  },

  getStockNewsUnderLying: (state) => {
    return state.price.newsUnderLying
  },

  /* CompanyProfile */

  getStockOneReport: (state) => {
    return state.realty.oneReport
  },
  getStockAnnualReport: (state) => {
    return state.realty.annualReport
  },
  getStockForm56Report: (state) => {
    return state.realty.form56Report
  },
  getStockShareholder: (state) => {
    return state.realty.shareholder
  },
  getDrShareholder: (state) => {
    return state.realty.drShareholder || []
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
