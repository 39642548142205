/**
 * State
 */
const state = () => ({
  userProfile: {
    firstName: null,
    lastName: null,
    userRef: null
  }
})

/**
 * Mutations
 */
const mutations = {
  setUserProfile (state, data) {
    state.userProfile = data
  }
}

/**
 * Actions
 */
const actions = {
  async getFavoriteSymbolsTable ({ commit }, securityType) {
    // console.log('securityType', securityType)
    return await this.$apiMember
      .get(`/api/gw-api/favorite/${securityType}/price`)
      .then((res) => {
        // console.log('res.data', res.data)
        return res.data
      }).catch(err => err)
  },
  async getFavoriteDelisted ({ commit }, securityType) {
    // console.log('securityType', securityType)
    return await this.$apiMember
      .get(`/api/gw-api/favorite/${securityType}/delisted`)
      .then((res) => {
        // console.log('res.data', res.data)
        return res.data
      }).catch(err => err)
  },
  async getUserInfomation ({ commit }) {
    return await this.$apiMember
      .get('/api/gw-api/member/profile')
      .then((res) => {
        return res.data
      }).catch(err => err)
  },
  async getFavoriteSymbols ({ commit }, securityType) {
    return await this.$apiMember
      .get(`/api/gw-api/favorite/${securityType}`)
      .then((res) => {
        return res.data
      }).catch(err => err)
  },
  async getETFInfoListBySymbols ({ commit }, payload) {
    const { symbolsList } = payload
    return await this.$axios
      .get(`api/set/etf/search?symbols=${symbolsList}`)
      .then((res) => {
        // console.log('res.data', res.data)
        return res.data
      }).catch(err => err)
  },
  async getDWInfoListBySymbols ({ commit }, payload) {
    const { symbolsList } = payload
    return await this.$axios
      .get(`api/set/dw-info/list-by-symbols?symbols=${symbolsList}`)
      .then((res) => {
        // console.log('res.data', res.data)
        return res.data
      }).catch(err => err)
  },
  async getStockInfoListBySymbols ({ commit }, payload) {
    const { symbolsList } = payload
    return await this.$axios
      .get(`api/set/stock-info/list-by-symbols?symbols=${symbolsList}`)
      .then((res) => {
        // console.log('res.data', res.data)
        return res.data
      }).catch(err => err)
  }
}

const getters = {
  getUserProfile: (state) => {
    return state.userProfile
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
