//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'IconChevronRight'
  // serverCacheKey: () => 'IconChevronRight-v1'
}
