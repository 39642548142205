import { render, staticRenderFns } from "./Floating.vue?vue&type=template&id=07dd6764&"
import script from "./Floating.vue?vue&type=script&lang=js&"
export * from "./Floating.vue?vue&type=script&lang=js&"
import style0 from "./Floating.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconClose: require('/app/components/Icon/Close.vue').default,Button: require('/app/components/Button/Button.vue').default,RawHtml: require('/app/components/RawHtml.vue').default})
