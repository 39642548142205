/**
 * State
 */
const state = () => ({
  tradingMethodDefault: [],
  nvdrOverview: [],
  nvdrComparative: [],
  tradingStockDefault: null,
  outStandingShareDefault: null
})

/**
     * Mutations
     */
const mutations = {
  setNvdrOverview (state, data) {
    state.nvdrOverview = data
  },
  setNvdrComparative (state, data) {
    state.nvdrComparative = data
  },
  setTradingStockDefault (state, data) {
    state.tradingStockDefault = data
  },
  setTradingMethodDefault (state, data) {
    state.tradingMethodDefault = data
  },
  setNvdrOutStandingShare  (state, data) {
    state.outStandingShareDefault = data
  }
}

/**
     * Actions
     */
const actions = {
  async getMarketInformationDefault ({ commit }, payload) {
    try {
      const response = await this.$axios.get(`/api/set/index/${payload.index}/latest-historical-trading`)
      return response.data
    } catch (error) {
      return null
    }
  },
  async getNvdrOverviewDefault ({ commit }) {
    try {
      const response = await this.$axios.get('/api/set/nvdr-trade/overview')
      return response.data
    } catch (error) {
      return []
    }
  },
  async getNvdrComparativeDefault ({ commit }) {
    try {
      const response = await this.$axios.get('/api/set/nvdr-trade/comparative-data')
      return response.data
    } catch (error) {
      return []
    }
  },
  async getTradingSectorDefault ({ commit }, payload) {
    const res = await this.$axios.get(`/api/set/nvdr-trade/trading-by-sector?market=${payload.market}`).then((res) => {
      return res.data
    }).catch(err => err)
    return res
  },
  async getTradingStockDefault ({ commit }, payload) {
    try {
      const apiPath = '/api/set/nvdr-trade/stock-trading'
      const { date, sortBy, sectors, symbols } = payload
      const options = {
        params: {
          sortBy,
          date,
          sectors,
          symbols
        }
      }
      const response = await this.$axios.$get(apiPath, options)
      commit('setTradingStockDefault', response)
      return response
    } catch (error) {
      return null
    }
  },
  async getOutStandingShareDefault ({ commit }, payload) {
    try {
      const apiPath = '/api/set/nvdr-trade/outstanding-share'
      const { symbols, date, sectors, type } = payload
      const options = {
        params: {
          symbols,
          date,
          sectors,
          type
        }
      }
      const response = await this.$axios.$get(apiPath, options)
      commit('setNvdrOutStandingShare', response)
      return response
    } catch (error) {
      return null
    }
  },
  async getTradingMethodDefault ({ commit }) {
    try {
      const response = await this.$axios.get('/api/set/nvdr-trade/trading-by-method')
      return response.data
    } catch (error) {
      return []
    }
  }
}

/**
     * Getters
     */
const getters = {
  getOutStandingShare: (state) => {
    return state.outStandingShareDefault ? state.outStandingShareDefault : null
  },
  getTradingStock: (state) => {
    return state.tradingStockDefault ? state.tradingStockDefault : null
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
