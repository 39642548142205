//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { setAttributeLink } from '~/helpers'
import { responsiveImg } from '~/plugins/responsive-img'
export default {
  name: 'SubMenuCard',
  props: {
    lastUpdate: {
      type: Date,
      default: null
    },
    menu: {
      type: Object,
      default: () => {}
    },
    menuParent: {
      type: Object,
      default: Object
    },
    isMouseHover: {
      type: Boolean,
      default: false
    }
  },
  serverCacheKey: props => `SubMenuCard::${props.menu?.menuItemUuid ?? ''}::${props.lastUpdate?.toISOString()}`,
  data () {
    return {
      isHasBgImage: false
    }
  },
  computed: {
    title () {
      return this.menu ? this.menu.name : ''
    },
    image () {
      const bgImg = responsiveImg((this.menu && this.menu.image) || null)
      if (bgImg && bgImg !== '') {
        return bgImg
      }
      return null
    }
  },
  watch: {
    isMouseHover () {
      if (this.isMouseHover && !this.isHasBgImage) {
        this.isHasBgImage = true
        this.setImg()
      }
    }
  },
  mounted () {
    if (this.isMouseHover && !this.isHasBgImage) {
      this.isHasBgImage = true
      this.setImg()
    }
  },
  methods: {
    setAttributeLink,
    setImg () {
      if (this.image) {
        // this.$refs.cardMenu.style.backgroundImage = `url('${this.image}')`
        this.$refs.cardMenu.setAttribute('data-bg', `${this.image}`)
        this.$refs.cardMenu.classList.add('lazyload')
      }
    },
    handleClickItem () {
      if (this.menu && this.menu.url !== '' && this.menu.url !== '#') {
        let title = ''
        if (this.menuParent && this.menuParent.name) {
          title = this.menuParent.name
        }
        if (title) {
          title = `${title},`
        }
        if (this.title) {
          title = `${title}${this.title}`
        }
        this.$personalized.clickElement({
          name: 'all15',
          optionLang: {
            menu: title
          }
        })
      }
    }
  }
}
