// const cacheControl = (values) => {
//   const cacheControlValue = Object.entries(values)
//     .map(([key, value]) => `${key}=${value}`)
//     .join(',')

//   return cacheControlValue
// }

/* module.exports = function (req, res, next) {
  const MobileDetect = require('mobile-detect')
  const md = new MobileDetect(req.headers['user-agent'])
  const isMobile = md.phone() !== null || md.mobile() === 'UnknownMobile'
  const isTablet = md.tablet() !== null || md.mobile() === 'UnknownTablet'
  const isDesktop = !isMobile && !isTablet
  const runOnDevice = isDesktop === false ? 'mobile' : 'desktop'
  res.setHeader('x-device', runOnDevice)
  next()
} */

export default ({ res, req }) => {
  if (!process.server) { return }

  const MobileDetect = require('mobile-detect')
  const md = new MobileDetect(req.headers['user-agent'])
  const isMobile = md.phone() !== null || md.mobile() === 'UnknownMobile'
  const isTablet = md.tablet() !== null || md.mobile() === 'UnknownTablet'
  const isDesktop = !isMobile && !isTablet
  const runOnDevice = isDesktop === false ? 'mobile' : 'desktop'
  res.setHeader('x-device', runOnDevice)
  // res.setHeader('cache-control', 'max-age=0, public')
}
