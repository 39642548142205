
const generatorXTypeColorHead = (xType) => {
  switch (xType) {
    case 'XD':
    case 'XM':
    case 'XN':
    case 'XB':
    case 'XE':
    case 'XR':
    case 'XW':
    case 'XT':
    case 'XD(ST)':
    case 'XI':
    case 'XP':
    case 'XA':
      return '#16B916'
    default :
      return '#F3F7F9'
  }
}
const generatorXTypeColor = (xType) => {
  switch (xType) {
    case 'XD':
    case 'XD(ST)':
      return '#CEF5CE'
    case 'XR':
      return '#D7F3A8'
    case 'XW':
      return '#D1F3F3'
    case 'XT':
      return '#FFCAAC'
    case 'XM':
      return '#BFDDFF'
    case 'XI':
      return '#ECB32A'
    case 'XP':
      return '#EB7C15'
    case 'XA':
      return '#A5503D'
    case 'XE':
      return '#DFD8F3'
    case 'XN':
      return '#E2C2F1'
    case 'XB':
      return '#F8D9ED'
    default :
      return '#F3F7F9'
  }
}
const generatorXTypeCardColor = (xType) => {
  switch (xType) {
    case 'XD':
    case 'XD(ST)':
      return '#16B916'
    case 'XR':
      return '#57B110'
    case 'XW':
      return '#3FC1C0'
    case 'XT':
      return '#106FDC'
    case 'XM':
      return '#3861CA'
    case 'XI':
      return '#ECB32A'
    case 'XP':
      return '#EB7C15'
    case 'XA':
      return '#A5503D'
    case 'XE':
      return '#937CD6'
    case 'XN':
      return '#9D32CF'
    case 'XB':
      return '#E77EC3'
    default:
      return '#F3F7F9'
  }
}
const generatorXTypeFontColor = (xType) => {
  switch (xType) {
    case 'XD':
    case 'XD(ST)':
      return 'xd-font-color'
    case 'XR':
      return 'xr-font-color'
    case 'XW':
      return 'xw-font-color'
    case 'XT':
      return 'xt-font-color'
    case 'XM':
      return 'xm-font-color'
    case 'XMMeeting':
      return 'xm-meet-font-color'
    case 'XI':
      return 'xi-font-color'
    case 'XP':
      return 'xp-font-color'
    case 'XA':
      return 'xa-font-color'
    case 'XE':
      return 'xe-font-color'
    case 'XN':
      return 'xn-font-color'
    case 'XB':
      return 'xb-font-color'
    default :
      return ''
  }
}
const convertArrayToObject = (array, key) =>
  array.reduce(
    (obj, item) => ({
      ...obj,
      [item[key]]: item
    }),
    {}
  )
const setMoneynessStatusLabel = (key) => {
  switch (key) {
    case 'gt-20-ITM':
      return '> 20% ITM'
    case 'gte-10-ITM,lte-20-ITM':
      return '10 - 20% ITM'
    case 'gte-0-ATM,lte-10-ITM':
      return 'ATM - 10% ITM'
    case 'gte-0-ATM,lte-10-OTM':
      return '10% OTM - ATM'
    case 'gte-10-OTM,lte-20-OTM':
      return '10 - 20% OTM'
    case 'gt-20-OTM':
      return '> 20% OTM'
  }
}
const setDwSearchTimeToMaturitiesLabel = (key, lang) => {
  if (lang === 'th') {
    switch (key) {
      case '0-30':
        return 'น้อยกว่า 1 เดือน'
      case '31-90':
        return '1 - 3 เดือน'
      case '91-180':
        return '3 - 6 เดือน'
      case '181':
        return 'มากกว่า 6 เดือน'
    }
  } else {
    switch (key) {
      case '0-30':
        return 'Less than 1 Month'
      case '31-90':
        return '1 - 3 Months'
      case '91-180':
        return '3 - 6 Months'
      case '181':
        return 'Greater than 6 Months'
    }
  }
}
const setDwSearchEgListLabel = (key) => {
  switch (key) {
    case 'lt-5':
      return '< 5x'
    case 'gte-5,lt-10':
      return '5 - 10x'
    case 'gte-10,lte-15':
      return '10 - 15x'
    case 'gt-15':
      return '> 15x'
  }
}
const setQuater = (quater, i18n) => {
  switch (quater) {
    case 'Q1':
    case 'Q2':
    case 'Q3':
    case 'Q4':
      return i18n.locale === 'th' ? `ไตรมาส ${quater.charAt(1)}/` : `${quater}/`
    case 'Q9':
    case 'YE':
      return i18n.locale === 'th' ? 'งบปี ' : 'Y/E '
    case '3M':
    case '6M':
    case '9M':
      return i18n.locale === 'th' ? `งบ ${quater.charAt(0)} เดือน` : `${quater.charAt(0)}M/`
    default:
      return ''
  }
}
const setQuaterSubmit = (quater, year, i18n) => {
  switch (quater) {
    case 'Q1':
    case 'Q2':
    case 'Q3':
    case 'Q4':
      return i18n.locale === 'th' ? `ไตรมาส ${quater.charAt(1)}/${parseInt(year) + 543}` : `${quater} /${year}`
    case 'Q9':
    case 'YE':
      return i18n.locale === 'th' ? `ประจำปี ${parseInt(year) + 543}` : `Yearly ${year}`
    case '3M':
    case '6M':
    case '9M':
      return i18n.locale === 'th' ? `งบ ${quater.charAt(0)} เดือน` : `${quater.charAt(0)}M/`
    default:
      return ''
  }
}
const setQuaterTitle = (quater, i18n) => {
  switch (quater) {
    case 'Q1':
    case 'Q2':
    case 'Q3':
      return i18n.locale === 'th' ? `ไตรมาส ${quater.charAt(1)}/` : `${quater}/`
    case 'Q4':
      return i18n.locale === 'th' ? 'งบปี' : 'Y/E'
    default:
      return ''
  }
}
const setQuaterValue = (quater, i18n) => {
  switch (quater) {
    case 'Q1':
    case 'Q2':
    case 'Q3':
      return quater.charAt(1)
    default:
      return ''
  }
}
const setQuaterFinancial5Years = (quater, i18n) => {
  switch (quater) {
    case 'Q1':
    case 'Q2':
    case 'Q3':
    case 'Q4':
      return i18n.locale === 'th' ? `ไตรมาส ${quater.charAt(1)}/` : `${quater}/`
    case 'Q9':
    case 'YE':
      return i18n.locale === 'th' ? 'งบปี ' : 'Y/E '
    case '3M':
      return i18n.locale === 'th' ? 'ไตรมาส 1/' : 'Q1/'
    case '6M':
    case '9M':
      return i18n.locale === 'th' ? `งบ ${quater.charAt(0)} เดือน` : `${quater.charAt(0)}M/`
    default:
      return ''
  }
}
const setQuaterFactSheet = (quater, month, year, i18n) => {
  switch (quater) {
    case 'Q1':
    case 'Q2':
    case 'Q3':
    case 'Q4':
      return i18n?.locale === 'th' ? `ไตรมาส ${quater.charAt(1)}/${year}` : `${quater}/${year}`
    case 'Q9':
    case 'YE':
      return i18n?.locale === 'th' ? `งบปี ${year}` : `Y/E ${year}`
    case '3M':
      return i18n?.locale === 'th' ? `ไตรมาส 1/${year}` : `Q1/${year}`
    case '6M':
      return i18n?.locale === 'th' ? `งบ 6 เดือน/${year}` : `6M/${year}`
    case '9M':
      return i18n?.locale === 'th' ? `งบ 9 เดือน/${year}` : `9M/${year}`
    default:
      return ''
  }
}
const setImportantChart = (quater) => {
  switch (quater) {
    case 'Q1':
    case 'Q2':
    case 'Q3':
    case 'Q4':
      return `${quater}/`
    case 'Q9':
    case 'YE':
      return ''
    case '3M':
    case '6M':
    case '9M':
      return `${quater}/`
    default:
      return ''
  }
}
const setStatusColor = (xType) => {
  switch (xType) {
    case 'Approved':
      return '#0DB23B'
    case 'Submitted':
      return '#F7921E'
    case 'Effective':
      return '#2f74fa'
    default :
      return '#1e1e21'
  }
}
const checkURLProtocol = (url) => {
  // console.log('url', url)
  let _url = ''
  const text = url === null ? '' : url
  const position = (text.includes('https') || text.includes('http'))
  _url = position ? url : `https://${url}`
  return _url
}
const setCategoryType = (securityType) => {
  switch (securityType) {
    case 'S':
    case 'F':
    case 'P':
    case 'Q':
    case 'W':
    case 'U':
      return 'stock'
    case 'L':
      return 'etf'
    case 'X':
      return 'dr'
    case 'XF':
      return 'drx'
    case 'V':
      return 'dw'
    case 'IO':
      return 'options'
    case 'FUND':
      return 'fund'
    case 'IF':
    case 'SIF':
    case 'SF':
    case 'PMF':
    case 'DPM':
    case 'CF':
    case 'IRF':
    case 'AF':
      return 'futures'
  }
}

const setValidateCategory = (securityType) => {
  switch (securityType) {
    case 'stock':
      return 'ประเภทหุ้น'
    case 'etf':
      return 'ประเภทETF'
    case 'dr':
      return 'ประเภทDR'
    case 'drx':
      return 'ประเภทDRx'
    case 'dw':
      return 'ประเภทDW'
    case 'options':
      return 'ประเภทoptions'
    case 'fund':
      return 'ประเภทfund'
    case 'futures':
      return 'ประเภทfutures'
    default:
      return 'ประเภทนี้'
  }
}
const setFlagStatus = (flag, securityType) => {
  switch (flag) {
    case 'D':
      return 'Delisted'
    case 'E':
      return 'Expired'
    default:
      return securityType === 'V' ? 'Expired' : 'Delisted'
  }
}
export {
  setQuater,
  setQuaterSubmit,
  setQuaterTitle,
  setQuaterValue,
  generatorXTypeColor,
  generatorXTypeColorHead,
  convertArrayToObject,
  generatorXTypeCardColor,
  generatorXTypeFontColor,
  setMoneynessStatusLabel,
  setDwSearchTimeToMaturitiesLabel,
  setDwSearchEgListLabel,
  setQuaterFactSheet,
  setValidateCategory,
  setStatusColor,
  checkURLProtocol,
  setImportantChart,
  setQuaterFinancial5Years,
  setCategoryType,
  setFlagStatus
}
