//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import { deviceSizes } from '@/config/core'
export default {
  name: 'AppBar',
  data () {
    return {
      setMemberLink: this.$authUser.login(), // link to login set member
      isStick: false,
      isPanelSearchSticky: false,
      showMenuMobile: false,
      navMenuHeight: 0,
      oldScroll: 0,
      newScroll: 0,
      scrollIsDown: true,
      textStatusResponsive: true,
      keywordSearch: '',
      delayShowDdStatus: true
    }
  },
  computed: {
    ...mapGetters({
      content: 'contents/getContentData',
      activeDropDown: 'search/getActiveDropDown'
    }),
    menuHeader () {
      if (!this.content.body) {
        return null
      }
      const layoutMenuLocations = this.content.body.layoutMenuLocations || []
      const menuHead = layoutMenuLocations.find(
        h => h.locationCode === 'M_HEADER'
      )
      if (menuHead) {
        return menuHead.menu
      }
      return null
    },
    lastUpdate () {
      if (this.menuHeader?.lastUpdate) {
        return new Date(this.menuHeader.lastUpdate)
      }
      return this.menuHeader?.lastUpdate
    },
    menuList () {
      if (this.menuHeader) {
        return this.menuHeader.menuItems
      }
      return []
    },
    permissionUser () {
      return this.$authUser.permissionUser()
    },
    userProfile () {
      return this.$authUser.userProfile()
    },
    isMyProfile () {
      return this.$route.fullPath.includes('/member/my-profile')
    },
    isMyFavorite () {
      return this.$route.fullPath.includes('/member/favorites')
    },
    toMyFavorite () {
      let url = ''
      url = `/${this.$i18n.locale}/member/favorites`
      return url
    },
    toMyProfile () {
      let url = ''
      url = `/${this.$i18n.locale}/member/my-profile/main`
      return url
    }
  },
  watch: {
    activeDropDown (status) {
      if (status) {
        this.updateSiteContentPaddingTop(`${this.getAppbarHeight()}px`)
        this.isPanelSearchSticky = this.isForMobile()
        this.delayShowDd()
      } else {
        this.updateSiteContentPaddingTop(null)
        this.isPanelSearchSticky = false
        this.delayShowDdStatus = true
      }
    }
  },
  mounted () {
    this.lastScrollTop = this.getNavbarTop()
    this.updateBackdrop()
    this.registerEvent()
  },
  beforeDestroy () {
    this.removeEvent()
  },
  methods: {
    ...mapActions('search', ['setActiveDropDown']),
    delayShowDd () {
      setTimeout(() => {
        this.delayShowDdStatus = false
      }, 100)
    },
    isForMobile () {
      return window.innerWidth <= deviceSizes.xl
    },
    registerEvent () {
      window.addEventListener('scroll', this.update)
    },
    removeEvent () {
      window.removeEventListener('scroll', this.update)
    },
    sectionTop () {
      return document.getElementById('sectionTop') || { clientHeight: 0 }
    },
    backDrop () {
      return document.querySelector('header .back-drop')
    },
    getNavbarTop () {
      const top = document.getElementById('appbar').offsetTop
      return top
    },
    getScrollTop () {
      return window.pageYOffset || document.documentElement.scrollTop
    },
    getAppbarHeight () {
      return (
        document.querySelector('.appbar').getBoundingClientRect().height || 0
      )
    },
    getMarketHeight () {
      return (
        document.querySelector('.market-summary').getBoundingClientRect()
          .height || 0
      )
    },
    getAppMenu () {
      return document.querySelector('.app-nav-menu')
    },
    getToolbarHeight () {
      return this.$refs.toolbar.getBoundingClientRect().height || 0
    },
    updateSiteContentPaddingTop (paddingTop) {
      const el = document.querySelector('header').nextElementSibling
      if (el) {
        el.style.paddingTop = paddingTop
      }
    },
    closeSearchPanel () {
      this.setActiveDropDown(false)
      this.isPanelSearchSticky = false
    },
    toggleMenuMobile () {
      this.showMenuMobile = !this.showMenuMobile
      // this.updateNavbarMobileTop()
      setTimeout(() => {
        this.updateNavbarMobileTop()
      }, 150)
      if (this.showMenuMobile) {
        document.body.classList.add('menu-open')
      } else {
        setTimeout(() => {
          document.body.classList.remove('menu-open')
        }, 200)
      }
    },
    updateNavbarMobileTop () {
      const appNavBarMobile = document.getElementById('app-nav-bar-mobile')
      if (appNavBarMobile) {
        // let top = this.getAppbarHeight()
        // if (!this.isStick) {
        //   top =
        //     this.getNavbarTop() + this.getAppbarHeight() - this.getScrollTop()
        // }
        const appbar = this.$refs.appbar
        if (appbar) {
          const heightAppBar = appbar.clientHeight
          appNavBarMobile.style.top = heightAppBar + 'px'
        }
      }
    },
    getScrollbarWidth () {
      const documentWidth = document.documentElement.clientWidth
      return Math.abs(window.innerWidth - documentWidth)
    },
    update () {
      this.detectScrollDown()
      const appbar = this.$refs.appbar || null
      const pageYOffset = this.getScrollTop()
      const sectionTop = this.sectionTop()
      const header = document.querySelector('header').nextElementSibling
      const lastScrollTop = sectionTop.clientHeight
      if (appbar && 'offsetTop' in appbar) {
        if (pageYOffset > lastScrollTop) {
          header.style.paddingTop = `${this.getAppbarHeight()}px`
          this.$refs.appbar.classList.add('fixed-top')
          this.isStick = true
        } else {
          const menuMobile = document.querySelector('.app-nav-bar-mobile.show')
          if (!menuMobile) {
            this.$refs.appbar.classList.remove('fixed-top')
            header.style.paddingTop = null
            this.isStick = false
          }
        }

        if (this.getAppMenu()) {
          if (
            pageYOffset > lastScrollTop + this.getAppbarHeight() &&
            this.scrollIsDown
          ) {
            this.getAppMenu().classList.add('hide')
            document.body.classList.add('menu-hidden')
          } else {
            this.getAppMenu().classList.remove('hide')
            document.body.classList.remove('menu-hidden')
          }
        }
        this.updateBackdrop()
      }
      this.updateNavbarMobileTop()
    },
    updateBackdrop () {
      const backDrop = this.backDrop()
      if (!backDrop) {
        return
      }
      this.backDrop().style.top = this.sectionTop().clientHeight + 'px'
    },
    detectScrollDown () {
      this.newScroll = window.pageYOffset
      if (this.oldScroll < this.newScroll) {
        this.scrollIsDown = true
      } else if (this.oldScroll > this.newScroll) {
        this.scrollIsDown = false
      }
      this.oldScroll = this.newScroll
    },
    logout () {
      this.$authUser.logout()
    },
    handleClickGetQuote () {
      this.$personalized.clickElement({
        name: 'all12'
      })
    },
    handleClickSetMember () {
      this.$personalized.clickElement({
        name: 'all14'
      })
    }
  }
}
