import { render, staticRenderFns } from "./Special.vue?vue&type=template&id=52b9c6cb&"
import script from "./Special.vue?vue&type=script&lang=js&"
export * from "./Special.vue?vue&type=script&lang=js&"
import style0 from "./Special.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconMenuArrow: require('/app/components/Icon/MenuArrow.vue').default})
