import main from '@/lang/specific-seo/th.json'
import EducationKnowledge from '@/lang/specific-seo/Education/Knowledge/th.json'
import Statistics from '@/lang/specific-seo/Market/Statistics/th.json'
import RuleBook from '@/lang/specific-seo/RuleBook/th.json'
import TSD from '@/lang/specific-seo/TSD/th.json'
import TCH from '@/lang/specific-seo/TCH/th.json'
import MarketQuote from '@/lang/specific-seo/Market/Quote/th.json'
import MarketIndex from '@/lang/specific-seo/Market/Index/th.json'
import MarketInfomation from '@/lang/specific-seo/Market/Information/th.json'
import MarketStockCalendar from '@/lang/specific-seo/Market/StockCalendar/th.json'
import MarketGetQuote from '@/lang/specific-seo/Market/GetQuote/th.json'
import News from '@/lang/specific-seo/News/th.json'
import Listing from '@/lang/specific-seo/Listing/th.json'
import SetSource from '@/lang/specific-seo/SetSource/th.json'
import Research from '@/lang/specific-seo/Education/Research/th.json'
import Member from '@/lang/specific-seo/Member/th.json'
import ErrorPage from '@/lang/specific-seo/Error/th.json'
import EventCalendar from '@/lang/specific-seo/EventCalendar/th.json'
import SetBook from '@/lang/specific-seo/SetBook/th.json'
import JoinUs from '@/lang/specific-seo/JoinUs/th.json'
export default [
  ...main,
  ...EducationKnowledge,
  ...Statistics,
  ...RuleBook,
  ...TSD,
  ...TCH,
  ...MarketQuote,
  ...MarketIndex,
  ...MarketInfomation,
  ...MarketStockCalendar,
  ...MarketGetQuote,
  ...News,
  ...Listing,
  ...SetSource,
  ...Research,
  ...Member,
  ...ErrorPage,
  ...EventCalendar,
  ...SetBook,
  ...JoinUs
]
