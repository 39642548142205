const state = () => ({
  playlist: null
})
const mutations = {
  setSetSourcePlaylist (state, data) {
    const result = data.sort((a, b) => { return a.order - b.order })
    state.playlist = result
  }
}
const actions = {
  async getSetSourcePlaylist ({ commit }, payload) {
    const options = {
      params: payload
    }
    try {
      await this.$apiCms.get('/api/cms/v1/playlists/video-playlists', options).then((response) => {
        const dataItem = []
        if (response.data && response.data.items && response.data.items.length > 0) {
          const playlist = response.data.items.map((value, index) => {
            const optionsVideoPlayList = {
              params: {
                playlistUuid: value.uuid,
                language: payload.language,
                pageIndex: 0,
                pageSize: 12
              }
            }
            return this.$apiCms.get('/api/cms/v1/playlists/video-playlists/videos', optionsVideoPlayList).then((res) => {
              const { items } = res.data
              dataItem.push({ ...value, order: index, items })
            })
          })
          return Promise.all(playlist).then(() => {
            commit('setSetSourcePlaylist', dataItem)
          })
        }
      })
    } catch (error) {
      console.log('error', error)
    }
  }
}
const getters = {
  getSetSourcePlaylist: (state) => {
    return state.playlist || []
  }
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
