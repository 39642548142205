//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { responsiveImg } from '~/plugins/responsive-img'
import { setAttributeLink } from '~/helpers'
export default {
  name: 'SubMenuSpecial',
  props: {
    lastUpdate: {
      type: Date,
      default: null
    },
    menu: {
      type: Object,
      required: true,
      default: Object
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    menuParent: {
      type: Object,
      default: Object
    }
  },
  data () {
    return {
      isBg: false
    }
  },
  serverCacheKey: props => `SubMenuSpecial::${props.menu?.menuItemUuid ?? ''}::${props.lastUpdate?.toISOString()}`,
  mounted () {
    // if (this.menu && this.menu.image && this.menu.image.length && !this.isMobile) {
    //   this.$refs.menuLink.style.backgroundImage = `url('${responsiveImg(this.menu.image)}')`
    //   this.isBg = true
    // } else {
    //   this.isBg = false
    // }
  },
  methods: {
    setAttributeLink,
    handleClickItem () {
      if (this.menu && this.menu.url !== '' && this.menu.url !== '#') {
        let title = ''
        if (this.menuParent && this.menuParent.name) {
          title = this.menuParent.name
        }
        if (title) {
          title = `${title},`
        }
        if (this.menu) {
          title = `${title}${this.menu.name}`
        }
        this.$personalized.clickElement({
          name: 'all15',
          optionLang: {
            menu: title
          }
        })
      }
    }
  }
}
