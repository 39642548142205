import { customAlphabet } from 'nanoid'
import ElementToasts from '~/components/Toasts/Toasts.vue'
function toastBodyBuilder (type, id, message) {
  const { $createElement } = window.$nuxt
  const toastBody = $createElement(ElementToasts, { props: { toastType: type, toastId: id, message } })
  return toastBody
}
function createIDToast () {
  const nanoid = customAlphabet(
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
    4
  )
  return nanoid()
}

function setToastClass (type) {
  if (type === 'favorite') {
    return 'notification-snackbar-outline outline-success'
  } else if (type === 'delete') {
    return 'notification-snackbar-outline outline-delete'
  } else if (type === 'unfavorite') {
    return 'notification-snackbar-outline outline-failed'
  } else if (type === 'List full') {
    return 'notification-snackbar-outline outline-failed'
  } else if (type === 'rating') {
    return 'notification-snackbar-inline outline-success'
  } else {
    return 'notification-snackbar'
  }
}
export default (context, inject) => {
  const plugin = {
    success (type) {
      const nanoid = createIDToast()
      const bodyToastClass = setToastClass(type)
      const { $bvToast } = window.$nuxt
      $bvToast.toast(toastBodyBuilder(type, 'bookmark-snackbar-success_' + nanoid), {
        id: 'bookmark-snackbar-success_' + nanoid,
        headerClass: 'd-none',
        toastClass: bodyToastClass,
        toaster: 'b-toaster-bottom-left',
        // noAutoHide: true,
        autoHideDelay: 4000,
        appendToast: true
      })
    },
    fail (type) {
      const nanoid = createIDToast()
      const bodyToastClass = setToastClass(type)
      const { $bvToast } = window.$nuxt
      $bvToast.toast(toastBodyBuilder(type, 'bookmark-snackbar-failed_' + nanoid), {
        id: 'bookmark-snackbar-failed_' + nanoid,
        headerClass: 'd-none',
        toastClass: bodyToastClass,
        toaster: 'b-toaster-bottom-left',
        // noAutoHide: true,
        autoHideDelay: 4000,
        appendToast: true
      })
    }
  }

  inject('toast', plugin)
  context.$toast = plugin
}
