/**
 * State
 */
const state = () => ({
  LastMarketFiveDay: []
})

/**
 * Mutations
 */
const mutations = {
  setLastMarketFiveDay (state, data) {
    state.LastMarketFiveDay = data
  }
}

/**
 * Actions
 */
const actions = {
  async getLastMarketFiveDay ({ commit }) {
    try {
      const response = await this.$axios.get('api/set/market/five-day')
      commit('setLastMarketFiveDay', response.data)
    } catch (err) {
      commit('setLastMarketFiveDay', null)
    }
  }
}

/**
 * Getters
 */
const getters = {
  getLastMarketFiveDayData: (state) => {
    return state.LastMarketFiveDay ? state.LastMarketFiveDay : []
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
