/* eslint-disable no-array-constructor */
/**
 * State
 */
const state = () => ({
  index: {
    set: null,
    ftseSet: null,
    ftseAsean: null,
    chart: null,
    overall: {
      overview: {},
      performance: {},
      changeOfIndex: {}
    },
    topten: {
      data: {},
      popular: {},
      chart: {}
      // chart: []
    },
    news: null,
    calendar: {},
    securitiesList: null,
    securitiesTypeList: null,
    // autoMatching: null,
    tradingReport: null,
    oddLot: null
  }
})

/**
 * Mutations
 */
const mutations = {
  setStockIndexSet (state, data) {
    state.index.set = data[0].indexIndustrySectors
    state.index.ftseSet = data[1]
    state.index.ftseAsean = data[2]
  },

  setStockOverview (
    state,
    [overviewSetData, overviewMaiData, conclusionSetData, conclusionMaiData]
  ) {
    state.index.overall.overview = {
      set: overviewSetData,
      mai: overviewMaiData
    }
    state.index.overall.performance = {
      date: conclusionSetData.date,
      items: [
        {
          name: this.$i18n.t('stock.overview.marketcap'),
          set: conclusionSetData.marketCap / 1000000,
          mai: conclusionMaiData.marketCap / 1000000
        },
        {
          name: this.$i18n.t('stock.overview.turnoverRatio'),
          set: conclusionSetData.ytdTurnoverRatio,
          mai: conclusionMaiData.ytdTurnoverRatio
        },
        {
          name: this.$i18n.t('stock.overview.pe'),
          set: conclusionSetData.pe,
          mai: conclusionMaiData.pe
        },
        {
          name: this.$i18n.t('stock.overview.pbv'),
          set: conclusionSetData.pbv,
          mai: conclusionMaiData.pbv
        },
        {
          name: this.$i18n.t('stock.overview.marketYield'),
          set: conclusionSetData.dividendYield,
          mai: conclusionMaiData.dividendYield
        },
        {
          name: this.$i18n.t('stock.overview.earnPerShare'),
          set: conclusionSetData.eps,
          mai: conclusionMaiData.eps
        }
      ]
    }
    state.index.overall.changeOfIndex = {
      date: conclusionSetData.date,
      items: [
        {
          name: this.$i18n.t('stock.overview.lastThreeMonth'),
          set: conclusionSetData.threeMonthPercentChange,
          mai: conclusionMaiData.threeMonthPercentChange
        },
        {
          name: this.$i18n.t('stock.overview.lastSixMonth'),
          set: conclusionSetData.sixMonthPercentChange,
          mai: conclusionMaiData.sixMonthPercentChange
        },
        {
          name: this.$i18n.t('stock.overview.YTD'),
          set: conclusionSetData.ytdPercentChange,
          mai: conclusionMaiData.ytdPercentChange
        }
      ]
    }
  },
  setStockIndexTop10 (state, data) {
    state.index.topten.data = data
  },
  setStockIndexTop10Popular (state, data) {
    state.index.topten.popular = data
  },
  // setStockIndexTop10 (state, data) {
  //   state.index.topten.data = data
  //   state.index.topten.chart = {}
  // },

  setStockOverviewNews (state, data) {
    state.index.news = (data && data.newsInfoList.slice(0, 5)) || []
  },

  setStockIndexChart (state, data) {
    state.index.chart = data
  },

  setStockIndexTop10Chart (state, data) {
    // state.index.topten.chart.push(data)
    state.index.topten.chart[`${data.symbol}`] = data.chart
  },

  setStockCalendar (state, [xCalendarData, meetingCalendarData]) {
    state.index.calendar = {
      xCalendar: xCalendarData,
      meetingCalendar: meetingCalendarData
    }
  },

  setSecuritiesList (state, data) {
    state.index.securitiesList = data
  },

  setSecuritiesTypeList (state, data) {
    state.index.securitiesTypeList = data
  },

  // setAutoMatching (state, data) {
  //   state.index.autoMatching = data
  // },

  setTradingReport (state, data) {
    state.index.tradingReport = data
  },

  setOddLot (state, data) {
    state.index.oddLot = data
  }
}

/**
 * Actions
 */
const actions = {
  async getStockIndexSet ({ commit }, payload) {
    try {
      const index = this.$axios.$get('/api/set/index/info/list?type=index')
      const ftseSet = this.$axios.$get('/api/set/ftse-set/indices')
      const ftseAsean = this.$axios.$get('/api/set/ftse-asean/indices')
      const indexInfo = await Promise.allSettled([
        index,
        ftseSet,
        ftseAsean
      ]).then((data) => {
        return [data[0].value, data[1].value, data[2].value]
      })
      commit('setStockIndexSet', indexInfo)
    } catch (err) {
      commit('setStockIndexSet', null)
    }
  },

  async getStockOverview ({ commit }, payload) {
    try {
      const overviewSet = this.$axios.$get('/api/set/market/SET/info')
      const overviewMai = this.$axios.$get('/api/set/market/mai/info')
      const conclusionSet = this.$axios.$get('/api/set/index/SET/performance')
      const conclusionMai = this.$axios.$get('/api/set/index/mai/performance')
      const [
        { value: overviewSetData },
        { value: overviewMaiData },
        { value: conclusionSetData },
        { value: conclusionMaiData }
      ] = await Promise.allSettled([
        overviewSet,
        overviewMai,
        conclusionSet,
        conclusionMai
      ])
      commit('setStockOverview', [
        overviewSetData,
        overviewMaiData,
        conclusionSetData,
        conclusionMaiData
      ])
    } catch (err) {
      commit('setStockOverview', null)
    }
  },
  async getStockIndexTop10 ({ commit, dispatch }, payload) {
    const { rankingType, market, securityType = 'S' } = payload
    try {
      // let data = ''
      if (rankingType === 'popular') {
        const response = await this.$apiCms.get(
          `/api/cms/v1/popularquote/getpopular?market=${market}&securityType=${securityType}&limit=10`
        )
        commit('setStockIndexTop10Popular', response.data)
        // data = response.data.stocks
        return response.data.stocks
      } else {
        const response = await this.$axios.$get(
          `/api/set/ranking/${rankingType}/${market}/${securityType}?count=10`
        )
        commit('setStockIndexTop10', response)
        // data = response.stocks
        return response.stocks
      }
      // if (data) {
      //   data.forEach((item, index) => {
      //     dispatch('getStockToptenIntradayChart', { quote: item.symbol })
      //   })
      // }
    } catch (err) {
      commit('setStockIndexTop10', null)
    }
  },
  async getStockToptenIntradayChart ({ commit }, payload) {
    const { quote } = payload
    try {
      const chartData = await this.$axios.$get(
        `/api/set/stock/${quote}/chart-quotation?period=1D&accumulated=true`
      )
      const response = {
        symbol: quote,
        chart: chartData
      }
      commit('setStockIndexTop10Chart', response)
    } catch (err) {
      commit('setStockIndexTop10Chart', null)
    }
  },

  async getStockOverviewNews ({ commit }) {
    try {
      const response = await this.$axios.$get(
        `/api/set/news/search?sourceId=company&securityType=S&lang=${this.$i18n.locale}`
      )
      commit('setStockOverviewNews', response)
    } catch (err) {
      commit('setStockOverviewNews', null)
    }
  },

  async getStockIndexChart ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/index/${quote}/chart-quotation?period=1D`
      )
      commit('setStockIndexChart', response)
    } catch (err) {
      commit('setStockIndexChart', null)
    }
  },

  async getStockCalendar ({ commit }, payload) {
    const { year, month } = payload
    try {
      const xCalendar = this.$axios.$get(
        `/api/set/stock-calendar/${year}/${month}/x-calendar?lang=${this.$i18n.locale}`
      )
      const meetingCalendar = this.$axios.$get(
        `/api/set/stock-calendar/${year}/${month}/meeting?lang=${this.$i18n.locale}`
      )
      const [{ value: xCalendarData }, { value: meetingCalendarData }] =
        await Promise.allSettled([xCalendar, meetingCalendar])
      commit('setStockCalendar', [xCalendarData, meetingCalendarData])
    } catch (err) {
      commit('setStockCalendar', null)
    }
  },

  async getSecuritiesList ({ commit }, payload) {
    const { market } = payload
    try {
      commit('setSecuritiesList', null)
      commit('setSecuritiesTypeList', null)

      const response = this.$axios.$get(
        `api/set/index/list?type=industry&market=${market}`
      )
      const [{ value: indexList }] = await Promise.allSettled([response])
      // console.log('indexList', indexList)
      if (indexList) {
        const INDUSTRY = indexList.filter(item => item.level === 'INDUSTRY')
        const requestAll = []
        for (const industryItem of INDUSTRY) {
          requestAll.push(
            this.$axios.$get(
              `/api/set/index/${industryItem.querySymbol}/composition`
            )
          )
        }
        const commonStock = await Promise.all(requestAll)
        const commonStockList = commonStock.map(item => item.composition)
        // console.log('commonStockList', commonStockList)
        let itemsGroupByIndustry = []

        if (market === 'SET') {
          itemsGroupByIndustry = commonStockList.map(industry => ({
            ...industry,
            level: 'INDUSTRY',
            parentIndex: market,
            querySymbol: industry.symbol,
            sectorList: industry.subIndices?.map(sector => ({
              ...sector,
              level: 'SECTOR',
              parentIndex: industry.symbol,
              querySymbol: sector.symbol,
              items: sector.stockInfos ?? []
            })) ?? []
          }))
          // const SECTOR = indexList.filter(item => item.level === 'SECTOR')

          // let sectorService = []
          // SECTOR.forEach((sectorItem) => {
          //   sectorService = [
          //     ...sectorService,
          //     this.$axios.$get(
          //       `api/set/stock-info/list-by-index?index=${encodeURIComponent(
          //         sectorItem.querySymbol
          //       )}`
          //     )
          //   ]
          // })

          // let securitiesList = []

          // const sectorList = await Promise.allSettled([...sectorService])
          // console.log('🚀 ~ sectorList', sectorList)
          // for (const [index, { value }] of [...sectorList].entries()) {
          //   SECTOR[index].items = value
          // }
          // for (const indexItem of INDUSTRY) {
          //   securitiesList = [
          //     ...securitiesList,
          //     {
          //       ...indexItem,
          //       sectorList: SECTOR.filter(
          //         item =>
          //           item.parentIndex === indexItem.querySymbol &&
          //           item.level === 'SECTOR'
          //       )
          //     }
          //   ]
          // }
          // console.log('itemsGroupByIndustry', itemsGroupByIndustry)
          commit('setSecuritiesList', itemsGroupByIndustry)
          return itemsGroupByIndustry
        } else if (market === 'mai') {
          itemsGroupByIndustry = commonStockList.map(industry => ({
            ...industry,
            level: 'INDUSTRY',
            parentIndex: market,
            querySymbol: industry.symbol,
            items: industry.stockInfos ?? []
          }))
          // let sectorService = []

          // INDUSTRY.forEach((industryItem) => {
          //   sectorService = [
          //     ...sectorService,
          //     this.$axios.$get(
          //       `api/set/stock-info/list-by-index?index=${encodeURIComponent(
          //         industryItem.querySymbol
          //       )}`
          //     )
          //   ]
          // })

          // let securitiesList = []

          // await Promise.allSettled([...sectorService]).then((res) => {
          //   for (const [index, { value }] of res.entries()) {
          //     securitiesList = [
          //       ...securitiesList,
          //       { ...INDUSTRY[index], items: value }
          //     ]
          //   }
          // })

          commit('setSecuritiesList', itemsGroupByIndustry)
          return itemsGroupByIndustry
        }
      }
    } catch (err) {
      commit('setSecuritiesList', null)
      return []
    }
  },

  async getSecuritiesTypeList ({ commit }, payload) {
    const { type, market } = payload

    commit('setSecuritiesList', null)
    commit('setSecuritiesTypeList', null)
    try {
      const filterTraded = type === 'Q' ? '&filterTraded=' + true : ''
      const response = await this.$axios.$get(
        `api/set/stock-info/list-by-security-type?securityType=${type}&market=${market}${filterTraded}`
      )
      commit('setSecuritiesTypeList', response)
      return response
    } catch (err) {
      commit('setSecuritiesTypeList', null)
    }
  },

  // async getAutoMatching ({ commit, dispatch }, payload) {
  //   try {
  //     const [
  //       { value: securitiesList },
  //       { value: securitiesListTypeF },
  //       { value: securitiesListTypeP },
  //       // { value: securitiesListTypeQ },
  //       { value: securitiesListTypeW },
  //       { value: securitiesListTypeU },
  //       { value: securitiesListTypeL },
  //       { value: securitiesListTypeX },
  //       // { value: securitiesListTypeXF },
  //       { value: securitiesListTypeV }
  //     ] = await Promise.allSettled([
  //       dispatch('getSecuritiesList', payload),
  //       dispatch('getSecuritiesTypeList', { ...payload, type: 'F' }),
  //       dispatch('getSecuritiesTypeList', { ...payload, type: 'P' }),
  //       // dispatch('getSecuritiesTypeList', { ...payload, type: 'Q' }),
  //       dispatch('getSecuritiesTypeList', { ...payload, type: 'W' }),
  //       dispatch('getSecuritiesTypeList', { ...payload, type: 'U' }),
  //       dispatch('getSecuritiesTypeList', { ...payload, type: 'L' }),
  //       dispatch('getSecuritiesTypeList', { ...payload, type: 'X' }),
  //       // dispatch('getSecuritiesTypeList', { ...payload, type: 'XF' }),
  //       dispatch('getSecuritiesTypeList', { ...payload, type: 'V' })
  //     ])
  //     const autoMatchingList = [
  //       {
  //         securitiesItem: {
  //           name: this.$i18n.t('stock.search.automatchingTitle.S'),
  //           value: 'S'
  //         },
  //         securitiesList
  //       },
  //       {
  //         securitiesItem: {
  //           name: this.$i18n.t('stock.search.automatchingTitle.F'),
  //           value: 'F'
  //         },
  //         securitiesTypeList: securitiesListTypeF
  //       },
  //       {
  //         securitiesItem: {
  //           name: this.$i18n.t('stock.search.automatchingTitle.P'),
  //           value: 'P'
  //         },
  //         securitiesTypeList: securitiesListTypeP
  //       },
  //       // {
  //       //   securitiesItem: {
  //       //     name: 'หุ้นบุริมสิทธิ (ต่างประเทศ)',
  //       //     value: 'Q'
  //       //   },
  //       //   securitiesTypeList: securitiesListTypeQ
  //       // },
  //       {
  //         securitiesItem: {
  //           name: this.$i18n.t('stock.search.automatchingTitle.W'),
  //           value: 'W'
  //         },
  //         securitiesTypeList: securitiesListTypeW
  //       },
  //       {
  //         securitiesItem: {
  //           name: this.$i18n.t('stock.search.automatchingTitle.U'),
  //           value: 'U'
  //         },
  //         securitiesTypeList: securitiesListTypeU
  //       },
  //       {
  //         securitiesItem: {
  //           name: this.$i18n.t('stock.search.automatchingTitle.L'),
  //           type: 'ETF',
  //           value: 'L'
  //         },
  //         securitiesTypeList: securitiesListTypeL
  //       },
  //       {
  //         securitiesItem: {
  //           name: this.$i18n.t('stock.search.automatchingTitle.X'),
  //           type: 'DR',
  //           value: 'X'
  //         },
  //         securitiesTypeList: securitiesListTypeX
  //       },
  //       // {
  //       //   securitiesItem: {
  //       //     name: this.$i18n.t('stock.search.automatchingTitle.XF'),
  //       //     type: 'DRx',
  //       //     value: 'XF'
  //       //   },
  //       //   securitiesTypeList: securitiesListTypeXF
  //       // },
  //       {
  //         securitiesItem: {
  //           name: this.$i18n.t('stock.search.automatchingTitle.V'),
  //           type: 'DW',
  //           value: 'V'
  //         },
  //         securitiesTypeList: securitiesListTypeV
  //       }
  //     ]
  //     commit('setAutoMatching', autoMatchingList)
  //   } catch (err) {
  //     commit('setAutoMatching', null)
  //   }
  // },

  async getTradingReport ({ commit }, payload) {
    try {
      const { market = 'SET' } = payload
      const response = await this.$axios.$get(
        'api/set/trading-method/tradereport?market=' + market
      )
      if (response && response.stockList) {
        commit('setTradingReport', response.stockList)
      }
    } catch (err) {
      commit('setTradingReport', null)
    }
  },

  async getOddLot ({ commit }, payload) {
    try {
      const { market = 'SET' } = payload
      const response = await this.$axios.$get(
        'api/set/trading-method/oddlot?market=' + market
      )
      if (response && response.stockList) {
        commit('setOddLot', response.stockList)
      }
    } catch (err) {
      commit('setOddLot', null)
    }
  }
}

/**
 * Getters
 */
const getters = {
  getStockIndexSet: (state) => {
    return state.index.set || []
  },

  getStockIndexFtseSet: (state) => {
    return state.index.ftseSet
  },

  getStockIndexFtseAsean: (state) => {
    return state.index.ftseAsean
  },

  getStockIndexTopten: (state) => {
    return state.index.topten.data
  },

  getStockIndexChart: (state) => {
    return state.index.chart
  },

  getStockIndexTop10Chart: (state) => {
    return state.index.topten.chart
  },

  getStockCalendar: (state) => {
    return state.index.calendar
  },

  getSecuritiesList: (state) => {
    return state.index.securitiesList || []
  },

  getSecuritiesTypeList: (state) => {
    return state.index.securitiesTypeList || []
  },

  // getAutoMatching: (state) => {
  //   return state.index.autoMatching || []
  // },

  getTradingReport: (state) => {
    return state.index.tradingReport || []
  },

  getOddLot: (state) => {
    return state.index.oddLot || []
  },
  getNewsInfo: (state) => {
    return state.index.news || []
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
