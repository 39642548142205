import signedSymbol from '@/store/rulebooks/regulatory-statistic/signed-symbol'

export const modules = {
  signedSymbol
}

const state = () => ({
  listYearRegulaStatis: [],
  informationStatis: null
})

const mutations = {
  setListYearRegulaStatis (state, data) {
    state.listYearRegulaStatis = data
  },
  setInformationStatis (state, data) {
    state.informationStatis = data
  }
}

const actions = {
  async getDropDownYear ({ commit, dispatch, state }, payload) {
    try {
      const apiPath = 'api/gw-api/reg-stat/years'
      const response = await this.$axios.$get(apiPath)
      commit('setListYearRegulaStatis', response)
      return response
    } catch (error) {
      commit('setListYearRegulaStatis', null)
    }
  },
  async getDataRegulatoryStatistic ({ commit, dispatch, state }, payload) {
    try {
      const apiPath = `api/gw-api/reg-stat/${payload.year}`
      const response = await this.$axios.$get(apiPath)
      commit('setInformationStatis', response)
      return response
    } catch (error) {
      commit('setInformationStatis', null)
    }
  }
}

const getters = {
  getDropDownYear: (state) => {
    return state.listYearRegulaStatis ? state.listYearRegulaStatis : []
  },
  getDataRegulatoryStatistic: (state) => {
    return state.informationStatis ? state.informationStatis : null
  }
}

export default {
  state,
  actions,
  mutations,
  getters,
  modules
}
