//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { customAlphabet } from 'nanoid'
import { integerStyle } from '@/helpers/index'
export default {
  name: 'MyQuoteContent',
  components: {
    Multiselect: () => import('vue-multiselect')
  },
  props: {
    swiperClass: {
      type: String,
      default: 'my-quote-recently'
    },
    itemList: {
      type: Array,
      default: Array
    }
  },
  data () {
    return {
      swiperOptions: {
        slidesPerView: 'auto',
        showPagination: false,
        showNavigation: false,
        watchOverflow: true,
        resizeObserver: true,
        spaceBetween: 16,
        navigation: {
          prevEl: `.${this.swiperClass} .swiper-btn-prev`,
          nextEl: `.${this.swiperClass} .swiper-btn-next`
        }
      },
      // quoteList: ['หุ้น', 'ETF', 'DR', 'DRx', 'DW'], // 'ฟิวเจอร์ส', 'ออปชัน', 'กองทุนรวม'
      quoteList: [{ name: this.$t('member.myQuote.stock') }, { name: 'ETF' }, { name: 'DR' }, { name: 'DRx', class: 'drx-info' }, { name: 'DW' }], // 'ฟิวเจอร์ส', 'ออปชัน', 'กองทุนรวม'
      labelQuote: this.$t('member.myQuote.stock')
    }
  },
  computed: {
    ...mapGetters({
      stockList: 'setmember/favorite/getStock',
      etfList: 'setmember/favorite/getETF',
      dwList: 'setmember/favorite/getDW',
      drList: 'setmember/favorite/getDR',
      drxList: 'setmember/favorite/getDRx'
    }),
    createIDMyFavoriteQuote () {
      const nanoid = customAlphabet(
        '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
        4
      )
      return nanoid()
    }
  },
  watch: {
    closeModal () {
      this.getMyFavoriteQuote()
    },
    stockList () {
      this.labelQuote = this.$t('member.myQuote.stock')
      this.$emit('typeQuote', this.$t('member.myQuote.stock'))
    },
    etfList () {
      this.labelQuote = 'ETF'
      this.$emit('typeQuote', 'ETF')
    },
    dwList () {
      this.labelQuote = 'DW'
      this.$emit('typeQuote', 'DW')
    },
    drList () {
      this.labelQuote = 'DR'
      this.$emit('typeQuote', 'DR')
    },
    drxList () {
      this.labelQuote = 'DRx'
      this.$emit('typeQuote', 'DRx')
    }
  },
  methods: {
    integerStyle,
    onSelect (value) {
      this.labelQuote = value.name
      this.handleClickTracking('myQuote4', { symbol: value.name })
      this.$emit('typeQuote', value.name)
    },
    async loginAuth () {
      const isLogin = await this.$authUser.isLogin()
      if (!isLogin) {
        window.location.href = this.$authUser.login()
      }
    },
    handleClickTracking (name, optionLang) {
      this.$personalized.clickElement({
        name,
        optionLang
      })
    }
  }
}
