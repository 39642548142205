const state = () => ({
  keyword: null
})
const mutations = {
  setKeyword (state, data) {
    console.log('store setKeyword', data)
    state.keyword = data
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
