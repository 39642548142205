const state = () => {
  return {
    dwOutstandingReportList: null
  }
}

const actions = {
  async fetchDWOutstandingList ({ commit }, payload) {
    let apiPath = 'api/set/dw-outstanding/list'
    if (payload) {
      apiPath = `api/set/dw-outstanding/list?issuerIds=${payload.issuerIds}&underlyings=${payload.underlyings}&symbols=${payload.symbols}&dwType=${payload.dwType}`
    }

    await this.$axios.$get(apiPath).then((res) => {
      if (res) {
        commit('setDWOutstandingReportList', res)
      } else {
        commit('setDWOutstandingReportList', null)
      }
    }).catch(err => err)
  },

  async fetOutstandingIssuerOptionList () {
    const list = await this.$axios.$get('api/set/dw-fundamental/issuer/list')

    return list ? (list.issuerList !== undefined && list.issuerList.length > 0 && list.issuerList !== null ? list.issuerList : null) : null
  },

  async fetchOutstandingDWOptionList () {
    const list = await this.$axios.$get('api/set/dw-info/list')
    if (list) {
      if (list.dwList) {
        return list.dwList.map((item) => {
          return {
            text: item.symbol,
            isSymbol: true
          }
        })
      }
    }

    return []
  },

  async fetchOutstandingundelyingOptionList () {
    const list = await this.$axios.$get('api/set/dw-fundamental/underlying/list')

    return list ? (list.underlyingList !== undefined ? list.underlyingList : null) : null
  }
}

const mutations = {
  setDWOutstandingReportList (state, data) {
    state.dwOutstandingReportList = data
  }
}

const getters = {
  getDWOutstandingReportList (state) {
    return state.dwOutstandingReportList
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
