import remove from 'lodash/remove'
import stock from '@/store/quote/stock/index'
import ETF from '@/store/quote/ETF/index'
import DW from '@/store/quote/DW/index'
import pop from '@/store/quote/popular/index'
import {
  formatNumber,
  formatNumberX,
  quoteSignNumberFormat
} from '@/helpers/number' // formatNumber
import { formatDateDash } from '@/helpers/dateTime'
import { quoteTypeString } from '@/helpers/index'
import { setCategoryType } from '@/helpers/utilities'
export const modules = {
  stock,
  ETF,
  DW,
  pop
}

const state = () => ({
  info: null,
  drxinfo: null,
  profile: null,
  intradayChart: null,
  pricePerformanceChart: null,
  indexList: null,
  newsList: null,
  newsOtherList: null,
  issuerNews: null,
  companyProfile: null,
  corporateActionList: [],
  highlightData: null,
  financialDataChart: null,
  keyFinancialData: null,
  upcomingEvent: [],
  historicalTrading: [],
  searchOption: [],
  dropdownAdditional: [],
  warrantsDocument: [],
  searchOptionCustom: []
})

const mutations = {
  /* CompanyPrice  */

  setStockInfo (state, data) {
    state.info = data
  },
  setDrxInfo (state, data) {
    state.drxinfo = data
  },

  setStockProfile (state, data) {
    state.profile = data
  },

  setDropdownAdditional (state, data) {
    state.dropdownAdditional = data
  },

  setStockIntradayChart (state, data) {
    state.intradayChart = data
  },

  setStockPricePerformanceChart (state, data) {
    state.pricePerformanceChart = data
  },

  setStockIndexList (state, data) {
    state.indexList = data
  },

  /* CompanyPrice - News */

  setStockNewsList (state, data) {
    if (data && data.totalCount > 7) {
      state.newsList = {
        totalCount: 7,
        newsInfoList: data.newsInfoList.slice(0, 7)
      }
    } else {
      state.newsList = data
    }
  },

  setStockOtherNewsList (state, data) {
    state.newsOtherList = data
  },
  setStockIssuerNews (state, data) {
    state.issuerNews = data
  },

  setStockCompanyProfile (state, data) {
    state.companyProfile = data
  },

  setStockUpcomingEvent (state, data) {
    state.upcomingEvent = data
  },

  /* CompanyPrice - Important */

  setStockHighlightData (state, data) {
    state.highlightData = data
  },

  setStockFinancialDataChart (state, data) {
    state.financialDataChart = data
  },
  setStockKeyFinancialData (state, data) {
    state.keyFinancialData = data
  },

  /* CompanyPrice - Historical */

  setStockHistoricalTrading (state, data) {
    state.historicalTrading = data
  },

  /* CompanyRight */

  setStockCorporateAction (state, data) {
    const result = data.map((item) => {
      let label = ''
      let value = ''
      const digitsDivinded =
        item.dividend && item.dividend.toString().split('.')[1]
      const dividendData =
        digitsDivinded && digitsDivinded.length > 5
          ? formatNumberX(item.dividend)
          : formatNumberX(item.dividend) || '-'
      const priceData = quoteSignNumberFormat(item.price)
      // const priceData = item.price && item.price.length > 0 ? item.price : null
      if (item.caType === 'XD(ST)') {
        label = this.$i18n.t('quote.dividendStockXDST')
        value = item.dividend
          ? `${dividendData} ${item.currency ? item.currency : ''}`
          : ''
      } else if (item.caType === 'XD') {
        label = this.$i18n.t('quote.dividendText')
        value = item.dividend
          ? `${formatNumberX(item.dividend)} ${
              item.currency ? item.currency : ''
            }`
          : ''
      } else if (
        item.caType === 'XT' ||
        item.caType === 'XW' ||
        item.caType === 'XR'
      ) {
        label = this.$i18n.t('quote.ratioHoldingNewAtPrice')
        value = ` ${item.ratio || '-'} ${this.$i18n.t('quote.atText')} ${
          priceData || '-'
        } ${item.currency ? item.currency : ''}`
      } else if (item.caType === 'XI') {
        label = ''
        value = ''
      } else if (item.caType === 'XP') {
        label = ''
        value = ''
      } else if (item.caType === 'XE') {
        label = this.$i18n.t('quote.exercisePriceLabel')
        value = item.price
          ? `${priceData || '-'} ${item.currency ? item.currency : ''}`
          : ''
      } else if (item.caType === 'XM') {
        label = this.$i18n.t('quote.agenda')
        value = item.agenda ? item.agenda : ''
      } else if (item.caType === 'XN') {
        label = this.$i18n.t('quote.capitalReturnPerShareBaht')
        value = item.returnAmount
          ? `${quoteSignNumberFormat(item.returnAmount) || ''} ${
              item.currency ? item.currency : ''
            }`
          : ''
      } else if (item.caType === 'XB') {
        label = this.$i18n.t('quote.subscriptionPrice')
        value = item.price
          ? `${priceData || '-'} ${item.currency ? item.currency : ''}`
          : ''
      }
      return {
        ...item,
        dividend: dividendData,
        price: priceData,
        categoryLabel: label,
        categoryValue: value
      }
    })
    state.corporateActionList = result
  },

  /* Search */

  setSearchStockList (state, data) {
    state.searchOption = data
  },
  setWarrantsDocument (state, data) {
    state.warrantsDocument = data
  },
  setSearchOptionCustom (state, data) {
    state.searchOptionCustom = data
  }
}

const actions = {
  /* CompanyPrice - Price */

  async getStockInfoData ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/info`, {
        params: { lang: this.$i18n.locale }
      })
      return response
    } catch (err) {
      return null
    }
  },

  async getStockInfo ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.get(`/api/set/stock/${quote}/info`, {
        params: { lang: this.$i18n.locale }
      })
      commit('setStockInfo', response.data)
      return response.status
    } catch (err) {
      commit('setStockInfo', null)
      return err.response.status
    }
  },
  async getDrxInfo ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.get(`/api/set/drx/${quote}/info`, {
        params: { lang: this.$i18n.locale }
      })
      commit('setDrxInfo', response.data)
      return response.status
    } catch (err) {
      commit('setDrxInfo', null)
      return err.response.status
    }
  },

  async getStockProfile ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/profile`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setStockProfile', response)
    } catch (err) {
      commit('setStockProfile', null)
    }
  },

  async getDropdownAdditional ({ commit }, payload) {
    const { quote } = payload
    try {
      const relatedStock = this.$axios.$get(
        `/api/set/stock/${quote}/related-product/o`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      const relatedWarrant = this.$axios.$get(
        `/api/set/stock/${quote}/related-product/w`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      const [
        {
          value: { relatedProducts: relatedStockData }
        },
        {
          value: { relatedProducts: relatedWarrantData }
        }
      ] = await Promise.allSettled([relatedStock, relatedWarrant])

      const additional = [...relatedStockData, ...relatedWarrantData].map(
        item => ({ ...item, label: item.symbol, key: item.symbol })
      )
      commit('setDropdownAdditional', additional)
    } catch (err) {
      commit('setDropdownAdditional', [])
    }
  },

  async getStockIntradayChart ({ commit }, payload) {
    const { quote, period, accumulated } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/chart-quotation?period=${period}&accumulated=${accumulated}`
      )
      commit('setStockIntradayChart', response)
    } catch (err) {
      commit('setStockIntradayChart', null)
    }
  },

  async getStockPricePerformanceChart ({ commit, state }, payload) {
    const { quote, period, accumulated } = payload
    const { info } = state
    try {
      const indexData = this.$axios.$get(`/api/set/stock/${quote}/index-list`)
      const isQuote = (
        info &&
        info.securityType &&
        (info.securityType === 'S' ||
          info.securityType === 'F' ||
          info.securityType === 'P' ||
          info.securityType === 'Q' ||
          info.securityType === 'U' ||
          info.securityType === 'W')
      )
      const [quoteList, indexList] =
        await Promise.allSettled([indexData]).then(async (data) => {
          const [{ value: indexList }] = data
          if (
            indexList &&
            isQuote
          ) {
            const dataChart = await this.$axios.$get(`/api/set/stock/${quote}/chart-comparison?period=${period}`)
            return [dataChart?.quotations ?? [], indexList]
          } else {
            const quoteList = await this.$axios.$get(
              `/api/set/stock/${quote}/chart-performance?period=${period}&accumulated=${accumulated}`
            )
            return [quoteList?.quotations ?? [], indexList]
          }
        })
      if (isQuote) {
        const performanceData = quoteList.map((item, index) => {
          return {
            datetime: item.localDatetime,
            quoteTitle: quote,
            sectorTitle: indexList.sector,
            marketTitle: indexList.market,
            quote: item.stock,
            quoteText: item.stock ? formatNumber(item.stock, 2) : item.stock,
            sector: item.sector,
            sectorText: item.sector ? formatNumber(item.sector, 2) : item.sector,
            market: item.market,
            marketText: item.market ? formatNumber(item.market, 2) : item.market
          }
        })
        commit('setStockPricePerformanceChart', performanceData)
      } else {
        const performanceData = quoteList.map((item, index) => {
          return {
            datetime: item.localDatetime,
            quoteTitle: quote,
            sectorTitle: indexList.sector,
            marketTitle: indexList.market,
            quote: item.price,
            quoteText: item.price ? formatNumber(item.price, 2) : item.price,
            sector: null,
            market: null
          }
        })
        commit('setStockPricePerformanceChart', performanceData)
      }
      commit('setStockIndexList', indexList)
    } catch (err) {
      commit('setStockPricePerformanceChart', null)
    }
    // try {
    //   const quoteData = this.$axios.$get(
    //     `/api/set/stock/${quote}/chart-performance?period=${period}&accumulated=${accumulated}`
    //   )
    //   // const indexData1 = await this.$axios.$get(`/api/set/stock/${quote}/chart-comparison?period=${period}`)
    //   const indexData = this.$axios.$get(`/api/set/stock/${quote}/index-list`)
    //   // console.log('🚀 ~ indexData1:', indexData1)
    //   const [quoteList, sectorList, marketList, indexList] =
    //     await Promise.allSettled([quoteData, indexData]).then(async (data) => {
    //       const [{ value: quoteList }, { value: indexList }] = data
    //       if (
    //         indexList &&
    //         info &&
    //         info.securityType &&
    //         (info.securityType === 'S' ||
    //           info.securityType === 'F' ||
    //           info.securityType === 'P' ||
    //           info.securityType === 'Q' ||
    //           info.securityType === 'U' ||
    //           info.securityType === 'W')
    //       ) {
    //         let sectorData = null
    //         if (indexList.sector) {
    //           sectorData = this.$axios.$get(
    //             `/api/set/index/${indexList.sector}/chart-performance?period=${period}`
    //           )
    //         }
    //         const marketData = this.$axios.$get(
    //           `/api/set/index/${indexList.market}/chart-performance?period=${period}`
    //         )
    //         const [{ value: sectorList }, { value: marketList }] =
    //           await Promise.allSettled([sectorData, marketData])
    //         return [quoteList, sectorList, marketList, indexList]
    //       } else {
    //         return [quoteList, null, null, indexList]
    //       }
    //     })
    //   if (quoteList || sectorList || marketList) {
    //     // console.log('quoteList==>>', quoteList.quotations[0].datetime)
    //     // const stockFirstTradeDate = quoteList.quotations[0].datetime
    //     // console.log('sectorList==>>', sectorList.quotations.filter(item => item.datetime >= stockFirstTradeDate))
    //     const performanceData = [
    //       ...[
    //         ...(quoteList.quotations.map((item, index, array) => {
    //           return {
    //             datetime: item.localDatetime,
    //             quoteTitle: quote,
    //             sectorTitle: indexList.sector,
    //             marketTitle: indexList.market,
    //             quote: item.price,
    //             quoteText: item.price ? formatNumber(item.price, 2) : item.price
    //           }
    //         }) || []),
    //         ...(sectorList
    //           ? sectorList.quotations.map((item, index, array) => {
    //             return {
    //               datetime: item.localDatetime,
    //               sector: item.price,
    //               sectorText: item.price
    //                 ? formatNumber(item.price, 2)
    //                 : item.price
    //             }
    //           })
    //           : []),
    //         ...(marketList
    //           ? marketList.quotations.map((item, index, array) => {
    //             return {
    //               datetime: item.localDatetime,
    //               market: item.price,
    //               marketText: item.price
    //                 ? formatNumber(item.price, 2)
    //                 : item.price
    //             }
    //           })
    //           : [])
    //       ]
    //         .reduce(
    //           (cur, acc) =>
    //             cur.set(
    //               acc.datetime,
    //               Object.assign(
    //                 cur.get(acc.datetime) || {
    //                   datetime: null,
    //                   quoteTitle: null,
    //                   sectorTitle: null,
    //                   marketTitle: null,
    //                   quote: null,
    //                   sector: null,
    //                   market: null
    //                 },
    //                 acc
    //               )
    //             ),
    //           new Map()
    //         )
    //         .values()
    //     ]
    //     console.log('🚀 ~ performanceData:', performanceData)
    //     commit('setStockIndexList', indexList)
    //     commit('setStockPricePerformanceChart', performanceData)
    //   }
    // } catch (err) {
    //   commit('setStockPricePerformanceChart', null)
    // }
  },

  async getStockIndexList ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/index-list`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setStockIndexList', response)
    } catch (err) {
      commit('setStockIndexList', null)
    }
  },

  /* CompanyPrice - Important */

  async getStockHighlightData ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/highlight-data`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setStockHighlightData', response)
    } catch (err) {
      commit('setStockHighlightData', null)
    }
  },

  async getStockFinancialDataChart ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/company-highlight/financial-data`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setStockFinancialDataChart', response)
    } catch (err) {
      commit('setStockFinancialDataChart', null)
    }
  },

  async getStockKeyFinancialData ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/key-financial-data`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setStockKeyFinancialData', response)
    } catch (err) {
      commit('setStockKeyFinancialData', null)
    }
  },

  /* CompanyPrice - News */

  async getStockNewsList ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/news/${quote}/list?lang=${this.$i18n.locale}&limit=5`
      )
      commit('setStockNewsList', response)
    } catch (err) {
      commit('setStockNewsList', null)
    }
  },
  async getStockOtherNewsList ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/news/${quote}/list?lang=${this.$i18n.locale}&type=non-nav&limit=5`
      )
      commit('setStockOtherNewsList', response)
    } catch (err) {
      commit('setStockOtherNewsList', null)
    }
  },
  async getStockIssuerNews ({ commit }, payload) {
    const { quote, limit = 5 } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/news/${quote}/issuer-news?lang=${this.$i18n.locale}&limit=${limit}`
      )
      commit('setStockIssuerNews', response)
    } catch (err) {
      commit('setStockIssuerNews', null)
    }
  },

  async getStockCompanyProfile ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/company/${quote}/profile`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setStockCompanyProfile', response)
    } catch (err) {
      commit('setStockCompanyProfile', null)
    }
  },

  async getStockUpcomingEvent ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/corporate-action`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      if (response) {
        const current = new Date(new Date().setHours(0, 0, 0, 0)).getTime()
        const upcomingEvent = response.filter(
          item =>
            formatDateDash(
              new Date(new Date(item.xdate).setHours(0, 0, 0, 0)).getTime()
            ) >= formatDateDash(current)
        )
        commit('setStockUpcomingEvent', upcomingEvent)
      }
    } catch (err) {
      commit('setStockUpcomingEvent', null)
    }
  },

  /* CompanyPrice - Historical */

  async getStockHistoricalTrading ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/historical-trading`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setStockHistoricalTrading', response)
    } catch (err) {
      commit('setStockHistoricalTrading', null)
    }
  },

  /* CompanyRights */

  async getStockCorporateAction ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/corporate-action`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setStockCorporateAction', response)
    } catch (err) {
      commit('setStockCorporateAction', null)
    }
  },

  /* Search */

  async getSearchStockList ({ commit, state }) {
    const { searchOption } = state
    try {
      if (searchOption.length <= 0) {
        const response = await this.$axios.$get('/api/set/stock/list')
        const { securitySymbols } = response ?? {}
        if (securitySymbols) {
          const sortData = securitySymbols
            .sort((a, b) => a.typeSequence - b.typeSequence)
            .map((item) => {
              return {
                ...item,
                name: item.symbol,
                value: item.symbol,
                nameTH: item.nameTH,
                nameEN: item.nameEN,
                market: item.market,
                securityType: item.securityType,
                securityTypeName: quoteTypeString(item.securityType, this.$i18n)
              }
            })
          commit('setSearchStockList', sortData)
        }
      }
    } catch (err) {
      commit('setSearchStockList', null)
    }
  },
  async getWarrantsDocument ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/report/warrants-term`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setWarrantsDocument', response)
    } catch (err) {
      commit('setWarrantsDocument', null)
    }
  },
  setSuggesttionByWriter ({ commit, state }, payload) {
    const searchOption = state.searchOption
    payload.type = payload.type === 'หุ้น' ? 'stock' : payload.type
    let evens

    // Set securityType
    const securities = searchOption.filter(
      item =>
        setCategoryType(item.securityType).toLowerCase() ===
        payload.type.toLowerCase()
    )
    // remove quote
    payload.stock.forEach((item) => {
      evens = remove(securities, function (n) {
        return n.symbol === item.symbol
      })
    })
    commit('setSearchOptionCustom', securities, evens)
  }
}

const getters = {
  /* CompanyPrice  */

  getStockInfo: (state) => {
    return state.info
  },

  getDrxInfo (state, data) {
    return state.drxinfo
  },

  getStockProfile: (state) => {
    return state.profile
  },

  getDropdownAdditional: (state) => {
    return state.dropdownAdditional
  },

  getStockIntradayChart: (state) => {
    return state.intradayChart
  },

  getStockPricePerformanceChart: (state) => {
    return state.pricePerformanceChart || []
  },

  getStockIndexList: (state) => {
    return state.indexList
  },

  /* CompanyPrice - News */

  getStockNewsList: (state) => {
    return state.newsList
  },
  getStockOtherNewsList: (state) => {
    return state.newsOtherList
  },
  getStockIssuerNews: (state) => {
    return state.issuerNews
  },

  getStockCompanyProfile: (state) => {
    return state.companyProfile
  },

  getStockUpcomingEvent: (state) => {
    return state.upcomingEvent || []
  },

  /* CompanyPrice - Important */

  getStockHighlightData: (state) => {
    return state.highlightData
  },

  getStockFinancialDataChart: (state) => {
    return state.financialDataChart || []
  },

  getStockKeyFinancialData: (state) => {
    return state.keyFinancialData
  },

  /* CompanyPrice - Historical */

  getStockHistoricalTrading: (state) => {
    return state.historicalTrading
  },

  /* CompanyRight */

  getStockCorporateAction: (state) => {
    return state.corporateActionList
  },

  /* Search */

  getSearchStockList: (state) => {
    return state.searchOption
  },
  getWarrantsDocument: (state) => {
    return state.warrantsDocument
  },
  getSearchStockListCustom: (state) => {
    return state.searchOptionCustom
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
