// import dayjs from 'dayjs'
// import { formatShortMonthYear, formatDateTimeShort, formatTimeShort } from '~/helpers/dateTime'
import { formatDateTimeShort, formatTimeShort, dayjs } from '~/helpers/dateTime'
import { formatNumber } from '~/helpers/number'

// pagination array taker
const paginatorTaker = (items, currentPage, perPageItems) => {
  const page = currentPage || 1
  const perPage = perPageItems || 10
  const offset = (page - 1) * perPage

  const paginatedItems = items.slice(offset).slice(0, perPageItems)
  // const totalPages = Math.ceil(items.length / perPage)

  return paginatedItems
}

// overview
const state = () => ({
})

const mutations = {}

const actions = {
  async fetchOverviewData ({ commit }, payload) {
    const { eventType, fromDate, toDate, perPage, currentPage } = payload
    const options = {
      params: {
        eventType: eventType !== 'all' ? eventType : '',
        fromDate: fromDate ? dayjs(fromDate).locale('en').format('DD/MM/YYYY') : '',
        toDate: fromDate ? dayjs(toDate).locale('en').format('DD/MM/YYYY') : ''
      }
    }
    const response = await this.$axios.$get('/api/gw-api/event/public/list', options)
      .then((data) => {
        return data
      })
      .catch(() => {
        return null
      })
    const mappedResponse = response
      ? await response.map((item) => {
        const startDate = item.startDateTime ? new Date(item.startDateTime) : null
        const endDate = item.endDateTime ? new Date(item.endDateTime) : null

        // const startDateDayOnly = startDate ? dayjs(startDate).format('DD') : ''
        // const endDateDayOnly = endDate ? dayjs(endDate).format('DD') : ''
        // const startDateMonthYearMsg = startDate ? formatShortMonthYear(startDate, this.$i18n.locale) : ''
        const isInSameDay = dayjs(endDate).diff(dayjs(startDate), 'h') < 24
        const startEndDateMsg = startDate ? `${formatDateTimeShort(startDate, this.$i18n.locale)}${endDate ? ' - ' + (isInSameDay ? formatTimeShort(endDate, this.$i18n.locale) : formatDateTimeShort(endDate, this.$i18n.locale)) : ''}` : '-'

        // check is in register time
        const currentDate = dayjs().locale(this.$i18n.locale)
        const erDate = dayjs(item.endRegistrationDateTime).locale(this.$i18n.locale)
        let registerContent = ''
        let registerCustomClass = ''
        // in register range
        if (currentDate.diff(erDate, 's') < 0) {
          if (item.totalSeat) {
            // registerContent = `เปิดรับสมัคร : ${formatNumber(item.totalSeat, 0)} ที่นั่ง`
            registerContent = this.$i18n.t('eventCalendar.totalSeats', { amount: formatNumber(item.totalSeat, 0) })
          } else {
            // registerContent = 'เปิดรับสมัคร : -'
            registerContent = this.$i18n.t('eventCalendar.noSeats')
          }
        } else {
          // registerContent = 'ปิดรับสมัคร'
          registerContent = this.$i18n.t('eventCalendar.closed')
          registerCustomClass = 'close-registor'
        }

        // Check hide 'ปิดรับสมัคร' or 'Closed'
        let iconUsers = 'IconUsers'
        const isSpecialActivity = item.courseType.toUpperCase() === 'Z' && item.eventType === 'activity'
        const isHideCloseRegistor = isSpecialActivity && currentDate.$d > erDate.$d
        if (isHideCloseRegistor) {
          registerContent = ''
          registerCustomClass = ''
          iconUsers = ''
        }

        const contentDetail = {
          eventId: item.eventId || '',
          title: item.name || '',
          feeStatus: item.fee ? item.fee : false,
          courseDetail: [
            {
              iconName: 'IconClock',
              content: startEndDateMsg,
              customClass: ''
            },
            {
              iconName: 'IconMapPin',
              content: item.venue || '-',
              customClass: ''
            },
            {
              iconName: iconUsers,
              content: registerContent,
              customClass: registerCustomClass
            }
          ]
        }

        return {
          ...item,
          // day: startDateDayOnly,
          // month: startDateMonthYearMsg,
          startDate,
          endDate,
          imgSrc: `${item.imageUrl}` || '',
          contentDetail
        }
      })
      : []
    const slicedData = paginatorTaker(mappedResponse, currentPage, perPage)
    const result = {
      total: mappedResponse.length,
      items: slicedData
    }

    return result
  }

}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters
}
