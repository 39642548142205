import fiveDays from '@/store/statistics/FiveDays/index'
import investorType from '@/store/statistics/InvestorType/index'
import nvdr from '@/store/statistics/Nvdr/index'
import shortsales from '@/store/statistics/Shortsales/index'
export const modules = {
  fiveDays,
  investorType,
  nvdr,
  shortsales
}
