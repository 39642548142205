//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { setAttributeLink } from '~/helpers'
export default {
  name: 'SubMenu',
  props: {
    lastUpdate: {
      type: Date,
      default: null
    },
    menuList: {
      type: Array,
      default: () => []
    },
    menuParent: {
      type: Object,
      default: Object
    },
    isMouseHover: {
      type: Boolean,
      default: false
    }
  },
  // serverCacheKey: props => `SubMenu-${JSON.stringify(props.menuList)}`,
  computed: {
    menuColumnLists () {
      const menu = JSON.parse(JSON.stringify((this.menuList)))
      const column = this.filterColumn(menu)
      let listMenu = Object.values(column)
      listMenu.forEach((item, index) => {
        let result = []
        const isHightlight = item.filter(h => h.menuType === 'Hightlight')
        if (isHightlight.length) {
          result = isHightlight
        }
        const isStandard = item.filter(s => s.menuType === 'Standard')
        if (isStandard.length) {
          result = [...result, ...isStandard]
        }
        const isSpecial = item.filter(s => s.menuType === 'Special')
        if (isSpecial.length) {
          result = [...result, ...isSpecial]
        }
        listMenu[index] = result
      })
      listMenu = listMenu.map((item, index) => {
        const isHightlight = item.filter(h => h.menuType === 'Hightlight')
        if (isHightlight.length) {
          return {
            isHightlight: true,
            menu: item
          }
        } else {
          return {
            isHightlight: false,
            menu: item
          }
        }
      })
      return listMenu.length ? listMenu : []
    },
    specialList () {
      const listMenu = JSON.parse(JSON.stringify((this.menuColumnLists)))
      listMenu.forEach((item, index) => {
        // filter get special
        const specialItem = item.menu.filter(h => h.menuType === 'Special')
        if (specialItem.length) {
          listMenu[index].menu = specialItem
        } else {
          listMenu[index].menu = []
        }
      })
      return listMenu
    }
  },
  methods: {
    attr (menu) {
      return setAttributeLink(menu)
    },
    filterColumn (menuList) {
      const column = {
        column1: [],
        column2: [],
        column3: [],
        column4: []
      }
      menuList.forEach((item, index) => {
        if (item.column === 1) {
          column.column1.push(item)
        } else if (item.column === 2) {
          column.column2.push(item)
        } else if (item.column === 3) {
          column.column3.push(item)
        } else if (item.column === 4) {
          column.column4.push(item)
        }
      })
      // console.log('before:', column)
      for (const propName in column) {
        if (column[propName].length === 0) {
          delete column[propName]
        }
      }
      // console.log('after:', column)
      return column
    },
    handleClickItem (item) {
      if (item && item.url !== '' && item.url !== '#') {
        let title = ''
        if (this.menuParent && this.menuParent.name) {
          title = this.menuParent.name
        }
        if (title) {
          title = `${title},`
        }
        if (item) {
          title = `${title}${item.name}`
        }
        this.$personalized.clickElement({
          name: 'all15',
          optionLang: {
            menu: title
          }
        })
      }
    }
  }
}
