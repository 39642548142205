//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { setAttributeLink } from '@/helpers'
export default {
  name: 'FooterAccordionMenuMobile',
  props: {
    menuList: {
      type: Array,
      default: null
    }
  },
  serverCacheKey: props => `FooterAccordionMenuMobile-${JSON.stringify(props.menuList)}`,
  data () {
    return {
      accordionId: 'footerAccordionMenuMobile'
      // locationCode: {
      //   setWeblinkFooter: 'M_WEBLINK_FOOTER',
      //   interrestingWeblinkFooter: 'M_INTERRESTING_FOOTER',
      //   sitemapFooter: 'M_SITEMAP_FOOTER'
      // }
    }
  },
  computed: {
    menuItems () {
      // const itemList = []
      // const setWeblinkFooter = this.$content.getLayoutMenuLocation(this.locationCode.setWeblinkFooter) || []
      // const interrestingWeblinkFooter = this.$content.getLayoutMenuLocation(this.locationCode.interrestingWeblinkFooter) || []
      // const sitemapFooter = this.$content.getLayoutMenuLocation(this.locationCode.sitemapFooter) || []
      // if (setWeblinkFooter && setWeblinkFooter.menuItems && setWeblinkFooter.menuItems.length) {
      //   itemList.push({ name: 'เว็บไซต์ในกลุ่มตลาดหลักทรัพย์ฯ', subMenus: setWeblinkFooter?.menuItems })
      // }
      // if (interrestingWeblinkFooter && interrestingWeblinkFooter.menuItems && interrestingWeblinkFooter.menuItems.length) {
      //   itemList.push({ name: 'เว็บไซต์น่าสนใจ', subMenus: interrestingWeblinkFooter?.menuItems })
      // }
      // if (sitemapFooter && sitemapFooter.menuItems && sitemapFooter.menuItems.length) {
      //   itemList.push({ name: 'แผนผังเว็บไซต์', subMenus: sitemapFooter?.menuItems })
      // }
      // return itemList
      return this.menuList || []
    }
  },
  methods: {
    setAttributeLink,
    handleClickTrackingSetGroup (arrTitle) {
      this.$personalized.clickElement({
        name: 'all18',
        optionLang: {
          menu: arrTitle.join(', ')
        }
      })
    },
    handleClickTrackingOtherLinks (arrTitle) {
      this.$personalized.clickElement({
        name: 'all19',
        optionLang: {
          menu: arrTitle.join(', ')
        }
      })
    },
    handleClickTrackingSiteMap (arrTitle) {
      this.$personalized.clickElement({
        name: 'all20',
        optionLang: {
          menu: arrTitle.join(', ')
        }
      })
    }
  }
}
