//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AppBarNav',
  // serverCacheKey: () => 'AppBarNav-v1',
  props: {
    lastUpdate: {
      type: Date,
      default: null
    },
    menuList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      // menuList: [
      //   {
      //     menuId: 1,
      //     url: '#',
      //     name: 'ภาพรวมตลาดและผลิตภัณฑ์',
      //     openNewTab: false,
      //     subMenus: []
      //   },
      //   {
      //     menuId: 2,
      //     url: '#',
      //     name: 'บริษัทจดทะเบียน',
      //     openNewTab: false,
      //     subMenus: []
      //   },
      //   {
      //     menuId: 3,
      //     url: '#',
      //     name: 'กฎเกณฑ์และการกำกับ',
      //     openNewTab: false,
      //     subMenus: []
      //   },
      //   {
      //     menuId: 4,
      //     url: '#',
      //     name: 'บริการ',
      //     openNewTab: false,
      //     subMenus: []
      //   },
      //   {
      //     menuId: 5,
      //     url: '#',
      //     name: 'วิจัยและการเรียนรู้',
      //     openNewTab: false,
      //     subMenus: []
      //   },
      //   {
      //     menuId: 6,
      //     url: '#',
      //     name: 'เกี่ยวกับ ตลท.',
      //     openNewTab: false,
      //     subMenus: []
      //   }
      // ]
    }
  },
  mounted () {
    // console.log(this.menuList)
  }
}
