import index from '@/store/market/index'
import stockCalendar from '@/store/market/stockCalendar/index'
export const modules = {
  index,
  stockCalendar
}

const state = () => ({
  marketStatus: null,
  marketInfo: null,
  marketIndexList: null,
  marketIndexInfo: null,
  marketIndexQuote: null,
  marketProductQuote: null,
  stockInfo: null,
  etfInfo: null,
  drInfo: null,
  drxInfo: null,
  dwInfo: null,
  tfexInfo: null
  // activeMarketTab: 'set'
})

const getters = {
  getMarketStatus: (state) => {
    return state.marketStatus
  },
  getMarketInfo: (state) => {
    return state.marketInfo
  },
  getMarketIndexList: (state) => {
    return state.marketIndexList
  },
  getMarketIndexInfo: (state) => {
    return state.marketIndexInfo
  },
  getMarketIndexQuote: (state) => {
    return state.marketIndexQuote
  },
  getMarketProductQuote: (state) => {
    return state.marketProductQuote
  },
  getStockInfo: (state) => {
    return state.stockInfo
  },
  getEtfInfo: (state) => {
    return state.etfInfo
  },
  getDrInfo: (state) => {
    return state.drInfo
  },
  getDrxInfo: (state) => {
    return state.drxInfo
  },
  getDwInfo: (state) => {
    return state.dwInfo
  },
  getTfexInfo: (state) => {
    return state.tfexInfo
  }
  // getActiveMarketTab: (state) => {
  //   return state.activeMarketTab
  // }
}

const actions = {
  async fetchMarketStatus ({ commit }, payload) {
    const { market } = payload
    try {
      const response = await this.$axios.$get(`api/set/market/${market}/marketstatus`)
      commit('setMarketStatus', response)
    } catch (err) {
      commit('setMarketStatus', null)
    }
  },

  async fetchMarketInfo ({ commit }, payload) {
    const market = payload.index.toUpperCase()
    await this.$axios.get(`api/set/${market}/market/info`).then((res) => {
      commit('setMarketInfo', res.data)
    }).catch(err => err)
  },

  async fetchMarketIndexList ({ commit }, payload) {
    const market = payload.index.toUpperCase()
    let apiPath = `api/set/${payload.index}/indices`

    if (market === 'SET') {
      apiPath = `api/set/market/${market}/index-list`
    } else if (market === 'FTSE-SET' || market === 'FTSE-ASEAN') {
      apiPath = `api/set/${payload.index}/indices`
    } else {
      apiPath = `api/set/${payload.index}/gms/indices`
    }

    await this.$axios.get(apiPath).then((res) => {
      commit('setMarketIndexList', res.data)
    }).catch(err => err)
  },

  async fetchMarketIndexInfoList ({ commit }, payload) {
    const index = payload.index
    const apiPath = `api/set/index/${index}/info`

    await this.$axios.get(apiPath).then((res) => {
      commit('setMarketIndexInfo', res.data)
    }).catch(err => err)
  },

  async fetchMarketIndexQuote ({ commit }, payload) {
    const index = payload.index
    const res = await this.$axios.get(`api/set/index/${index}/chart-quotation?period=1D`)
    commit('setMarketIndexQuote', res.data)
    return res
  },
  async fetchMarketProductQuote ({ commit }, payload) {
    const symbol = payload.symbol || null
    const product = payload.product || null
    // let returnData = null
    let apipath = (symbol != null && symbol !== undefined) && (product != null && product !== undefined) ? `api/set/stock/${symbol}/chart-quotation?period=1D` : null

    if (product === 'tfex') {
      apipath = (symbol != null && symbol !== undefined) && (product != null && product !== undefined) ? `api/set/tfex/series/${symbol}/chart-quotation?period=1D` : null
    }

    if (apipath != null) {
      await this.$axios.get(apipath).then((res) => {
        commit('setMarketProductQuote', res.data)
        // returnData = res.data
        // return res.data
      }).catch(err => err)
    } else {
      commit('setMarketProductQuote', null)
    }

    // return returnData
  },

  async fetchStockInfo ({ commit }) {
    await this.$axios.get('api/set/ranking/mostActiveValue/SET/S?count=3').then((res) => {
      commit('setStockInfo', res.data)
    }).catch(err => err)
  },

  async fetchEtfInfo ({ commit }) {
    await this.$axios.get('api/set/ranking/mostActiveValue/SET/L?count=3').then((res) => {
      commit('setEtfInfo', res.data)
    }).catch(err => err)
  },

  async fetchDrInfo ({ commit }) {
    await this.$axios.get('api/set/ranking/mostActiveValue/SET/X?count=3').then((res) => {
      commit('setDrInfo', res.data)
    }).catch(err => err)
  },

  async fetchDrxInfo ({ commit }) {
    // await this.$axios.get('api/set/ranking/mostActiveValue/SET/XF?count=3').then((res) => {
    await this.$axios.get('api/set/drx/top-ranking/mostActiveValue').then((res) => {
      commit('setDrxInfo', res.data)
    }).catch(err => err)
  },

  async fetchDwInfo ({ commit }) {
    await this.$axios.get('api/set/ranking/mostActiveValue/SET/V?count=3').then((res) => {
      commit('setDwInfo', res.data)
    }).catch(err => err)
  },

  async fetchTfexInfo ({ commit }) {
    await this.$axios.get('api/set/tfex/market/mostactive?limit=3').then((res) => {
      commit('setTfexInfo', res.data)
    }).catch(err => err)
  },

  async fetchTfexList ({ commit }) {
    const datas = await this.$axios.get('api/set/tfex/series/list').then((res) => {
      return res.data.series
    })

    return datas
  }

  // changeActiveMarketTab ({ commit }, tabName) {
  //   commit('setActiveMarketTab', tabName)
  // }
}

const mutations = {
  setMarketStatus (state, data) {
    state.marketStatus = data
  },
  setMarketInfo (state, data) {
    state.marketInfo = data
  },
  setMarketIndexList (state, data) {
    state.marketIndexList = data
  },
  setMarketIndexInfo (state, data) {
    state.marketIndexInfo = data
  },
  setMarketIndexQuote (state, data) {
    state.marketIndexQuote = data
  },
  setMarketProductQuote (state, data) {
    state.marketProductQuote = data
  },
  setStockInfo (state, data) {
    state.stockInfo = data
  },
  setEtfInfo (state, data) {
    state.etfInfo = data
  },
  setDrInfo (state, data) {
    state.drInfo = data
  },
  setDrxInfo (state, data) {
    state.drxInfo = data
  },
  setDwInfo (state, data) {
    state.dwInfo = data
  },
  setTfexInfo (state, data) {
    state.tfexInfo = data
  }
  // setActiveMarketTab (state, data) {
  //   state.activeMarketTab = data
  // }
}

export default {
  state,
  actions,
  mutations,
  getters
}
