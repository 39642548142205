import { render, staticRenderFns } from "./SubMenu.vue?vue&type=template&id=3914df68&"
import script from "./SubMenu.vue?vue&type=script&lang=js&"
export * from "./SubMenu.vue?vue&type=script&lang=js&"
import style0 from "./SubMenu.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SubMenuCard: require('/app/components/Menu/SubMenu/Card.vue').default,SubMenuItem: require('/app/components/Menu/SubMenu/Item.vue').default,SubMenuSpecial: require('/app/components/Menu/SubMenu/Special.vue').default})
