/**
 * State
 */
const state = () => ({
  annoucement: {
    procurement: [],
    publicRelation: [],
    data: []
  },
  newsOption: {
    source: [],
    types: [],
    securities: [],
    securitiesBtn: []
  },
  marketAlertOption: {
    types: []
  },
  news: null,
  marketAlert: null,
  newsDetails: null,
  marketClarification: null,
  stockList: null
})

/**
 * Mutations
 */
const mutations = {
  setNewsSourceOption (state, data) {
    if (data) {
      const securityType = data.map((item, index) => {
        return {
          ...item,
          name: index === 0 ? '' : this.$i18n.locale === 'th' ? item.nameTh : item.nameEn,
          symbols: index === 0 ? [this.$i18n.locale === 'th' ? item.nameTh : item.nameEn] : item.symbols
        }
      })
      state.newsOption.source = [{
        id: '',
        name: '',
        nameTh: 'ทั้งหมด',
        nameEn: 'All',
        symbols: [this.$i18n.t('news.allNews.all')]
      }, ...securityType]
    }
  },
  setNewsTypesOption (state, data) {
    if (data) {
      const newData = [{ typeId: 0, typeNameTh: 'ทั้งหมด', typeNameEn: 'All' }, ...data.types]
      state.newsOption.types = newData.map((item) => {
        return {
          name: this.$i18n.locale === 'th' ? item.typeNameTh : item.typeNameEn,
          value: item.typeId,
          ...item
        }
      })
    }
  },
  setNewsSecuritiesOption (state, data) {
    if (data) {
      const defaultQuote = ['หุ้น', 'ETF', 'DR', 'DRX', 'DW']
      const securityTypeBtn = data.filter(e => defaultQuote.includes(e.nameTh.toUpperCase()))
      const securityType = data.filter(e => e.type === 'securityType' && !defaultQuote.includes(e.nameTh.toUpperCase()))
      const market = data.filter(e => e.type === 'market' && !defaultQuote.includes(e.nameTh.toUpperCase()))
      state.newsOption.securitiesBtn = securityTypeBtn.map((item) => {
        return {
          ...item,
          active: false
        }
      })
      state.newsOption.securities = [
        {
          id: 'all',
          name: '',
          symbols: [{
            id: '',
            nameTh: 'ทั้งหมด',
            nameEn: 'All',
            type: ''
          }]
        },
        {
          id: 'market',
          name: this.$i18n.t('news.allNews.market'),
          symbols: [...market]
        }, {
          id: 'security',
          name: this.$i18n.t('news.allNews.securities'),
          symbols: [...securityType]
        }
      ]
    }
  },
  setMarketAlertTypeOption (state, data) {
    if (data) {
      // const newData = [{ typeId: 0, typeNameTh: 'ทั้งหมด', typeNameEn: 'All' }, ...data.types]
      state.marketAlertOption.types = data.types.map((item) => {
        return {
          ...item,
          label: this.$i18n.locale === 'th' ? item.typeNameTh : item.typeNameEn,
          key: item.typeId,
          checked: true,
          disabled: false
        }
      })
    }
  },
  setSearchMarketAlertNews (state, data) {
    if (data) {
      state.marketAlert = data
    }
  },
  setSearchNews (state, data) {
    if (data) {
      state.news = data
    }
  },
  setNewsDetail (state, data) {
    if (data) {
      state.newsDetails = data
    }
  },
  setStockList (state, data) {
    if (data) {
      state.stockList = data
    }
  },
  setMarketClarification (state, data) {
    state.marketClarification = data
  },
  setHomeAnnoucement (state, [procurementData, publicData]) {
    const procure = []
    const pulbicR = []
    let pSet = []
    let prSet = []
    const allData = []
    Array.prototype.push.apply(allData, publicData)
    Array.prototype.push.apply(allData, procurementData)
    procurementData?.forEach((item, index) => {
      pSet.push(item)
      if ((index + 1) % 3 === 0 || (index + 1) === procurementData.length) {
        procure.push(pSet)
        pSet = []
      }
    })
    publicData?.forEach((item, index) => {
      prSet.push(item)
      if ((index + 1) % 3 === 0 || (index + 1) === publicData.length) {
        pulbicR.push(prSet)
        prSet = []
      }
    })
    state.annoucement.procurement = procure
    state.annoucement.publicRelation = pulbicR
    state.annoucement.data = allData
  }
}

/**
 * Actions
 */
const actions = {
  async getNewsSourceOption ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/news/source')
      commit('setNewsSourceOption', response)
    } catch (err) {
      commit('setNewsSourceOption', null)
    }
  },
  async getNewsTypesOption ({ commit }, payload) {
    try {
      const response = await this.$axios.$get('/api/set/news/type')
      commit('setNewsTypesOption', response)
    } catch (err) {
      commit('setNewsTypesOption', null)
    }
  },
  async getNewsSecuritiesOption ({ commit }, payload) {
    try {
      const response = await this.$axios.$get('/api/set/news/security-type')
      commit('setNewsSecuritiesOption', response)
    } catch (err) {
      commit('setNewsSecuritiesOption', null)
    }
  },
  async getMarketAlertTypeOption ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/news/market-alert/type')
      commit('setMarketAlertTypeOption', response)
    } catch (err) {
      commit('setMarketAlertTypeOption', null)
    }
  },
  async searchNews ({ commit }, payload) {
    const { sourceId, typeIds, securityTypeIds, symbols, keyword, fromDate, toDate, page, perPage, orderBy } = payload
    let url = '/api/cms/v1/news/set?'
    url += sourceId ? `sourceId=${sourceId}&` : ''
    url += typeIds ? `typeId=${typeIds}&` : ''
    url += securityTypeIds ? `securityTypeIds=${securityTypeIds}&` : ''
    // url += symbols && symbols !== this.$i18n.t('news.allNews.companyNews') && symbols !== this.$i18n.t('news.allNews.all') ? `symbols=${encodeURIComponent(symbols)}&` : ''
    // url += keyword ? `keyword=${encodeURIComponent(keyword)}&` : ''
    url += fromDate ? `fromDate=${fromDate}&` : ''
    url += toDate ? `toDate=${toDate}&` : ''
    url += page ? `page=${page}&` : ''
    url += perPage ? `perPage=${perPage}&` : ''
    url += orderBy ? `orderBy=${orderBy}&` : ''
    url += `lang=${this.$i18n.locale}`

    let _symbols = null
    if (
      symbols &&
      symbols !== this.$i18n.t('news.allNews.companyNews') &&
      symbols !== this.$i18n.t('news.allNews.all')
    ) {
      _symbols = symbols
    }
    const options = {
      params: {
        keyword: keyword || null,
        symbols: _symbols
      }
    }
    try {
      const response = await this.$apiCms.get(url, options)
      commit('setSearchNews', response.data)
    } catch (err) {
      commit('setSearchNews', null)
    }
  },
  async searchMarketAlertNews ({ commit }, payload) {
    const { typeIds, symbols, keyword, fromDate, toDate, orderBy } = payload
    const url = '/api/cms/v1/news/set-marketalert'
    const options = {
      params: {
        typeIds: typeIds || null,
        symbols: symbols || null,
        keyword: keyword || null,
        fromDate: fromDate || null,
        toDate: toDate || null,
        orderBy: orderBy || null,
        lang: this.$i18n.locale
      }
    }
    // let url = '/api/cms/v1/news/set-marketalert?'
    // url += typeIds ? `typeIds=${typeIds}&` : ''
    // url += symbols ? `symbols=${symbols}&` : ''
    // url += keyword ? `keyword=${keyword}&` : ''
    // url += fromDate ? `fromDate=${fromDate}&` : ''
    // url += toDate ? `toDate=${toDate}&` : ''
    // url += orderBy ? `orderBy=${orderBy}&` : ''
    // url += `lang=${this.$i18n.locale}`
    try {
      const response = await this.$apiCms.get(url, options)
      commit('setSearchMarketAlertNews', response.data)
    } catch (err) {
      commit('setSearchMarketAlertNews', null)
    }
  },
  async getNewsDetail ({ commit }, payload) {
    const url = `/api/set/news/${payload.id}/detail`
    const options = {
      params: {
        symbol: payload.symbol,
        lang: this.$i18n.locale
      }
    }
    try {
      const response = await this.$axios.$get(url, options)
      commit('setNewsDetail', response)
    } catch (err) {
      commit('setNewsDetail', null)
    }
  },
  async getMarketClarification ({ commit }, payload) {
    const { symbol, datetime, typeId } = payload
    let url = '/api/set/news/clarification?'
    url += symbol ? `symbol=${symbol}&` : ''
    url += datetime ? `datetime=${datetime}&` : ''
    url += typeId ? `typeId=${typeId}&` : ''
    url += `lang=${this.$i18n.locale}`
    try {
      const response = await this.$axios.$get(url)
      commit('setMarketClarification', response)
    } catch (err) {
      commit('setMarketClarification', null)
    }
  },
  async getStockList ({ commit }, payload) {
    try {
      const response = await this.$axios.$get('/api/set/stock/list')
      commit('setStockList', response.securitySymbols)
    } catch (err) {
      commit('setStockList', null)
    }
  },
  async addPopularNews ({ commit }, payload) {
    const apiPath = '/api/cms-w/v1/news/set-news-view'
    const options = {
      method: 'post',
      url: apiPath,
      data: payload,
      headers: {
        'Content-Type': 'application/json'
      }
    }
    await this.$apiCms(options)
      .then(res => res)
      .catch(err => err)
  },
  async addPopularMarketAlertNews ({ commit }, payload) {
    const apiPath = '/api/cms-w/v1/news/set-marketalert-view'
    const options = {
      method: 'post',
      url: apiPath,
      data: payload,
      headers: {
        'Content-Type': 'application/json'
      }
    }
    await this.$apiCms(options)
      .then(res => res)
      .catch(err => err)
  },
  async getHomeAnnoucement ({ commit }, payload) {
    try {
      const procurement = this.$apiCms.get('/api/cms/v1/news/cms/procurement')
      const publicRelation = this.$apiCms.get('/api/cms/v1/news/cms/press-releas')
      const [
        { value: procurementData },
        { value: publicData }
      ] = await Promise.allSettled([
        procurement,
        publicRelation
      ])
      commit('setHomeAnnoucement', [
        procurementData && procurementData.data,
        publicData && publicData.data
      ])
    } catch (error) {
      commit('setHomeAnnoucement', null)
    }
  }
}

const getters = {
  getNewsOption: (state) => {
    return state.newsOption
  },
  getMarketAlertOption: (state) => {
    return state.marketAlertOption
  },
  getMarketAlert: (state) => {
    return state.marketAlert
  },
  getNews: (state) => {
    return state.news
  },
  getNewsDetail: (state) => {
    return state.newsDetails
  },
  getMarketClarification: (state) => {
    return state.marketClarification
  },
  getStockList: (state) => {
    return state.stockList
  },
  getProcurement: (state) => {
    return state.annoucement.procurement
  },
  getPublicRelation: (state) => {
    return state.annoucement.publicRelation
  },
  getPublicAndProcurement: (state) => {
    return state.annoucement.data
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
