/**
 * State
 */
const state = () => ({
  UnderlyingLists: null,
  IssuerList: null,
  YtdPerChangeLists: null,
  resultFilter: [],
  dRxSymbolList: []
})

/**
     * Mutations
     */
const mutations = {
  setDRxUnderlying (state, data) {
    state.UnderlyingLists = data
  },
  setDRxIssuer (state, data) {
    state.IssuerList = data
  },
  setDRxYtdPercentChange (state, data) {
    state.YtdPerChangeLists = data
  },
  setResultFilter (state, data) {
    state.resultFilter = data
  },
  setDRxSymbolList (state, data) {
    if (data) {
      state.dRxSymbolList = data.securitySymbols.map((item) => {
        const { symbol } = item
        return {
          text: symbol
        }
      })
    }
  }
}

/**
     * Actions
     */
const actions = {
  async getDrxDropDownList ({ dispatch }, payload) {
    await Promise.allSettled([
      dispatch('getDRxUnderlying', payload),
      dispatch('getDRxIssuer', payload),
      dispatch('getDRxYtdPercentChange', payload)
    ])
  },
  async getDRxUnderlying ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/drx/search/condition/underlying-class')
      commit('setDRxUnderlying', response)
    } catch (err) {
      commit('setDRxUnderlying', null)
    }
  },
  async getDRxIssuer ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/drx/search/condition/issuers')
      commit('setDRxIssuer', response)
    } catch (err) {
      commit('setDRxIssuer', null)
    }
  },
  async getDRxYtdPercentChange ({ commit }) {
    try {
      const response = await this.$axios.$get('/api/set/drx/search/condition/ytd-percent-change')
      commit('setDRxYtdPercentChange', response)
    } catch (err) {
      commit('setDRxYtdPercentChange', null)
    }
  },
  async getDRxSearch ({ commit }, payload) {
    try {
      const apiPath = '/api/set/drx/search'
      const { symbols, underlyingClassIds, issuerIds, ytdPercentChanges, lang } = payload
      const options = {
        params: {
          symbols, underlyingClassIds, issuerIds, ytdPercentChanges, lang
        }
      }
      const response = await this.$axios.$get(apiPath, options)
      commit('setResultFilter', response)
    } catch (error) {
      commit('setResultFilter', [])
    }
  },
  async getDRxSymbolList ({ commit }) {
    try {
      const response = await this.$axios.$get(
        '/api/set/stock/list?securityType=XF'
      )
      commit('setDRxSymbolList', response)
    } catch (err) {
      commit('setDRxSymbolList', null)
    }
  }
}

/**
     * Getters
     */
const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
