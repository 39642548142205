//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
const { contentRenderType } = require('@/utils')
const { isPageBuilder } = require('@/helpers/content')
export default {
  name: 'LocaleLanguage',
  data () {
    return {
      webLangList: [],
      languageDefault: null,
      fixedLayoutDefault: contentRenderType.fixedLayout,
      dropdownInstance: null
    }
  },
  computed: {
    ...mapGetters({ content: 'contents/getContentData' }),
    contentInfoRenderType () {
      const { info } = this.content
      return info.renderType ?? ''
    },
    contentInfo () {
      const { info } = this.content
      return info ?? {}
    },
    isPageBuilder () {
      const { renderType, pageType } = this.contentInfo
      return isPageBuilder({ renderType, pageType })
    },
    isFixedLayout () {
      return contentRenderType.fixedLayout === this.contentInfoRenderType
    }
  },
  async created () {
    await this.update()
  },
  updated () {
    this.$nextTick(() => {
      if (this.dropdownInstance) { return }
      const { Dropdown } = require('bootstrap')
      const elementDropdown = this.$refs.dropdownLang
      const dropdownInstance = Dropdown.getOrCreateInstance(elementDropdown)
      this.dropdownInstance = dropdownInstance
    })
  },
  methods: {
    async update () {
      this.webLangList = await this.setWebLangList()
      this.setLanguageDefaultObject()
    },
    setLanguageDefaultObject () {
      this.languageDefault = this.webLangList?.find(el => (el.languageCode).toLowerCase() === this.$i18n.locale)
    },
    setWebLangList () {
      const { info } = this.content
      const { isTHOnly } = this.$store.state.contents
      const websiteLanguages = info.websiteLanguages ?? []
      let langList = websiteLanguages?.map((item) => {
        const obj = {
          ...item
        }
        const { languageCode } = obj
        if (this.isPageBuilder === false) {
          obj.url = this.setLanguageURL(languageCode)
        }
        return obj
      })
      if (isTHOnly === true) {
        langList = websiteLanguages?.filter((f) => { return f.languageCode.toUpperCase() === 'TH' })
      }
      return langList
    },
    setLanguageURL (languageCode) {
      const { query, fullPath } = this.$route
      let newPath = this.$nuxt.$router.resolve({
        query: { ...query }
      }).href

      // This for user click on language button
      if (process.client) {
        const { origin, href } = window.location
        const locationHref = href.replace(origin, '')
        if (locationHref !== fullPath) { // check url changed
          newPath = locationHref.replace(`/${this.$i18n.locale}`, '') // replace lang with ''
        }
      }

      const url = this.localePath(newPath, languageCode.toLowerCase())
      return url
    },
    languageFlagIconClass (language) {
      return language !== undefined ? `icon-flag-${(language.languageCode).toLowerCase()}` : ''
    }
  }
}
