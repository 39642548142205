import Vue from 'vue';

import {
  TablePlugin,
  PaginationPlugin,
  ToastPlugin,
  DropdownPlugin
} from 'bootstrap-vue';

Vue.use(TablePlugin);
Vue.use(PaginationPlugin);
Vue.use(ToastPlugin);
Vue.use(DropdownPlugin);
