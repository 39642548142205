import { render, staticRenderFns } from "./home.vue?vue&type=template&id=085c4a10&"
import script from "./home.vue?vue&type=script&lang=js&"
export * from "./home.vue?vue&type=script&lang=js&"
import style0 from "./home.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BannerLocationPushDownTop: require('/app/components/Banner/Location/PushDownTop.vue').default,SmartBanner: require('/app/components/SmartBanner/SmartBanner.vue').default,MarketSummary: require('/app/components/MarketSummary.vue').default,LocaleLanguage: require('/app/components/LocaleLanguage.vue').default,MarketAlertsBarMain: require('/app/components/MarketAlertsBar/Main.vue').default,Header: require('/app/components/Header.vue').default,Footer: require('/app/components/Footer/Footer.vue').default,AdvertisementLightbox: require('/app/components/Advertisement/Lightbox.vue').default,ChatBotZwiz: require('/app/components/ChatBotZwiz/index.vue').default,MyQuote: require('/app/components/MyQuote/MyQuote.vue').default,MiscModal: require('/app/components/Misc/Modal/index.vue').default,Pdpa: require('/app/components/Pdpa/Pdpa.vue').default,IconClose: require('/app/components/Icon/Close.vue').default,Button: require('/app/components/Button/Button.vue').default,MemberFavoriteMessageLimitFollow: require('/app/components/Pages/Member/Favorite/MessageLimitFollow.vue').default,Modal: require('/app/components/Modal/Modal.vue').default})
