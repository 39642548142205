//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { getOffsetTop } from '@/helpers/dom'
export default {
  name: 'MenuItem',
  props: {
    menuItemUuid: {
      type: String,
      default: null
    },
    lastUpdate: {
      type: Date,
      default: null
    },
    link: {
      type: String,
      default: '#'
    },
    title: {
      type: String,
      default: 'Link'
    },
    newTab: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
    menu: {
      type: Array,
      default: Array
    }
  },
  serverCacheKey: props => `MenuItem::${props.menuItemUuid}::${props.lastUpdate?.toISOString()}`,
  data () {
    return {
      isMouseHover: false
    }
  },
  computed: {
    ...mapGetters({
      activeDropDown: 'search/getActiveDropDown'
    }),
    target () {
      if (this.newTab === true) {
        return '_blank'
      }
      return null
    },
    rel () {
      if (this.newTab === true) {
        return 'noreferrer'
      }
      return null
    }
  },
  mounted () {
    this.registerEvent()
  },
  beforeDestroy () {
    this.removeEvent()
  },
  methods: {
    sectionTop () {
      return document.getElementById('sectionTop') || { clientHeight: 0 }
    },
    backDrop () {
      return document.querySelector('header .back-drop')
    },
    getScrollTop () {
      return window.pageYOffset || document.documentElement.scrollTop
    },
    handleClickItem () {
      this.$personalized.clickElement({
        name: 'all15',
        optionLang: {
          menu: this.title
        }
      })
    },
    handleHover () {
      this.isMouseHover = true
      if (this.menu.length) {
        const isShowBD = this.backDrop().classList.contains('show')
        if (isShowBD) { return }
        this.responsiveSubMenu()
        setTimeout(() => {
          this.updateBackdrop()
          this.backDrop().classList.add('show')
        }, 400)
      }
    },
    handleLeave () {
      this.isMouseHover = false
      if (this.menu.length) {
        setTimeout(() => {
          const isShowBD = this.backDrop().classList.contains('show')
          if (isShowBD && !this.activeDropDown) {
            this.backDrop().classList.remove('show')
          }
        }, 400)
      }
    },
    updateBackdrop () {
      const backDrop = this.backDrop()
      if (!backDrop) { return }
      this.backDrop().style.top = this.sectionTop().clientHeight - this.getScrollTop() + 'px'
    },
    registerEvent () {
      window.addEventListener('scroll', this.handleScroll)
    },
    removeEvent () {
      window.removeEventListener('scroll', this.handleScroll)
    },
    handleScroll () {
      this.updateBackdrop()
    },
    responsiveSubMenu () {
      if (this.$device.isDesktop === false) { return }
      const windowHeight = window.innerHeight
      const subMenuContainer = this.$refs.menuItem.getElementsByClassName('sub-menu')[0]
      subMenuContainer.style.maxHeight = 'none'
      const subMenuContainerTop = getOffsetTop(subMenuContainer)
      const subMenuContainerHeight = subMenuContainer?.clientHeight
      const subMenuTotalHeight = (subMenuContainerTop + subMenuContainerHeight)
      const isOverScreen = subMenuTotalHeight > windowHeight

      if (isOverScreen) {
        const maxHeight = subMenuTotalHeight - windowHeight
        subMenuContainer.style.minHeight = 'auto'
        subMenuContainer.style.maxHeight = ((subMenuContainerHeight - maxHeight) - 20) + 'px'
      } else {
        subMenuContainer.style.maxHeight = '43rem'
      }
    }
  }
}
