/**
 * State
 */
const state = () => ({
  investorSummary: [],
  chartSummary: []
})

/**
   * Mutations
   */
const mutations = {
  setInvestorSummary (state, data) {
    state.investorSummary = data
  },
  setChartSummary (state, data) {
    state.chartSummary = data
  }
}

/**
   * Actions
   */
const actions = {
  async getInvestorSummary ({ commit }, payload) {
    await this.$axios.get(`api/set/market/${payload.market}/investor-type-summary`).then((res) => {
      commit('setInvestorSummary', res.data)
    }).catch(() => {
      commit('setInvestorSummary', null)
    })
  },
  async getInvestorChartSummary ({ commit }, payload) {
    await this.$axios.get(`api/set/market/${payload.market}/investor-type-chart?period=1Y`).then((res) => {
      commit('setChartSummary', res.data)
    }).catch(err => err)
  }
}

/**
   * Getters
   */
const getters = {
  getInvestorSummary: (state) => {
    return state.investorSummary
  },
  getInvestorChartSummary: (state) => {
    return state.chartSummary ? state.chartSummary : []
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
