module.exports = {
  env: process.env.NODE_ENV,
  port: process.env.PORT,
  // apiKey: process.env.API_CMS_KEY,
  apiBaseUrl: process.env.API_CMS_HOST,
  apiBaseUrlV1: process.env.API_CMS_ENDPOINT_V1,
  apiMarketUrl: process.env.SET_API_MARKET_DATA,
  apiMarketMockUrl: process.env.SET_API_MARKET_DATA_MOCK,
  websiteChannel: process.env.WEB_CHANNEL
}
