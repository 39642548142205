//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Button',
  props: {
    outline: {
      type: Boolean,
      default: false
    },
    newTab: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // primary, secondary, success, danger, warning, info, light, dark
    color: {
      type: String,
      default: ''
    },
    // sm, md(default), lg
    size: {
      type: String,
      default: ''
    },
    href: {
      type: String,
      default: '#'
    }
  },
  data () {
    return {
      colors: [
        'primary',
        'secondary',
        'success',
        'danger',
        'warning',
        'info',
        'light',
        'dark'
      ],
      sizes: [
        'sm',
        'lg'
      ]
    }
  },
  computed: {
    attr () {
      const target = this.newTab ? '_blank' : null
      const rel = this.newTab ? 'noopener noreferrer' : null
      return {
        target,
        rel
      }
    },
    buttonClass () {
      const calssName = []

      // set size
      if (this.sizes.includes(this.size)) {
        calssName.push('btn-' + this.size)
      }

      // set color
      const outline = this.outline ? '-outline' : ''
      const color = this.color
      if (this.colors.includes(color)) {
        calssName.push('btn' + outline + '-' + color)
      }
      return calssName.join(' ')
    }
  }
}
