//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'IconPlusCircle'
}
