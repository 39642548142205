const state = () => ({
  stockData: null,
  etfData: null,
  dwData: null,
  warrentData: null,
  drData: null,
  drxData: null,
  preferredData: null
})

const actions = {
  async fetchQuotelist ({ commit }, payload) {
    await this.$axios.get(`api/set/stock/list?securityType=${payload.securityType}`).then((res) => {
      const pData = {
        secType: payload.securityType,
        resData: res.data.securitySymbols
      }
      commit('setQuoteData', pData)
      // if (payload.securityType.toLowerCase() === 's') {
      //   commit('setStockData', res.data.securitySymbols)
      // }
      // if (payload.securityType.toLowerCase() === 'l') {
      //   commit('setETFData', res.data.securitySymbols)
      // }
      // if (payload.securityType.toLowerCase() === 'x') {
      //   commit('setDRData', res.data.securitySymbols)
      // }
      // if (payload.securityType.toLowerCase() === 'v') {
      //   commit('setDWData', res.data.securitySymbols)
      // }
      // if (payload.securityType.toLowerCase() === 'p') {
      //   commit('setPreferredData', res.data.securitySymbols)
      // }
      // if (payload.securityType.toLowerCase() === 'drx') {
      //   commit('setDRXData', res.data.securitySymbols)
      // }
      // if (payload.securityType.toLowerCase() === 'ot') {
      //   commit('setOptionData', res.data.securitySymbols)
      // }
    }).catch(err => err)
  }
}

const mutations = {
  setQuoteData (state, data) {
    if (data.secType.toLowerCase() === 's') {
      state.stockData = data.resData
    }

    if (data.secType.toLowerCase() === 'l') {
      state.etfData = data.resData
    }

    if (data.secType.toLowerCase() === 'x') {
      state.drData = data.resData
    }

    if (data.secType.toLowerCase() === 'xf') {
      state.drxData = data.resData
    }

    if (data.secType.toLowerCase() === 'w') {
      state.warrentData = data.resData
    }

    if (data.secType.toLowerCase() === 'v') {
      state.dwData = data.resData
    }

    if (data.secType.toLowerCase() === 'p') {
      state.preferredData = data.resData
    }
  },

  setStockData (state, data) {
    state.stockData = data
  },
  setETFData (state, data) {
    state.etfData = data
  },
  setOptionData (state, data) {
    state.optionData = data
  },
  setDWData (state, data) {
    state.dwData = data
  },
  setWarrentData (state, data) {
    state.warrentData = data
  },
  setDRData (state, data) {
    state.drData = data
  },
  setDRXData (state, data) {
    state.drxData = data
  },
  setPreferredData (state, data) {
    state.preferredData = data
  }
}

const getters = {

  getStockData: (state) => {
    return state.stockData
  },
  getETFData: (state) => {
    return state.etfData
  },
  getDWData: (state) => {
    return state.dwData
  },
  getDRData: (state) => {
    return state.drData
  },
  getDRXData: (state) => {
    return state.drxData
  },
  getPreferredData: (state) => {
    return state.preferredData
  },
  getWarrentData: (state) => {
    return state.warrentData
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
