//
//
//
//
//
//
//
//
//

export default {
  name: 'MarketAlertsBar',
  data () {
    return {
      newsList: []
    }
  },
  async fetch () {
    await this.init()
  },
  methods: {
    async init () {
      this.newsList = await this.getMarketAlertsNews()
    },
    async getMarketAlertsNews () {
      const url = '/api/set/news/market-alert'
      try {
        const { newsInfoList } = await this.$axios.$get(url, {
          params: {
            lang: this.$i18n.locale
          }
        })
        return (newsInfoList ?? []).slice(0, 15)
      } catch (ex) {
        return []
      }
    }
  }
}
