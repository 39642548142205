//
//
//
//
//
//
//
//

export default {
  name: 'IconDWexpire'
}
