//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AdvertisementFloating',
  props: {
    html: {
      type: String,
      default: null
    },
    autoCloseInSec: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      closeInSec: this.autoCloseInSec,
      showAds: false
    }
  },
  computed: {
    banner () {
      return {}
    }
  },
  mounted () {
    this.registerObserver()
  },
  methods: {
    show () {
      const modalFloatingAdsEl = this.$refs?.modalFloatingAds
      if (!modalFloatingAdsEl) {
        return
      }
      setTimeout(() => {
        this.showAds = true
        this.autoClose()
      }, 100)
      /* setTimeout(() => {
        if (modalFloatingAdsEl) {
          this.showAds = true
        }
        this.autoClose()
      }, 1500) // delay 1.5ec
      */
    },
    hide () {
      this.showAds = false
    },
    autoClose () {
      const interval = setInterval(() => {
        this.closeInSec -= 1
        if (this.closeInSec < 1) {
          clearInterval(interval)
          this.hide()
        }
      }, 1000)
    },
    registerObserver () {
      const elementToObserve = this.$refs?.modalFloatingAds
      if (!elementToObserve) { return }
      const observer = new MutationObserver((mutationList) => {
        mutationList.forEach((mutation) => {
          const { addedNodes, type } = mutation
          if (type === 'childList' && addedNodes?.length) {
            this.show()
            observer.disconnect()
          }
        })
      })
      observer.observe(elementToObserve, { subtree: true, childList: true })
    }
  }
}
