/**
 * State
 */
const state = () => ({
  stockOverview: null,
  pricePerformance: null,
  tradingStat: null,
  historical: null,
  balance: null,
  income: null,
  cashFlow: null,
  highlightData: null,
  profile: null,
  financeRatio: null,
  financeGrowth: null,
  financeCashCycle: null,
  financeCapitalMovement: null,
  financeTradingSign: null,
  financeMarketAlert: null,
  relatedProduct: null,
  finanState: null
})

/**
 * Mutations
 */

const mutations = {

  setStockOverview (state, data) {
    state.stockOverview = data
  },
  setStockFactSheetPricePerformance (state, data) {
    state.pricePerformance = data
  },

  setStockFactSheetTradingStat (state, data) {
    state.tradingStat = data
  },

  setStockFactSheetHistorical (state, data) {
    state.historical = data // .slice(0, 6)
  },

  setStockFactSheetFinanState (state, data) {
    state.finanState = data
  },

  setStockFactSheetFinancial (
    state,
    { balanceSheet, incomeStatement, cashFlow }
  ) {
    state.balance = balanceSheet
    state.income = incomeStatement
    state.cashFlow = cashFlow
  },

  setStockFactSheetFinancialRatio (state, data) {
    state.financeRatio = data
  },

  setStockFactSheetFinancialGrowth (state, data) {
    state.financeGrowth = data
  },

  setStockFactSheetFinancialCashCycle (state, data) {
    state.financeCashCycle = data
  },

  setStockFactSheetFinancialCapitalMovement (state, data) {
    state.financeCapitalMovement = data
  },

  setStockFactSheetFinancialTradingSign (state, data) {
    state.financeTradingSign = data
  },

  setStockFactSheetFinancialMarketAlert (state, data) {
    state.financeMarketAlert = data
  },

  setStockFactSheetRelatedProduct (state, data) {
    state.relatedProduct = data
  },

  setStockFactSheetHighlightData (state, data) {
    state.highlightData = data
  },

  setStockFactSheetProfile (state, data) {
    state.profile = data
  },
  setStockFactSheetInfo (state, data) {
    state.info = data
  }
}

/**
 * Actions
 */

const actions = {
  async getFactSheet ({ dispatch }, payload) {
    await Promise.allSettled([
      // dispatch('quote/getStockInfo', payload, { root: true }),
      dispatch('getStockFactSheetPricePerformance', payload),
      dispatch('getStockOverview', payload),
      dispatch('quote/getStockPricePerformanceChart', payload, { root: true }),
      dispatch('quote/getStockIndexList', payload, { root: true }),
      // dispatch('quote/getStockHighlightData', payload, { root: true }),
      dispatch('quote/getStockNewsList', payload, { root: true }),
      // dispatch('quote/getStockCompanyProfile', payload, { root: true }),
      dispatch('getStockFactSheetProfile', payload),
      dispatch('getStockFactSheetHighlightData', payload),
      dispatch('getStockFactSheetTradingStat', payload),
      dispatch('getStockFactSheetHistorical', payload),
      dispatch('getStockFactSheetFinanState', payload),
      dispatch('quote/stock/getStockBoardDirector', payload, { root: true }),
      dispatch('quote/stock/getStockShareholder', payload, { root: true })
    ])
  },
  async getFactSheetFinancial ({ dispatch }, payload) {
    await Promise.allSettled([
      dispatch('getStockFactSheetFinancial', payload),
      dispatch('getStockFactSheetFinancialRatio', payload),
      dispatch('getStockFactSheetFinancialGrowth', payload),
      dispatch('getStockFactSheetFinancialCashCycle', payload),
      dispatch('getStockFactSheetFinancialCapitalMovement', payload),
      dispatch('getStockFactSheetFinancialTradingSign', payload),
      dispatch('getStockFactSheetRelatedProduct', payload),
      dispatch('getStockFactSheetFinancialMarketAlert', payload)
    ])
  },
  async getStockOverview ({ commit, dispatch }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(`/api/set/stock/${quote}/overview?lang=${this.$i18n.locale}`)
      commit('setStockOverview', response)
    } catch (err) {
      commit('setStockOverview', null)
    }
  },

  async getStockFactSheetPricePerformance ({ commit, dispatch }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/factsheet/${quote}/price-performance`, {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setStockFactSheetPricePerformance', response)
    } catch (err) {
      commit('setStockFactSheetPricePerformance', null)
    }
  },

  async getStockFactSheetTradingStat ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/factsheet/${quote}/trading-stat`, {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setStockFactSheetTradingStat', response)
    } catch (err) {
      commit('setStockFactSheetTradingStat', null)
    }
  },

  async getStockFactSheetHistorical ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/corporate-action/historical?caType=XD&lang=${this.$i18n.locale}`
      )
      commit('setStockFactSheetHistorical', response)
    } catch (err) {
      commit('setStockFactSheetHistorical', null)
    }
  },

  async getStockFactSheetFinanState ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/factsheet/${quote}/financialstatement-submission`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setStockFactSheetFinanState', response)
    } catch (err) {
      commit('setStockFactSheetFinanState', null)
    }
  },

  async getStockFactSheetFinancial ({ commit }, payload) {
    const { quote } = payload
    try {
      const balance = this.$axios.$get(
        `/api/set/factsheet/${quote}/financialstatement`,
        {
          params: { accountType: 'balance_sheet', lang: this.$i18n.locale }
        }
      )
      const income = this.$axios.$get(
        `/api/set/factsheet/${quote}/financialstatement`,
        {
          params: {
            accountType: 'income_statement',
            lang: this.$i18n.locale
          }
        }
      )
      const cashflow = this.$axios.$get(
        `/api/set/factsheet/${quote}/financialstatement`,
        {
          params: { accountType: 'cash_flow', lang: this.$i18n.locale }
        }
      )
      const [
        { value: balanceSheet },
        { value: incomeStatement },
        { value: cashFlow }
      ] = await Promise.allSettled([balance, income, cashflow])

      commit('setStockFactSheetFinancial', {
        balanceSheet,
        incomeStatement,
        cashFlow
      })
    } catch (err) {
      commit('setStockFactSheetFinancial', null)
    }
  },

  async getStockFactSheetFinancialRatio ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/factsheet/${quote}/financial-ratio?lang=${this.$i18n.locale}`
      )
      commit('setStockFactSheetFinancialRatio', response)
    } catch (err) {
      commit('setStockFactSheetFinancialRatio', null)
    }
  },

  async getStockFactSheetFinancialGrowth ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set//factsheet/${quote}/financial-growth?lang=${this.$i18n.locale}`
      )
      commit('setStockFactSheetFinancialGrowth', response)
    } catch (err) {
      commit('setStockFactSheetFinancialGrowth', null)
    }
  },

  async getStockFactSheetFinancialCashCycle ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/factsheet/${quote}/cash-cycle?lang=${this.$i18n.locale}`
      )
      commit('setStockFactSheetFinancialCashCycle', response)
    } catch (err) {
      commit('setStockFactSheetFinancialCashCycle', null)
    }
  },

  async getStockFactSheetFinancialCapitalMovement ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/factsheet/${quote}/capital-movement`, {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setStockFactSheetFinancialCapitalMovement', response)
    } catch (err) {
      commit('setStockFactSheetFinancialCapitalMovement', null)
    }
  },

  async getStockFactSheetFinancialTradingSign ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/factsheet/${quote}/trading-sign?lang=${this.$i18n.locale}`
      )
      commit('setStockFactSheetFinancialTradingSign', response)
    } catch (err) {
      commit('setStockFactSheetFinancialTradingSign', null)
    }
  },

  async getStockFactSheetFinancialMarketAlert ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/factsheet/${quote}/market-alert?lang=${this.$i18n.locale}`
      )
      commit('setStockFactSheetFinancialMarketAlert', response)
    } catch (err) {
      commit('setStockFactSheetFinancialMarketAlert', null)
    }
  },

  async getStockFactSheetRelatedProduct ({ commit }, payload) {
    const { quote } = payload
    try {
      const relatedWarrant = this.$axios.$get(
        `/api/set/stock/${quote}/related-product/W`, {
          params: { lang: this.$i18n.locale }
        }
      )
      const relatedTFEX = this.$axios.$get(
        `/api/set/stock/${quote}/related-product/O`, {
          params: { lang: this.$i18n.locale }
        }
      )
      const [
        { value: relatedWarrantData },
        { value: relatedTFEXData }
      ] = await Promise.allSettled([
        relatedWarrant,
        relatedTFEX
      ])

      let relatedProduct = []
      if (relatedWarrantData && relatedWarrantData.relatedProducts) {
        relatedProduct = [...relatedProduct, ...relatedWarrantData.relatedProducts]
      }
      // console.log('relatedTFEX.relatedProducts', relatedTFEXData.relatedProducts.filter(f => f.symbol.toUpperCase() !== quote.toUpperCase()))
      if (relatedTFEXData && relatedTFEXData.relatedProducts) {
        relatedProduct = [...relatedProduct, ...relatedTFEXData.relatedProducts.filter(f => f.symbol.toUpperCase() !== quote.toUpperCase() && (f.securityType !== 'F') && (f.securityType !== 'Q'))]
      }
      commit('setStockFactSheetRelatedProduct', relatedProduct)
    } catch (err) {
      commit('setStockFactSheetRelatedProduct', null)
    }
  },

  async getStockFactSheetHighlightData ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/factsheet/${quote}/highlight-data`, {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setStockFactSheetHighlightData', response)
    } catch (err) {
      commit('setStockFactSheetHighlightData', null)
    }
  },

  async getStockFactSheetProfile ({ commit, dispatch }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/factsheet/${quote}/profile`, {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setStockFactSheetProfile', response)
    } catch (err) {
      commit('setStockFactSheetProfile', null)
    }
  }
}

/**
 * Getters
 */
const getters = {
  getFactSheet: (state) => {
    return state
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
