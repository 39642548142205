const getDefaultState = () => {
  return {
    setYears: [],
    setHolidayPerYears: []
  }
}

const state = getDefaultState

const mutations = {
  setYears (state, data) {
    state.setYears = data
  },
  setHolidayPerYears (state, data) {
    state.setHolidayPerYears = data
  }
}

const actions = {
  async getYears ({ commit }) {
    try {
      const response = await this.$axios.$get(
        'api/set/holiday/display-year'
      )
      commit('setYears', response)
    } catch {
      commit('setYears', null)
    }
  },
  async getHolidayPerYears ({ commit }, payload) {
    try {
      const response = await this.$axios.$get(
        'api/set/holiday/year/' + payload + '?lang=' + this.$i18n.locale
      )
      commit('setHolidayPerYears', response)
    } catch {
      commit('setHolidayPerYears', null)
    }
  }
}

const getters = {
  getYears: (state) => {
    return state.setYears
  },
  getHolidayPerYears: (state) => {
    return state.setHolidayPerYears
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
