/**
 * State
 */
const state = () => ({
  companyProfile: null,
  price: {
    highlightData: null,
    industry: null,
    chartOutstanding: null,
    chartMarketCap: null
  },
  realty: {
    oneReport: [],
    annualReport: [],
    form56Report: [],
    shareholder: null,
    drShareholder: []
  }
})

/**
 * Mutations
 */
const mutations = {
  /* CompanyPrice - Price */

  setStockHighlightData (state, data) {
    state.price.highlightData = data
  },
  setStockIndustry (state, data) {
    state.price.industry = data
  },

  /* CompanyPrice - News */

  setStockUpcomingEvent (state, data) {
    state.price.upcomingEvent = data
  },

  /* CompanyProfile */

  setStockOneReport (state, data) {
    state.realty.oneReport = data
  },
  setStockAnnualReport (state, data) {
    state.realty.annualReport = data
  },
  setStockForm56Report (state, data) {
    state.realty.form56Report = data
  },
  setStockShareholder (state, data) {
    state.realty.shareholder = data
  },
  setStockDrCompanyProfile (state, data) {
    state.companyProfile = data
  },
  setStockDrIssuerNews (state, data) {
    state.issuerNews = data
  },
  setDrChartMarketCap (state, data) {
    state.price.chartMarketCap = data
  },
  setDrChartOutStanding (state, data) {
    state.price.chartOutstanding = data
  },
  setDrShareholder (state, data) {
    state.realty.drShareholder = data
  }
}

/**
 * Actions
 */
const actions = {
  async searchSymbol ({ dispatch }, payload) {
    await Promise.allSettled([
      dispatch('quote/getStockIntradayChart', payload, { root: true }),
      dispatch('quote/getStockIndexList', payload, { root: true }),
      dispatch('getDrChartMarketCap', payload),
      dispatch('getDrChartOutStanding', payload),
      dispatch('quote/getStockHighlightData', payload, { root: true }),
      dispatch('quote/getStockNewsList', payload, { root: true }),
      dispatch('quote/getStockIssuerNews', payload, { root: true }),
      dispatch('quote/getStockCompanyProfile', payload, { root: true }),
      dispatch('quote/getStockHistoricalTrading', payload, { root: true }),
      dispatch('getStockDrCompanyProfile', payload),
      dispatch('getDrShareholder', payload)
    ])
  },

  /* CompanyProfile */

  async getStockDrCompanyProfile ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/dr/${quote}/profile`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setStockDrCompanyProfile', response)
    } catch (err) {
      commit('setStockDrCompanyProfile', null)
    }
  },

  async getDrChartOutStanding ({ commit }, payload) {
    const { quote, importantPeriod } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/dr/${quote}/chart-outstanding?period=${importantPeriod}&lang=${this.$i18n.locale}`
      )
      commit('setDrChartOutStanding', response)
    } catch (err) {
      commit('setDrChartOutStanding', null)
    }
  },

  async getDrChartMarketCap ({ commit }, payload) {
    const { quote, importantPeriod } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/dr/${quote}/chart-market-cap?period=${importantPeriod}&lang=${this.$i18n.locale}`
      )
      commit('setDrChartMarketCap', response)
    } catch (err) {
      commit('setDrChartMarketCap', null)
    }
  },

  async getDrShareholder ({ commit }, payload) {
    const { quote } = payload
    try {
      const response = await this.$axios.$get(
        `/api/set/stock/${quote}/shareholder`,
        {
          params: { lang: this.$i18n.locale }
        }
      )
      commit('setDrShareholder', response)
    } catch (err) {
      commit('setDrShareholder', null)
    }
  }
}

/**
 * Getters
 */
const getters = {
  /* CompanyPrice - Price */

  getStockHighlightData: (state) => {
    return state.price.highlightData
  },
  getStockDrChartOutStanding: (state) => {
    return state.price.chartOutstanding || []
  },
  getStockDrChartMarketCap: (state) => {
    return state.price.chartMarketCap || []
  },

  /* CompanyProfile */
  getStockDrCompanyProfile: (state) => {
    return state.companyProfile
  },
  getDrShareholder: (state) => {
    return state.realty.drShareholder || []
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
