//
//
//
//
//
//

export default {
  name: 'MyQuoteFavorite',
  props: {
    myFavoriteQuote: {
      type: Array,
      default: Array
    }
  },
  data () {
    return {
      myQuoteList: [{
        id: '1',
        symbol: 'PTT',
        last: '39.75',
        change: 0.75,
        percentChange: 1.68
      }]
    }
  },
  methods: {
    sendTypeQuote (value) {
      this.$emit('typeQuote', value)
    }
  }
}
