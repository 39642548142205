//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
export default {
  name: 'SmartBanner',
  data () {
    return {
      appId: '',
      link: '#',
      mixins: {
        ios: {
          appMeta: 'apple-itunes-app',
          iconRels: ['apple-touch-icon-precomposed', 'apple-touch-icon']
        },
        android: {
          appMeta: 'google-play-app',
          iconRels: [
            'android-touch-icon',
            'apple-touch-icon-precomposed',
            'apple-touch-icon'
          ]
        },
        windows: {
          appMeta: 'msApplication-ID',
          iconRels: [
            'windows-touch-icon',
            'apple-touch-icon-precomposed',
            'apple-touch-icon'
          ]
        }
      },
      option: {
        daysHidden: 15, // days to hide banner after close button is clicked (defaults to 15)
        daysReminder: 30, // days to hide banner after "VIEW" button is clicked (defaults to 90)
        appStoreLanguage: this.$i18n.locale, // language code for the App Store (defaults to user's browser language)
        title: 'SET Application',
        author: 'The Stock Exchange of Thailand',
        button: 'VIEW',
        store: {
          ios: 'On the App Store',
          android: 'In Google Play',
          windows: 'In the Windows Store'
        },
        price: {
          ios: 'FREE',
          android: 'FREE',
          windows: 'FREE'
        },
        icon: '' // 'https://www.settrade.com/settrade/assets/images/set-app-logo.png' // full path to icon image if not using website icon image
      }
    }
  },
  computed: {
    ...mapGetters({ dataContent: 'contents/getContentData' }),
    websiteUrl () {
      const { url } = this.dataContent.info || {}
      return url
    },
    appSuggest () {
      const { appSuggest } = this.dataContent.head || {}
      return appSuggest || undefined
    },
    theme () {
      let type = ''
      if (this.$device.isAndroid) {
        type = 'android'
      } else if (this.$device.isIos) {
        type = 'ios'
      } else if (this.$device.isWindows) {
        type = 'windows'
      }
      return type
    }
  },
  created () {
    this.setAppLogo()
  },
  mounted () {
    // Don't show banner on ANY of the following conditions:
    // - device os is not supported,
    // - user is on mobile safari for ios 6 or greater (iOS >= 6 has native support for SmartAppBanner)
    // - running on standalone mode
    // - user dismissed banner
    const preview = this.$route.query.mode === 'preview' && this.$route.query.device === 'mobile'
    const unsupported = !this.theme || !this.option.store[this.theme] || (!this.$device.isMobile && !preview)
    if (unsupported) {
      return
    }
    this.appMeta = this.mixins[this.theme].appMeta
    this.parseAppId()
    const isMobileSafari =
    this.theme === 'ios' &&
    this.$ua.browser() === 'Safari' &&
    parseInt(this.$ua.osVersion(), 10) >= 6
    const isInWebAppiOS = (window.navigator.standalone === true)
    const isInWebAppChrome = (window.matchMedia('(display-mode: standalone)').matches)
    const runningStandAlone = isInWebAppChrome || isInWebAppiOS
    const userDismissed = this.$cookies.get(escape(this.appId) + '-smartbanner-closed')
    const userInstalled = this.$cookies.get(escape(this.appId) + '-smartbanner-installed')
    if (isMobileSafari || runningStandAlone || userDismissed || userInstalled) {
      return
    }
    // - If we dont have app id in meta, dont display the banner
    // - If opened in safari IOS, dont display the banner
    if (
      !this.appId &&
    this.$ua.os() === 'IOS' &&
    this.$ua.browser() === 'Safari'
    ) {
      return
    }

    if (this.theme === 'android') {
      this.link = 'http://play.google.com/store/apps/details?id=' + this.appId
    } else if (this.theme === 'ios') {
      this.link = 'https://itunes.apple.com/' + this.option.appStoreLanguage + '/app/id' + this.appId + '?mt=8'
    } else if (this.theme === 'windows') {
      this.link = 'http://www.windowsphone.com/s?appid=' + this.appId
    }

    this.show()
  },
  methods: {
    setAppLogo () {
      // this.option.icon = `${this.websiteUrl}/images/app/app-logo.png`
      this.option.icon = '/images/app/app-logo.png'
    },
    hide () {
      const smartbanner = document.getElementById('smartbanner')
      smartbanner.classList.remove('smartbanner-show')
    },
    show () {
      const smartbanner = document.getElementById('smartbanner')
      smartbanner.classList.add('smartbanner-show')
    },
    close (days, type) {
      this.hide()
      const Name = escape(this.appId) + (type === 'daysHidden' ? '-smartbanner-closed' : '-smartbanner-installed')
      this.$cookies.set(Name, true, {
        path: '/',
        expires: new Date(Number(new Date()) + days * 1000 * 60 * 60 * 24)
      })
    },
    parseAppId () {
      const meta = document.querySelector('meta[name="' + this.mixins[this.theme].appMeta + '"]')
      if (!meta && this.appSuggest === undefined) {
        return
      }
      if (meta) {
        if (this.theme === 'windows') {
          this.appId = meta.getAttribute('content')
        } else {
          this.appId = /app-id=([^\s,]+)/.exec(meta.getAttribute('content'))[1]
        }
      } else if (this.appSuggest !== undefined) {
        if (this.theme === 'android' && this.appSuggest.playStore) {
          this.appId = /app-id=([^\s,]+)/.exec(this.appSuggest.playStore)[1]
        } else if (this.theme === 'ios' && this.appSuggest.appStore) {
          this.appId = /app-id=([^\s,]+)/.exec(this.appSuggest.appStore)[1]
        }
      }
    }
  }
}
