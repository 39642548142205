/**
 * State
 */
const CONST_TAB = {
  OVERVIEW: 'OVERVIEW',
  STOCK: 'STOCK',
  NEWS: 'NEWS',
  CONTENT: 'CONTENT'
}
const DEFAULT_PAGE_INDEX = 1
const DEFAULT_PAGE_SIZE = 20
const DEFAULT_FILTER_STOCK = 'ทั้งหมด'
const state = () => ({
  groupFilter: [
    {
      name: 'ทั้งหมด',
      active: true,
      count: 0
    }
  ],
  KeyWord: '',
  FilterStock: DEFAULT_FILTER_STOCK,
  ActiveDropDwon: false,
  Hashtag: [
    { text: 'IPO' },
    { text: 'ปันผลหุ้น' },
    { text: 'rulebook' },
    { text: 'งบการเงิน' },
    { text: 'ปฏิทินวันหยุด' },
    { text: 'แบบ56-1' }
  ],
  News: [
    {
      datetime: '2021-12-01T17:40:00+07:00',
      headline:
        'ขอเชิญผู้ถือหุ้นเสนอระเบียบวาระการประชุมสามัญผู้ถือหุ้นประจำปี 2565 และชื่อกรรมการล่วงหน้า',
      id: 16383150357041,
      isTodayNews: false,
      marketAlertTypeId: null,
      percentPriceChange: null,
      source: 'AS',
      symbol: 'AS',
      tag: '',
      url: 'https://test2.set.or.th/set/newsdetails.do?newsId=16383150357041&language=th&country=TH',
      viewClarification: null
    },
    {
      datetime: '2021-12-01T17:40:00+07:00',
      headline:
        'ขอเชิญผู้ถือหุ้นเสนอระเบียบวาระการประชุมสามัญผู้ถือหุ้นประจำปี 2565 และชื่อกรรมการล่วงหน้า',
      id: 16383150357041,
      isTodayNews: false,
      marketAlertTypeId: null,
      percentPriceChange: null,
      source: 'AS',
      symbol: 'AS',
      tag: '',
      url: 'https://test2.set.or.th/set/newsdetails.do?newsId=16383150357041&language=th&country=TH',
      viewClarification: null
    },
    {
      datetime: '2021-12-01T17:40:00+07:00',
      headline:
        'ขอเชิญผู้ถือหุ้นเสนอระเบียบวาระการประชุมสามัญผู้ถือหุ้นประจำปี 2565 และชื่อกรรมการล่วงหน้า',
      id: 16383150357041,
      isTodayNews: false,
      marketAlertTypeId: null,
      percentPriceChange: null,
      source: 'AS',
      symbol: 'AS',
      tag: '',
      url: 'https://test2.set.or.th/set/newsdetails.do?newsId=16383150357041&language=th&country=TH',
      viewClarification: null
    }
  ],
  Stocks: [],
  StocksOverview: [],
  Contents: [],
  StocksList: [],
  NewsList: [],
  ContentsList: [],
  loadingStock: true,
  loadingNews: true,
  loadingContent: true,
  countItem: 0,
  // paginationStock: {
  //   currentPage: 1,
  //   perPage: 20,
  //   totalCount: 0
  // },
  // paginationNews: {
  //   currentPage: 1,
  //   perPage: 20,
  //   totalCount: 0
  // },
  // paginationContent: {
  //   currentPage: 1,
  //   perPage: 20,
  //   totalCount: 0
  // },
  tabSearchResult: [
    {
      id: CONST_TAB.OVERVIEW,
      title: 'searchResultPage.all',
      titlePane: 'overview-tab-pane',
      component: 'SearchResultTabOverview',
      active: true,
      hide: false
    },
    {
      id: CONST_TAB.STOCK,
      title: 'searchResultPage.securities',
      titlePane: 'stock-tab-pane',
      component: 'SearchResultTabStock',
      active: false,
      hide: false
    },
    {
      id: CONST_TAB.NEWS,
      title: 'searchResultPage.news',
      titlePane: 'news-tab-pane',
      component: 'SearchResultTabNews',
      active: false,
      hide: false
    },
    {
      id: CONST_TAB.CONTENT,
      title: 'searchResultPage.content',
      titlePane: 'content-tab-pane',
      component: 'SearchResultTabContent',
      active: false,
      hide: false
    }
  ],
  currentTab: CONST_TAB.OVERVIEW,
  CONST_TAB
})

/**
 * Mutations
 */
const mutations = {
  setState (state, opts) {
    state[opts.key] = opts.data
  },
  setTempKey (state, data) {
    state.tempKey = data
  },
  clearAllList (state, pageIndex) {
    if (pageIndex === 1) {
      state.StocksOverview = []
    }
    state.StocksList = []
    state.NewsList = []
    state.ContentsList = []
  },
  setAllLoading (state, data) {
    state.loadingStock = data
    state.loadingNews = data
    // state.loadingContent = data
  },
  setLoadingStock (state, data) {
    state.loadingStock = data
  },
  setLoadingNews (state, data) {
    state.loadingNews = data
  },
  setLoadingContent (state, data) {
    state.loadingContent = data
  },
  setGroupFilter (state, data) {
    state.groupFilter = data
  },
  setCountItem (state, data) {
    state.countItem = data
  }
}

/**
 * Actions
 */
const actions = {
  setKeyWordSearch ({ commit, dispatch }, payload) {
    commit('setState', { key: 'FilterStock', data: DEFAULT_FILTER_STOCK })
    commit('setState', { key: 'KeyWord', data: payload })
  },
  setActiveDropDown ({ commit }, payload) {
    commit('setState', { key: 'ActiveDropDwon', data: payload })
  },
  rerenderTabs ({ commit, dispatch }) {
    dispatch('getContentByKeyword')
  },
  async getStockList ({ state, commit }, payload) {
    if (!state.Stocks.length) {
      await this.$axios
        .get('/api/set/stock/list')
        .then((res) => {
          if (res && res.data && res.data.securitySymbols.length) {
            commit('setState', {
              key: 'Stocks',
              data: res.data.securitySymbols
            })
          }
        })
        .catch(err => err)
    }
  },
  setCookieRecentSearch ({ commit }, symbol) {
    const { cookiesExpires } = require('@/config/cookies')
    let tempQuote = []
    if (this.$cookies.get('recent-search')) {
      tempQuote = this.$cookies.get('recent-search')
    } else {
      tempQuote = []
    }
    const quoteNow = []
    if (symbol) {
      quoteNow.push(symbol)
    }
    if (tempQuote.length) {
      if (quoteNow.length) {
        const isSame = tempQuote.filter(q => q === quoteNow[0])
        if (isSame.length) {
          const index = tempQuote.indexOf(isSame[0])
          if (index > -1) {
            tempQuote.splice(index, 1)
          }
          tempQuote = [...quoteNow, ...tempQuote]
          // tempQuote.splice(5, 1)
        } else {
          tempQuote = [...quoteNow, ...tempQuote]
          // tempQuote.splice(5, 1)
        }
      }
      this.$cookies.set('recent-search', JSON.stringify(tempQuote), { path: '/', expires: cookiesExpires })
    } else {
      this.$cookies.set('recent-search', JSON.stringify(quoteNow), { path: '/', expires: cookiesExpires })
    }
  },
  async handleSearchKeyword ({ state, dispatch, commit }, payload) {
    const { key, pageIndex, pageSize, filterBy, count } = payload
    const keyword = key.trim().toUpperCase()
    const _pageIndex = pageIndex || DEFAULT_PAGE_INDEX
    const _pageSize = pageSize || DEFAULT_PAGE_SIZE
    const _filterBy = filterBy || state.FilterStock

    const start = (_pageIndex - 1) * _pageSize
    const end = _pageIndex * _pageSize
    if (keyword && keyword !== '') {
      commit('setAllLoading', true)
      commit('clearAllList', _pageIndex)
      // commit('setState', { key: 'ContentsList', data: state.Contents })
      if (!(state.getStockList && length)) {
        await dispatch('getStockList')
      }
      await dispatch('getContentByKeyword')

      const stockOverview = await dispatch('getStockFromQoute', {
        keyword,
        start: 0,
        end: 10,
        count: count || 0,
        filterBy: DEFAULT_FILTER_STOCK
      })

      const newsList = []
      if (stockOverview && stockOverview.length) {
        if (stockOverview.length > 10) {
          stockOverview.length = 10
        }
        for (let i = 0; i < stockOverview.length; i++) {
          if (stockOverview[i].symbol) {
            const news = await dispatch('getNewsFromQoute', stockOverview[i].symbol)
            newsList.push(news)
          }
        }
        await Promise.all(newsList).then((res) => {
          if (res.length) {
            const listNews = res.filter(r => r !== null)
            commit('setState', {
              key: 'NewsList',
              data: listNews
            })
          } else {
            commit('setState', {
              key: 'NewsList',
              data: []
            })
          }
        }).catch((ex) => {
          commit('setState', {
            key: 'NewsList',
            data: []
          })
        })
      }
      const stockList = await dispatch('getStockFromQoute', {
        keyword,
        start,
        end,
        count: count || 0,
        filterBy: _filterBy
      })
      // const newsList = await dispatch('getNewsFromQoute', keyword)
      // const contentList = await dispatch('getContentFromQoute', keyword)
      commit('setState', { key: 'StocksOverview', data: stockOverview })
      commit('setState', { key: 'StocksList', data: stockList })
      // commit('setState', { key: 'NewsList', data: newsList })
      // commit('setState', { key: 'ContentsList', data: state.Contents })
      dispatch('filterGroupQuote', { key, filterBy: _filterBy, stockList })
      commit('setAllLoading', false)
    }
  },
  async getStockFromQoute ({ state, dispatch, commit }, payload) {
    const { keyword, start, end, filterBy, count } = payload
    let listSearch = null
    // const listSearch = state.Stocks.filter(item =>
    //   item.symbol.trim().toUpperCase().startsWith(keyword)
    // )
    if (filterBy === 'ทั้งหมด') {
      listSearch = await state.Stocks.filter(item =>
        item.symbol.trim().toUpperCase().startsWith(keyword)
      )
      if (!listSearch.length) {
        listSearch = await state.Stocks.filter(item =>
          item.nameTH.trim().toUpperCase().includes(keyword)
        )
      }
      if (!listSearch.length) {
        listSearch = await state.Stocks.filter(item =>
          item.nameEN.trim().toUpperCase().includes(keyword)
        )
      }
    } else if (filterBy === 'หุ้น') {
      listSearch = state.Stocks.filter(
        item =>
          item.symbol.trim().toUpperCase().startsWith(keyword) &&
          (item.securityType === 'S' ||
            item.securityType === 'F' ||
            item.securityType === 'P' ||
            item.securityType === 'Q' ||
            item.securityType === 'W' ||
            item.securityType === 'U')
      )
      if (!listSearch.length) {
        listSearch = state.Stocks.filter(
          item =>
            item.nameTH.trim().toUpperCase().includes(keyword) &&
            (item.securityType === 'S' ||
              item.securityType === 'F' ||
              item.securityType === 'P' ||
              item.securityType === 'Q' ||
              item.securityType === 'W' ||
              item.securityType === 'U')
        )
      }
      if (!listSearch.length) {
        listSearch = state.Stocks.filter(
          item =>
            item.nameEN.trim().toUpperCase().includes(keyword) &&
            (item.securityType === 'S' ||
              item.securityType === 'F' ||
              item.securityType === 'P' ||
              item.securityType === 'Q' ||
              item.securityType === 'W' ||
              item.securityType === 'U')
        )
      }
    } else if (filterBy === 'DW') {
      listSearch = state.Stocks.filter(
        item =>
          item.symbol.trim().toUpperCase().startsWith(keyword) &&
          item.securityType === 'V'
      )
      if (!listSearch.length) {
        listSearch = state.Stocks.filter(
          item =>
            item.nameTH.trim().toUpperCase().includes(keyword) &&
            item.securityType === 'V'
        )
      }
      if (!listSearch.length) {
        listSearch = state.Stocks.filter(
          item =>
            item.nameEN.trim().toUpperCase().includes(keyword) &&
            item.securityType === 'V'
        )
      }
    } else if (filterBy === 'ETF') {
      listSearch = state.Stocks.filter(
        item =>
          item.symbol.trim().toUpperCase().startsWith(keyword) &&
          item.securityType === 'L'
      )
      if (!listSearch.length) {
        listSearch = state.Stocks.filter(
          item =>
            item.nameTH.trim().toUpperCase().includes(keyword) &&
            item.securityType === 'L'
        )
      }
      if (!listSearch.length) {
        listSearch = state.Stocks.filter(
          item =>
            item.nameEN.trim().toUpperCase().includes(keyword) &&
            item.securityType === 'L'
        )
      }
    } else if (filterBy === 'DR') {
      listSearch = state.Stocks.filter(
        item =>
          item.symbol.trim().toUpperCase().startsWith(keyword) &&
          item.securityType === 'X'
      )
      if (!listSearch.length) {
        listSearch = state.Stocks.filter(
          item =>
            item.nameTH.trim().toUpperCase().includes(keyword) &&
            item.securityType === 'X'
        )
      }
      if (!listSearch.length) {
        listSearch = state.Stocks.filter(
          item =>
            item.nameEN.trim().toUpperCase().includes(keyword) &&
            item.securityType === 'X'
        )
      }
    } else if (filterBy.toUpperCase() === 'DRX') {
      listSearch = state.Stocks.filter(
        item =>
          item.symbol.trim().toUpperCase().startsWith(keyword) &&
          item.securityType === 'XF'
      )
      if (!listSearch.length) {
        listSearch = state.Stocks.filter(
          item =>
            item.nameTH.trim().toUpperCase().includes(keyword) &&
            item.securityType === 'XF'
        )
      }
      if (!listSearch.length) {
        listSearch = state.Stocks.filter(
          item =>
            item.nameEN.trim().toUpperCase().includes(keyword) &&
            item.securityType === 'XF'
        )
      }
    }
    listSearch = listSearch.filter(
      item => item !== null || item !== undefined
    )
    if ((filterBy !== 'ทั้งหมด' && count <= 0) && (listSearch && listSearch.length === 0)) {
      listSearch = []
    }
    if (listSearch && listSearch.length) {
      const symbolList = []
      for (let i = start; i < end && i < listSearch.length; i++) {
        symbolList.push(listSearch[i].symbol)
      }
      const listSearchInfo = await dispatch('getInfoList', symbolList.join(','))
      if (listSearchInfo) {
        for (let i = start; i < end && i < listSearch.length; i++) {
          // const index = i - start
          if (listSearch[i]) {
            const info = listSearchInfo.find(item =>
              item.symbol.toUpperCase() === listSearch[i].symbol.toUpperCase()
            )
            if (info) {
              listSearch[i] = info
              listSearch[i].market = listSearch[i].marketName
            }
          }
        }
      } else {
        const promiseCollection = []
        for (let i = start; i < end && i < listSearch.length; i++) {
          const info = dispatch('getInfo', listSearch[i].symbol)
          promiseCollection.push(info)
        }
        await Promise.all(promiseCollection).then((res) => {
          for (let i = start; i < end && i < listSearch.length; i++) {
            const index = i - start
            if (res[index] != null) {
              listSearch[i] = res[index]
              listSearch[i].market = listSearch[i].marketName
            }
          }
        })
      }
      dispatch('showTab', CONST_TAB.STOCK)
      return listSearch
    } else {
      dispatch('hideTab', CONST_TAB.STOCK)
      return []
    }
  },
  async getNewsFromQoute ({ state, dispatch, commit }, quote) {
    let result = null
    await this.$axios
      .get(`/api/set/news/${quote}/list?limit=1&lang=${this.$i18n.locale}`)
      .then((res) => {
        if (res.data && res.data.newsInfoList && res.data.newsInfoList.length) {
          result = res.data.newsInfoList[0]
          // commit('setState', {
          //   key: 'NewsList',
          //   data: res.data.newsInfoList
          // })
        }
      })
      .catch(err => err)
    return result
  },
  getContentFromQoute ({ state, dispatch, commit }, quote) {
    return state.Contents
  },
  async getInfo ({ state, commit }, symbol) {
    let result = null
    await this.$axios
      .get(`api/set/stock/${symbol}/info`)
      .then((res) => {
        result = res.data
      })
      .catch(err => err)
    return result
  },
  async getInfoList ({ state, commit }, symbol) {
    let result = null
    const options = {
      params: {
        symbols: symbol
      }
    }
    await this.$axios
      .get('/api/set/stock-info/list-by-symbols', options)
      .then((res) => {
        result = res.data
      })
      .catch(err => err)
    return result
  },
  changeTab ({ state, dispatch, commit }, tab) {
    let tabSearchResult = JSON.parse(JSON.stringify(state.tabSearchResult))
    tabSearchResult = tabSearchResult.map((data) => {
      return {
        ...data,
        active: false
      }
    })
    const tabSearchResultIndex = tabSearchResult.findIndex(
      data => data.id === tab
    )
    if (!tabSearchResult[tabSearchResultIndex].hide) {
      tabSearchResult[tabSearchResultIndex].hide = false
      tabSearchResult[tabSearchResultIndex].active = true
      commit('setState', {
        key: 'currentTab',
        data: tabSearchResult[tabSearchResultIndex].id
      })
      // Refresh on Change Tab
      // commit('setState', { key: 'FilterStock', data: DEFAULT_FILTER_STOCK })
      // dispatch('handleSearchKeyword', { key: state.KeyWord })
      setTimeout(() => {
        commit('setState', { key: 'tabSearchResult', data: tabSearchResult })
      }, 200)
    }
  },
  showTab ({ state, commit }, tab) {
    const tabSearchResult = JSON.parse(JSON.stringify(state.tabSearchResult))
    const tabSearchResultIndex = tabSearchResult.findIndex(
      data => data.id === tab
    )
    tabSearchResult[tabSearchResultIndex].hide = false
    commit('setState', { key: 'tabSearchResult', data: tabSearchResult })
  },
  hideTab ({ state, commit }, tab) {
    let tabSearchResult = JSON.parse(JSON.stringify(state.tabSearchResult))
    const tabSearchResultIndex = tabSearchResult.findIndex(
      data => data.id === tab
    )
    tabSearchResult[tabSearchResultIndex].hide = true
    if (tabSearchResult[tabSearchResultIndex].active) {
      tabSearchResult = tabSearchResult.map((data) => {
        return {
          ...data,
          active: false
        }
      }) // clear Tab Choose
      tabSearchResult[0].active = true // change Tab to OVERVIEW
      commit('setState', {
        key: 'currentTab',
        data: CONST_TAB.OVERVIEW
      })
    }
    commit('setState', { key: 'tabSearchResult', data: tabSearchResult })
  },
  filterGroupQuote ({ state, commit }, payload) {
    const group = {
      stock: `หุ้น (${0})`,
      ETF: `ETF (${0})`,
      DR: `DR (${0})`,
      DRX: `DRx (${0})`,
      DW: `DW (${0})`
    }
    if (payload.filterBy === 'ทั้งหมด') {
      const keyword = payload.key.trim().toUpperCase()
      if (keyword && keyword !== '') {
        // const listSearch = state.Stocks.filter(item =>
        //   item.symbol.trim().toUpperCase().startsWith(keyword)
        // )
        let objectCount = {}
        const listSearch = payload.stockList
        if (listSearch && listSearch.length) {
          let stockSize = 0
          let DWSize = 0
          let ETFSize = 0
          let DRSize = 0
          let DRXSize = 0
          listSearch.forEach((item, index) => {
            if (
              item.securityType === 'S' ||
              item.securityType === 'F' ||
              item.securityType === 'P' ||
              item.securityType === 'Q' ||
              item.securityType === 'W' ||
              item.securityType === 'U'
            ) {
              stockSize++
              group.stock = `หุ้น (${stockSize})`
            } else if (item.securityType === 'V') {
              DWSize++
              group.DW = `DW (${DWSize})`
            } else if (item.securityType === 'L') {
              ETFSize++
              group.ETF = `ETF (${ETFSize})`
            } else if (item.securityType === 'X') {
              DRSize++
              group.DR = `DR (${DRSize})`
            } else if (item.securityType === 'XF') {
              DRXSize++
              group.DRX = `DRx (${DRXSize})`
            }
          })
          objectCount = {
            stock: stockSize,
            DW: DWSize,
            ETF: ETFSize,
            DR: DRSize,
            DRX: DRXSize
          }
          objectCount['ทั้งหมด'] = listSearch.length
          objectCount['หุ้น'] = stockSize
        }
        if (Object.values(group) && Object.values(group).length) {
          commit(
            'setGroupFilter',
            ['ทั้งหมด', ...Object.values(group)].map((item, index) => {
              const value = item.split(' ')[0]
              if (payload.filterBy === value && value === 'ทั้งหมด') {
                return {
                  name: `${value} (${listSearch.length})`,
                  active: true,
                  value,
                  count: objectCount[value]
                }
              } else if (value === 'ทั้งหมด') {
                return {
                  name: `${value} (${listSearch.length})`,
                  active: false,
                  value,
                  count: listSearch.length
                }
              } else if (payload.filterBy === value) {
                return {
                  name: item,
                  active: true,
                  value,
                  count: objectCount[value]
                }
              } else {
                return {
                  name: item,
                  active: false,
                  value,
                  count: objectCount[value]
                }
              }
            })
          )
        }
      }
    } else {
      const groupFilter = state.groupFilter
      commit('setGroupFilter',
        groupFilter.map((item, index) => {
          if (payload.filterBy === item.value) {
            return {
              name: item.name,
              active: true,
              value: item.value,
              count: item.count
            }
          } else {
            return {
              name: item.name,
              active: false,
              value: item.value,
              count: item.count
            }
          }
        })
      )
    }
  },
  searchByGroupFilter ({ state, dispatch, commit }, filter) {
    if (state.KeyWord) {
      commit('setState', { key: 'FilterStock', data: filter.value })
      const filterBy = { key: state.KeyWord, filterBy: filter.value, count: filter.count }
      dispatch('handleSearchKeyword', filterBy)
    }
  },
  setLoadingNews ({ commit }, status) {
    commit('setLoadingNews', status)
  },
  setCountItem ({ commit }, NOItem) {
    commit('setCountItem', NOItem)
  },
  async getContentByKeyword ({ commit, state }, pagination) {
    const keyword = state.KeyWord
    let type = 'PathStructure|ArticleDetail|ManageInfographic|VideoDetail|PodcastDetail|VideoPlaylist|PodcastPlaylist|ELearning|EBookDetail|ManageContent|ResearchSET|News|ExternalPage|MediaCenter|Media|Rulebook'
    if (this.$i18n.locale === 'th') {
      type += '|Glossary'
    }
    // const type = 'PathStructure|ManageContent|News|ExternalPage|MediaCenter|Media'
    // const type = 'MediaCenter|Media'
    // const type = 'PathStructure|ManageContent|News|ExternalPage'
    // const type = 'Rulebook'
    // const type = 'VideoPlaylist|PodcastPlaylist'
    let paging = pagination
    if (!paging) {
      paging = {
        currentPage: 1,
        perPage: 20,
        totalCount: 0
      }
    }
    commit('setState', { key: 'ContentsList', data: [] })
    commit('setState', { key: 'Contents', data: [] })
    commit('setLoadingContent', true)
    try {
      const options = {
        params: {
          keyword
        }
      }
      const res = await this.$apiCms.get(`api/cms/v1/search/all?types=${type}&language=${this.$i18n.locale}&pageIndex=${paging.currentPage - 1}&pageSize=${paging.perPage}&setMemberUserRef=0`,
        options)
      if (res && res.data && res.data.datas && res.data.datas.items && res.data.datas.items.length) {
        const tempItem = []
        tempItem.length = res.data.datas.totalCount
        commit('setState', { key: 'ContentsList', data: tempItem || [] })
      } else {
        commit('setState', { key: 'ContentsList', data: [] })
      }
      commit('setState', { key: 'Contents', data: res.data || [] })
      commit('setLoadingContent', false)
      // setTimeout(() => {
      // }, 2000)
    } catch (err) {
      commit('setState', { key: 'ContentsList', data: [] })
      commit('setState', { key: 'Contents', data: [] })
      commit('setLoadingContent', false)
      // setTimeout(() => {
      // }, 2000)
    }
  }
  // resetPagination (state, payload) {
  //   let { size, tab } = payload
  //   size = size || 0
  //   tab = tab || state.tab
  //   if (tab === CONST_TAB.STOCK) {
  //     this.paginationStock.currentPage = 1
  //     this.paginationStock.totalCount = size
  //   }
  //   if (tab === CONST_TAB.NEWS) {
  //     this.paginationNews.currentPage = 1
  //     this.paginationNews.totalCount = size
  //   }
  //   if (tab === CONST_TAB.CONTENT) {
  //     this.paginationContent.currentPage = 1
  //     this.paginationContent.totalCount = size
  //   }
  // }
}

/**
 * Getters
 */
const getters = {
  getKeyWordSearch: (state) => {
    return state.KeyWord ? state.KeyWord : ''
  },
  getActiveDropDown: (state) => {
    return state.ActiveDropDwon ? state.ActiveDropDwon : false
  },
  getHashtag: (state) => {
    return state.Hashtag ? state.Hashtag : null
  },
  getNews: (state) => {
    return state.News ? state.News : null
  },
  getStocks: (state) => {
    return state.Stocks ? state.Stocks : null
  },
  getContents: (state) => {
    return state.Contents ? state.Contents : null
  },
  getConstTab: (state) => {
    return state.CONST_TAB
  },
  getTabSearchResult: (state) => {
    return state.tabSearchResult ? state.tabSearchResult : []
  },
  getStocksOverview: (state) => {
    return state.StocksOverview ? state.StocksOverview : null
  },
  getStocksList: (state) => {
    return state.StocksList ? state.StocksList : null
  },
  getNewsList: (state) => {
    return state.NewsList ? state.NewsList : null
  },
  getContentsList: (state) => {
    return state.ContentsList ? state.ContentsList : null
  },
  getLoadingStock (state) {
    return state.loadingStock
  },
  getLoadingNews (state) {
    return state.loadingNews
  },
  getLoadingContent (state) {
    return state.loadingContent
  },
  getGroupFilter (state) {
    return state.groupFilter
  },
  getCountItem (state) {
    return state.countItem
  }
  // getPaginationStock (state) {
  //   return state.paginationStock ? state.paginationStock : null
  // },
  // getPaginationNews (state) {
  //   return state.paginationNews ? state.paginationNews : null
  // },
  // getPaginationContent (state) {
  //   return state.paginationContent ? state.paginationContent : null
  // }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
