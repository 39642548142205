const setCookieMyQuote = (cookies, quote) => {
  const _quote = encodeURIComponent(quote)
  const { cookiesExpires } = require('@/config/cookies')
  let tempCookie = []
  const limit = 20
  if (cookies.get('my-quote')) {
    tempCookie = cookies.get('my-quote')
    if (_quote) {
      const isSame = tempCookie.filter(q => q.toUpperCase() === _quote.toUpperCase())
      if (tempCookie && tempCookie.length >= limit && isSame.length === 0) {
        tempCookie.splice(0, 0, _quote)
        tempCookie.splice(limit, 1)
      } else if (isSame.length) {
        const index = tempCookie.indexOf(isSame[0])
        if (index > -1) {
          tempCookie.splice(index, 1)
        }
        tempCookie = [...isSame, ...tempCookie]
        // tempCookie.unshift(_quote)
      } else {
        tempCookie.splice(0, 0, _quote)
      }
    }
  }
  cookies.set('my-quote', JSON.stringify(tempCookie), { path: '/', expires: cookiesExpires })
}
/**
     * Get goToQuote
     * @param {Object} btnQuoteData
     * @exampleParam {  securities: '',symbol: '',securityType: 'S'}
     */
function goToQuote (btnQuoteData) {
  if (
    ((btnQuoteData && btnQuoteData.securityType) || '') === 'S' ||
    ((btnQuoteData && btnQuoteData.securityType) || '') === 'F' ||
    ((btnQuoteData && btnQuoteData.securityType) || '') === 'Q' ||
    ((btnQuoteData && btnQuoteData.securityType) || '') === 'P' ||
    ((btnQuoteData && btnQuoteData.securityType) || '') === 'W' ||
    ((btnQuoteData && btnQuoteData.securityType) || '') === 'U'
  ) {
    // this.router.push(
    //   this.localeRoute({
    //     name: 'market-product-stock-quote-slug-all',
    //     params: {
    //       pathMatch: 'price',
    //       slug: btnQuoteData.securities || btnQuoteData.symbol
    //     }
    //   })
    // )
    const { fullPath } = this.localeRoute({
      name: 'market-product-stock-quote-slug-all',
      params: {
        pathMatch: 'price',
        slug: btnQuoteData.securities || btnQuoteData.symbol
      }
    })
    window.location = fullPath
  } else if (btnQuoteData.securityType === 'V') {
    // this.router.push(
    //   this.localeRoute({
    //     name: 'market-product-dw-quote-slug-all',
    //     params: {
    //       pathMatch: 'price',
    //       slug: btnQuoteData.securities || btnQuoteData.symbol
    //     }
    //   })
    // )

    const { fullPath } = this.localeRoute({
      name: 'market-product-dw-quote-slug-all',
      params: {
        pathMatch: 'price',
        slug: btnQuoteData.securities || btnQuoteData.symbol
      }
    })
    window.location = fullPath
  } else if (btnQuoteData.securityType === 'X') {
    // this.router.push(
    //   this.localeRoute({
    //     name: 'market-product-dr-quote-slug-all',
    //     params: {
    //       pathMatch: 'price',
    //       slug: btnQuoteData.securities || btnQuoteData.symbol
    //     }
    //   })
    // )
    const { fullPath } = this.localeRoute({
      name: 'market-product-dr-quote-slug-all',
      params: {
        pathMatch: 'price',
        slug: btnQuoteData.securities || btnQuoteData.symbol
      }
    })
    window.location = fullPath
  } else if (btnQuoteData.securityType === 'L') {
    // this.router.push(
    //   this.localeRoute({
    //     name: 'market-product-etf-quote-slug-all',
    //     params: {
    //       pathMatch: 'price',
    //       slug: btnQuoteData.securities || btnQuoteData.symbol
    //     }
    //   })
    // )
    const { fullPath } = this.localeRoute({
      name: 'market-product-etf-quote-slug-all',
      params: {
        pathMatch: 'price',
        slug: btnQuoteData.securities || btnQuoteData.symbol
      }
    })
    window.location = fullPath
  } else if (btnQuoteData.securityType === 'underlying') {
    // this.router.push(
    //   this.localeRoute({
    //     name: 'market-product-dw-index-search',
    //     params: { tab: 'search-filter-1' },
    //     query: { symbol: btnQuoteData.securities || btnQuoteData.symbol }
    //   })
    // )
    const { fullPath } = this.localeRoute({
      name: 'market-product-dw-index-search',
      params: { tab: 'search-filter-1' },
      query: { symbol: btnQuoteData.securities || btnQuoteData.symbol }
    })
    window.location = fullPath
  } else if (btnQuoteData.securityType === 'XF') {
    // this.router.push(
    //   this.localeRoute({
    //     name: 'market-product-drx-quote-slug-all',
    //     params: {
    //       pathMatch: 'price',
    //       slug: btnQuoteData.securities || btnQuoteData.symbol
    //     }
    //   })
    // )
    const { fullPath } = this.localeRoute({
      name: 'market-product-drx-quote-slug-all',
      params: {
        pathMatch: 'price',
        slug: btnQuoteData.securities || btnQuoteData.symbol
      }
    })
    window.location = fullPath
  }
}
export { setCookieMyQuote, goToQuote }
