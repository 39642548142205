//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FooterMenu',
  props: {
    menuList: {
      type: Array,
      default: () => []
    }
  },
  serverCacheKey: props => `FooterMenu-${JSON.stringify(props.menuList)}`,
  methods: {
    handleClickTracking (title) {
      this.$personalized.clickElement({
        name: 'all17',
        optionLang: {
          menu: title
        }
      })
    }
  }
}
