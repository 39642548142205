//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FooterSocial',
  props: {
    socialMedia: {
      type: Object,
      default: Object
    }
  },
  serverCacheKey: props => `FooterSocial-${JSON.stringify(props.socialMedia)}`,
  methods: {
    handleClickItem (title) {
      this.$personalized.clickElement({
        name: 'all16',
        optionLang: {
          menu: title
        }
      })
    }
  }
}
